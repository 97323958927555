import React from "react";
import cx from "classnames";

function GalleryHeaderButton(props) {
  const buttonClassName = cx({
    "gallery-header-left-caret": props.type === "left",
    "gallery-header-right-caret": props.type === "right",
    "gallery-header-caret-light": props.colorMode === 1,
    "gallery-header-caret-dark": props.colorMode === 2,
    caret: true,
  });

  const emptyButtonClassName = cx({
    "gallery-header-left-caret": props.type === "left",
    "gallery-header-right-caret": props.type === "right",
    "empty-caret": true,
    caret: true,
  });

  return (
    <>
      {props.showArrows[props.type] ? (
        <button
          onClick={() =>
            props.scroll(
              0.8,
              props.type === "left" ? -1 : 1,
              props.scrollRef?.current?.childNodes[1],
              props.showArrows,
              props.setShowArrows
            )
          }
          className={buttonClassName}
        >
          <img src="/assets/images/icons/left-caret.svg" alt="caret" />
        </button>
      ) : (
        <div className={emptyButtonClassName}></div>
      )}
    </>
  );
}

export default GalleryHeaderButton;
