import React from "react";
import CountryLandingHeader from "./components/Navbar";
import css from "../ads/AdsLanding.module.css";
import Spinner from "views/components/loader/Spinner";
import Testimonies from "./components/Testimonies";
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import Pricing from "./components/Pricing";
import PlatformFeatures from "./components/PlatformFeatures";
import GetInTouch from "./components/GetInTouch";
import FeaturesGroup from "./components/FeaturesGroup";
import Footer from "./components/Footer";
import { detect } from "detect-browser";
import { useLocation } from "react-router-dom";

const features = [
  {
    title: "Facial Recognition",
    image: "/assets/images/country-landing/facial-recognition.png",
    description: `Kwikpic uses blazing-fast facial recognition technology to match, tag, and share photos with every client instantly. Its 99.9% accurate algorithm sorts uploaded photos, and each group member receives their own pics in no time. Best of all, users can use the face recognition feature as many times as they like, for free!`,
    buttons: [
      {
        title: "Try Now",
        link: "/auth/login",
      },
    ],
  },
  {
    title: "Personalized App & Website Solution",
    image: "/assets/images/country-landing/uk/uk-app.png",
    description:
      "Elevate your brand with our white-label website and app services tailored for iOS and Android platforms. Construct a seamless, user-friendly digital experience and increase your brand's visibility and reach. Your vision, our expertise—amplify your digital presence with Kwikpic.",
    buttons: [
      {
        title: "View Sample Website",
        link: "https://locaflash.kwikpic.in",
      },
      {
        title: "View Sample App",
        link:
          detect()?.os == "android" || detect()?.os == "Android OS"
            ? "https://play.google.com/store/apps/details?id=com.locaFlashKwikpic"
            : "https://apps.apple.com/us/app/locaflash/id6466164299",
      },
    ],
  },
  {
    title: "Sell Photographs",
    image: "/assets/images/country-landing/uk/uk-sell.png",
    description:
      "Seamlessly present your work to a vast audience, set your prices, and watch your revenue grow. Monetize your talent and let your art thrive with Kwikpic's AI Photo Sharing platform. Your work, our stage. Elevate your earnings.",
    buttons: [
      {
        title: "Contact Us",
        link: "#contact",
      },
    ],
  },
  {
    title: "Branding & Watermarking",
    image: "/assets/images/country-landing/uk/uk-branding.png",
    description:
      "Kwikpic enables you to prominently display your brand, including your name, logo, and contact details, at every client interaction point, while also offering an automatic watermarking feature. This tool ensures your watermark is added to each photo you upload, enhancing the professional appearance and protection of your work.",
    buttons: [
      {
        title: "Watch Now",
        link: "https://www.youtube.com/watch?v=sZmD1cBuErk",
      },
    ],
  },
  {
    title: "User Analytics",
    image: "/assets/images/country-landing/analytics.png",
    description: `Kwikpic provides user analytics for businesses to improve their success. The tool offers accurate user registration data of your groups, to enhance album security and identify potential leads. Use Kwikpic to take your business to the next level.`,
    buttons: [
      {
        title: "Try Now",
        link: "/auth/login",
      },
    ],
  },
  {
    title: "Album Selection",
    image: "/assets/images/country-landing/album-selection.png",
    description: `Kwikpic's Album Selection feature makes client management a breeze. Your clients can easily select photos by favoriting them and leaving comments. You can then download the list of file names from the client favorite tab, making it simple to keep track of your clients' selections.`,
    buttons: [
      {
        title: "Watch Now",
        link: "https://www.youtube.com/watch?v=oQyaYjVFUeU",
      },
    ],
  },
  {
    title: "Restrict Downloads",
    image: "/assets/images/country-landing/restrict-download.png",
    description: `Kwikpic's download on/off feature lets you control when clients can download your photos. If your client is delaying your payment, use the "download off" setting to temporarily prevent downloads and screenshots until payment is received. Simplify client management and ensure timely payment with Kwikpic.`,
    buttons: [
      {
        title: "Try Now",
        link: "/auth/login",
      },
    ],
  },
  {
    title: "Design to Impress",
    image: "/assets/images/country-landing/design-impress.png",
    description: `Kwikpic provides 50+ gallery designs for photographers to choose from for their clients. Customize layouts, colour modes, fonts, image sizes, and add a cover image with a focal point. The galleries are designed to be responsive across all devices. Personalise your client's experience and showcase your photography in a stunning way.`,
    buttons: [
      {
        title: "Watch Now",
        link: "https://www.youtube.com/watch?v=GaSuQsJfqBQ",
      },
    ],
  },
  {
    title: "Portfolio Website",
    image: "/assets/images/country-landing/uk/uk-portfolio.png",
    description:
      "Kwikpic's portfolio website feature helps photographers showcase their work and attract clients. Display your brand, contact info, services, and pricing. Display your best photographs and organize them into categories. Share your professional portfolio website with clients and boost your online presence to stand out!",
    buttons: [
      {
        title: "Watch Now",
        link: "https://www.youtube.com/watch?v=a0y7HEp1zJM",
      },
    ],
  },
  {
    title: "One Shot Upload",
    image: "/assets/images/country-landing/oneshot.png",
    description: `Kwikpic offers a time-saving One-Shot upload feature that can upload multiple folders and subfolders quickly. Just add the master folder, and Kwikpic will automatically detect the subfolders and upload them for you.`,
    buttons: [
      {
        title: "Try Now",
        link: "/auth/login",
      },
    ],
  },
];

const testimonies = [
  {
    image: "/assets/images/country-landing/br1.png",
    name: "Rahul Kanojia",
    company: "House of Infinity",
    testimony:
      "Sharing photos on Kwikpic with clients has made my work really simple and smart.",
  },
  {
    image: "/assets/images/country-landing/br2.png",
    name: "Chirag Thakkar",
    company: "SAP Studios",
    testimony:
      "It's a very great app to share photos, the best that I have used so far.",
  },
  {
    image: "/assets/images/country-landing/br3.png",
    name: "Yogesh",
    company: "HMV Studios",
    testimony:
      "This app is now a part of my business and helped us a lot with our clients.",
  },
];

const USLanding = () => {
  const location = useLocation();

  React.useEffect(() => {
    let timeout;
    if (location.hash) {
      timeout = setTimeout(() => {
        const element = document.getElementById(location.hash.slice(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [location.hash]);

  return (
    <React.Suspense fallback={<Spinner />}>
      <div className="no-overflow-x">
        <CountryLandingHeader
          country="US"
          home="/us"
          flag="/assets/images/country/us.png"
        />
        <div className={css["ads-page"]}>
          <Hero country="US" />
          <Testimonies testimonies={testimonies} />
          <FeaturesGroup features={features} />
          <AboutUs
            description="Kwikpic is a leading AI-powered photo-sharing platform. With a focus on user-friendliness and unmatched technology, we're redefining the Event photo sharing experience by using instant Face Recognition. Together with a enthusiastic team and partners in crime, our vision is to create a new-age digital album for the users and provide maximum visibility and engagement to businesses."
            team1={{
              name: "Harsh Khaitan",
              title: "Founder, Kwikpic",
              image: "/assets/images/country-landing/uk/team/harsh.png",
            }}
          />
          <Pricing country="us" />
          <GetInTouch
            slackChannel="#us-web-contact-form"
            countryCode="+1"
            sendTrackingEvent={() => {
              window.gtag("event", "conversion", {
                send_to: "AW-413510263/HP41COOsyaQZEPfUlsUB",
              });
            }}
          />
          <PlatformFeatures />
          <Footer />
        </div>
      </div>
    </React.Suspense>
  );
};

export default USLanding;
