import React from "react";
import { Carousel } from "react-bootstrap";
import SponsorBlock from "./SponsorBlock";
import { useSelector } from "react-redux";
import cx from "classnames";

/**
 * Component to render branding data
 * - If there are sponsors, it renders a carousel
 * - Else it renders photographer's branding
 * @typedef {object} BrandingData
 * @property {object} branding - Photographer's branding data
 * @property {boolean} showPortfolio - Show photographer's portfolio link
 * @property {boolean} isAnonymous - Is user anonymous
 * @property {("1"|"2"|"3")} template - Style of the component
 *  - 1: for template 1, 2, 3
 *  - 2: for template 4
 *  - 3: for template 5
 * @param {BrandingData} props
 */
const Sponsors = ({ branding, showPortfolio, isAnonymous, template = "1" }) => {
  const {
    sponsors,
    designSettings: { showMyBranding },
  } = useSelector((state) => state.settings);

  return branding?.business_name ? (
    sponsors.length ? (
      <div
        className={cx("gallaryHeadingRight", {
          "w-45": template === "1",
          "w-100 align-items-center": template === "3",
        })}
      >
        <Carousel
          indicators={false}
          fade={true}
          controls={false}
          interval={3000}
          className={cx({ "w-100": template === "3" })}
        >
          {[
            showMyBranding && {
              _id: "1",
              sTitle: "Album by",
              sName: branding?.business_name,
              sImage: branding?.logo,
              sLink: showPortfolio
                ? `/portfolio/${branding?.website_route}`
                : "#",
            },
            ...sponsors,
          ]
            .filter((i) => i)
            .map((sponsor) => (
              <Carousel.Item key={sponsor._id}>
                <SponsorBlock
                  sponsor={sponsor}
                  isAnonymous={isAnonymous}
                  showMedia={false}
                  template={template}
                  classOverrides={template === "2" ? "mt-5" : ""}
                />
              </Carousel.Item>
            ))}
        </Carousel>
      </div>
    ) : showMyBranding ? (
      <div className="gallaryHeadingRight">
        <SponsorBlock
          sponsor={{
            _id: "1",
            sTitle: "Album by",
            sName: branding?.business_name,
            sImage: branding?.logo,
            sLink: showPortfolio
              ? `/portfolio/${branding?.website_route}`
              : "#",
          }}
          isAnonymous={isAnonymous}
          showMedia={true}
          template={template}
          classOverrides={template === "2" ? "mt-5" : ""}
        />
      </div>
    ) : null
  ) : null;
};

export default Sponsors;
