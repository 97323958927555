import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import css from "./SolutionsDropdown.module.css";
import cx from "classnames";

const SOLUTIONS = [
  {
    title: "For Photographers",
    url: "https://www.kwikpic.in/landing/photographer-dashboard",
  },
  {
    title: "For Weddings",
    url: "https://www.kwikpic.in/solutions/kwikpic-for-weddings",
  },
  {
    title: "For Parties & Celebrations",
    url: "https://www.kwikpic.in/solutions/kwikpic-for-parties-celebrations",
  },
  {
    title: "For Corporates",
    url: "https://www.kwikpic.in/solutions/kwikpic-for-corporate",
  },
  {
    title: "For Colleges",
    url: "https://www.kwikpic.in/solutions/kwikpic-for-colleges",
  },
];

const SolutionsDropdown = () => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <div
        className={css.dropdown}
        onClick={() => setShow(!show)}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <Nav.Link role="button">
          <span>Solutions & Use-cases</span>
          <img
            src="/assets/images/icons/arrow-down.png"
            alt="chevron-down"
            className={cx([css.chevron], { [css.up]: show })}
          />
        </Nav.Link>

        <div
          className={cx(css["dropdown-menu"], {
            [css.show]: show,
          })}
        >
          {show && (
            <div>
              {SOLUTIONS.map((solution, index) => (
                <a
                  key={index}
                  href={solution.url}
                  className="d-block px-3 py-2"
                >
                  {solution.title}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SolutionsDropdown;
