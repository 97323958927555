import { call, put, takeLatest } from "redux-saga/effects";
import { AXIOS } from "utils/setup/axios";
import { UPLOAD_SERVICE_ICON } from "./saga-actions";
import { toast } from "react-toastify";
import { setPortfolioLoader, setServices } from "redux-store/slices/portfolio";

async function uploadServiceIcon(data) {
  const { image, index } = data || {};
  const formData = new FormData();
  formData.append("serviceImage", image);
  formData.append("servicePosition", index);

  return AXIOS.put("/api/app/user/upload-portfolio-services", formData);
}

function* uploadServiceIconSaga({ payload }) {
  try {
    yield put(setPortfolioLoader(true));
    const { data } = yield call(uploadServiceIcon, payload);

    toast.success("Service icon uploaded successfully");
    yield put(setServices(data.data?.services || []));
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
  } finally {
    yield put(setPortfolioLoader(false));
  }
}

export function* portfolioSaga() {
  yield takeLatest(UPLOAD_SERVICE_ICON, uploadServiceIconSaga);
}
