import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = ({ aboutUs }) => {
  return (
    <Container>
      <div className="text-left text-md-left">
        <img
          className="pt-2 mb-3 footer-logo"
          src="/assets/images/global-images/logo.png"
          alt="logo"
        />
      </div>
      <div className="footer-info thick-font gap-3">
        <ul className="list-unstyled m-0">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="#about" onClick={aboutUs}>
              About Us
            </a>
          </li>
          <li>
            <a href="#plans">Plans & Pricing</a>
          </li>
          <li>
            <a href="#contact">Contact Us</a>
          </li>
          <li>
            <a href="/helpdesk" target="_blank">
              Helpdesk
            </a>
          </li>
        </ul>
        <div className="flex-wrap justify-content-between justify-content-md-start w-100 w-md-auto">
          <a
            href="https://www.instagram.com/kwikpic.in/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-background px-3">
              <img
                src="/assets/images/social-media/instagram.png"
                alt="instragram"
              />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/kwikpic-in/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-background px-3">
              <img
                src="/assets/images/social-media/linkedin.png"
                alt="linkedin"
              />
            </div>
          </a>
          <a
            href="https://www.facebook.com/kwikpic.in/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-background px-3">
              <img
                src="/assets/images/social-media/facebook.png"
                alt="facebook"
              />
            </div>
          </a>
          <a
            href="https://www.youtube.com/channel/UCsQGbmHxhFisZU2kif2AK6Q"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-background px-3">
              <img
                src="/assets/images/social-media/youtube.png"
                alt="youtube"
              />
            </div>
          </a>
        </div>
      </div>
      <hr />
      <div className="footer-copyright text-left py-3 d-flex justify-content-between flex-lg-wrap">
        <a href="#"> © 2024 Kwikpic. All rights reserved.</a>
        <div>
          <Link
            to="/policy/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy&nbsp;
          </Link>
          <p>|&nbsp;</p>
          <Link
            to="/policy/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms &amp; conditions&nbsp;
          </Link>
          <p>|&nbsp; </p>
          <Link to="/policy/refund" target="_blank" rel="noopener noreferrer">
            Refunds
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
