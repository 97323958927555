import React from "react";

const PrimarySmallBtn = ({ onClick, title, className }) => {
  return (
    <button className={`primarySmallBtn ${className ?? ""}`} onClick={onClick}>
      {title}
    </button>
  );
};
export default PrimarySmallBtn;
