import React, { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./css/DefaultGallaryHeading.css";
import { useNavigate } from "react-router-dom";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import {
  SEND_USER_CLICK_ANALYTICS,
  GET_GROUP_ADMIN_TOKEN,
} from "redux-store/sagas/saga-actions";
import { handleDownloadRouter } from "utils/helpers/downloads";
import Sponsors from "./Sponsors";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import ANALYTICS from "constants/analyticsKeys";
import ExpandableButton from "views/components/button/ExpandableButton";

const GroupShareModal = React.lazy(() =>
  import("views/components/modals/GroupShareModal")
);
const UserAgentErrorModal = React.lazy(() =>
  import("views/components/modals/UserAgentModal")
);

const MODAL_TYPES = {
  SHARE: "share",
  ERROR: "error",
  NONE: "",
};

const DefaultGallaryHeading = ({
  title,
  group,
  branding,
  isAnonymous,
  showPortfolio,
  template2,
  showClickSelfie,
}) => {
  const [modalShow, setModalShow] = React.useState(MODAL_TYPES.NONE);
  const [deviceType, setDeviceType] = useState({ type: "desktop" });
  useDetectUserAgent(setDeviceType);

  const dispatch = useDispatch();
  const {
    leaveParticipantSettings: { isAdmin },
    downloadSettings: { allowDownload, bulkDownload },
    groupDetails = {},
  } = useSelector((state) => state.settings);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const groupID = params.get("groupId");

  const handleSetModal = () => {
    if (groupDetails) {
      setModalShow(MODAL_TYPES.SHARE);
      if (!isAnonymous && isAdmin && !groupDetails?.isPrivate) {
        dispatch({
          type: GET_GROUP_ADMIN_TOKEN,
          groupCode: groupDetails.groupCode,
        });
      }
    }
  };

  return (
    <>
      {template2 && (
        <div className="overlayCenter">
          <h2 className="text-center">{title}</h2>
          <span>{group?.allPicsCount || 0} Photos</span>
        </div>
      )}
      <div className="gallaryHeading">
        <div className="gallaryHeadingLeft d-flex flex-column">
          {!template2 && (
            <h2>
              <p>{title}</p>
              {!isAnonymous && (
                <span className="d-block">
                  {group?.allPicsCount || 0} Photos
                </span>
              )}
            </h2>
          )}
          <div className="p-1 ps-0 d-flex gap-2">
            {!isAnonymous && (
              <>
                <ExpandableButton
                  className="GalleryHeadingLeftSetting d-flex d-md-none"
                  icon="/assets/images/icons/setting.png"
                  title="Settings"
                  to={`/settings/list?groupId=${groupID}`}
                  onClick={() => {
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.SETTINGS] },
                    });
                  }}
                />
                <ExpandableButton
                  className="GalleryHeadingLeftSetting d-none d-md-flex"
                  icon="/assets/images/icons/setting.png"
                  title="Settings"
                  to={`/settings/general?groupId=${groupID}`}
                  onClick={() => {
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.SETTINGS] },
                    });
                  }}
                />
              </>
            )}
            <ExpandableButton
              className="GalleryHeadingLeftShare"
              icon="/assets/images/icons/share.png"
              title="Share"
              onClick={() => {
                handleSetModal();
                !isAnonymous &&
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: {
                      buttons: [ANALYTICS.SHARE],
                    },
                  });
              }}
            />
            <ExpandableButton
              className="GalleryHeadingLeftDownload"
              icon="/assets/images/icons/download.png"
              title="Download&nbsp;All"
              onClick={() => {
                !isAnonymous &&
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: { buttons: [ANALYTICS.DOWNLOAD] },
                  });
                handleDownloadRouter(
                  groupID,
                  navigate,
                  deviceType,
                  () => setModalShow(MODAL_TYPES.ERROR),
                  {
                    isAdmin,
                    allowDownload,
                    bulkDownload,
                    isAnonymous,
                    anonymousUserId: params?.get("anonymousUserId"),
                  }
                );
              }}
            />
            {!isAnonymous && groupDetails.folders?.length > 10 && (
              <ExpandableButton
                className="GalleryHeadingLeftFolders"
                titleClassName="text-nowrap"
                {...(pathname === "/gallery/folders"
                  ? {
                      icon: "/assets/images/icons/gallery/image.png",
                      to: `/gallery?groupId=${groupID}`,
                      title: "Image View",
                    }
                  : {
                      icon: "/assets/images/icons/folder2.png",
                      to: `/gallery/folders?groupId=${groupID}`,
                      title: "Folder view",
                    })}
              />
            )}
            {isAnonymous && showClickSelfie && (
              <ExpandableButton
                className="GalleryHeadingLeftSelfie"
                icon="/assets/icons/faceai.png"
                title="Face&nbsp;AI"
                to={`/anonymous/anonymous-selfies?groupId=${groupID}`}
                rel="noopener noreferrer"
                target="_blank"
              />
            )}
            {template2 && <div className="mt-5" />}
          </div>
        </div>
        <Sponsors
          branding={branding}
          showPortfolio={showPortfolio}
          isAnonymous={isAnonymous}
        />
      </div>
      <React.Suspense fallback={<SuspenseLoader />}>
        {modalShow === MODAL_TYPES.SHARE && (
          <GroupShareModal
            show={true}
            groupDetails={groupDetails}
            onHide={() => setModalShow(MODAL_TYPES.NONE)}
            branding={branding}
            isAdmin={isAdmin}
            isPrivate={groupDetails?.isPrivate}
          />
        )}
        {modalShow === MODAL_TYPES.ERROR && (
          <UserAgentErrorModal
            show={true}
            onHide={() => setModalShow(MODAL_TYPES.NONE)}
            deviceType={deviceType}
            variant="1"
          />
        )}
      </React.Suspense>
    </>
  );
};

export default DefaultGallaryHeading;
