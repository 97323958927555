import React from "react";
import css from "./index.module.css";
import { Col, Row, Dropdown } from "react-bootstrap";
import SettingPageHeading from "../components/SettingPageHeading";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import SmallRoundedButton from "../../components/button/SmallRoundedButton";
import { useSelector, useDispatch } from "react-redux";
import {
  GET_BUSINESS_BRANDING,
  POST_BUSINESS_WATERMARK,
  DELETE_WATERMARK,
  POST_WATERMARK_OPTIONS,
  SEND_USER_CLICK_ANALYTICS,
} from "../../../redux-store/sagas/saga-actions";
import Spinner from "views/components/loader/Spinner";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import SubscriptionModal from "views/subscription";
import { toast } from "react-toastify";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import UserAgentErrorModal from "views/components/modals/UserAgentModal";
import { useSearchParams } from "react-router-dom";
import WaterMarkCard from "./components/WatermarkCard";
import { CARD_DATA, POSITIONS } from "./constants";
import ANALYTICS from "constants/analyticsKeys";

const Watermark = () => {
  const { businessBranding, saveLoader, settingLoader } = useSelector(
    (state) => state.settings
  );
  const [watermarkProps, setWatermarkProps] = React.useState({
    watermark_position: POSITIONS.CENTER,
    watermark_percent: 50,
  });
  const [watermarkSrc, setWatermarkSrc] = React.useState("");
  const [subscriptionModal, setSubscriptionModal] = React.useState(false);
  const watermarkRef = React.useRef(null);
  const [userAgentErrorModal, setUserAgentErrorModal] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState({ type: "desktop" });
  useDetectUserAgent(setDeviceType);
  // const initialVals = useRef({}); // not required for now

  const watermarkAllowed = useSubscription().hasAccessToFeature(
    PLAN_FEATURES.watermark
  );

  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  React.useEffect(() => {
    dispatch({
      type: GET_BUSINESS_BRANDING,
    });
  }, []);

  React.useEffect(() => {
    if (Object.keys(businessBranding).length === 0) return;
    // initialVals.current = {
    // 	watermark_position: businessBranding.watermark_position,
    // 	watermark_percent: businessBranding.watermark_percent,
    // };
    setWatermarkProps({
      watermark_position: businessBranding.watermark_position || "",
      watermark_percent: businessBranding.watermark_percent || "",
    });
  }, [businessBranding]);

  const handleOnChange = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size < 5242880) {
        setWatermarkSrc(e.target.files[0]);
        dispatch({
          type: POST_BUSINESS_WATERMARK,
          payload: {
            watermark: e.target.files[0],
          },
        });
      } else {
        toast.error("Watermark image size should be less than 5MB.");
      }
    }
  };

  const deleteWatermark = () => {
    dispatch({
      type: DELETE_WATERMARK,
    });
  };

  const handleBrowse = (e) => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: {
        buttons: [ANALYTICS.WATERMARK_BROWSE_EDIT],
      },
    });

    if (!watermarkAllowed) {
      e?.preventDefault();
      return setSubscriptionModal(true);
    }
    if (deviceType.type === "mobile") {
      e?.preventDefault();
      return setUserAgentErrorModal(true);
    }
    watermarkRef.current.click();
  };

  const handleSave = () => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.WATERMARK_SAVE] },
    });

    if (!watermarkAllowed) return setSubscriptionModal(true);
    dispatch({
      type: POST_WATERMARK_OPTIONS,
      payload: {
        ...watermarkProps,
      },
    });
  };

  return (
    <div>
      <Spinner loading={saveLoader || settingLoader} />
      <SettingPageHeading
        title="Watermark"
        onClick={handleSave}
        btnTitle="Save"
        goBack={token && !noback ? () => history.back() : false}
      />
      <Row className="pt-4 switchOnMobile">
        <Col xs={12} lg={5}>
          <div className="mb-5">
            <h4 className="mb-3">Position</h4>
            <div className={css["card-container"]}>
              {CARD_DATA.map((card) => (
                <WaterMarkCard
                  key={card.position}
                  active={watermarkProps.watermark_position === card.position}
                  setActive={() =>
                    setWatermarkProps({
                      ...watermarkProps,
                      watermark_position: card.position,
                    })
                  }
                  title={card.title}
                  icon={card.icon}
                />
              ))}
            </div>
          </div>
          <div className="mb-3">
            <h4 className="mb-3">Size Range</h4>
            <Row className="p-2">
              <Col
                xs={1}
                className="d-flex justify-content-end align-items-center"
              >
                1
              </Col>
              <Col xs={10}>
                <RangeSlider
                  style={{ width: "100%" }}
                  min={1}
                  max={99}
                  step={1}
                  value={watermarkProps.watermark_percent}
                  onChange={(v) =>
                    setWatermarkProps({
                      ...watermarkProps,
                      watermark_percent: v.target.value,
                    })
                  }
                  tooltip="on"
                  tooltipPlacement="bottom"
                />
              </Col>
              <Col
                xs={1}
                className="d-flex justify-content-start align-items-center"
              >
                99
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} lg={5} className="ms-xl-4">
          <h4>Add Watermark</h4>
          <div>
            <div
              style={{ display: !businessBranding?.watermark ? "" : "none" }}
              className="dropableCard m-0 mt-3 mb-5"
            >
              <input
                type="file"
                id="file"
                className="d-none"
                ref={watermarkRef}
                onChange={handleOnChange}
                accept=".png"
              />
              <span>Upload Logo for Watermark</span>
              <div className="mt-4" />
              <SmallRoundedButton title="Browse" onClick={handleBrowse} />
              <div className="mt-4" />
              <p className="text-center">
                We recommend using PNG format
                <br />
                Maximum size allowed is 2 mb
              </p>
            </div>
          </div>
          {businessBranding?.watermark && (
            <div className="mb-5">
              <div className="dropableCard m-0 mt-3">
                <img
                  className={css["business-watermark"]}
                  src={
                    watermarkSrc
                      ? URL.createObjectURL(watermarkSrc)
                      : businessBranding.watermark
                  }
                  alt="branding watermark"
                />
              </div>
              <div className="conver-edit-dropdown">
                <Dropdown className="mt-3">
                  <Dropdown.Toggle
                    className="cover-edit position-static w-fit"
                    variant="primary"
                    id="dropdown-basic"
                  >
                    Edit
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-0">
                    <Dropdown.Item onClick={handleBrowse}>
                      <img
                        src="/assets/images/landing/upload.svg"
                        alt="upload"
                      />
                      Upload Watermark
                    </Dropdown.Item>
                    <Dropdown.Item onClick={deleteWatermark}>
                      <img
                        src="/assets/images/landing/Vector.svg"
                        alt="delete"
                      />
                      Delete Watermark
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
          <div className="dropdown-divider d-xl-none mb-4" />
        </Col>
      </Row>
      {subscriptionModal && (
        <SubscriptionModal
          isOpen={subscriptionModal}
          onClose={() => setSubscriptionModal(false)}
        />
      )}
      {userAgentErrorModal && (
        <UserAgentErrorModal
          show={userAgentErrorModal}
          onHide={() => setUserAgentErrorModal(false)}
          deviceType={deviceType}
          variant={1}
        />
      )}
    </div>
  );
};

export default Watermark;
