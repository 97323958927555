import React from "react";
import DefaultInput from "views/components/input/DefaultInput";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import css from "./PasswordModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_PASSWORD } from "redux-store/sagas/saga-actions";
import { toast } from "react-toastify";
import { setPassSetResponse } from "redux-store/slices/auth";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import Spinner from "views/components/loader/Spinner";

const MIN6 = /^.{6,20}$/;
const UPPER = /[A-Z]/;
const LOWER = /[a-z]/;
const NUMBER = /[0-9]/;
const SPECIAL = /[`~!@#$%^&*()\-=_+[\]\\{}|;':",./<>?]/;

const PasswordModal = ({
  show = false,
  onClose = () => {},
  closeButton = false,
  backdrop = false,
  suggestPassword = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [fieldType, setfieldType] = React.useState("password");
  const [checks, setCkecks] = React.useState({
    allok: false,
    min6: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
  });
  const dispatch = useDispatch();
  const { passSetResponse } = useSelector((state) => state.auth);
  const { settingLoader } = useSelector((state) => state.settings);
  const { name } = getLocalStorageUser();

  React.useEffect(() => {
    let interval = null;
    interval = setTimeout(() => {
      let checksRes = {
        min6: MIN6.test(input),
        upper: UPPER.test(input),
        lower: LOWER.test(input),
        number: NUMBER.test(input),
        special: SPECIAL.test(input),
      };

      checksRes.allok = Object.values(checksRes).every((v) => v === true);

      setCkecks({
        ...checksRes,
      });
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, [input]);

  React.useEffect(() => {
    if (passSetResponse?.status === 200) {
      setOpen(false);
      onClose();
      dispatch(setPassSetResponse({}));
    }
  }, [dispatch, onClose, passSetResponse]);

  React.useEffect(() => {
    if (suggestPassword) {
      setfieldType("text");
    }
  }, [suggestPassword]);

  const handlePasswordSave = () => {
    if (!checks.allok)
      return toast.error(
        "Password must contain 6-20 characters, 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character"
      );

    dispatch({
      type: SET_PASSWORD,
      payload: {
        password: input,
      },
    });
  };

  return (
    <ConfirmationModal
      show={open || show}
      onCancel={() => {
        setOpen(false);
        onClose();
      }}
      title="Set a Password"
      confirmText="Save Password"
      cancelText=""
      closeButton={closeButton}
      onConfirm={handlePasswordSave}
      animation={true}
      backdrop={backdrop}
      zIndex={1050}
      className="p-0"
    >
      <div className="p-3">
        <div className="position-relative w-100">
          <DefaultInput
            className="no-border pe-5"
            name="password"
            type={fieldType}
            value={input}
            onChange={(e) => setInput(e.target?.value || "")}
            onClick={() => (suggestPassword ? setInput(`${name}123#`) : "")}
            autoFocus={true}
            placeholder={`For eg: ${name}123#`}
          />
          <img
            src={
              fieldType === "text"
                ? "/assets/images/icons/eye.png"
                : "/assets/images/icons/eye2.png"
            }
            className="position-absolute end-0 top-50 translate-middle-y me-2 cursor-pointer"
            onClick={() =>
              setfieldType(fieldType === "password" ? "text" : "password")
            }
          />
        </div>
        <p className="mt-3 mb-1 text-start">Password must contain:</p>
        <ul className={css.checkList}>
          <li>
            <input
              type="checkbox"
              checked={checks.min6}
              readOnly
              className="checkbox-img-select"
              id="cb-min6"
            />
            <label
              htmlFor="cb-min6"
              className="checkbox-img-select-label"
            ></label>
            <span>6-20 characters</span>
          </li>
          <li>
            <input
              type="checkbox"
              checked={checks.upper}
              readOnly
              className="checkbox-img-select"
              id="cb-upper"
            />
            <label
              htmlFor="cb-upper"
              className="checkbox-img-select-label"
            ></label>
            <span>1 uppercase letter</span>
          </li>
          <li>
            <input
              type="checkbox"
              checked={checks.lower}
              readOnly
              className="checkbox-img-select"
              id="cb-lower"
            />
            <label
              htmlFor="cb-lower"
              className="checkbox-img-select-label"
            ></label>
            <span>1 lowercase letter</span>
          </li>
          <li>
            <input
              type="checkbox"
              checked={checks.number}
              readOnly
              className="checkbox-img-select"
              id="cb-number"
            />
            <label
              htmlFor="cb-number"
              className="checkbox-img-select-label"
            ></label>
            <span>1 number</span>
          </li>
          <li>
            <input
              type="checkbox"
              checked={checks.special}
              readOnly
              className="checkbox-img-select"
              id="cb-special"
            />
            <label
              htmlFor="cb-special"
              className="checkbox-img-select-label"
            ></label>
            <span>1 special character</span>
          </li>
        </ul>
      </div>

      <p className={css.infoRibbon}>
        No waiting for OTP to Login.
        <br />
        Set password for Quick Access.
      </p>
      <Spinner loading={settingLoader} />
    </ConfirmationModal>
  );
};

export default PasswordModal;
