import axios from "axios";
import { toast } from "react-toastify";
import { lsProxy } from "utils/helpers/localstorage";
import CryptoJS from "crypto-js";

/**
 * This is an axios's instance. we can configure some settings here in the future.
 */
export const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const AXIOS2 = axios.create({
  baseURL: process.env.REACT_APP_UPLOAD_URI,
});

export const AXIOS_ANALYTICS = axios.create({
  baseURL: process.env.REACT_APP_ANALYTICS_URL,
});

/**
 * An axios-interceptor which will add auth-token to each request.
 */
AXIOS.interceptors.request.use(
  (request) => {
    const AUTH_TOKEN = lsProxy.getItem("token");
    if (AUTH_TOKEN) {
      request.headers.Authorization = AUTH_TOKEN;
    }
    request.headers.uuid = CryptoJS.lib.WordArray.random(8).toString();

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AXIOS2.interceptors.request.use(
  (request) => {
    const AUTH_TOKEN = lsProxy.getItem("token");
    if (AUTH_TOKEN) {
      request.headers.Authorization = AUTH_TOKEN;
    }
    request.headers.uuid = CryptoJS.lib.WordArray.random(8).toString();

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AXIOS_ANALYTICS.interceptors.request.use(
  (request) => {
    const AUTH_TOKEN = lsProxy.getItem("token");
    if (AUTH_TOKEN) {
      request.headers.Authorization = AUTH_TOKEN;
    }
    request.headers.uuid = CryptoJS.lib.WordArray.random(8).toString();

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AXIOS.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // prevent page reload when anonymous user hits apis that require auth

      if (
        lsProxy.getItem("anonymous") === "anonymous" &&
        !lsProxy.getItem("token")
      )
        return;

      lsProxy.clear();

      // exception for join group api, since we need to preserve group join link
      if (error?.response?.config?.url !== "api/app/group/join")
        window.location.href = "/auth/login"; // redirect to login page in case of token expiry / 401
    }
    if (error.response.status === 429) {
      toast.error("Api rate limit exceeded. Kindly try after minutes", {
        toastId: "CORS",
      });
    }
    if (error.response.status === 403) {
      if (
        error.response.data?.message.includes("scheduled for deletion") &&
        !window.location.pathname.includes("auth/login") // prevent page reload on login page
      ) {
        lsProxy.clear();
        window.location.href = "/auth/login"; // redirect to login page in case of account deletion
      }
    }
    // if (
    //   error.config.baseURL === error.config.url &&
    //   error.response.status === 0
    // ) {
    //   toast.error("Api rate limit exceeded. Kindly try after minutes", {
    //     toastId: "CORS",
    //   });
    // }

    return Promise.reject(error);
  }
);

AXIOS2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (lsProxy.getItem("anonymous") === "anonymous") return;

      lsProxy.removeItem("token");
      lsProxy.removeItem("user");
      window.location.reload();
    }
    if (error.response.status === 427) {
      toast.error("Api rate limit exceeded. Kindly try after minutes", {
        toastId: "CORS",
      });
    }
    // if (
    //   error.config.baseURL === error.config.url &&
    //   error.response.status === 0
    // ) {
    //   toast.error("Api rate limit exceeded. Kindly try after minutes", {
    //     toastId: "CORS",
    //   });
    // }

    return Promise.reject(error);
  }
);

AXIOS_ANALYTICS.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (lsProxy.getItem("anonymous") === "anonymous") return;

      lsProxy.removeItem("token");
      lsProxy.removeItem("user");
      window.location.reload();
    }
    if (error.response.status === 427) {
      toast.error("Api rate limit exceeded. Kindly try after minutes", {
        toastId: "CORS",
      });
    }

    return Promise.reject(error);
  }
);
