import React from "react";
const IconChip = ({styleCss, src, styleClass}) => {
  return (
    <>
        <img src={src} style={styleCss} alt={src} className={`iconchip ${styleClass}`} />
    </>
  );
};

export default IconChip;
