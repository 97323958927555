import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import LinkShareModal from "views/components/modals/LinkShareModal";
import { allowShareDownload, downloadVideo } from "views/download/helpers";
import { useDispatch } from "react-redux";
import { DELETE_VIDEO_OF_FOLDER } from "redux-store/sagas/saga-actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { modifyGallaryRoute } from "utils/helpers/routes";
import {
  useController,
  useLightboxState,
} from "yet-another-react-lightbox/core";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import css from "./Lightbox.module.css";

const BinIcon = ({
  allVideos,
  vidIndex,
  setViewConfirmationModal,
  viewConfirmationModal,
  closeImageView,
  allVideosCount,
}) => {
  const { currentIndex, slides } = useLightboxState();
  const { prev } = useController();

  const [params] = useSearchParams();
  const _id = params.get("groupId");

  const dispatch = useDispatch();
  const url = allVideos?.[vidIndex]?.url;

  const handleDeleteVideo = () => {
    // see LightboxToolbar.js for explanation
    if (allVideosCount == 1) closeImageView();

    let video_id = allVideos?.[vidIndex]?._id;

    if (currentIndex === slides.length - 1) prev();

    dispatch({
      type: DELETE_VIDEO_OF_FOLDER,
      _id,
      url,
      video_id,
    });
  };
  return (
    <>
      <li onClick={() => setViewConfirmationModal(true)}>
        <img src="/assets/icons/bin.png" alt="Delete" />
      </li>
      <ConfirmationModal
        title="Delete Video"
        show={viewConfirmationModal}
        message="Are you sure you want to delete this Video?"
        onCancel={() => setViewConfirmationModal(false)}
        onConfirm={() => {
          handleDeleteVideo();
          setViewConfirmationModal(false);
        }}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  );
};

export default function LightboxToolbarVideo({
  closeImageView,
  vidIndex,
  isYoutube,
}) {
  const [socialShareModal, setSocialShareModal] = useState("");
  const [viewConfirmationModal, setViewConfirmationModal] = useState(false);
  const { allVideosCount, allVideos } = useSelector(
    (state) => state.folderImages
  );
  const navigate = useNavigate();
  const {
    leaveParticipantSettings,
    downloadSettings: { allowDownload },
  } = useSelector((state) => state.settings);
  const [params] = useSearchParams();
  const newPage = params.get("page");
  const isAdmin = leaveParticipantSettings.isAdmin;
  useEffect(() => {
    navigate(
      modifyGallaryRoute(
        { pageNo: newPage, videoId: allVideos[vidIndex]?._id },
        params
      )
    );
  }, [allVideos, vidIndex]);

  // const getVideoName = (url) => {
  //   if (!url) return "";
  //   let name = url.split("@").pop();
  //   name = decodeURI(name);
  //   return name;
  // };

  const user = getLocalStorageUser();
  const isUploadByUser = allVideos[vidIndex]?.uploadedBy === user?._id;
  return (
    <>
      <div>
        <img
          className={`navigate_icons gallary-modal-back-button video-margin`}
          onClick={closeImageView}
          src="/assets/icons/arrow.svg"
          alt=""
        />
      </div>

      <div className={`image_count video-margin`}>
        {`${vidIndex + 1}/${allVideosCount}`}
      </div>
      {/* <div className="image_name">
        {!isYoutube && getVideoName(allVideos?.[vidIndex]?.url)}
      </div> */}
      <div
        className={`${css["video-toolbar-container"]} ${css["toolbar-container"]}`}
      >
        <ul className={css.toolbar}>
          {!isYoutube && (
            <li
              onClick={() => {
                allowShareDownload(
                  isAdmin,
                  allowDownload,
                  () => downloadVideo(allVideos?.[vidIndex]?.url),
                  "Download"
                );
              }}
            >
              <img src="/assets/icons/download.png" alt="Download" />
            </li>
          )}

          {!isYoutube && (
            <li
              onClick={() => {
                allowShareDownload(isAdmin, allowDownload, () => {
                  const url = allVideos?.[vidIndex]?.url;
                  setSocialShareModal(url);
                });
              }}
            >
              <img src="/assets/icons/share.png" alt="Share" />
            </li>
          )}

          {(isAdmin || isUploadByUser) && (
            <BinIcon
              allVideos={allVideos}
              allVideosCount={allVideosCount}
              vidIndex={vidIndex}
              closeImageView={closeImageView}
              setViewConfirmationModal={setViewConfirmationModal}
              viewConfirmationModal={viewConfirmationModal}
            />
          )}
        </ul>
      </div>

      {socialShareModal && (
        <LinkShareModal
          show={socialShareModal}
          onHide={() => setSocialShareModal("")}
          url={socialShareModal}
        />
      )}
    </>
  );
}
