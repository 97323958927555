import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UPLOAD_YOUTUBE_VIDEO } from "redux-store/sagas/saga-actions";
import DefaultSwitch from "views/components/input/DefaultSwitch";
import YoutubeInput from "views/components/input/YoutubeInput";
import StorageFullModal from "./StorageFullModal";
import SubscriptionModal from "views/subscription";
import { MODAL_TYPES } from "./UploadFromLink";

const YoutubeVideoUploadModal = ({ show, handleClose }) => {
  const [url, setUrl] = useState("");
  const [isVideoPrivate, setIsVideoPrivate] = useState(false);
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const groupId = params.get("groupId");
  const [modal, setModal] = React.useState(MODAL_TYPES.NONE);

  const { userAnalytics } = useSelector((state) => state.settings);
  const { videoUploads, videoDeletes, uploadVideoLimit } = userAnalytics || {};

  const oneXCondition = videoUploads - videoDeletes >= uploadVideoLimit;
  const twoXCondition = videoUploads >= uploadVideoLimit * 2;

  React.useEffect(() => {
    setModal(MODAL_TYPES.NONE);
  }, []);

  const handleSubmit = () => {
    if (url.length > 0) {
      if (oneXCondition) return setModal(MODAL_TYPES.STORAGE);
      if (twoXCondition) return setModal(MODAL_TYPES.STORAGE2X);
      dispatch({
        type: UPLOAD_YOUTUBE_VIDEO,
        groupId,
        url,
        isVideoPrivate,
      });
      setUrl("");
    } else {
      toast.error("Please Enter a Link");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered animation={false}>
      <Modal.Header
        className="uploadYoutubeModal youtube-modal-padding border-0"
        closeButton
      >
        <p className="font-bold font-20 semi-black-text mx-auto mb-0">
          Upload Video from Youtube Link
        </p>
      </Modal.Header>
      <Modal.Body className="uploadYoutubeModal yt-modal-padding mb-2">
        <div className="d-flex align-items-center mb-2">
          <span className="me-3 font-bold font-15">Visible to all</span>
          <DefaultSwitch
            isChecked={!isVideoPrivate}
            onChange={() => setIsVideoPrivate(!isVideoPrivate)}
          />
        </div>
        <p className="font-12 color-h d-flex align-items-center gap-1 font-base">
          <img
            src="/assets/images/icons/info.png"
            alt="info"
            className="hw-15px"
          />
          Video will be visible to all members in a public group
        </p>
        <div className="mb-3">
          <YoutubeInput
            onClick={(value) => handleSubmit(value)}
            placeholder="Insert Youtube Link"
            value={url}
            setUrl={setUrl}
            imgSrc="/assets/icons/link.svg"
          />
        </div>
      </Modal.Body>
      <StorageFullModal
        show={modal === MODAL_TYPES.STORAGE || modal === MODAL_TYPES.STORAGE2X}
        onHide={() => setModal(MODAL_TYPES.NONE)}
        onConfirm={() => setModal(MODAL_TYPES.SUBSCRIPTION)}
        type="videos"
        exceed={modal === MODAL_TYPES.STORAGE ? "1x" : "2x"}
      />
      <SubscriptionModal
        title="UPGRADE YOUR PLAN"
        isOpen={modal === MODAL_TYPES.SUBSCRIPTION}
        onClose={() => setModal(MODAL_TYPES.NONE)}
      />
    </Modal>
  );
};

export default YoutubeVideoUploadModal;
