import React from "react";
import { Modal } from "react-bootstrap";
import ProgressCard from "views/components/progressBar/ProgressBar";
import SmallRoundedButton from "../button/SmallRoundedButton";
import Dropzone from "react-dropzone";
import { UploadingCard } from "./UploadFromComputer";
import { AutoSizer, List } from "react-virtualized";
import useUploadVideo from "hooks/useUploadVideo";
import css from "./css/Uploads.module.css";
import { bytesToSize } from "utils/helpers";
import PrimarySmallBtn from "views/components/button/PrimarySmallBtn";
import DefaultButton from "views/components/button/DefaultButton";
import SecondarySmallBtn from "views/components/button/SecondarySmallBtn";
import Spinner from "views/components/loader/Spinner";
import ConfirmationModal from "./ConfirmationModal";
import DefaultSwitch from "views/components/input/DefaultSwitch";
import { useSelector } from "react-redux";
import StorageFullModal from "./StorageFullModal";
import SubscriptionModal from "views/subscription";
import { toast } from "react-toastify";

export default function UploadVideosModal({ show, handleClose }) {
  const {
    videosToUpload,
    handleUploadVideo,
    totalSize,
    startUpload,
    isUploading,
    hasUploadedAllVideos,
    videosWithError,
    handleFailedUploads,
    handleConfirmationModal,
    loading,
    confirmationModal,
    successFullySentToBackend,
    successfullyUploaded,
    duplicateVideos,
    limitExceededVideos,
  } = useUploadVideo({
    handleClose,
    show,
  });
  const [isPublic, setIsPublic] = React.useState(true);
  const [modal, setModal] = React.useState({
    storage: false,
    subscription: false,
  });

  const { userAnalytics } = useSelector((state) => state.settings);
  const { videoUploads, videoDeletes, uploadVideoLimit } = userAnalytics || {};

  const oneXCondition =
    videoUploads - videoDeletes + videosToUpload.length > uploadVideoLimit;

  const twoXCondition =
    videoUploads + videosToUpload.length > uploadVideoLimit * 2;

  const storageCon = oneXCondition || twoXCondition;

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0)
      toast.error(
        <>
          Only MP4 and M4V videos are supported.
          <br />
          {rejectedFiles.length} file(s) were rejected.
        </>
      );

    handleUploadVideo(acceptedFiles);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        animation={true}
        className="uploadOneShot"
        onHide={handleConfirmationModal}
      >
        <Modal.Header
          closeButton={show}
          className="justify-content-center border-0 "
        >
          <Modal.Title className="font-bold fs-12 semi-black-text mx-auto">
            <p className="font-bold font-24 mb-0">Upload Videos</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="video-modal px-2">
          <div className="d-flex justify-content-between ">
            <div
              className=" d-flex align-items-center justify-content-around"
              style={{ width: "55%" }}
            >
              <div style={{ width: "10%" }}>
                <img src="/assets/icons/Video.svg" alt="jpg-icon" />
              </div>
              <div style={{ width: "82%" }}>
                <ProgressCard
                  className="mx-4"
                  progressPercentage={
                    (successFullySentToBackend / videosToUpload.length) * 100 ||
                    0
                  }
                  thumb={true}
                  leftText={`${successFullySentToBackend}/${
                    videosToUpload.length
                  } Videos${
                    hasUploadedAllVideos
                      ? ", " +
                        successfullyUploaded.length +
                        " success, " +
                        duplicateVideos.length +
                        " duplicates, " +
                        videosWithError.length +
                        " errors, " +
                        limitExceededVideos.length +
                        " limit exceeded"
                      : ""
                  }`}
                  rightText={bytesToSize(totalSize)}
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-between flex-column"
              style={{ width: "42%" }}
            >
              <div className="d-flex align-items-center font-15 font-bold mb-2">
                <span className="me-3 font-bold font-15">Visible to all</span>
                <DefaultSwitch
                  isChecked={isPublic}
                  onChange={() => setIsPublic(!isPublic)}
                />
              </div>
              <p className="font-12 color-h d-flex align-items-center gap-1 font-base mb-0">
                <img
                  src="/assets/images/icons/info.png"
                  alt="info"
                  className="hw-15px"
                />
                Video will be visible to all members in a public group
              </p>
            </div>
          </div>
          <div className="col-md-12 mt-3 card-height cards-outer h-100 bg-white">
            {videosToUpload.length > 0 ? (
              <div className="d-flex">
                {/* <div
                  style={{
                    background: "#f3f7f9",
                    width: "6.5ch",
                  }}
                >
                  <p className="m-0 mt-3 position-absolute top-0 font-bold color-primary font-12">
                    Displayed
                    <br />
                    LAST →
                  </p>
                  <p className="m-0 mb-3 position-absolute bottom-0 font-bold color-primary font-12">
                    Displayed
                    <br />
                    FIRST →
                  </p>
                </div> */}
                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      <List
                        className={css.listStyle}
                        height={height}
                        width={width}
                        rowHeight={45}
                        rowCount={videosToUpload.length}
                        rowRenderer={({ style, key, index }) => {
                          let video = videosToUpload[index];

                          return (
                            <UploadingCard
                              key={key}
                              style={style}
                              image={video}
                              isUploading={isUploading}
                            />
                          );
                        }}
                      />
                      // </div>
                    );
                  }}
                </AutoSizer>
              </div>
            ) : (
              <Dropzone
                accept={{
                  "video/mp4": [".mp4"],
                }}
                multiple={true}
                noClick={true}
                onDrop={onDrop}
              >
                {({ getRootProps, getInputProps, open }) => (
                  <label
                    className="dropableCard-video dragvideo "
                    htmlFor="fileUploader"
                    {...getRootProps()}
                  >
                    <input
                      {...getInputProps({
                        className: "d-none",
                        id: "fileUploader",
                      })}
                    />
                    <span className="bold-font font-15">Drag videos here</span>
                    <div className="mt-2" />
                    <span className="bold-font font-15">or</span>
                    <div className="mt-2" />
                    <SmallRoundedButton title="Browse" onClick={open} />
                    <div className="mt-3" />
                    <span className="bold-font font-12">
                      Max size per video is 100mb
                    </span>
                  </label>
                )}
              </Dropzone>
            )}
          </div>
          {!videosToUpload.length && (
            <p className="font-base font-15 color-h mt-2">
              <img
                src="/assets/images/icons/info.png"
                alt="info"
                className="hw-15px d-inline-block mb-1 me-1"
              />
              Please compress videos on&nbsp;
              <a
                href="https://www.veed.io/tools/video-compressor"
                target="_blank"
                rel="noreferrer noopener"
              >
                www.veed.io
              </a>
              &nbsp;at medium quality before uploading.
            </p>
          )}
        </Modal.Body>
        {videosToUpload.length > 0 && (
          <Modal.Footer className="px-1">
            <div className={css.footerContainer}>
              {isUploading ? (
                <>
                  <span className="warning-message">
                    *Please do not quit the application. Videos are still
                    Uploading.
                  </span>
                </>
              ) : (
                <>
                  {hasUploadedAllVideos ? (
                    <>
                      <>
                        <span className="uploadComplete">Completed!</span>
                        {videosWithError.length > 0 && (
                          <PrimarySmallBtn
                            title="Retry Failed Uploads"
                            onClick={handleFailedUploads}
                          />
                        )}
                        <Dropzone
                          accept={{
                            "video/mp4": [".mp4"],
                          }}
                          multiple={true}
                          noClick={true}
                          onDrop={onDrop}
                        >
                          {({ getRootProps, getInputProps, open }) => (
                            <label {...getRootProps()} htmlFor="uploadMore">
                              <input
                                {...getInputProps()}
                                type="file"
                                id="uploadMore"
                                multiple="multiple"
                              />
                              <SecondarySmallBtn
                                title="Upload More"
                                onClick={open}
                              />
                            </label>
                          )}
                        </Dropzone>
                      </>
                    </>
                  ) : (
                    <>
                      <DefaultButton
                        title="Start Upload"
                        className="fw-bold"
                        onClick={() =>
                          storageCon
                            ? setModal((p) => ({ ...p, storage: true }))
                            : startUpload(undefined, isPublic)
                        }
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </Modal.Footer>
        )}
      </Modal>
      {modal.subscription && (
        <SubscriptionModal
          title="UPGRADE YOUR PLAN"
          isOpen={modal.subscription}
          onClose={() => setModal((p) => ({ ...p, subscription: false }))}
        />
      )}
      <StorageFullModal
        type="videos"
        exceed={oneXCondition ? "1x" : "2x"}
        show={modal.storage}
        onConfirm={() => setModal({ subscription: true, storage: false })}
        onHide={() => setModal((p) => ({ ...p, storage: false }))}
      />
      <ConfirmationModal {...confirmationModal} />
      <Spinner loading={loading} />
    </>
  );
}
