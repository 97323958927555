/**
 * script to load razorpay only on pages where it is required
 */
export const loadRZP = () => {
  const existingScript = document.getElementById("razorpay-dynamic-load");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.id = "razorpay-dynamic-load";
    document.body.appendChild(script);
  }
};
