import { call, put, takeLatest } from "redux-saga/effects";
import { AXIOS } from "utils/setup/axios";
import { toast } from "react-toastify";
import {
  GET_DELEGATES,
  CREATE_DELETGATE,
  UPDATE_DELEGATE,
  DELETE_DELEGATE,
} from "./saga-actions";
import {
  addOneDelegate,
  deleteOneDelegate,
  setDelegates,
  reqSuccess,
  reqStart,
  updateOneDelegate,
  reqFail,
} from "redux-store/slices/user";

function getDelegates() {
  return AXIOS.get("/api/app/user/all-delegate");
}

function* getDelegatesGenerator() {
  try {
    yield put(reqStart());
    const response = yield call(getDelegates);
    const { data } = response;
    yield put(setDelegates(data));
    yield put(reqSuccess());
  } catch (error) {
    const err = error?.response?.data?.message;
    toast.error(err);
    yield put(reqFail(err));
  }
}

function createDelegate(data) {
  return AXIOS.post("/api/app/user/create-delegate", data);
}

function* createDelegateGenerator({ payload }) {
  try {
    yield put(reqStart());
    const response = yield call(createDelegate, payload);
    const { data } = response;
    toast.success(data?.message);
    yield put(addOneDelegate(data?.data?.user));
    yield put(reqSuccess());
  } catch (error) {
    const err = error?.response?.data?.message;
    toast.error(err);
    yield put(reqFail(err));
  }
}

function updateDelegate(data) {
  const { id, ...rest } = data;
  return AXIOS.put(`/api/app/user/update-delegate-access/${id}`, rest);
}

function* updateDelegateGenerator({ payload }) {
  try {
    yield put(reqStart());
    const response = yield call(updateDelegate, payload);
    const { data } = response;
    toast.success(data?.message);
    yield put(updateOneDelegate(data?.data?.user));
    yield put(reqSuccess());
  } catch (error) {
    const err = error?.response?.data?.message;
    toast.error(err);
    yield put(reqFail(err));
  }
}

function deleteDelegate(data) {
  const { id } = data;
  return AXIOS.post(`/api/app/user/delete-delegate/${id}`);
}

function* deleteDelegateGenerator({ payload }) {
  try {
    yield put(reqStart());
    const response = yield call(deleteDelegate, payload);
    const { data } = response;
    toast.success(data?.message);
    yield put(deleteOneDelegate(payload));
    yield put(reqSuccess());
  } catch (error) {
    const err = error?.response?.data?.message;
    toast.error(err);
    yield put(reqFail(err));
  }
}

export function* userSaga() {
  yield takeLatest(GET_DELEGATES, getDelegatesGenerator);
  yield takeLatest(CREATE_DELETGATE, createDelegateGenerator);
  yield takeLatest(UPDATE_DELEGATE, updateDelegateGenerator);
  yield takeLatest(DELETE_DELEGATE, deleteDelegateGenerator);
}
