import React from "react";
import { Container } from "react-bootstrap";

const StayTouch = () => {
  return (
    <Container className="subscription">
      <div className="contact-header mb-3">Stay in Touch</div>
      <div className="news-letter bottom-margin-32 font-base">
        Subscribe to our newsletter and keep up with our latest updates and
        offers!
      </div>
      <div className="subscribe">
        <input type="text" placeholder="Email Address" />
        <div className="col-md-4">
          <button className="form-submit">Subscribe</button>
        </div>
      </div>
    </Container>
  );
};

export default StayTouch;
