import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import css from "../../ads/AdsLanding.module.css";
import GradButton from "views/components/button/GradButton";
import { useNavigate } from "react-router-dom";

const PlatformFeatures = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/auth/login", {
      state: {
        email: e.target.email.value,
      },
    });
  };

  return (
    <Container fluid>
      <Row className={css["image-features"]}>
        <Col>
          <div className={css.center}>
            <div>
              <h2 className="font-24">
                The Most Affordable and User Friendly Platform
              </h2>
              <p className="font-base">
                Share photos of every occasion with Kwikpic!
              </p>
            </div>
          </div>
          <div className={css.lcr}>
            <div className={css.left}>
              <div>
                <h2 className="font-24">Group Based Sharing</h2>
                <p className="font-base">
                  Organize your event photos in Small Personal & Big Public
                  Groups
                </p>
              </div>
              <div>
                <h2 className="font-24">Privacy Settings</h2>
                <p className="font-base">
                  Control how much access do group members have in your group
                </p>
              </div>
            </div>
            <img
              src="/assets/images/country-landing/uk/footer.png"
              alt="mobile"
            />
            <div className={css.right}>
              <div>
                <h2 className="font-24">Quality is our Priority</h2>
                <p className="font-base">
                  Share photos in high-quality without any digital quality loss
                </p>
              </div>
              <div>
                <h2 className="font-24">Partial & Full Access</h2>
                <p className="font-base">
                  Organize your event photos in Small Personal & Big Public
                  Groups
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className={css["get-started"]}>
        <Col xs={12} lg={6}>
          <h1 className={`font-bold ${css.heading}`}>
            Get Started with Kwikpic today!
          </h1>
          <p>
            Use Kwikpic for free forever and upgrade whenever you&apos;re ready.
          </p>
        </Col>
        <Col xs={12} lg={6}>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Your email here"
              required
            ></input>
            <GradButton
              type="submit"
              title="Get Started"
              variant="sm"
              className="px-3 lh-12"
            />
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default PlatformFeatures;
