import usePagination from "hooks/usePagination";
import React from "react";
import { Pagination } from "react-bootstrap";

const Paginator = ({ onPageChange, totalCount, perPageCount, currentPage }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const { totalPages, pagesArray } = usePagination({
    totalCount,
    perPageCount,
    currentPage,
  });

  // check if user is on wrong page
  // if true redirect to last page
  if (totalCount && currentPage > totalPages)
    return onPageChange(totalPages, totalPages);

  if (pagesArray?.length <= 1) return;

  return (
    <div
      className="PaginationIcon cursor-pointer"
      onMouseOver={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {isExpanded ? (
        <Pagination className="my-0 h-100" size="sm">
          <div className="pagination-button">
            <img
              disabled={currentPage === 1}
              onClick={() => {
                onPageChange(currentPage - 1, totalPages);
                setIsExpanded(false);
              }}
              src="/assets/images/icons/back-white.png"
              alt="Pagination back icon"
            />
          </div>
          {pagesArray?.map((pageNumber, index) => (
            <Pagination.Item
              className="pagination-item font-bold"
              active={currentPage === pageNumber}
              onClick={() => {
                typeof pageNumber === "number"
                  ? onPageChange(pageNumber, totalPages)
                  : onPageChange(currentPage, totalPages);
                setIsExpanded(false);
              }}
              key={"page-" + pageNumber + "--" + index}
            >
              {pageNumber}
            </Pagination.Item>
          ))}
          <div className="pagination-button">
            <img
              onClick={() => {
                onPageChange(currentPage + 1, totalPages);
                setIsExpanded(false);
              }}
              disabled={currentPage === totalPages}
              className="rot-180"
              src="/assets/images/icons/back-white.png"
              alt="Pagination back icon"
            />
          </div>
        </Pagination>
      ) : (
        <>
          <div className="pagination-button">
            <img
              onClick={() => {
                onPageChange(currentPage - 1, totalPages);
                setIsExpanded(false);
              }}
              src="/assets/images/icons/back-white.png"
              alt="Pagination back icon"
            />
          </div>
          <Pagination.Item className="pagination-item font-bold">
            {currentPage}
          </Pagination.Item>
          <div className="pagination-button">
            <img
              onClick={() => {
                onPageChange(
                  currentPage + 1,
                  totalPages
                ); /* This looks buggy so i removed it setIsExpanded(false); */
              }}
              src="/assets/images/icons/back-white.png"
              className="rot-180"
              alt="Pagination next icon"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Paginator;
