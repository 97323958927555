import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TestimonialItem from "./testimonialItem";
import IconChip from "./iconChip";
const TestimonialWrapper = ({ testimonials = [] }) => {
  return (
    <>
      {testimonials && testimonials.length > 0 && (
        <div>
          <div className="testimonial-header">
            <h1>TESTIMONIALS</h1>
            <p>Hear from our Photographers</p>
            <img
              className="under-line"
              src="/assets/images/landing/yellow-divider.png"
              alt="Yellow-divider"
            />
          </div>

          <div className="testimonail-body">
            <IconChip
              src="/assets/images/icons/Ellipse135.png"
              styleCss={{ top: "150px", left: "70px" }}
            />
            <IconChip
              src="/assets/images/icons/Ellipse136.png"
              styleCss={{ top: "20px", left: "20px" }}
            />
            <IconChip
              src="/assets/images/icons/Ellipse137.png"
              styleCss={{ top: "20px", right: "100px" }}
            />
            <IconChip
              src="/assets/images/icons/Ellipse134.png"
              styleCss={{ bottom: "20px", left: "50px" }}
            />
            <IconChip
              src="/assets/images/icons/Ellipse138.png"
              styleCss={{ top: "150px", right: "20px" }}
            />
            <IconChip
              src="/assets/images/icons/Ellipse139.png"
              styleCss={{ bottom: "20px", right: "80px" }}
            />

            {/* starts */}
            <IconChip
              src="/assets/images/icons/group758.png"
              styleCss={{
                bottom: "54px",
                right: "124px",
                width: "77px",
                height: "24.18px",
              }}
            />

            <IconChip
              src="/assets/images/icons/group758.png"
              styleCss={{
                top: "20px",
                right: "60px",
                width: "77px",
                height: "24.18px",
              }}
            />

            <IconChip
              src="/assets/images/icons/group756.png"
              styleCss={{
                top: "169px",
                left: "20px",
                width: "77px",
                height: "24.18px",
              }}
            />

            <img src="/assets/images/icons/Group 755.png" className="ratings" />
            <div className="testimonial-body-container">
              <div className="testimonial-quote"></div>
              <Slider
                {...{
                  dots: false,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  prevArrow: <img src="/assets/images/icons/backward.png" />,
                  nextArrow: <img src="/assets/images/icons/forward.png" />,
                  responsive: [
                    {
                      breakpoint: 600,
                      settings: {
                        arrows: false,
                        dots: true,
                      },
                    },
                  ],
                }}
              >
                {testimonials.map((item, index) => (
                  <TestimonialItem key={index} item={item} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TestimonialWrapper;
