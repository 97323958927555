/* eslint-disable no-console */
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GET_ALL_VIDEOS } from "../../../../redux-store/sagas/saga-actions";
import GallaryModal from "./GallaryModal";
import Masony from "react-masonry-component";
import useImpressionAnalytics from "hooks/useImpressionAnalytics";
import "../../../routes/styles/folderImages.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearFolderImages } from "redux-store/slices/folderImages";
import Paginator from "./Paginator";
import Spinner from "views/components/loader/Spinner";
import usePagination from "hooks/usePagination";
import ThreeDotAnimation from "views/components/loader/ThreeDotAnimation";
import {
  modifyAnonymousGallaryRoute,
  modifyGallaryRoute,
} from "utils/helpers/routes";
import VideosMasony from "./VideosMasony";
import UploadIconComp from "./UploadIconComp";
import AnonymousVideosModal from "views/anonymous/AnonymousVideosModal";

export const masonryOptions = {
  fitWidth: false,
  gutter: 0,
  itemSelector: ".photo-item",
  transitionDuration: 0,
};

export const VIDEOS_PER_PAGE = 30;

const FolderVideos = ({ folderId, hasUploadPermission, handleShow }) => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const [imageView, setImageView] = useState({
    show: false,
    selectedID: null,
  });

  const [loading, setLoading] = useState(true);

  const pageNumberFromURL = Number(params.get("page"));

  const pageRef = useRef(1);
  const [imagesToBeRendered, setImagesToBeRendered] = useState([]);
  const dispatch = useDispatch();

  // if folderId is all-videos, it's from anonymous gallery
  const isAnonymous = folderId === "all-videos";

  const {
    allPics: images = [],
    totalCount,
    imagesLoading,
    allVideos,
    allVideosCount,
    videos,
  } = useSelector((state) => state.folderImages);

  const handlePageChange = (goToPage, totalPages) => {
    if (
      !(goToPage <= 0) &&
      !(goToPage > totalPages) &&
      goToPage !== pageNumberFromURL
    ) {
      window.scroll(0, 0);
      // setRemoveLoader({});
      navigate(
        isAnonymous
          ? modifyAnonymousGallaryRoute(
              { pageNo: goToPage, totalPages },
              params
            )
          : modifyGallaryRoute({ pageNo: goToPage, totalPages }, params)
      );
      // dispatch(setFoldersPage({ page: goToPage, totalPages }));
      setLoading(true);
    }
  };

  useImpressionAnalytics({
    images,
    folderId,
  });

  useEffect(() => {
    if (!isNaN(pageNumberFromURL)) {
      getData(pageNumberFromURL);
    }
  }, [pageNumberFromURL]);

  const getData = (currentPage) => {
    if (!folderId) return;
    setLoading(false);
    dispatch({
      type: GET_ALL_VIDEOS,
      payload: {
        groupID: params.get("groupId"),
        page: currentPage,
        limit: VIDEOS_PER_PAGE,
        anonymoususerid: params.get("anonymousUserId"),
      },
    });
  };

  const init = () => {
    dispatch(clearFolderImages());
    pageRef.current = 1;
    setImagesToBeRendered([]);
  };

  useEffect(() => {
    // getData();
    return () => {
      init();
    };
  }, [folderId]);

  const closeImageView = () => {
    params.delete("videoId");
    setParams(params);
    setImageView({
      show: false,
      selectedID: null,
    });
  };

  const { totalPages } = usePagination({
    totalCount,
    perPageCount: VIDEOS_PER_PAGE,
    currentPage: Number(pageNumberFromURL),
  });

  return (
    <div className="no-overflow-x position-relative">
      {allVideosCount > 0 && !isAnonymous && (
        <UploadIconComp
          hasUploadPermission={hasUploadPermission}
          handleShow={handleShow}
        />
      )}

      <Masony
        /* masonryoptions is undef: check import */
        options={masonryOptions}
        disableImagesLoaded={false}
      >
        <VideosMasony
          videos={videos}
          setImageView={setImageView}
          params={params}
          modifyGallaryRoute={
            isAnonymous ? modifyAnonymousGallaryRoute : modifyGallaryRoute
          }
        />
      </Masony>
      {imagesToBeRendered?.length > 0 ? (
        <ThreeDotAnimation
          customizeClass={"d-flex justify-content-center vw-100 mb-3 pt-5"}
        />
      ) : (
        images?.[folderId]?.length !== 0 && (
          <div className="d-flex justify-content-center gallary-footer">
            {pageNumberFromURL > 1 && !loading && (
              <div
                onClick={() =>
                  handlePageChange(pageNumberFromURL - 1, totalPages)
                }
                className="prev-wrapper-footer font-bold font-20 cursor-pointer"
              >
                <img
                  className="prev-btn-footer"
                  src="/assets/images/icons/back-arrow.png"
                  alt="prev-arrow"
                />
                Previous
              </div>
            )}
            {pageNumberFromURL > 1 &&
              pageNumberFromURL < totalPages &&
              !loading && <div className="separator-footer" />}
            {pageNumberFromURL < totalPages && !loading && (
              <div
                onClick={() =>
                  handlePageChange(pageNumberFromURL + 1, totalPages)
                }
                className="next-wrapper-footer font-bold font-20 cursor-pointer"
              >
                Next
                <img
                  className="next-btn-footer rot-180"
                  src="/assets/images/icons/back-arrow.png"
                  alt="next-arrow"
                />
              </div>
            )}
          </div>
        )
      )}
      <Paginator
        currentPage={Number(pageNumberFromURL)}
        totalCount={allVideosCount}
        perPageCount={VIDEOS_PER_PAGE}
        onPageChange={(goToPage, totalPages) => {
          handlePageChange(goToPage, totalPages);
        }}
      />

      {params.has("videoId") && allVideos?.length > 0 ? (
        isAnonymous ? (
          <AnonymousVideosModal
            open={true}
            folderId={folderId}
            closeImageView={closeImageView}
            selectedID={imageView.selectedID}
            page={pageNumberFromURL}
            params={params}
            fetchData={getData}
          />
        ) : (
          <GallaryModal
            selectedID={imageView.selectedID}
            folderId={folderId}
            closeImageView={closeImageView}
            fetchData={getData}
          />
        )
      ) : null}

      <Spinner loading={loading || imagesLoading} />
    </div>
  );
};

export default FolderVideos;
