import React from "react";

const IconedButton = ({ title, icon, onClick }) => {
  return (
    <button className="iconed-button w-100" onClick={onClick}>
      {title}
      {icon && <img src="/assets/images/icons/Vector104.svg" alt="icon" />}
    </button>
  );
};
export default IconedButton;
