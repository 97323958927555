import React, { useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { delay } from "utils/helpers";
import { AXIOS } from "utils/setup/axios";
import {
  DEFAULT_SORT,
  SORT_TYPES,
} from "views/components/modals/UploadOneShot";
import Observable from "zen-observable";
import usePreventUnsavedNavigation from "./usePreventUnsavedNavigation";
// import uploadImageToBackendWithMultipleCall from "./useUploadFromComputer";
import {
  checkImageStatus,
  // handleAllImages,
  imagesStatus,
  // IMAGES_COUNT_PER_REQUEST,
  NETWORK_ERROR,
  uploadImageToBackend,
  findLastImage,
} from "./useUploadFromComputer";
import useUploadStatus from "./useUploadingStatus";
import { getLocalStorageUser, lsProxy } from "utils/helpers/localstorage";
import { STATIC_FOLDERS } from "views/components/modals/UploadFromComputer";

export default function useOneShotUpload({ show, handleClose }) {
  const [loading, setLoading] = React.useState(false);
  const [uploadedImages, setUploadedImages] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [params] = useSearchParams();
  const [confirmationModal, setConfirmationModal] = React.useState({}); // state to manage confirmation modal
  const [hasInternetConnection, setHasInternetConnection] =
    React.useState(false);
  const [imagesPerRequest, setImagesPerRequest] = React.useState(5);
  const sortByRef = useRef(DEFAULT_SORT !== "default");

  const { isParallelyUploading } = useUploadStatus();

  usePreventUnsavedNavigation({
    message: "Uploading is still in process. Are you sure you want to leave?",
    block: isUploading,
  });

  const groupID = params.get("groupId");

  useEffect(() => {
    if (!show) {
      setLoading(false);
      setUploadedImages([]);
      setIsUploading(false);
      setConfirmationModal({});
    }
  }, [show]);

  useEffect(() => {
    if (hasInternetConnection) {
      const relevantImages = uploadedImages?.filter(
        (image) =>
          image.status !== imagesStatus.DUPLICATE &&
          image.status !== imagesStatus.SUCCESS &&
          image.status !== imagesStatus.ERROR
      );
      toast.success("Uploading has been resumed .", {
        position: "top-right",
        autoClose: false,
        pauseOnHover: true,
      });

      if (relevantImages.length > 0) {
        lsProxy.removeItem("isUploading");
        startUploading();
      } else {
        setIsUploading(false);
      }
    }
  }, [hasInternetConnection]);

  useEffect(() => {
    if (isUploading) {
      lsProxy.setItem("isUploading", true);
    } else {
      lsProxy.removeItem("isUploading");
    }
  }, [isUploading]);

  const allFolders = useMemo(() => {
    return getFoldersFromImages(uploadedImages);
  }, [uploadedImages]);

  const totalImagesSize = React.useMemo(() => {
    return uploadedImages.reduce((acc, image) => {
      return acc + image.size;
    }, 0);
  }, [uploadedImages.length]);

  const duplicateImages = React.useMemo(() => {
    return uploadedImages.filter(
      (image) => image?.status === imagesStatus.DUPLICATE
    );
  }, [uploadedImages]);

  const successfullyUploaded = useMemo(() => {
    return uploadedImages.filter(
      (image) => image.status === imagesStatus.SUCCESS
    );
  }, [uploadedImages]);

  const imagesWithError = useMemo(() => {
    return uploadedImages.filter(
      (image) => image.status === imagesStatus.ERROR
    );
  }, [uploadedImages]);

  const successFullySentToBackend =
    successfullyUploaded.length +
    imagesWithError.length +
    duplicateImages.length;

  const hasUploadedAllImages =
    successFullySentToBackend === uploadedImages.length &&
    successFullySentToBackend !== 0;

  const handleFolderUpload = async (
    files,
    keepNested = false,
    guestOnly = false
  ) => {
    setLoading(true);

    if (files.length === 0) {
      setLoading(false);
      return;
    }

    const images = files.filter((file) => {
      return (
        file.type?.toLowerCase()?.includes("png") ||
        file.type?.toLowerCase()?.includes("jpeg") ||
        file.type?.toLowerCase()?.includes("jpg") ||
        file.type?.toLowerCase()?.includes("heic") ||
        file.type?.toLowerCase()?.includes("heif")
      );
    });

    if (images.length != files.length)
      toast.error(
        <>
          Only PNG, JPG and HEIC images are supported.
          <br />
          {files.length - images.length} file(s) were rejected.
        </>
      );

    const duplicateImages = [];
    // keep only one copy of same image
    const imagesWithoutDuplicates = images.filter((image, index, self) => {
      const prevImages = self.slice(0, index);
      const hasImage = !!prevImages.find(
        (prevImage) =>
          prevImage.name === image.name && prevImage.size === image.size
      );
      if (hasImage) {
        duplicateImages.push({
          name: image.name,
          size: image.size,
          status: imagesStatus.DUPLICATE,
          type: image.type,
          file: image,
        });
      }
      return !hasImage;
    });

    let updatedImages = await checkImageStatus(
      imagesWithoutDuplicates,
      groupID
    );
    if (updatedImages instanceof Error) {
      toast.error("Some error occured. Please try again");
      setLoading(false);
      return;
    }

    updatedImages = [...updatedImages, ...duplicateImages];

    const imagesWithFolderStatus = updatedImages.map((image) => {
      const path =
        image.file?.webkitRelativePath || image.file?.path.slice("1") || "";
      if (isImage(path?.split("/")[1])) {
        return {
          ...image,
          folderPrefix: guestOnly ? STATIC_FOLDERS.GUEST_UPLOADS : "All photos",
        };
      } else {
        let pth = path?.split("/");
        pth.shift();
        pth.pop();

        return {
          ...image,
          folderPrefix: guestOnly
            ? STATIC_FOLDERS.GUEST_UPLOADS
            : keepNested
            ? pth.join(" - ")
            : pth[0],
        };
      }
    });

    const sortedFolders = getFoldersFromImages(imagesWithFolderStatus);

    const newImages = getSortedImagesFromFolders(sortedFolders, DEFAULT_SORT);

    setUploadedImages(newImages);
    setLoading(false);
  };

  const startUploading = async (retriedImages = undefined) => {
    setIsUploading(true);
    const isAlreadyuploading = await isParallelyUploading();

    if (isAlreadyuploading) {
      toast.error(
        "One upload is already in progress. Please wait for that to finish"
      );
      setIsUploading(false);
      return;
    }

    const _allUploadedImages = retriedImages || uploadedImages;

    let relevantImages = _allUploadedImages?.filter(
      (image) =>
        image.status !== imagesStatus.DUPLICATE &&
        image.status !== imagesStatus.SUCCESS &&
        image.status !== imagesStatus.ERROR
    );
    let totalProcessedImages = imagesPerRequest;
    let images = relevantImages.slice(0, totalProcessedImages);

    let result = {
      error: [],
      success: [],
    };

    // running a recursive call to upload all the images to the backend ({IMAGES_COUNT_PER_REQUEST} at a time)
    while (images.length > 0) {
      const startTime = Date.now();
      let lastImg = null;

      if (totalProcessedImages >= relevantImages.length)
        lastImg = findLastImage(images);

      const resp = await upload(images, lastImg);
      const endTime = Date.now();

      const timeTaken = endTime - startTime;

      // making a delay so that it doesnot put too much load on the backend
      if (timeTaken < 2000) {
        await delay(2000 - timeTaken);
      }

      if (resp === "DO_NOTHING") {
        toast.warning(
          "Seems like you have lost the internet connection .Uploading will resume once the internet will be back .",
          {
            position: "top-right",
            autoClose: false,
            pauseOnHover: true,
          }
        );
        // break;
        return;
      }

      result.error = result.error.concat(resp.error);
      result.success = result.success.concat(resp.success);

      images = relevantImages.slice(
        totalProcessedImages,
        totalProcessedImages + imagesPerRequest
      );
      totalProcessedImages += imagesPerRequest;
    }
    setIsUploading(false);
  };

  async function upload(images, lastImg = null) {
    const { highRes } = getLocalStorageUser() || {};

    return new Promise((resolve) => {
      let result = {
        success: [],
        error: [],
      };

      let observable = new Observable.from(images);

      // eslint-disable-next-line no-unused-vars
      const subscription = observable.subscribe({
        next: async (image) => {
          // this is to ensure that user doesnot clear the localstorage while uploading
          lsProxy.setItem("isUploading", true);
          try {
            /**
             * This is to move the progress bar of each particular image
             *
             * @param {number} percentCompleted
             */
            const uploadProgress = (percentCompleted) => {
              setUploadedImages((prevState) =>
                prevState.map((img) => {
                  if (img.name === image.name) {
                    return { ...img, uploadProgress: percentCompleted };
                  }
                  return img;
                })
              );
            };

            const folderPrefix =
              image.folderPrefix === "All photos" ? "" : image.folderPrefix;

            // const status = await uploadImageToBackendWithMultipleCall(
            //   image,
            //   params.get("groupId"),
            //   folderPrefix,
            //   uploadProgress
            // );

            const isLast =
              image?.name === lastImg?.name && image?.size === lastImg?.size;

            const status = await uploadImageToBackend(
              image,
              params.get("groupId"),
              folderPrefix,
              uploadProgress,
              highRes,
              isLast
            );

            if (status === NETWORK_ERROR) {
              try {
                await AXIOS.get(process.env.REACT_APP_API_URL);
              } catch (err) {
                if (err?.code === NETWORK_ERROR) {
                  const _interval = setInterval(() => {
                    AXIOS.get(process.env.REACT_APP_API_URL).then(() => {
                      setHasInternetConnection(true);
                      clearInterval(_interval);
                    });
                  }, 3000);
                  subscription.unsubscribe();
                  setHasInternetConnection(false);
                  resolve("DO_NOTHING");
                }
              }
              // subscription.unsubscribe();
              // const _interval = setInterval(async () => {
              //   if (navigator.onLine) {
              //     try {
              //       const resp = await AXIOS.get(process.env.REACT_APP_API_URL);
              //       if (resp) {
              //         setHasInternetConnection(true);
              //         clearInterval(_interval);
              //       }
              //     } catch (err) {
              //       setHasInternetConnection(false);
              //     }
              //   } else {
              //     setHasInternetConnection(false);
              //   }
              // }, 3000);
              // resolve("DO_NOTHING");
            }

            setUploadedImages((prevState) =>
              prevState.map((img) => {
                if (img.name === image.name && img.size === image.size) {
                  if (status === NETWORK_ERROR) {
                    return { ...img, status: imagesStatus.ERROR };
                  }
                  return { ...img, status };
                }
                return img;
              })
            );

            if (
              status === imagesStatus.SUCCESS ||
              status === imagesStatus.DUPLICATE
            ) {
              result.success.push(image);

              if (
                images.length ===
                result.success.length + result.error.length
              ) {
                resolve(result);
                // handleAllImages(groupID);
              }
            } else if (
              status === imagesStatus.ERROR ||
              status === NETWORK_ERROR
            ) {
              result.error.push(image);

              if (
                images.length ===
                result.success.length + result.error.length
              ) {
                resolve(result);
              }
            }
          } catch (err) {
            setUploadedImages((prevState) =>
              prevState.map((img) => {
                if (img.name === image.name) {
                  return { ...img, status: imagesStatus.ERROR };
                }
                return img;
              })
            );

            result.error.push(image);

            if (images.length === result.success.length + result.error.length) {
              resolve(result);
            }
          }
        },
      });
    });
  }

  async function handleFailedUploads() {
    setUploadedImages((prev) => {
      const _prev = prev
        .filter((image) => image.status === imagesStatus.ERROR)
        .map((image) => {
          return { ...image, status: imagesStatus.READY, uploadProgress: 0 };
        });
      startUploading(_prev);
      return _prev;
    });
  }

  const handleConfirmationModal = () => {
    if (isUploading) {
      setConfirmationModal({
        show: true,
        title: "Uploading in progress",
        message: "Are you sure you want to cancel the upload?",
        confirmText: "Yes",
        cancelText: "No",
        onConfirm: () => {
          setIsUploading(false);
          setTimeout(() => handleClose(), 0);
        },
        onCancel: () => {
          setConfirmationModal({});
        },
      });
    } else if (!hasUploadedAllImages && uploadedImages.length > 0) {
      setConfirmationModal({
        show: true,
        title: "Selected Images",
        message:
          "You have not uploaded the selected images. Are you sure you want to cancel the process?",
        confirmText: "Yes",
        cancelText: "No",
        onConfirm: () => {
          setConfirmationModal({});
          setTimeout(() => handleClose(), 0);
        },
        onCancel: () => {
          setConfirmationModal({});
        },
      });
    } else {
      setConfirmationModal({});
      setTimeout(() => handleClose(), 0);
    }
  };

  const handleSortBy = (sort) => {
    if (isUploading) {
      toast.error("This change will not have any effect on order of images");
      return;
    }
    if (sort !== SORT_TYPES.DEFAULT) {
      sortByRef.current = true;
    }

    const sortedImages = getSortedImagesFromFolders(allFolders, sort);

    setUploadedImages(sortedImages);
  };

  const uploadLimitChange = (event) => {
    if (isUploading) {
      toast.error("This change will not have any effect.");
      return;
    }
    const value = event.target.value;
    if (value === "one") {
      setImagesPerRequest(1);
    } else if (value === "five") {
      setImagesPerRequest(5);
    }
  };

  return {
    loading,
    uploadedImages,
    isUploading,
    totalImagesSize,
    handleFolderUpload,
    allFolders,
    startUploading,
    duplicateImages,
    successfullyUploaded,
    imagesWithError,
    hasUploadedAllImages,
    successFullySentToBackend,
    handleFailedUploads,
    handleConfirmationModal,
    confirmationModal,
    setLoading,
    setUploadedImages,
    sortByRef,
    handleSortBy,
    imagesPerRequest,
    uploadLimitChange,
  };
}

export const isImage = (fileName) => {
  const fileExtension = fileName?.split(".")?.pop();
  return (
    fileExtension?.toLowerCase() === "jpg" ||
    fileExtension?.toLowerCase() === "jpeg" ||
    fileExtension?.toLowerCase() === "png"
  );
};

const getFoldersFromImages = (images) => {
  let folders = [];
  images.forEach((image) => {
    const hasFolder = folders.find(
      (folder) => folder.name === image.folderPrefix
    );

    if (!hasFolder) {
      folders.push({
        name: image.folderPrefix,
        images: [image],
      });
    } else {
      folders = folders.map((folder) => {
        if (folder.name === image.folderPrefix) {
          return { ...folder, images: [...folder.images, image] };
        }
        return folder;
      });
    }
  });
  return folders;
};

export const getSortedImagesFromFolders = (folders, sort) => {
  let newImages = [];
  if (sort === SORT_TYPES.ASC) {
    folders.forEach((folder) => {
      const newFolderImages = folder?.images?.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      newImages.push(...newFolderImages);
    });
  } else if (sort === SORT_TYPES.DESC) {
    folders.forEach((folder) => {
      const newFolderImages = folder?.images?.sort((a, b) =>
        b.name.localeCompare(a.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      newImages.push(...newFolderImages);
    });
  } else {
    folders.forEach((folder) => {
      newImages.push(...(folder?.images || []));
    });
  }
  if (newImages?.length > 0) {
    return newImages;
  }
  return [];
};
