import React from "react";
import { Card } from "react-bootstrap";
import css from "../index.module.css";
import cx from "classnames";

const WaterMarkCard = ({ active, setActive, title, icon }) => {
  return (
    <Card
      className={cx(css["position-card"], {
        [css["active-watermark-position"]]: active,
      })}
      onClick={setActive}
    >
      <img src={icon} alt={title} />
      <p>{title}</p>
    </Card>
  );
};

export default WaterMarkCard;
