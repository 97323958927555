import React from "react";

const SecondarySmallBtn = ({ title, onClick, type, className }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`secondarySmallButton bold-font ${className ?? ""}`}
    >
      {title}
    </button>
  );
};
export default SecondarySmallBtn;
