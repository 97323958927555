import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import css from "../index.module.css";

const AboutUs = ({ description, team1, team2 }) => {
  return (
    <Container
      id="about"
      fluid
      style={{
        backgroundColor: "#F3F6FB",
      }}
    >
      <Container>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row my-4 my-md-5">
              <div>
                <div className="d-none d-lg-block">
                  <h2 className="font-24 font-bold mb-3">About Us</h2>
                  <img
                    src="/assets/images/landing/yellow-divider.png"
                    alt="Yellow-divider"
                    className="mb-4"
                  />
                </div>
                <p className={css.description}>{description}</p>
              </div>
              <div className="w-100 d-flex justify-content-around justify-content-sm-start gap-sm-4">
                <div className={css.member}>
                  <img src={team1.image} alt={team1.name} />
                  <h3>{team1.name}</h3>
                  <p>{team1.title}</p>
                </div>
                {team2 && (
                  <div className={css.member}>
                    <img src={team2.image} alt={team2.name} />
                    <h3>{team2.name}</h3>
                    <p>{team2.title}</p>
                  </div>
                )}
              </div>
              <div className="d-block d-lg-none">
                <h2 className="font-24 font-bold mb-3">About Us</h2>
                <img
                  src="/assets/images/landing/yellow-divider.png"
                  alt="Yellow-divider"
                  className="mb-4"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default AboutUs;
