import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { VERIFY_LOGOUT_REQUEST } from "redux-store/sagas/saga-actions";
import { setLoading } from "redux-store/slices/auth";
import useUploadStatus from "./useUploadingStatus";
import { getMessaging, deleteToken } from "firebase/messaging";
import { pushSupported } from "./useNotifications";

const useLogoutHandler = () => {
  const dispatch = useDispatch();
  const { isParallelyUploading, uploadingFromLink } = useUploadStatus();

  async function logoutHandler() {
    const isUploading = await isParallelyUploading();
    const isUploadingFromLink = await uploadingFromLink();
    const messaging = (await pushSupported()) ? getMessaging() : null;

    if (!isUploadingFromLink) {
      if (isUploading) {
        toast.error("Images are uploading. Please wait for it to complete.");
        dispatch(setLoading(false));
        return;
      }
    }

    if (messaging) {
      await deleteToken(messaging).catch(() => {});
    }

    dispatch({
      type: VERIFY_LOGOUT_REQUEST,
    });
  }

  return { logoutHandler };
};

export default useLogoutHandler;
