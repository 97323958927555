import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_ORDER,
  CREATE_ORDER_VISITOR,
  PAYMENT_LINK,
  PAYMENT_VISITOR,
} from "redux-store/sagas/saga-actions";
import {
  clearSelectedPlan,
  setPaymentResponse,
} from "redux-store/slices/globals";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { clearCreditsResponse } from "redux-store/slices/settings";
import { clearOrderDetails } from "redux-store/slices/subscription";

// userType USER doesn't have quarterly plans
const getPlanId = (plan) => {
  let plan_id = plan.plan_id;
  if (plan.period === "monthly" || plan.type === "month")
    if (plan_id === "kwikpic_standard_monthly")
      plan_id = "kwikpic_standard_three_months";
    else plan_id = "kwikpic_essential_three_months";

  return plan_id;
};

const useOrder = (frontendType, src = "subscription") => {
  const { isUserInIndia } = useSelector((state) => state.globals);
  const credit = useSelector((state) => state.settings.credit);
  const userOrderDetails = useSelector(
    (state) => state.subscription.orderDetails
  );
  const orderDetails = src === "subscription" ? userOrderDetails : credit;
  const dispatch = useDispatch();

  const { name, email, phoneNumber } = getLocalStorageUser();
  const currency = isUserInIndia ? "INR" : "USD";

  const createOrder = (plan) => {
    const plan_id = getPlanId(plan);

    dispatch({
      type: CREATE_ORDER,
      payload: {
        plan_id,
        currency,
        frontendType: frontendType || "web",
      },
    });
  };

  const createOrderVisitor = (user, plan) => {
    const plan_id = getPlanId(plan);

    const name = user?.name.trim().split(" ");

    dispatch({
      type: CREATE_ORDER_VISITOR,
      payload: {
        plan_id,
        visitorFirstName: name[0],
        visitorLastName: name[1] || name[0],
        visitorEmail: user?.email,
        visitorPhone: `${user.cc}${user?.phone}`,
        currency,
        frontendType: frontendType || "web",
      },
    });
  };

  const createPaymentLink = (plan) => {
    const plan_id = getPlanId(plan);

    dispatch({
      type: PAYMENT_LINK,
      payload: {
        plan_id,
        currency,
        frontendType: frontendType || "web",
      },
    });
  };

  const createPaymentLinkVisitor = (user, plan) => {
    const plan_id = getPlanId(plan);

    dispatch({
      type: PAYMENT_VISITOR,
      payload: {
        plan_id,
        visitorFirstName: user?.name.split(" ")[0],
        visitorLastName: user?.name.split(" ")[1] || "",
        visitorEmail: user?.email,
        visitorPhone: `${user.cc}${user?.phone}`,
        currency,
        frontendType: frontendType || "web",
      },
    });
  };

  const handlePayment = () => {
    if (!orderDetails) return;

    let options = {
      key: process.env.REACT_APP_RZP_KEY,
      amount: orderDetails?.amount,
      currency,
      name: "Kwikpic",
      description: "Test Transaction",
      image: "https://www.kwikpic.in/logo512.jpg",
      order_id: orderDetails?.id,
      prefill: {
        name:
          `${orderDetails?.notes?.firstName ?? ""} ${
            orderDetails?.user?.lastName ?? ""
          }` || name,
        email: orderDetails?.notes?.email || email,
        contact: orderDetails?.notes?.phoneNumber || phoneNumber,
      },
      handler() {
        dispatch(
          setPaymentResponse({
            success: true,
            title: "Payment Successful!",
            details:
              src === "subscription"
                ? "Your plan will be automatically activated within 10 minutes."
                : "Your credits will be added to your wallet within 10 minutes.",
            creditsPurchased: parseInt(orderDetails?.notes?.creditsPurchased),
          })
        );
        if (src === "subscription") {
          dispatch(clearSelectedPlan());
          dispatch(clearOrderDetails());
        } else dispatch(clearCreditsResponse());
        rzp1.close();
      },
      notes: {
        address: "Mumbai, Maharashtra",
      },
      theme: {
        color: "#2887AF",
      },
      modal: {
        backdropclose: false,
        escape: false,
        confirm_close: true,
        ondismiss: () => {
          if (src === "subscription") {
            dispatch(clearSelectedPlan());
            dispatch(clearOrderDetails());
          } else dispatch(clearCreditsResponse());
        },
      },
    };

    // eslint-disable-next-line no-undef
    let rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function () {
      dispatch(
        setPaymentResponse({
          success: false,
          title: "Payment Failed!",
          details:
            "Please try again. Any amount debited will be refunded within 7 days.",
        })
      );
      dispatch(clearSelectedPlan());
    });
    rzp1.open();
  };

  return {
    createOrder,
    createOrderVisitor,
    createPaymentLink,
    createPaymentLinkVisitor,
    handlePayment,
  };
};

export default useOrder;
