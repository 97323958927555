import React, { useEffect, useState } from "react";
import DefaultInput from "../../components/input/DefaultInput";
import SmallRoundedButton from "../../components/button/SmallRoundedButton";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_USER_PROFILE,
  VERIFY_OTP_TO_UPDATE_PHONE_OR_EMAIL,
} from "redux-store/sagas/saga-actions";
import Spinner from "views/components/loader/Spinner";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import { isValidEmail, isValidPhoneNumber } from "utils/helpers";
import CountryJson from "views/auth/components/CountryJson";
import { clearUpdatePhoneOrEmailSendOTPResponse } from "redux-store/slices/settings";
import { MODAL_TYPES } from "../profile";

const EnterPhoneModal = ({ type, show, handleClose, setSettings }) => {
  const [isVerifing, setIsVerifing] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [error, setError] = React.useState();
  const [countryCode, setCountryCode] = useState("+91");
  const [fieldType, setfieldType] = useState("Email");

  const {
    userProfileDetails: {
      name,
      phoneNumber,
      email,
      tocAccepted,
      lastName,
    } = {},
    updatePhoneOrEmailSendOTPResponse,
    settingLoader,
    updatePhoneOrEmailVerifyOTPResponse,
  } = useSelector((state) => state.settings);
  const { country } = useSelector((state) => state.globals);

  const dispatch = useDispatch();

  const init = () => {
    setIsVerifing(false);
    setModal(false);
    setInput("");
    setError("");
    setOtp("");
    handleClose();
    dispatch(clearUpdatePhoneOrEmailSendOTPResponse());
  };

  useEffect(() => {
    if (type === MODAL_TYPES.PHONE) {
      setCountryCode(country?.dial_code);
    }
  }, [country, type]);

  useEffect(() => {
    if (updatePhoneOrEmailSendOTPResponse?.status === 201) {
      setIsVerifing(true);
    }
  }, [updatePhoneOrEmailSendOTPResponse]);

  useEffect(() => {
    if (type === MODAL_TYPES.PHONE) return setfieldType("Number");
    setfieldType(type);
  }, [type]);

  useEffect(() => {
    if (updatePhoneOrEmailVerifyOTPResponse?.status === 200) {
      if (type === MODAL_TYPES.PHONE) {
        setSettings((prev) => ({
          ...prev,
          newPhoneNumber: input,
        }));
      } else if (type === MODAL_TYPES.EMAIL) {
        setSettings((prev) => ({
          ...prev,
          newEmail: input,
        }));
      }
      init();
    }
  }, [updatePhoneOrEmailVerifyOTPResponse]);

  let header =
    type === MODAL_TYPES.EMAIL
      ? "Enter Email"
      : type === MODAL_TYPES.PHONE
      ? "Enter Phone number"
      : "";
  let buttonText = "Continue";

  if (isVerifing) {
    header = "Verify OTP";
    buttonText = "Verify";
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let updatedPhoneNumber;
    let updatedEmail;

    if (type === MODAL_TYPES.EMAIL) {
      if (input === "") {
        setError("*email is required");
        return;
      }
      if (!isValidEmail(input)) {
        setError("*please enter a valid email");
        return;
      }
      updatedEmail = input;
      updatedPhoneNumber = phoneNumber;
    } else if (type === MODAL_TYPES.PHONE) {
      if (input === "") {
        setError("* phone number is required");
        return;
      }
      if (!isValidPhoneNumber(input, countryCode)) {
        setError("* please enter a valid phone number");
        return;
      }
      updatedPhoneNumber = input;
      updatedEmail = email;
    }

    if (isVerifing) {
      dispatch({
        type: VERIFY_OTP_TO_UPDATE_PHONE_OR_EMAIL,
        payload: {
          type,
          otp,
          countryCode,
          phoneNumber: updatedPhoneNumber,
          email: updatedEmail,
        },
      });
      return;
    }

    if (type === MODAL_TYPES.PHONE || type === MODAL_TYPES.EMAIL) {
      dispatch({
        type: SET_USER_PROFILE,
        payload: {
          name,
          lastName,
          tocAccepted,
          ...(type === MODAL_TYPES.PHONE && {
            phoneNumber: updatedPhoneNumber,
            countryCode,
          }),
          ...(type === MODAL_TYPES.EMAIL && { email: updatedEmail }),
          isEdit: true,
        },
      });
    }
  };

  const onHide = () => {
    if (isVerifing) return setModal(true);
    handleClose();
  };

  return (
    <ConfirmationModal
      show={show}
      onCancel={onHide}
      onExited={init}
      title={header}
      footer={false}
      animation
    >
      <ConfirmationModal
        title="Cancel Process"
        show={modal}
        message="Are you sure you want to cancel this process?"
        onCancel={() => setModal(false)}
        onConfirm={init}
        cancelText="No"
        confirmText="Yes"
        animation
      />
      <Spinner loading={settingLoader} />

      <form
        className="d-flex flex-column w-100 phoneModal"
        onSubmit={handleSubmit}
      >
        {isVerifing ? (
          <DefaultInput
            name="otp"
            key="otp"
            value={otp}
            type="number"
            placeholder={header}
            onChange={(e) => setOtp(e.target?.value || "")}
            className="no-border"
          />
        ) : (
          <div
            className={
              type === MODAL_TYPES.EMAIL
                ? "d-flex w-100 justify-content-center"
                : "d-flex w-100 justify-content-center countryCode"
            }
          >
            {type === MODAL_TYPES.PHONE && (
              <CountryJson
                countryCode={countryCode}
                setCountryCode={setCountryCode}
              />
            )}
            <div className="w-100">
              <DefaultInput
                className="no-border"
                name={fieldType}
                key={fieldType}
                type={fieldType}
                error={error}
                value={input}
                onChange={(e) => setInput(e.target?.value || "")}
                onKeyDown={(e) => {
                  if (type === MODAL_TYPES.PHONE) {
                    // reject [.,-,e,+] keys
                    if (["e", ".", "-", "+"].includes(e.key))
                      e.preventDefault();
                  }
                }}
                placeholder={header}
                autoFocus={true}
              />
            </div>
          </div>
        )}

        <SmallRoundedButton
          title={buttonText}
          type="submit"
          className="mt-3 w-fit mx-auto"
        />
      </form>
    </ConfirmationModal>
  );
};

export default EnterPhoneModal;
