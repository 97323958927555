import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import DefaultButton from "../button/DefaultButton";

/**
 * @typedef {object} StorageFullModalProps
 * @property {boolean} show
 * @property {function} onConfirm
 * @property {function} onHide
 * @property {string} type - photos, videos
 * @property {("1x"|"2x")} exceed - 1x, 2x
 * @param {StorageFullModalProps} props
 */
const StorageFullModal = ({
  show,
  onConfirm,
  onHide,
  type = "photos",
  exceed = "1x",
}) => {
  const { userAnalytics } = useSelector((state) => state.settings);
  const {
    totalUtilisation,
    uploadVideoLimit,
    uploadLimit,
    videoUploads,
    videoDeletes,
    photoUploads,
    highResPhotoUploads,
    maxPhotoUpload,
  } = userAnalytics || {};

  let percentUtiliation = Math.round(
    type == "photos"
      ? (totalUtilisation / uploadLimit) * 100
      : ((videoUploads - videoDeletes) / uploadVideoLimit) * 100
  );

  return (
    <Modal
      className="storageFullModal"
      show={show}
      size="md"
      animation={false}
      onHide={onHide}
      centered
    >
      <Modal.Header className="border-0 pb-0 pt-4">
        <Modal.Title className="text-center w-100">
          <h4 className="bold-font text-center text-danger">
            {exceed == "1x" ? "Storage is Full" : "Total Upload Limit Exceeded"}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <div className="text-center font-14 mt-3">
          <p
            className="mb-0"
            style={{
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            {exceed === "1x" ? (
              <>
                You have used {percentUtiliation}
                % of your Storage.
                <br /> Your total upload limit is of&nbsp;
                {type === "photos" ? uploadLimit : uploadVideoLimit} {type}.
              </>
            ) : (
              <>
                You can only delete and reupload {type} 2 times per year.
                <br />
                Storage Limit&nbsp;-&nbsp;
                {type === "photos" ? uploadLimit : uploadVideoLimit} {type}
                <br />
                Total Upload Limit&nbsp;-&nbsp;
                {type === "photos"
                  ? maxPhotoUpload
                    ? maxPhotoUpload
                    : 2 * uploadLimit
                  : 2 * uploadVideoLimit}
                &nbsp;{type}
                <br />
                Total Uploads&nbsp;-&nbsp;
                {Math.round(
                  type === "photos"
                    ? photoUploads + 2.5 * highResPhotoUploads
                    : videoUploads
                )}
                &nbsp;{type}
              </>
            )}
            <br />
            Upgrade Plan to upload more!
          </p>
        </div>
        <div className="d-flex justify-content-center mt-4 mb-3">
          <button
            className="bg-transparent mx-3 color-primary font-bold"
            onClick={onHide}
          >
            Not now
          </button>
          <DefaultButton
            onClick={onConfirm}
            title="Upgrade Plan"
            className="px-4 font-bold w-auto"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StorageFullModal;
