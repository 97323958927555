import React, { useState, useEffect } from "react";
import SettingPageHeading from "./components/SettingPageHeading";
import FolderTable from "../components/table/FolderTable";
import { useDispatch, useSelector } from "react-redux";
import { setFolderOrderEditor } from "redux-store/slices/folders";
import {
  CHANGE_FOLDER_ORDER,
  GET_FOLDERS,
  SEND_USER_CLICK_ANALYTICS,
} from "redux-store/sagas/saga-actions";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./css/Folders.css";
import TransferFolderModal from "views/components/modals/TransferFolderModal";
import ANALYTICS from "constants/analyticsKeys";
import SortDropdown from "views/components/dropdowns/Sort";

const Folders = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  const { editOrder } = useSelector((state) => state.folders);
  const { folders } = useSelector((state) => state.folders);
  const [items, setItems] = useState([]);
  const [open, isOpen] = useState(false);

  const getAllFolders = () =>
    dispatch({
      type: GET_FOLDERS,
      groupID: params.get("groupId"),
      includeAllPhotos: true,
    });

  useEffect(() => {
    dispatch({
      type: GET_FOLDERS,
      groupID: params.get("groupId"),
      includeAllPhotos: true,
    });
  }, [dispatch, params]);

  useEffect(() => {
    setItems(
      folders
        .filter(
          (folder) =>
            folder.folderPrefix !== "Highlights" &&
            folder.folderPrefix !== "All Photos"
        )
        .map((folder) => {
          return { id: folder.groupFolder, folderPrefix: folder.folderPrefix };
        })
    );
  }, [folders]);

  const changeOrder = async (newOrder) => {
    dispatch({
      type: CHANGE_FOLDER_ORDER,
      payload: {
        groupId: params.get("groupId"),
        order: newOrder,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    getAllFolders();
    dispatch(setFolderOrderEditor(false));
  };

  const handleChanges = () => {
    if (editOrder) {
      changeOrder([...items.map((item) => item.id)]);
    } else {
      if (items.length > 0) dispatch(setFolderOrderEditor(!editOrder));
      else toast.error("You don't have enough folders to rearrange.");

      dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.FOLDER_REARRANGE] },
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((i) => i.id === active.id);
        const newIndex = items.findIndex((i) => i.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const handleSort = (e) => {
    const res = items.sort((a, b) => {
      if (e === "ASC") return a?.folderPrefix.localeCompare(b?.folderPrefix);
      else return b?.folderPrefix.localeCompare(a?.folderPrefix);
    });

    changeOrder([...res.map((item) => item.id)]);
  };

  return (
    <div>
      {open && (
        <TransferFolderModal
          open
          folders={folders}
          onHide={() => isOpen(false)}
          groupID={params.get("groupId")}
        />
      )}
      <SettingPageHeading
        title="Folders"
        btnTitle={editOrder ? "Save" : "Rearrange"}
        btnTitle2="Transfer"
        onClick={handleChanges}
        onClick2={() => {
          isOpen(true);
          dispatch({
            type: SEND_USER_CLICK_ANALYTICS,
            payload: { buttons: [ANALYTICS.FOLDER_TRANSFER] },
          });
        }}
        showSecond={!editOrder}
        goBack={token && !noback ? () => history.back() : false}
        className="mb-4"
      />
      {editOrder && (
        <>
          <h3 className="mb-2">Sort Folders</h3>
          <SortDropdown handleSort={handleSort} />
        </>
      )}
      <div className="folderTable">
        {editOrder ? (
          <>
            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
              <SortableContext items={items}>
                {items
                  .filter((i) => i.folderPrefix !== "All Photos")
                  .map((item) => (
                    <SortableItem key={item.id} id={item.id}>
                      <span>{item.folderPrefix}</span>
                    </SortableItem>
                  ))}
              </SortableContext>
            </DndContext>
          </>
        ) : (
          <FolderTable />
        )}
      </div>
    </div>
  );
};

export function SortableItem({ id, children }) {
  const { listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none",
    zIndex: 100,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      className="folder-holder"
    >
      {children}
    </div>
  );
}

export default Folders;
