import React from "react";
import { Modal } from "react-bootstrap";
import SmallRoundedButton from "../button/SmallRoundedButton";
import MutedButton from "../button/MutedButton";

/**
 * @typedef {object} ConfirmationModalProps
 * @property {boolean} show modal visibility
 * @property {string} title modal title
 * @property {string} message modal body message
 * @property {function} onCancel cancel button click handler
 * @property {function} onConfirm confirm button click handler
 * @property {string} cancelClass cancel button class
 * @property {string} confirmClass confirm button class
 * @property {string} [cancelText] cancel button text
 * @property {string} [confirmText] confirm button text
 * @property {function} onExit modal exit handler (in case onCancel has a different handler)
 * @property {React.ReactNode} children modal body
 * @property {boolean} [footer] show modal footer
 * @property {string} [headerClass] modal title class
 * @property {string} [className] modal body class
 * @property {string} [footerClass] modal footer class
 * @property {("sm"|"lg"|"xl")} [size] modal size
 * @property {boolean} [animation] modal animation
 * @property {("xs"|"sm")} [buttonVariant] button variants
 * @property {boolean} [buttonRounded] button rounded
 * @property {boolean} [closeButton] show default close button
 * @property {number} [zIndex] modal z-index
 * @property {function} [onExited] callback when modal is closed
 * @property {boolean} [backdrop] modal backdrop
 * @property {string} [backdropClassName] modal backdrop class
 * @param {ConfirmationModalProps} props
 */
const ConfirmationModal = ({
  show,
  title,
  message,
  onCancel,
  onConfirm,
  cancelClass,
  confirmClass,
  cancelText = "Cancel",
  confirmText = "Confirm",
  onExit,
  children,
  footer = true,
  headerClass = "",
  className = "",
  footerClass = "",
  size = "md",
  animation = false,
  buttonVariant = "xs",
  buttonRounded = true,
  closeButton = false,
  zIndex = 10000,
  onExited = () => {},
  backdrop = "",
  backdropClassName = "",
}) => {
  return (
    <Modal
      className="confirmationModal"
      show={show}
      size={size}
      animation={animation}
      onHide={onExit ?? onCancel}
      onExited={onExited}
      centered
      style={{
        zIndex,
      }}
      {...(backdrop ? { backdrop: "static" } : {})}
      backdropClassName={backdropClassName}
    >
      {title && (
        <Modal.Header
          className={`border-0 pb-0 ${headerClass ?? ""}`}
          closeButton={closeButton}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="bold-font font-20"
          >
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      {message && (
        <Modal.Body className={className}>
          <p className="font-base font-15">{message}</p>
        </Modal.Body>
      )}
      {children && <Modal.Body className={className}>{children}</Modal.Body>}
      {footer && (
        <Modal.Footer
          className={`border-0 d-flex justify-content-center ${footerClass}`}
        >
          {cancelText && (
            <MutedButton
              title={cancelText}
              onClick={onCancel}
              className={cancelClass}
              variant={buttonVariant}
              rounded={buttonRounded}
            />
          )}
          {confirmText && (
            <SmallRoundedButton
              title={confirmText}
              onClick={onConfirm}
              className={confirmClass}
              variant={buttonVariant}
              rounded={buttonRounded}
            />
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
