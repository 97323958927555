import { isImage } from "hooks/useOneShotUpload";
import usePreventUnsavedNavigation from "hooks/usePreventUnsavedNavigation";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  GET_FOLDERS,
  IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
} from "redux-store/sagas/saga-actions";
import { getDOURL, getCFURL } from "utils/helpers";
import { SELECTION_TYPE } from "../AlbumDownload";
import {
  downloadImages,
  getFilesRecursively,
  getFolderImages,
  getToBeDownloadedImages,
  isDirectorySelectionSupported,
} from "../helpers";
import Folder from "./Folder";
import { toast } from "react-toastify";
import { LOG_TYPE } from "redux-store/sagas/images";
import { NETWORK_ERROR } from "hooks/useUploadFromComputer";
import { AXIOS } from "utils/setup/axios";

export const IMAGE_SIZE_DIFFERENCE = 0; // ( in bytes )

/**
 * This component is used to download folders,
 * Used in Bulk Downloads and Anonymous Bulk Downloads
 */
const FolderDownloads = ({
  type,
  headerText = "Download Full Album or Download Folders",
  isAnonymous,
  setDownloading = () => {},
}) => {
  const DEFAULT_FOLDER =
    type === SELECTION_TYPE.ALL_FOLDERS
      ? {
          id: "All",
          name: "All Photos",
          status: "Download",
          progress: 0,
        }
      : type === SELECTION_TYPE.MY_FOLDER
      ? {
          id: "my-photos",
          name: "My Photos",
          status: "Download",
          progress: 0,
        }
      : type === SELECTION_TYPE.PURCHASED
      ? {
          id: "purchased",
          name: "Purchased",
          status: "Download",
          progress: 0,
        }
      : type === SELECTION_TYPE.SELECTED_IMAGES
      ? {
          id: "selected-images",
          name: "Selected Images",
          status: "Download",
          progress: 0,
        }
      : {
          id: "favorites",
          name: "My Favorites",
          status: "Download",
          progress: 0,
        };

  const [foldersStatus, setFoldersStatus] = React.useState([DEFAULT_FOLDER]);

  const directorRef = useRef([]);

  const isDownloading = foldersStatus.some(
    (folder) =>
      folder?.status === "Downloading" && folder?.progress < folder?.totalPics
  );

  useEffect(() => {
    setDownloading(isDownloading);
  }, [isDownloading]);

  usePreventUnsavedNavigation({
    message: "Downloading in progress. Do you want to leave?",
    block: isDownloading,
    refresh: true,
  });

  const dispatch = useDispatch();
  const { folders } = useSelector((state) => state.folders);
  const { images } = useSelector((state) => state.anonymous);
  const [params] = useSearchParams();
  const groupId = params.get("groupId");

  useEffect(() => {
    directorRef.current = Array(foldersStatus.length).fill({});
  }, [foldersStatus.length]);

  useEffect(() => {
    if (isAnonymous) {
      setFoldersStatus([
        {
          id: "my-photos",
          name: "My Photos",
          status: "Download",
          progress: 0,
        },
      ]);
    } else if (Array.isArray(folders)) {
      const _folders = folders
        .filter((folder) => folder.folderPrefix)
        .map((folder) => ({
          id: folder?.groupFolder,
          name: folder?.folderPrefix,
          status: "Download",
          progress: 0,
        }));

      setFoldersStatus((prev) => [...prev, ..._folders]);
    }
  }, [folders?.length]);

  useEffect(() => {
    if (
      type === SELECTION_TYPE.MY_FOLDER ||
      type === SELECTION_TYPE.MY_FAVORITES ||
      type === SELECTION_TYPE.PURCHASED ||
      type === SELECTION_TYPE.SELECTED_IMAGES
    )
      return;
    dispatch({ type: GET_FOLDERS, groupID: groupId });
  }, []);

  function incrementProgress(
    id = undefined,
    progress = undefined,
    status = undefined,
    url = undefined
  ) {
    setFoldersStatus((prev) =>
      prev.map((folder) => {
        if (folder.id === id) {
          let data = { progress: folder.progress + 1 };
          if (status === false) {
            data = { ...data, error: [...(folder?.error || []), url] };
          }
          if (progress !== undefined) {
            data = {
              ...data,
              progress,
              duplicateImage: progress,
            };
          }
          return {
            ...folder,
            ...data,
          };
        }
        return folder;
      })
    );
  }

  const handleFolderDownloads = async (
    { id, name }, // eslint-disable-line
    originalQuality = false
  ) => {
    try {
      if (!isDirectorySelectionSupported())
        return toast.error(
          "Batch Download is not supported on this browser. Please use Chrome, Edge or Opera for downloading full album."
        );

      let directory = await window.showDirectoryPicker();
      const folderIndex = foldersStatus.findIndex((folder) => folder.id === id);
      const allPics = isAnonymous ? images : await getFolderImages(id, groupId);

      if (allPics?.length === 0) {
        setFoldersStatus((prev) =>
          prev.map((folder) => {
            if (folder.id === id) {
              return {
                ...folder,
                progress: 0,
                totalPics: 0,
                status: "Empty",
              };
            }
            return folder;
          })
        );
        return;
      }

      // downloadURL is used by default, url is used when downloadURL fails
      const downloadListURLs = allPics.map((pic) => {
        let downloadURL = getCFURL({ url: pic?.url });
        let url = getDOURL({ url: pic?.url });

        if (originalQuality) {
          downloadURL = getCFURL({ url: pic?.url, original: true });

          // original quality is available only on s3, adding compressed url as fallback
          url = getCFURL({ url: pic?.url, original: false });
        }

        return {
          ...pic,
          url,
          downloadURL,
        };
      });

      setFoldersStatus((prev) =>
        prev.map((folder) => {
          if (folder.id === id) {
            return {
              ...folder,
              status: "Downloading",
              progress: 0,
              totalPics: downloadListURLs.length,
            };
          }
          return folder;
        })
      );

      let imagesNameAndSizes = [];

      // if (id !== "All") {
      //   const selectedFolder = foldersStatus?.find((_fold) => {
      //     return _fold?.id === id;
      //   });
      //   const folderNameCheck =
      //     directory?.kind === "directory" &&
      //     (directory?.name === selectedFolder?.name ||
      //       directory?.name === createFolderName(selectedFolder?.name));

      //   if (!folderNameCheck) {
      //     try {
      //       directory = await directory.getDirectoryHandle(newFolderName, {
      //         create: true,
      //       });
      //     } catch (_) {
      //       directory = await directory.getDirectoryHandle(
      //         createFolderName(newFolderName),
      //         {
      //           create: true,
      //         }
      //       );
      //     }
      //   }
      // }

      if (folderIndex >= 0) {
        directorRef.current[folderIndex] = directory;
      }

      for await (const file of getFilesRecursively(directory)) {
        if (!isImage(file.name)) {
          continue;
        }

        imagesNameAndSizes.push({
          name: file.name,
          size: file.size,
        });
      }

      let { toBeDownloadedImages, successResponseCount } =
        getToBeDownloadedImages(
          downloadListURLs,
          imagesNameAndSizes,
          originalQuality
        );

      incrementProgress(id, successResponseCount);

      const toBeDownloadedImages_copy = [...toBeDownloadedImages];

      let portionOfImages = toBeDownloadedImages_copy.splice(-25);

      while (portionOfImages.length > 0) {
        await checkInternetConnection();

        try {
          await downloadImages(portionOfImages, directory, (...rest) =>
            incrementProgress(id, ...rest)
          );
        } catch (_) {
          portionOfImages = [];
        }

        // send download impression after the images are downloaded
        !isAnonymous &&
          dispatch({
            type: IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
            payload: {
              groupId,
              imageIds: portionOfImages.map(
                (image) => image?.imageId ?? image?._id
              ),
              logType: LOG_TYPE.DOWNLOAD,
            },
          });

        portionOfImages = toBeDownloadedImages_copy.splice(-25);
      }
    } catch (e) {
      toast.error("Error occurred while downloading images.");
    }
  };

  const handleRetry = async ({ id, error, progress }) => {
    const folderIndex = foldersStatus.findIndex((folder) => folder.id === id);
    const errors = (error || []).length;
    const diff = progress - errors;

    const directory = directorRef.current[folderIndex];
    // const imagesURLs = foldersStatus[folderIndex]?.error || [];

    // let toBeDownloadedImages = imagesURLs?.map((url) => {
    //   const digitalOceanURL = getDigitalOceanURL(url);
    //   return [digitalOceanURL, url];
    // });

    let toBeDownloadedImages = foldersStatus[folderIndex]?.error || [];

    setFoldersStatus((prev) =>
      prev.map((folder) => {
        if (folder.id === id) {
          return {
            ...folder,
            progress: diff,
            error: [],
          };
        }
        return folder;
      })
    );

    let portionOfImages = toBeDownloadedImages.splice(-5);

    while (portionOfImages.length > 0) {
      await checkInternetConnection();
      try {
        await downloadImages(portionOfImages, directory, (...rest) =>
          incrementProgress(id, ...rest)
        );
      } catch (_) {
        portionOfImages = [];
      }

      portionOfImages = toBeDownloadedImages.splice(-5);
    }
  };

  const checkInternetConnection = async () => {
    let hasInternet = false;
    try {
      await AXIOS.get(process.env.REACT_APP_API_URL);
      hasInternet = true;
    } catch (err) {
      if (err?.code === NETWORK_ERROR) {
        hasInternet = false;
      }

      toast.warning(
        "Seems like you have lost the internet connection. Downloading will resume once the connection is reestablished.",
        {
          position: "top-right",
          autoClose: false,
          pauseOnHover: true,
        }
      );

      while (!hasInternet) {
        await new Promise((resolve) => setTimeout(resolve, 5000));

        try {
          await AXIOS.get(process.env.REACT_APP_API_URL);
          hasInternet = true;
          toast.success("Downloading resumed.");
        } catch (err) {
          if (err?.code === NETWORK_ERROR) {
            hasInternet = false;
          }
        }
      }
    }
  };

  return (
    <div className="download-folders-list d-flex justify-content-center flex-column ">
      <p
        className="text-center fs-5 fw-semibold"
        style={{ color: "rgba(0, 0, 0, 0.4)" }}
      >
        {headerText}
      </p>
      <Folder
        folder={foldersStatus[0]}
        onDownload={handleFolderDownloads}
        onRetry={handleRetry}
        groupId={groupId}
      />
      {foldersStatus?.slice(1)?.length > 0 &&
        type !== SELECTION_TYPE.MY_FOLDER &&
        type !== SELECTION_TYPE.MY_FAVORITES &&
        type !== SELECTION_TYPE.PURCHASED &&
        type !== SELECTION_TYPE.SELECTED_IMAGES && (
          <>
            <p className="mt-5 mb-1">Folders</p>
            <hr className="mt-0" />
            <div className="mt-4">
              {foldersStatus?.slice(1)?.map((folder) => (
                <Folder
                  key={folder?.id}
                  folder={folder}
                  onDownload={handleFolderDownloads}
                  onRetry={handleRetry}
                />
              ))}
            </div>
          </>
        )}
    </div>
  );
};

export default FolderDownloads;
