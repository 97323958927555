import React from "react";
import { useEffect } from "react";
import SecondaryButton from "../../components/button/SecondaryButton";
import DisableUploadButton from "./components/DisableUploadButton";

import { modifyGallaryRoute } from "utils/helpers/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export const handleNoUploadPermission = () => {
  toast.error("You do not have upload permission.\n Contact admin.");
};
export const GallaryEmpty = (props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  useEffect(() => {
    props?.setLoading(false);
  }, []);

  return (
    <div className="emptyGallary flex-col-full-empty-gallary">
      <div className="nophotos-flex-col-full-empty-gallary">
        <p>No Photos to show yet</p>
      </div>
      {props.folderId === "my-photos" ? (
        <SecondaryButton
          onClick={() =>
            navigate(modifyGallaryRoute({ folderId: "all-photos" }, params))
          }
          title="See all photos"
        />
      ) : props?.hasUploadPermission ? (
        <SecondaryButton onClick={props.handleShow} title="Upload Now" />
      ) : (
        <div
          className="cursor-pointer"
          onClick={() => handleNoUploadPermission()}
        >
          <DisableUploadButton />
        </div>
      )}
      <div className="mb-5" />
    </div>
  );
};

export default GallaryEmpty;
