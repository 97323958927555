import * as React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import {
  BUY_PREMIUM_FEATURE,
  GET_ALL_PLANS,
  GET_USER_ANALYTICS,
} from "redux-store/sagas/saga-actions";
import Spinner from "views/components/loader/Spinner";
import { ChooseSubscriptionPlan } from "./ChooseSubscriptionPlan";
import "./subscription.css";
import {
  clearPaymentResponse,
  setSelectedPlan,
  clearSelectedPlan,
  setSlackMessage,
} from "redux-store/slices/globals";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import Backdrop from "views/components/modals/components/Backdrop";
import SecondaryButton from "views/components/button/SmallRoundedButton";
import PaymentLink from "views/components/modals/PaymentLink";
import PlanUpgrade from "views/components/modals/PlanUpgrade";
import { slackMessage } from "utils/helpers/subscription";
import sendSlackMessage from "utils/helpers/sendSlackMessage";
import { useSearchParams } from "react-router-dom";

export default function SubscriptionModal({
  isOpen,
  onClose,
  groupId,
  src = "",
  title = "UPGRADE TO USE THIS FEATURE",
}) {
  const [chosenPlanId, setChosenPlanId] = React.useState();
  const dispatch = useDispatch();
  const { allPlans, plansLoading, featureUpgrades } = useSelector(
    (state) => state.subscription
  );
  const { userAnalytics } = useSelector((state) => state.settings);
  const { paymentResponse, selectedPlan, isUserInIndia } = useSelector(
    (state) => state.globals
  );
  const [showPurchaseFeature, setShowPurchaseFeature] = React.useState(!!src);
  const [params] = useSearchParams();

  const frontendType = params.get("frontendType") || "web";
  const selectedFeatureDetails = featureUpgrades[src] || {};
  const currency = isUserInIndia ? "INR" : "USD";

  const purchasePremiumFeature = () => {
    dispatch(
      setSelectedPlan({
        plan_id: src,
        alreadySubscribed: false,
        ...selectedFeatureDetails,
      })
    );

    dispatch({
      type: BUY_PREMIUM_FEATURE,
      payload: {
        groupId,
        currency,
        premiumFeature: src,
        frontendType,
      },
    });
  };

  React.useEffect(() => {
    if (Object.keys(userAnalytics).length === 0) {
      dispatch({
        type: GET_USER_ANALYTICS,
      });
    }

    if (allPlans && allPlans.length > 0) return;
    dispatch({
      type: GET_ALL_PLANS,
    });
  }, []);

  React.useEffect(() => {
    if (selectedPlan?.plan_id) {
      const text = slackMessage(
        showPurchaseFeature ? src : selectedPlan?.packageName,
        selectedPlan?.period,
        frontendType,
        selectedPlan?.alreadySubscribed,
        groupId
      );
      sendSlackMessage(
        text,
        showPurchaseFeature
          ? "#upgrade-group-premium-feature"
          : "#subscriptions"
      );
      dispatch(setSlackMessage(text));
    }
  }, [selectedPlan, showPurchaseFeature]);

  return (
    <>
      <Modal
        className="subscriptionModal"
        show={isOpen}
        onHide={onClose}
        centered
        size={showPurchaseFeature ? "" : "lg"}
      >
        <div
          className={`subscription-header d-flex p-3 ${
            chosenPlanId ? "justify-content-between" : "justify-content-end"
          }`}
        >
          {chosenPlanId ? (
            <img
              className="cursor-pointer"
              src="/assets/icons/blue-back.svg"
              width={"16px"}
              onClick={() => setChosenPlanId()}
            />
          ) : null}
          <img
            className="cursor-pointer"
            src="/assets/icons/cross.svg"
            width={"16px"}
            onClick={() => onClose()}
          />
        </div>

        <Modal.Header className="d-flex flex-column align-items-center bg-white border-0 m-0 pb-0">
          <div className="d-flex align-items-center">
            <img
              src="../../../assets/images/icons/premium.png"
              className="small ms-1"
              alt="icon"
              width={24}
            />
            <h6 className="mb-0 mx-2 color-primary upgrade-title">{title}</h6>
          </div>
        </Modal.Header>

        <ModalBody className="rounded-3">
          {showPurchaseFeature ? (
            <div className="d-flex flex-column align-items-center justify-content-center p-3">
              <div className="card-tertiary w-100 rounded-2 p-3 pb-4">
                <p className="font-bold font-18 mb-2 text-center">
                  Activate only for this group
                </p>
                <SecondaryButton
                  rounded={false}
                  title={`Pay ${
                    currency === "INR"
                      ? `₹ ${selectedFeatureDetails.INR / 100} + GST`
                      : `$ ${selectedFeatureDetails.USD / 100} + tax`
                  }`}
                  onClick={purchasePremiumFeature}
                  className="mx-auto"
                />
              </div>

              <div className="d-flex w-100 align-items-center">
                <div className="col border-bottom" />
                <p className="m-2 font-13 font-base">OR</p>
                <div className="col border-bottom" />
              </div>

              <div className="card-tertiary w-100 rounded-2 p-3 pb-4">
                <p className="font-bold font-18 mb-2 text-center">
                  Activate for entire account
                </p>
                <SecondaryButton
                  rounded={false}
                  title="Upgrade my plan"
                  onClick={() => setShowPurchaseFeature(false)}
                  className="mx-auto"
                />
              </div>
            </div>
          ) : (
            <ChooseSubscriptionPlan
              onChoose={(planId) => setChosenPlanId(planId)}
            />
          )}
        </ModalBody>
      </Modal>

      <PaymentLink
        show={selectedPlan?.plan_id && !selectedPlan?.alreadySubscribed}
        hide={() => {
          if (showPurchaseFeature) onClose();
          dispatch(clearSelectedPlan());
        }}
        showPurchaseFeature={showPurchaseFeature}
      />

      <PlanUpgrade
        show={selectedPlan?.alreadySubscribed}
        hide={() => dispatch(clearSelectedPlan())}
      />

      <Spinner loading={plansLoading} />

      {paymentResponse?.title && (
        <>
          <Backdrop />
          <ConfirmationModal
            show={paymentResponse?.title}
            onCancel={() => {
              if (src) {
                window.location.reload();
                onClose();
              } else {
                window.location.href = "/profile-settings/profile";
              }
              dispatch(clearPaymentResponse());
            }}
            title={paymentResponse?.title}
            footer={false}
          >
            <p className="text-center font-15 mb-3">
              {paymentResponse?.details}
            </p>
          </ConfirmationModal>
        </>
      )}
    </>
  );
}
