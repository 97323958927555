import React from "react";
import { Modal } from "react-bootstrap";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import css from "./css/SocialShareModal.module.css";

export const SHARE_STRING = "Check out this photo on Kwikpic!";

const SOCIAL_BUTTONS = [
  {
    icon: "/assets/icons/social/facebook.png",
    title: "Facebook",
    share: (url, __, hashtags) => {
      const link = `https://www.facebook.com/sharer/sharer.php?u=${url}&hashtag=${hashtags[0]}`;
      window.open(link, "Share this photo on Facebook", "width=400,height=600");
    },
  },
  {
    icon: "/assets/icons/social/whatsapp.png",
    title: "Whatsapp",
    share: (url, text) => {
      window.open(
        `https://api.whatsapp.com/send?text=${text}%20${url}`,
        "Share this photo on Whatsapp",
        "width=400,height=600"
      );
    },
  },
  {
    icon: "/assets/icons/social/twitter.png",
    title: "Twitter",
    share: (url, text, hashtags, suggest) => {
      const link = `https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}&related=${suggest}`;
      window.open(link, "Share this photo on Twitter", "width=400,height=600");
    },
  },
  {
    icon: "/assets/icons/social/linkedin.png",
    title: "Linkedin",
    share: (url, text) => {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${text}`,
        "Share this photo on Linkedin",
        "width=400,height=600"
      );
    },
  },
  {
    icon: "/assets/icons/social/email.png",
    title: "Email",
    share: (url, text) => {
      window.location.href = `mailto:?subject=${text}&body=${url}`;
    },
  },
];

const LinkShareModal = ({
  show,
  onHide,
  url,
  modalTitle,
  showCopy = true,
  children,
}) => {
  const newUrl = encodeURI(url.toString());

  const handleCopy = () => {
    copy(newUrl);
    toast.success("Link copied to clipboard");
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      animation={false}
      className={css.shareModal}
    >
      <Modal.Header closeButton className="border-0 pb-0 mx-auto">
        <Modal.Title className="font-20">{modalTitle || "SHARE"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        {showCopy && (
          <>
            <div className="mb-3 d-flex">
              <input
                className="border-0 rounded ps-3 py-2 pe-11 w-100"
                defaultValue={newUrl}
                readOnly
              />
              <button
                onClick={handleCopy}
                className="ms-2 px-2 border-0 rounded primaryButton"
              >
                Copy
              </button>
            </div>
            <div className={css.shareContainer}>
              {SOCIAL_BUTTONS.map((social, index) => (
                <button
                  key={index}
                  title={social.title}
                  onClick={() =>
                    social.share(
                      newUrl,
                      SHARE_STRING,
                      ["kwikpic"],
                      "@kwikpic_in"
                    )
                  }
                  className="border-0 bg-transparent p-0"
                >
                  <img
                    src={social.icon}
                    alt={social.title}
                    className="socialIcon"
                  />
                </button>
              ))}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LinkShareModal;
