import React from "react";
import { Card, Modal } from "react-bootstrap";
import { getWhatsappMessage } from "utils/helpers/subscription";
import DefaultButton from "../button/DefaultButton";
import css from "./css/PaymentLink.module.css";
import { useSelector, useDispatch } from "react-redux";
import useOrder from "hooks/useOrder";
import { clearOrderDetails } from "redux-store/slices/subscription";
import Backdrop from "./components/Backdrop";

/**
 * Component for showing payment link modal.
 * @param {message} String - Optional, used to send slack message.
 */
const PaymentLink = ({ show, hide, showPurchaseFeature = false }) => {
  const { paymentLink } = useSelector((state) => state.subscription);
  const { selectedPlan, slackMessage, isUserInIndia } = useSelector(
    (state) => state.globals
  );

  const dispatch = useDispatch();
  const { handlePayment } = useOrder();

  const handlePaymentWithCheck = () => {
    if (process.env.REACT_APP_STANDARD_CHECKOUT == "true")
      return handlePayment();
    else return window.open(paymentLink?.short_url);
  };

  return (
    <>
      {show && <Backdrop onClick={hide} />}
      <Modal
        show={show}
        onHide={() => {
          dispatch(clearOrderDetails());
          hide();
        }}
        centered
        style={{
          zIndex: 1101,
        }}
      >
        <Modal.Header closeButton className="border-0 text-center pb-0">
          <Modal.Title className="w-100">Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {showPurchaseFeature ? null : (
              <p className="d-flex justify-content-between mb-2">
                <span>Billing</span>
                <span>
                  {selectedPlan?.period == "monthly" ? "Quarterly" : "Yearly"}
                </span>
              </p>
            )}
            <p className="d-flex justify-content-between mb-3 font-bold">
              <span>Total Amount</span>
              <span>
                {isUserInIndia ? (
                  <span>
                    ₹&nbsp;
                    {showPurchaseFeature
                      ? selectedPlan?.INR / 100 || "-"
                      : selectedPlan?.price *
                        (selectedPlan?.period == "monthly" ? 3 : 1)}
                    &nbsp;+ GST
                  </span>
                ) : (
                  <span>
                    $&nbsp;
                    {showPurchaseFeature
                      ? selectedPlan?.USD / 100 || "-"
                      : (Math.round(selectedPlan?.price * 100) *
                          (selectedPlan?.period == "monthly" ? 3 : 1)) /
                        100}
                    &nbsp;+ tax
                  </span>
                )}
              </span>
            </p>
            <DefaultButton
              className="font-bold"
              title="Proceed to Pay"
              onClick={handlePaymentWithCheck}
            />
          </div>

          <p className={css.separator}>or</p>
          <p className="color-primary font-bold text-center mb-2">
            Get Payment Link via
          </p>
          <div className="px-4 font-bold color-secondary d-flex flex-row justify-content-center">
            <Card
              onClick={() => {
                window.open(getWhatsappMessage(slackMessage));
              }}
              className="border-0 d-flex flex-row align-items-center cursor-pointer p-0 bg-transparent"
            >
              <img
                src="/assets/icons/social/whatsapp.png"
                className="social-icon mx-4"
              />
            </Card>
            <Card
              onClick={() =>
                window.open(
                  `mailto:sales@kwikpic.in?subject=Account Upgrade&body=${slackMessage}`
                )
              }
              className="border-0 d-flex flex-row align-items-center cursor-pointer p-0 bg-transparent"
            >
              <img
                src="/assets/icons/social/email.png"
                className="social-icon mx-4"
              />
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentLink;
