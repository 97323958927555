import React from "react";
import "./SelectRadioGroup.css";

const SelectRadioGroup = ({
  title,
  options,
  onChange = () => {},
  selectedValue,
  recommended = -1,
  titleClassName = "mb-3",
  extra,
}) => {
  if (Array.isArray(options) && options.length > 0) {
    const _id = randomID();

    const handleChange = ({ target }) => {
      const selectedID = target.id;
      const selectedIDIndex = selectedID.split("-")[1];
      onChange(options[selectedIDIndex].value);
    };

    const isChecked = (index) => {
      return options[index].value === selectedValue;
    };

    const getDescription = (desc) => {
      if (Array.isArray(desc)) {
        return desc.map((d, i) => (
          <p key={i} className="radio-para">
            <img src={d.icon} className="radio-group-icons" />
            {d.text}
          </p>
        ));
      }
      return desc;
    };

    return (
      <div className="settingRadioCard">
        {title && (
          <h4 className={titleClassName}>
            {title} {extra}
          </h4>
        )}

        {options.map((option, index) => {
          const radioID = `${_id}-${index}`;
          return (
            <div
              key={index + option.heading}
              className="form-check mb-3 position-relative"
            >
              <input
                id={radioID}
                className="form-check-input d-none"
                checked={selectedValue !== undefined ? isChecked(index) : false}
                type="radio"
                onChange={handleChange}
                name={title}
              />
              <label className="form-check-label" htmlFor={radioID}>
                {index === recommended ? (
                  <span className="recommend-label lh-1">Recommended</span>
                ) : null}
                <span className="checked"></span>
                <span className="unchecked"></span>
                <div className="ms-4">
                  <h4>{option.heading}</h4>
                  <span>{getDescription(option.description)}</span>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    );
  }
};
export default SelectRadioGroup;

export function randomID() {
  return "_" + Math.random().toString(36).substr(2, 9);
}
