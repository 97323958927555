import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "./Table";
import cx from "classnames";
import {
  setSaveLoader,
  // setSettingLoader,
} from "../../../redux-store/slices/settings";
import Spinner from "../../components/loader/Spinner";
import {
  CREATE_TRANSFER_REQUEST,
  PATCH_GROUP_PARTICIPANTS_MAKE_ADMIN,
  // GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  REMOVE_PARTICIPANT,
  TOGGLE_BLOCK_PARTICIPANT,
  UPDATE_GROUP_ACCESS,
  SEND_USER_CLICK_ANALYTICS,
} from "../../../redux-store/sagas/saga-actions";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import css from "../../settings/participants/index.module.css";
import ConfirmationModal from "../modals/ConfirmationModal";
import OutlinedButton from "../button/OutlinedButton";
import ANALYTICS from "constants/analyticsKeys";

const totalWidth = 1000;

const ADMIN_ACTIONS = {
  BLOCK: "block",
  UNBLOCK: "unblock",
  NONE: "",
};

const ParticipantsTable = ({
  admin,
  analyticsEnabled,
  highlight,
  search = "",
  showBlocked = false,
}) => {
  const dispatch = useDispatch();
  const [media, setMedia] = useState(
    window.matchMedia("(max-width: 410px)").matches
  );

  useEffect(() => {
    window.matchMedia("(max-width: 410px)").addEventListener("change", (e) => {
      setMedia(e.matches);
    });
  }, []);

  const columns = analyticsEnabled
    ? [
        {
          dataKey: "name",
          label: "Name",
          width: media ? window.innerWidth - 120 : 250,
        },
        {
          dataKey: "email",
          label: "Email",
          width: 250,
        },
        {
          dataKey: "phone",
          label: "Phone",
          width: 250,
        },
        {
          dataKey: "role",
          label: "Role",
          width: media ? 120 : 250,
        },
      ]
    : [
        {
          dataKey: "name",
          label: "Name",
          width: media ? window.innerWidth - 120 : 250,
        },
        {
          dataKey: "role",
          label: "Role",
          width: media ? 120 : 250,
        },
      ];

  // useEffect(() => {
  //   dispatch(setSettingLoader(true));
  //   dispatch({
  //     type: GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  //     groupID: params.get("groupId"),
  //   });
  // }, []);

  const {
    participantsSettings,
    saveLoader,
    settingLoader,
    groupDetails: { createdBy, isPrivate },
  } = useSelector((state) => state.settings);

  const [settings, setSettings] = React.useState(participantsSettings);
  const [confirmationModal, setConfirmationModal] = React.useState({
    show: false,
    participant: null,
  });
  const [adminAction, setAdminAction] = React.useState({
    action: ADMIN_ACTIONS.NONE,
    participant: null,
  });

  useEffect(() => {
    if (Object.keys(participantsSettings).length > 0) {
      setSettings(participantsSettings);
    }
  }, [participantsSettings]);

  const { participants, groupId, blockedParticipants } = settings;

  const user = getLocalStorageUser();
  const isCreator = user?._id === createdBy;

  const changeAccessLevel = (participantId, allPicsAccess) => {
    dispatch({
      type: UPDATE_GROUP_ACCESS,
      payload: {
        id: groupId,
        participantId,
        allPicsAccess,
      },
    });
  };

  const handleBlockParticipant = (participantId, blockUser) => {
    dispatch({
      type: TOGGLE_BLOCK_PARTICIPANT,
      payload: {
        groupId,
        participantId,
        blockUser,
        src: showBlocked ? "blocked" : "",
      },
    });
  };

  const handleChange = (eventKey, selectedParticipant) => {
    const { participantId } = selectedParticipant || {};

    if (eventKey === "RemoveParticipant") {
      dispatch({
        type: REMOVE_PARTICIPANT,
        payload: {
          groupId,
          participantId,
        },
      });

      return dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.PARTICIPANT_REMOVE] },
      });
    }

    if (eventKey === "Block") {
      return setAdminAction({
        action: ADMIN_ACTIONS.BLOCK,
        participant: selectedParticipant,
      });
    }

    if (eventKey === "Unblock") {
      return setAdminAction({
        action: ADMIN_ACTIONS.UNBLOCK,
        participant: selectedParticipant,
      });
    }

    if (eventKey === "TransferOwnership") {
      setConfirmationModal({
        show: true,
        participant: selectedParticipant,
      });

      return dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.PARTICIPANT_TRANSFER] },
      });
    }

    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.PARTICIPANT_CHANGE_ROLE] },
    });

    if (eventKey === "Viewer - Partial Access")
      return changeAccessLevel(participantId, false);
    if (eventKey === "Viewer - Full Access")
      return changeAccessLevel(participantId, true);

    const changeToAdmin = eventKey === "Admin";
    if (selectedParticipant.isAdmin && changeToAdmin) return;
    else {
      dispatch(setSaveLoader(true));
      dispatch({
        type: PATCH_GROUP_PARTICIPANTS_MAKE_ADMIN,
        payload: {
          isAdmin: changeToAdmin,
          participantId,
          groupId,
        },
      });
    }
  };

  const confirmOwnershipChange = () => {
    dispatch({
      type: CREATE_TRANSFER_REQUEST,
      payload: {
        groupId,
        receiverId: confirmationModal.participant?.participantId,
      },
    });

    setConfirmationModal({
      show: false,
      participant: null,
    });
  };

  const updatedParticipants = useMemo(() => {
    return (showBlocked ? blockedParticipants : participants)
      ?.map((participant) => {
        if (participant?.participantId === user?._id) {
          return {
            ...participant,
            name: "You",
          };
        }
        return participant;
      })
      .filter((p) =>
        [
          p?.name?.toLowerCase(),
          p?.email?.toLowerCase(),
          p?.phoneNumber?.toLowerCase(),
        ]
          .join(" ")
          .includes(search)
      );
  }, [showBlocked, blockedParticipants, participants, user?._id, search]);

  const rows = generateRows(
    updatedParticipants,
    handleChange,
    admin,
    isCreator,
    isPrivate,
    highlight,
    showBlocked
  );

  return (
    <>
      <Spinner loading={saveLoader || settingLoader} />
      {rows.length === 0 ? (
        <p className="text-center font-16 font-md-18 color-4 my-5 font-bold w-100">
          {`No ${showBlocked ? "blocked " : ""}participants found`}
        </p>
      ) : (
        <CustomTable
          rows={rows}
          columns={columns}
          totalWidth={media ? window.innerWidth - 40 : totalWidth}
          admin={admin}
          analyticsEnabled={analyticsEnabled}
          handleChange={(isAdmin, participantId) =>
            handleChange(isAdmin, participantId)
          }
        />
      )}

      {isCreator && (
        <ConfirmationModal
          show={confirmationModal.show}
          title={`Transfer ownership to ${confirmationModal.participant?.name}?`}
          onCancel={() =>
            setConfirmationModal({
              show: false,
              participant: null,
            })
          }
          onConfirm={confirmOwnershipChange}
          message="You will be the owner until this person accepts ownership. Once this person accepts ownership, you will no longer have these photos on your account."
        />
      )}

      {adminAction.action && adminAction.participant && (
        <ConfirmationModal
          show={true}
          title={
            adminAction.action === ADMIN_ACTIONS.BLOCK
              ? `Block Participant?`
              : adminAction.action === ADMIN_ACTIONS.UNBLOCK
              ? `Unblock Participant?`
              : ""
          }
          message={
            adminAction.action === ADMIN_ACTIONS.BLOCK
              ? `${
                  adminAction.participant.name || "Participant"
                } will be blocked from this group. ${
                  adminAction.participant.name || "Participant"
                } will not be able to join this group again.`
              : adminAction.action === ADMIN_ACTIONS.UNBLOCK
              ? `${
                  adminAction.participant.name || "Participant"
                } will be unblocked and will be able to access the group again.`
              : ""
          }
          onCancel={() =>
            setAdminAction({ action: ADMIN_ACTIONS.NONE, participant: null })
          }
          onConfirm={() => {
            if (adminAction.action === ADMIN_ACTIONS.BLOCK) {
              handleBlockParticipant(
                adminAction.participant.participantId,
                true
              );
            } else if (adminAction.action === ADMIN_ACTIONS.UNBLOCK) {
              handleBlockParticipant(
                adminAction.participant.participantId,
                false
              );
            }

            setAdminAction({ action: ADMIN_ACTIONS.NONE, participant: null });
          }}
          animation
        />
      )}
    </>
  );
};

export default ParticipantsTable;

function generateRows(
  participants,
  handleChange,
  admin,
  isCreator,
  isPrivate,
  highlight,
  blockedView
) {
  const { _id: userId } = getLocalStorageUser();

  return (participants || []).map((participant, i) => {
    return {
      name: (
        <div className="d-flex align-items-center">
          <div className="userImage me-2 overflow-hidden d-flex justify-content-center align-items-center">
            <img
              src={participant.avatar || "/assets/images/icons/user.png"}
              alt="avatar"
              width="100%"
            />
          </div>
          {participant.name}
        </div>
      ),
      email: participant?.email || "_",
      phone: participant?.phoneNumber || "_",
      role: blockedView ? (
        <OutlinedButton
          title="Unblock"
          className="h-auto py-2 px-4 min-w-auto"
          onClick={() => handleChange("Unblock", participant)}
        />
      ) : (
        <RoleDropdown
          admin={admin}
          participant={participant}
          isCreator={isCreator}
          isPrivate={isPrivate}
          className={highlight && i === 0 ? css.highlight : ""}
          onSelect={(eventKey) => {
            handleChange(eventKey, participant);
          }}
          userId={userId}
          blockedView={blockedView}
        />
      ),
    };
  });
}

function RoleDropdown({
  onSelect,
  participant,
  admin,
  isCreator,
  className,
  isPrivate,
  userId,
}) {
  const { isAdmin, participantId, allPicsAccess } = participant || {};

  const dropDownClassName = cx({
    "m-0 thick-font font-15 d-flex justify-content-center participants-dropdown": true,
    "gray-text": !admin || userId === participantId,
    "bright-blue-text": admin && userId !== participantId,
  });

  const dropdownBorderClassName = cx({
    roleDropdown: true,
    "not-active-border": !admin || userId === participantId,
    "active-border": admin && userId !== participantId,
  });

  return (
    <Dropdown
      className={dropdownBorderClassName}
      onSelect={(eventKey) => {
        onSelect(eventKey);
      }}
    >
      <Dropdown.Toggle
        id="dropdown-button-dark-example1"
        style={{ width: "70px" }}
        variant="secondary"
        className={className}
      >
        <p className={dropDownClassName}>
          {isAdmin ? (
            "Admin"
          ) : isPrivate ? (
            "Viewer"
          ) : allPicsAccess ? (
            <p className="m-0 lh-1 text-center">
              <span className="font-14 font-bold color-secondary">Viewer</span>
              <br />
              <span className="font-11">Full Access</span>
            </p>
          ) : (
            <p className="m-0 lh-1 text-center">
              <span className="font-14 font-bold color-secondary">Viewer</span>
              <br />
              <span className="font-11">Partial Access</span>
            </p>
          )}
        </p>

        {admin && userId !== participantId && (
          <img
            src="/assets/images/icons/arrow-down.png"
            alt="drop-down-arrow"
          />
        )}
      </Dropdown.Toggle>

      {admin && userId !== participantId && (
        <Dropdown.Menu variant="light" className="p-0">
          <Dropdown.Item
            eventKey="Admin"
            active={isAdmin}
            className="m-0 thick-font font-15 bright-blue-text"
          >
            Admin
          </Dropdown.Item>
          {isPrivate ? (
            <Dropdown.Item
              eventKey="Viewer"
              active={!isAdmin}
              className="m-0 thick-font font-15 bright-blue-text"
            >
              Viewer
            </Dropdown.Item>
          ) : (
            <>
              <Dropdown.Item
                eventKey="Viewer - Partial Access"
                active={!isAdmin && !allPicsAccess}
                className="m-0 thick-font font-15 bright-blue-text"
              >
                Viewer - Partial Access
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="Viewer - Full Access"
                active={!isAdmin && allPicsAccess}
                className="m-0 thick-font font-15 bright-blue-text"
              >
                Viewer - Full Access
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item
            eventKey="Block"
            className="m-0 thick-font font-15 color-danger"
          >
            Block Participant
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="RemoveParticipant"
            className="m-0 thick-font font-15 color-danger"
          >
            Remove Participant
          </Dropdown.Item>
          {isCreator && (
            <Dropdown.Item
              eventKey="TransferOwnership"
              className="m-0 thick-font font-15 bright-blue-text"
            >
              Transfer Ownership
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}
