import useLogoutHandler from "hooks/useLogoutHandler";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  GET_MY_SUBSCRIPTION_PLAN,
  GET_USER_ANALYTICS,
} from "redux-store/sagas/saga-actions";
import {
  getFeaturesAccessFromLocalStorage,
  getUserSubscriptionLastUpdate,
  getUserAnalyticsFromLS,
  getUserSubscriptionData,
  // getUserAnalyticsLastUpdate,
} from "utils/helpers/localstorage";

export const TIME_GAP = 86400000; // 24hrs

const useSubscription = () => {
  const dispatch = useDispatch();
  const { logoutHandler } = useLogoutHandler();

  const hasAccessToFeature = useCallback(
    (feature_text) => {
      if (!Object.values(PLAN_FEATURES).includes(feature_text)) {
        return false;
      }
      const features = getFeaturesAccessFromLocalStorage();

      if (Array.isArray(features)) {
        const feature = features?.find?.((feat) => feat.name === feature_text);
        if (!feature) return false;
        return feature?.enabled;
      } else {
        logoutHandler();
      }
    },
    [logoutHandler]
  );

  const getFeatureLimit = useCallback(
    (feature_text) => {
      if (!Object.values(PLAN_FEATURES).includes(feature_text)) {
        return false;
      }
      const features = getFeaturesAccessFromLocalStorage();

      if (Array.isArray(features)) {
        const feature = features?.find?.((feat) => feat.name === feature_text);
        if (!feature || !feature?.enabled) return 0;
        return feature?.limit;
      } else {
        logoutHandler();
      }
    },
    [logoutHandler]
  );

  const getMySubscriptionPlanAPI = () => {
    const lastTime = Number(getUserSubscriptionLastUpdate());

    // means its over 24hrs we have not fetched the user subscription plan
    if (Date.now() - lastTime >= TIME_GAP) {
      dispatch({ type: GET_MY_SUBSCRIPTION_PLAN });
    }
  };

  const getUserAnalyticsAPI = () => {
    // const lastTime = Number(getUserAnalyticsLastUpdate());

    // means its over 24hrs we have not fetched the user subscription plan
    // if (Date.now() - lastTime >= TIME_GAP) {
    dispatch({ type: GET_USER_ANALYTICS });
    // }
  };

  const getMyActivePlan = () => {
    return getUserSubscriptionData();
  };

  const hasExceededUploadLimit = () => {
    const { userAnalytics, uploadLimit } = getUserAnalyticsFromLS() || {};
    const { photoUploads, photoDeletes } = userAnalytics || {};

    return (
      Number(photoUploads || 0) - Number(photoDeletes || 0) >=
      Number(uploadLimit || 0)
    );
  };
  return {
    hasAccessToFeature,
    getFeatureLimit,
    getMySubscriptionPlanAPI,
    getMyActivePlan,
    hasExceededUploadLimit,
    getUserAnalyticsAPI,
  };
};

export default useSubscription;

export const PLAN_FEATURES = {
  extended_photo_storage: "extended_photo_storage",
  business_branding: "business_branding",
  high_quality_downloads: "high_quality_downloads",
  access_client_favorites: "access_client_favorites",
  switch_on_off_downloads: "switch_on_off_downloads",
  group_gallery_design_templates: "group_gallery_design_templates",
  anonymous_viewing: "anonymous_viewing",
  view_analytics: "view_analytics",
  bulk_downloads: "bulk_downloads",
  watermark: "watermark",
  portfolio: "portfolio",
  DIGITAL_ALBUM: "digital_album",
  SPONSOR_BRANDING: "sponsor_branding",
  MUTLI_ACCESS: "multi_access",
};
