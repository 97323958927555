import React from "react";

const TestimonialItem = ({ item }) => {
  return (
    <>
      <div className="testimonial-item">
        <h1>{item.title}</h1>
        <p className="testimonial-text">{item.text}</p>
        <div className="testimonail-dots">.&nbsp;.&nbsp;.</div>
        <p className="testimonial-name">{item.name}</p>
        <p className="testimonial-party">{item.party}</p>
      </div>
    </>
  );
};
export default TestimonialItem;
