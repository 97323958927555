import useDetectUserAgent from "hooks/useDetectUserAgent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GET_ANONYMOUS_USER_IMAGES } from "redux-store/sagas/saga-actions";
import AlbumDownload from "views/download/AlbumDownload";
import "./styles/downloadRoute.css";
import Anonymoushead from "views/anonymous/anonymousHead";

const AnonymousDownloadRoute = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deviceType, setDeviceType] = useState({ type: "desktop" });
  useDetectUserAgent(setDeviceType);

  const { allowDownload, bulkDownload } = useSelector(
    (state) => state.settings.downloadSettings
  );

  useEffect(() => {
    if (!params.has("groupId") || !params.has("anonymousUserId")) {
      navigate("/");
    }
  }, [params]);

  useEffect(() => {
    dispatch({
      type: GET_ANONYMOUS_USER_IMAGES,
      payload: {
        userId: params.get("anonymousUserId"),
        groupId: params.get("groupId"),
      },
    });
  }, []);

  useEffect(() => {
    if (deviceType?.type === "mobile") {
      navigate("/");
    }
  }, [deviceType?.type]);

  const downloadAllowed = bulkDownload && allowDownload;

  return (
    <>
      <Anonymoushead goBack={true} />
      {!downloadAllowed ? (
        <div className="background-image">
          <h3 className="mb-3 text-center header-text">
            Album Downloading is disabled
          </h3>
          <p className="text-center description-text">
            Contact group admin for access.
          </p>
        </div>
      ) : (
        <div className="download-page">
          <div className="download-page-content">
            <div className="p-3">
              <AlbumDownload isAnonymous={true} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnonymousDownloadRoute;
