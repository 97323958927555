import React from "react";
import copy from "clipboard-copy";
import css from "./Buttons.module.css";
import cx from "classnames";

const CopyButton = ({ title, copyText, onClickCb = () => {}, className }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const onClick = () => {
    onClickCb();
    setIsCopied(true);
    copy(copyText);
  };

  React.useEffect(() => {
    let timeout;
    if (isCopied)
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  return (
    <button
      title="Copy to clipboard"
      onClick={onClick}
      className={cx(className, css.copyButton)}
    >
      <img src="/assets/images/icons/copy.png" alt="copy" height={15} />
      {isCopied ? "Copied" : title}
    </button>
  );
};

export default CopyButton;
