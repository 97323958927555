import React from "react";
import { ProgressBar } from "react-bootstrap";
import cx from "classnames";

const ProgressCard = ({
  leftText,
  rightText,
  type,
  progressPercentage = 0,
  variant = "default",
  fullWidth = false,
}) => {
  const progressClassName = cx({
    "progressCard d-flex w-100": true,
    "progressCard-danger": variant === "danger",
  });
  const leftTextClassName = cx({
    "progressCard-left-text text-clip": true,
    "font-12 font-base": !(type === "files"),
    "font-15 font-bold": type === "files",
  });
  return (
    <div className={progressClassName}>
      <div
        className={`d-flex flex-column justify-content-center w-100 ${
          fullWidth ? "pe-4" : "limit-w-547"
        }`}
      >
        <div className="d-flex justify-content-between">
          <span className={leftTextClassName}>{leftText}</span>
          <span className="text-end font-12 font-base progressCard-right-text text-clip">
            {rightText}
          </span>
        </div>
        <ProgressBar now={progressPercentage} />
      </div>
    </div>
  );
};
export default ProgressCard;
