import useAuth from "hooks/useAuth";
import React from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { lsProxy } from "utils/helpers/localstorage";

const initSocket = async () => {
  let val = lsProxy.getItem("token");
  let token;
  if (val !== null) {
    token = val.split(" ")[1];
  } else {
    return;
  }

  const options = {
    transports: ["websocket"],
    upgrade: false,
    rejectUnauthorized: false,
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    timeout: 20000,
    autoConnect: true,
    query: { token },
  };
  let socket = io(process.env.REACT_APP_API_URL, options);

  return socket;
};

/**
 * @type {React.Context<import("socket.io-client").Socket>}
 */
export const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();

  // using _id here to trigger useEffect when user logs in
  const { user: { _id } = {} } = useSelector((state) => state.user);

  /**
   * @type [import("socket.io-client").Socket, React.SetStateAction<import("socket.io-client").Socket>]
   */
  const [socket, setSocket] = React.useState(null);
  const notLoggedIn = !_id && !isAuthenticated;

  React.useEffect(() => {
    if (notLoggedIn) return;

    let _socket;
    initSocket().then((s) => {
      _socket = s;
      setSocket(s);
    });

    return () => _socket?.disconnect();
  }, [notLoggedIn]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;
