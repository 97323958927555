import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { modifyGallaryRoute } from "utils/helpers/routes";
import Lightbox from "yet-another-react-lightbox";

import { VIDEOS_PER_PAGE } from "./FolderVideos";
import LightboxToolbarVideo from "./LightboxToolbarVideo";
import { pushState } from "utils/helpers/debounced-history";
import { stopVideo } from "utils/helpers/gallery";

const LightboxVideos = ({
  open,
  folderId,
  closeImageView,
  vidIndex,
  setVideoIndex,
  setOpen,
  page,
  params,
}) => {
  const [isYoutube, setIsYoutube] = useState(false);
  const { allVideos } = useSelector((state) => state.folderImages);

  useEffect(() => {
    const newPage = Math.floor(vidIndex / VIDEOS_PER_PAGE) + 1;

    if (newPage !== page) {
      pushState(
        null,
        "",
        modifyGallaryRoute(
          { pageNo: newPage, videoId: allVideos[vidIndex]?._id },
          params
        )
      );
    } else {
      pushState(
        null,
        "",
        modifyGallaryRoute(
          { pageNo: newPage, videoId: allVideos[vidIndex]?._id },
          params
        )
      );
    }
  }, [vidIndex, allVideos]);

  const videosData = useMemo(() => {
    if (folderId === "videos") {
      // let videoData = allVideos?.map((items) => {
      //   return {
      //     sources: [
      //       {
      //         src: items?.url,
      //         type: "video/mp4",
      //       },
      //     ],
      //     width: 1280,
      //     height: 720,
      //     type: "video",
      //     poster: items?.url,
      //   };
      // });

      const data = allVideos?.map((video, index) => {
        return { src: video?.url || "", index, type: "video" };
      });

      return data;
    }
  }, [allVideos, folderId]);

  useEffect(() => {
    if (videosData[vidIndex]?.src.includes("youtube")) {
      setIsYoutube(true);
    } else {
      setIsYoutube(false);
    }
  }, [vidIndex, videosData]);

  useEffect(() => {
    stopVideo();
  }, [vidIndex]);

  return (
    <Lightbox
      key={allVideos[vidIndex]?._id}
      open={open}
      index={vidIndex}
      on={{
        view: ({ index }) => {
          setVideoIndex(index);
        },
      }}
      close={() => {
        setOpen(false);
        closeImageView();
      }}
      slides={videosData}
      carousel={{
        preload: 0,
      }}
      render={{
        slide: ({ slide }) => {
          if (slide?.src) {
            if (slide?.src?.includes("youtube.com")) {
              return (
                <iframe
                  width="100%"
                  id={"youtube_player"}
                  className="yt_player_iframe"
                  height="100%"
                  src={`${slide?.src}?t=${new Date().valueOf()}`}
                  title="YouTube video player"
                  referrerPolicy="no-referrer-when-downgrade"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowscriptaccess="always" //eslint-disable-line
                ></iframe>
              );
            } else {
              return (
                <video
                  key={slide?.index}
                  width="100%"
                  height="100%"
                  className="_videos"
                  controls
                  onBlur={(e) => e.target.pause()}
                  nofullscreen //eslint-disable-line
                  playsInline
                >
                  <source src={slide?.src} type="video/mp4" />
                </video>
              );
            }
          }
        },
      }}
      toolbar={{
        buttons: [
          <LightboxToolbarVideo
            key={folderId}
            vidIndex={vidIndex}
            closeImageView={closeImageView}
            isYoutube={isYoutube}
          />,
        ],
      }}
    />
  );
};

export default LightboxVideos;
