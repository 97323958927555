import { LINKS_REGEX } from "constants/regexlib";

/**
 * @param {string} url
 * @param {number} urlMaxLength
 * @returns {string}
 */
const removeQueryParams = (url, urlMaxLength = 64) => {
  if (!LINKS_REGEX.test(url)) return url;

  const qIndex = url.indexOf("?");
  if (qIndex === -1) return url;

  const urlBase = url.slice(0, qIndex);
  if (urlBase.length > urlMaxLength) return url;
  return urlBase;

  /**
   * To-do
   * Don't change the order of query params
   */

  // const searchParams = new URLSearchParams(url.slice(qIndex));

  // /**
  //  * keep on removing query params until the length is less than urlMaxLength
  //  * or there are no more query params
  //  */
  // while (
  //   searchParams.size &&
  //   urlBase.length + searchParams.toString().length > urlMaxLength
  // ) {
  //   searchParams.delete(searchParams.keys().next().value);
  // }

  // if (searchParams.size) return `${urlBase}?${searchParams.toString()}`;

  // return urlBase;
};

export default removeQueryParams;
