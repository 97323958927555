import React from "react";

const Fallback = () => {
  return (
    <div className="text-center">
      <h4>Something went wrong.</h4>
      <p className="font-base">
        Please try refreshing the page.
        <br />
        If the problem persists,&nbsp;
        <a
          href="https://www.kwikpic.in/landing/contactus"
          target="_blank"
          rel="noopener noreferrer"
        >
          contact support
        </a>
        .
      </p>
    </div>
  );
};

export default Fallback;
