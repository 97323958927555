import React from "react";
import { Modal } from "react-bootstrap";
import SecondaryButton from "views/components/button/SecondaryButton";

const UserAgentErrorModal = ({
  show,
  onHide,
  deviceType,
  variant = 0,
  textOverride = "",
}) => {
  const prop = [
    {
      text: "Download the Kwikpic App or use the Kwikpic Desktop Version to enable this feature.",
      image: "/assets/images/gallary/no-download-mobile.png",
    },
    {
      text: "Open the Kwikpic website on a computer or laptop to access this feature.",
      image: "/assets/images/gallary/no-download.png",
    },
    {
      text: "Download the Kwikpic App to use this feature.",
      image: "/assets/images/gallary/mobile.png",
    },
  ];

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0 d-none" />
      <Modal.Body>
        <div className="user-agent-modal">
          <img src={prop[variant].image} />
          <p className="bold-font font-15 my-3">
            {textOverride || prop[variant].text}
          </p>
          <DownloadButtons variant={variant} deviceType={deviceType} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const DownloadButtons = ({ variant, deviceType, className }) => {
  return (
    <>
      {variant == 0 || (deviceType?.type == "mobile" && variant == 2) ? (
        <SecondaryButton
          title={"Download Kwikpic"}
          style={{ width: "100%" }}
          className={className}
          onClick={() => {
            window.open(
              deviceType?.os === "ios"
                ? "https://apps.apple.com/app/kwikpic-smart-photo-sharing/id1635944378"
                : "https://play.google.com/store/apps/details?id=com.kwicpic",
              "_blank"
            );
          }}
        />
      ) : variant == 2 ? (
        <div className="ua-store-links">
          <img
            role="button"
            src="/assets/images/landing/appstore-badge.png"
            alt="apple store link"
            onClick={() =>
              window.open(
                "https://apps.apple.com/app/kwikpic-smart-photo-sharing/id1635944378"
              )
            }
          />
          <img
            role="button"
            src="/assets/images/landing/google-play-badge-logo.png"
            alt="play store link"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.kwicpic"
              )
            }
          />
        </div>
      ) : null}
    </>
  );
};

export default UserAgentErrorModal;
