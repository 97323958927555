import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FocusedImage } from "image-focus";

const FocusedImageComponent = ({
  imageSrc,
  x = 0,
  y = 0,
  onError = () => {},
}) => {
  const imageRef = useRef(null);
  useEffect(() => {
    if (imageRef.current !== null) {
      new FocusedImage(imageRef.current, {
        focus: {
          x,
          y,
        },
      });
    }
  }, [x, y]);

  return (
    <img
      alt="Focused"
      ref={imageRef}
      src={imageSrc}
      className="focused-image"
      onError={onError}
    />
  );
};

FocusedImageComponent.defaultProps = {
  x: 0,
  y: 0,
};

FocusedImageComponent.propTypes = {
  imageSrc: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
};

export default FocusedImageComponent;
