import React from "react";
import SettingPageHeading from "./components/SettingPageHeading";
import ClientFavTable from "../components/table/ClientFavTable";
import css from "./css/ClientFavourite.module.scss";
import GroupedButton from "views/components/button/GroupedButton";
import { useSearchParams } from "react-router-dom";

const ClientFavourite = () => {
  const [params, setParams] = useSearchParams();
  const isAdmin = params.get("isAdmin") === "true";

  return (
    <div>
      <SettingPageHeading
        title="Client Favorites"
        subTitle="Images selected as favorites by group admins, among the photos uploaded by you"
        btnThree={
          <GroupedButton
            primaryText="Admins"
            secondaryText="Users"
            primaryClassName="px-3 min-w-auto"
            secondaryClassName="px-3 min-w-auto"
            primaryOnClick={() => {
              params.set("isAdmin", true);
              params.set("page", 1);
              setParams(params);
            }}
            secondaryOnClick={() => {
              params.set("isAdmin", false);
              params.set("page", 1);
              setParams(params);
            }}
            variant="xss"
            active={isAdmin ? 0 : 1}
          />
        }
      />
      <div className={css.clientFavTable}>
        <ClientFavTable />
      </div>
    </div>
  );
};

export default ClientFavourite;
