import React from "react";
import cx from "classnames";

function PricingLeftArrow({ onClick }) {
  const disabled = false;
  // className.toString().split(" ").at(-1) === "slick-disabled";

  const arrowClass = cx({
    "pricing-arrow left-pricing-arrow": true,
    "not-disabled-pricing-arrow": !disabled,
    "disabled-pricing-arrow": disabled,
  });
  // const arrowFill = cx({
  //   "#2887af": !disabled,
  //   "#a9e2fa": disabled,
  // });
  return (
    <div onClick={onClick} className={arrowClass}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#BDD4DF" fillOpacity="0.36" />
        <path
          d="M20.2126 15.9575L30.1701 25.532L20.2126 35.1065"
          stroke="#3E6588"
          strokeWidth="3.19149"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

export default PricingLeftArrow;
