import { put, call, takeLatest } from "redux-saga/effects";
import { AXIOS } from "../../utils/setup/axios";
import { GET_MY_GROUP_ANALYTICS } from './saga-actions';
import { setMyAnalytics, setAnalyticsLoader } from "../slices/analytics";

async function getMyAnalytics(groupId){
    return AXIOS.get(`/api/app/analytics/groupAnalytics/${groupId}`);
}

function* getMyGroupAnalyticsGenerator(action) {
    const {groupId} = action.payload;
    try {
        const response = yield call(getMyAnalytics, groupId);
        const {status, message, data} = response.data;
        yield put(setMyAnalytics({data, status, message}));
        yield put(setAnalyticsLoader(false));
    } catch(e) {
        // 
    }
}

export function* analyticsSaga(){
    yield takeLatest(GET_MY_GROUP_ANALYTICS, getMyGroupAnalyticsGenerator)
}