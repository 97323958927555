import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCFURL, getDOURL } from "utils/helpers";
import {
  downloadImages,
  isDirectorySelectionSupported,
} from "views/download/helpers";
import SubscriptionModal from "views/subscription";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import {
  GET_FAVOURITE_IMAGES,
  SEND_USER_CLICK_ANALYTICS,
} from "../../../redux-store/sagas/saga-actions";
import Spinner from "../loader/Spinner";
import ConfirmationCommentModal from "../modals/ConfirmationCommentModal";
import CustomTable from "./Table";
import ClientFavPagination from "./Pagination";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { saveAs } from "file-saver";
import ANALYTICS from "constants/analyticsKeys";

const ClientFavTable = () => {
  const {
    clientFavourites: { favourites },
    settingLoader,
    participantsSettings: { participants },
  } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [subscriptionModal, setSubscriptionModal] = React.useState(false);
  const [selectedParticipant, setSelectedParticipant] = React.useState(null);

  const [params, setParams] = useSearchParams();
  const isAdmin = params.get("isAdmin") === "true";

  const columns = [
    {
      label: isAdmin ? "Admin Name" : "User Name",
      dataKey: "adminName",
      width: 250,
    },
    {
      label: "Image Link",
      dataKey: "downloadImageLink",
      width: 250,
    },
    {
      label: "Txt/CSV File",
      dataKey: "downloadTXTfileLink",
      width: 250,
    },
  ];

  const { hasAccessToFeature } = useSubscription();
  const { _id: userId } = getLocalStorageUser();

  const adminCount =
    React.useMemo(() => {
      return participants?.filter((participant) => participant?.isAdmin)
        ?.length;
    }, [participants]) || 0;
  const usersCount = participants?.length - adminCount || 0;

  const getPageData = (page) => {
    dispatch({
      type: GET_FAVOURITE_IMAGES,
      payload: {
        groupID: params.get("groupId"),
        isViewer: !isAdmin,
        page,
        limit: 50,
      },
    });
  };

  useEffect(() => {
    getPageData(params.get("page") || 1);
  }, [params]);

  useEffect(() => {
    hasFeatureAccess();
  }, [favourites]);

  const hasFeatureAccess = () => {
    if (!hasAccessToFeature(PLAN_FEATURES.access_client_favorites)) {
      setSubscriptionModal(true);
      return false;
    }
    return true;
  };

  const handleDownloadImages = async (participantId) => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.CLIENT_FAVOURITES_IMG_DOWNLOAD] },
    });

    try {
      if (!hasFeatureAccess()) return;

      const data = favourites.find((favourite) => {
        return favourite?.participant?.participantId === participantId;
      });

      const clientName = data?.participant?.user?.name;
      const clientId = data?.participant?.user?._id;

      if (!Array.isArray(data?.favourites)) return;

      if (data?.favourites.length === 0) {
        toast.error("No images to download.");
        return;
      }

      if (!isDirectorySelectionSupported())
        return toast.error(
          "Bulk download is not supported in this browser. Please use Chrome, Edge or Opera on desktop."
        );

      let directory = await window.showDirectoryPicker();
      directory = await directory.getDirectoryHandle(
        clientName + "-" + clientId,
        {
          create: true,
        }
      );

      setLoading(true);

      // Gives a list of URLs, downloadURL is used by default and in case it fails url is used.
      const downloadListURLs = data.favourites.map((pic) => {
        const downloadURL = getCFURL({ url: pic?.url });
        const url = getDOURL({ url: pic?.url });
        return {
          ...pic,
          url,
          downloadURL,
        };
      });

      let portionOfImages = downloadListURLs.splice(-5);

      while (portionOfImages.length > 0) {
        try {
          await downloadImages(portionOfImages, directory);
        } catch (_) {
          portionOfImages = [];
        }

        portionOfImages = downloadListURLs.splice(-5);
      }
      setLoading(false);
      toast.success("Images downloaded successfully.");
    } catch (e) {
      setLoading(false);
      toast.error("Failed to download images.");
    }
  };

  const handleDownloadTXTFile = async (participantId, needComments) => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: {
        buttons: [
          needComments
            ? ANALYTICS.CLIENT_FAVOURITES_CSV_DOWNLOAD
            : ANALYTICS.CLIENT_FAVOURITES_TXT_DOWNLOAD,
        ],
      },
    });

    setSelectedParticipant(null);
    if (!participantId) return;
    if (!hasFeatureAccess()) return;
    const data = favourites.find((favourite) => {
      return favourite?.participant?.participantId === participantId;
    });

    const clientName = data?.participant?.user?.name;

    if (!Array.isArray(data?.favourites)) return;

    if (data?.favourites.length === 0) {
      toast.error("No images to create TXT file");
      return;
    }

    let text = "";

    if (needComments) {
      text += "Picture title, Folder name, Comment\n";
    }

    text += data.favourites
      .map((pic) => {
        const splittedArray = pic?.url.split("@");
        let picName = splittedArray[splittedArray.length - 1].split(".");
        picName.pop();
        picName = picName.join(".");

        if (picName.startsWith("highlights_"))
          picName = picName.replace("highlights_", "");

        if (needComments) {
          return `${picName},${
            pic?.folderPrefix ? pic.folderPrefix.split(",").join(" ") : "" //blank in case of All Photos
          },${
            pic?.comment
              ? pic.comment.split(",").join(" ").split("\n").join(" ")
              : ""
          }`;
        } else {
          return picName;
        }
      })
      .join("\n");

    const blob = new Blob([text], {
      type: needComments ? "text/csv" : "text/plain",
    });
    const fileName = `favourites-${clientName}.${needComments ? "csv" : "txt"}`;

    saveAs(blob, fileName);
  };

  const rows = generateRows(
    favourites,
    handleDownloadImages,
    setSelectedParticipant,
    userId
  );

  return (
    <>
      {selectedParticipant && (
        <ConfirmationCommentModal
          show={selectedParticipant}
          onClose={() => setSelectedParticipant(null)}
          onConfirm={(bool) => handleDownloadTXTFile(selectedParticipant, bool)}
        />
      )}
      {favourites?.length === 0 ? (
        <h4>No Favorite Pics</h4>
      ) : (
        <>
          <CustomTable
            columns={columns}
            rows={rows}
            rowHeight={50}
            totalHieght={380}
          />
          <ClientFavPagination
            currentPage={+params.get("page") || 1}
            setPage={(page) => {
              params.set("page", page);
              setParams(params);
            }}
            totalPages={Math.ceil((isAdmin ? +adminCount : +usersCount) / 50)}
          />
        </>
      )}
      {subscriptionModal ? (
        <SubscriptionModal
          isOpen={subscriptionModal}
          onClose={() => setSubscriptionModal(false)}
        />
      ) : null}
      <Spinner loading={loading || settingLoader} />
    </>
  );
};

export default ClientFavTable;

// missing favourite pics object
function generateRows(
  favourites,
  handleDownloadImages,
  setSelectedParticipant,
  userId
) {
  return (favourites || []).map((favourite) => {
    const participantId = favourite?.participant?.participantId;
    return {
      adminName: `${
        participantId === userId
          ? "You"
          : favourite?.participant?.user?.name || ""
      }`,
      downloadImageLink: (
        <a
          onClick={() => handleDownloadImages(participantId)}
          style={{ cursor: "pointer" }}
        >
          Download
        </a>
      ),
      downloadTXTfileLink: (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedParticipant(participantId);
            // handleDownloadTXTFile(favourite?.participant?.participantId);
          }}
        >
          Download
        </a>
      ),
    };
  });
}
