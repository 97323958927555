import React from "react";
import "@docsearch/css";
import { DocSearch } from "@docsearch/react";
import css from "./index.module.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";
import ANALYTICS from "constants/analyticsKeys";
import useAuth from "hooks/useAuth";

const Search = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  const hideSearch =
    pathname === "/gallery" ||
    pathname === "/gallery/folders" ||
    pathname.startsWith("/auth") ||
    pathname === "/anonymous/anonymous-selfies" ||
    pathname === "/anonymous/gallery" ||
    pathname === "/mobile-support" ||
    pathname === "/mobile-pricing" ||
    pathname === "/update-profile" ||
    pathname.startsWith("/portfolio");

  return (
    <aside className={css.search}>
      <DocSearch
        appId="SK136BB71Q"
        apiKey="c4aba1f654eedb6baba04a21dff6d9ee"
        indexName="kp-docs-2"
        placeholder="Ask Kwikpic Genie"
        hitComponent={({ hit, children }) => (
          <a href={hit.url} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        )}
      />
      {!hideSearch && (
        <img
          role="button"
          src="/assets/icons/help.png"
          alt="search helpdesk"
          onClick={() => {
            document.getElementsByClassName("DocSearch-Button")[0].click();
            isAuthenticated &&
              dispatch({
                type: SEND_USER_CLICK_ANALYTICS,
                payload: { buttons: [ANALYTICS.HELPDESK_SUPPORT_SEARCH] },
              });
          }}
        />
      )}
    </aside>
  );
};

export default Search;
