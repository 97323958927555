import React, { useEffect, useMemo, useRef, useState } from "react";
// import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Observable from "zen-observable";
import // GET_ALL_IMAGES_OF_GROUP,
// GET_FOLDERS,
"../redux-store/sagas/saga-actions";
import { AXIOS, AXIOS2 } from "../utils/setup/axios";
// import { store } from "../redux-store/store";
// import { allImagesType } from "views/routes/GallaryRoute";
import { STATIC_FOLDERS } from "views/components/modals/UploadFromComputer";
import usePreventUnsavedNavigation from "./usePreventUnsavedNavigation";
import useUploadStatus from "./useUploadingStatus";
import { delay, isImageOptimized } from "utils/helpers";
import { DEFAULT_SORT } from "views/components/modals/UploadOneShot";
import { getSortedImagesFromFolders } from "./useOneShotUpload";
import { getLocalStorageUser, lsProxy } from "utils/helpers/localstorage";
import { useSelector } from "react-redux";

export const THUMB_SIZE = 5120;

const HIGHLIGHTS_IMAGE_PREFIX = "highlights_";
// export const IMAGES_COUNT_PER_REQUEST = 5;
export const INITIAL_FOLDER = "Select";

export default function useUploadFromComputer({ params, handleClose, show }) {
  const [allUploadedImages, setAllUploadedImages] = useState([]); // all images uploaded with status
  const [isUploading, setIsUploading] = useState(false); // state to handle uploading to the backend
  const [loading, setLoading] = useState(false); // state to handle loading while uploading to the IndexedDb
  const [folderPrefix, setFolderPrefix] = useState(INITIAL_FOLDER); // state to mamnage folders
  const [confirmationModal, setConfirmationModal] = useState({}); // state to manage confirmation modal
  const [hasInternetConnection, setHasInternetConnection] = useState(false);
  const [imagesPerRequest, setImagesPerRequest] = React.useState(5);
  const {
    privacySettings: { guestFolderOnly },
    groupDetails: { isAdmin },
  } = useSelector((state) => state.settings);

  const sortByRef = useRef(DEFAULT_SORT);

  // const intervalRef = useRef();
  const { isParallelyUploading } = useUploadStatus();

  // const dispatch = useDispatch();
  usePreventUnsavedNavigation({
    message: "Uploading is still in process. Are you sure you want to leave?",
    block: isUploading,
  });

  useEffect(() => {
    if (hasInternetConnection) {
      const relevantImages = allUploadedImages?.filter(
        (image) =>
          image.status !== imagesStatus.DUPLICATE &&
          image.status !== imagesStatus.SUCCESS &&
          image.status !== imagesStatus.ERROR
      );

      toast.success("Uploading has been resumed .", {
        position: "top-right",
        autoClose: false,
        pauseOnHover: true,
      });
      if (relevantImages.length > 0) {
        lsProxy.removeItem("isUploading");
        startUploading();
      } else {
        setIsUploading(false);
      }
    }
  }, [hasInternetConnection]);

  // total size of all the images collectively
  const totalImagesSize = allUploadedImages?.reduce(
    (acc, curr) => acc + curr.size,
    0
  );

  useEffect(() => {
    (async () => {
      if (folderPrefix === STATIC_FOLDERS.HIGHLIGHTS) {
        setLoading(true);
        const uploadedImages = allUploadedImages?.map((image) => image.file);

        try {
          const result = await checkImageStatus(
            uploadedImages,
            params.get("groupId"),
            folderPrefix
          );
          if (result instanceof Error) {
            toast.error("Some error occured. Please try again");
            setLoading(false);
            return;
          }
          handleSortBy(sortByRef.current, result);
          // setAllUploadedImages(result);
          setLoading(false);
        } catch (_) {
          setLoading(false);
        }
      }
    })();
  }, [folderPrefix]);

  useEffect(() => {
    if (!isAdmin && guestFolderOnly) {
      setFolderPrefix(STATIC_FOLDERS.GUEST_UPLOADS);
    }
  }, [guestFolderOnly, isAdmin]);

  // successfully uploaded images
  const successfullyUploaded = useMemo(() => {
    return allUploadedImages?.filter(
      (image) => image.status === imagesStatus.SUCCESS
    );
  }, [allUploadedImages]);

  // failed uploaded images
  const imagesWithError = useMemo(() => {
    return allUploadedImages?.filter(
      (image) => image.status === imagesStatus.ERROR
    );
  }, [allUploadedImages]);

  // duplicate uploaded images
  const duplicateImages = useMemo(() => {
    return allUploadedImages?.filter(
      (image) => image.status === imagesStatus.DUPLICATE
    );
  }, [allUploadedImages]);

  // count of images which has been sent to backend inc(images with success,error,duplicate status)
  const successFullySentToBackend =
    successfullyUploaded.length +
    imagesWithError.length +
    duplicateImages.length;

  const hasUploadedAllImages =
    successFullySentToBackend === allUploadedImages?.length &&
    successFullySentToBackend !== 0;

  // useEffect(() => {
  //   dispatch({ type: GET_FOLDERS, groupID: params.get("groupId") });
  // }, [dispatch, params]);

  /**
   * init function as it is not un-mounting on close
   */
  useEffect(() => {
    if (!show) {
      setAllUploadedImages([]);
      setIsUploading(false);
      setLoading(false);
      setFolderPrefix(INITIAL_FOLDER);
      setConfirmationModal({});
    }
  }, [show]);

  useEffect(() => {
    if (isUploading) {
      lsProxy.setItem("uploadingFrom", "computer");
      lsProxy.setItem("isUploading", true);
    } else {
      lsProxy.removeItem("isUploading");
      lsProxy.removeItem("uploadingFrom");
    }
  }, [isUploading]);

  /**
   * handles and uploads the images to the indexedDB
   *
   * @param event
   */
  const handleImageUploads = async (uploadedImages) => {
    setLoading(true);

    try {
      const result = await checkImageStatus(
        uploadedImages,
        params.get("groupId"),
        folderPrefix
      );
      if (result instanceof Error) {
        setLoading(false);
        toast.error("Some error occured. Please try again.");
        return;
      }
      handleSortBy(DEFAULT_SORT, result);
      // setAllUploadedImages(result);
      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  };

  const setFldrPrefix = (prefix) => {
    if (isUploading)
      return toast.error("Please wait for current uploads to finish");

    setFolderPrefix(prefix);
  };

  /**
   * will be called when user starts uploading the images to backend
   */
  const startUploading = async (retriedImages = undefined, ref = null) => {
    if (folderPrefix === INITIAL_FOLDER) {
      ref?.current?.classList.add("error-outline");
      setTimeout(() => {
        ref?.current?.classList.remove("error-outline");
      }, 2500);
      return toast.error("Please select a folder to upload.");
    }

    setIsUploading(true);

    const isAlreadyuploading = await isParallelyUploading();

    if (isAlreadyuploading) {
      toast.error(
        "One upload is already in progress. Please wait for that to finish"
      );
      setIsUploading(false);
      return;
    }
    const _allUploadedImages = retriedImages || allUploadedImages;

    let relevantImages = _allUploadedImages?.filter(
      (image) =>
        image.status !== imagesStatus.DUPLICATE &&
        image.status !== imagesStatus.SUCCESS &&
        image.status !== imagesStatus.ERROR
    );

    let images = relevantImages.slice(0, imagesPerRequest);
    let totalProcessedImages = imagesPerRequest;

    let result = {
      error: [],
      success: [],
    };

    // running a recursive call to upload all the images to the backend ({imagesPerRequest} at a time)

    while (images.length > 0) {
      const startTime = Date.now();
      let lastImg = null;

      if (totalProcessedImages >= relevantImages.length)
        lastImg = findLastImage(images);

      const resp = await upload(images, lastImg);
      const endTime = Date.now();

      const timeTaken = endTime - startTime;

      // making a delay so that it doesnot put too much load on the backend
      if (timeTaken < 2000) {
        await delay(2000 - timeTaken);
      }

      if (resp === "DO_NOTHING") {
        toast.warning(
          "Seems like you have lost the internet connection. Uploading will resume once the internet will be back .",
          {
            position: "top-right",
            autoClose: false,
            pauseOnHover: true,
          }
        );
        // break;
        return;
      }
      result.error = result.error.concat(resp.error);
      result.success = result.success.concat(resp.success);

      images = relevantImages.slice(
        totalProcessedImages,
        totalProcessedImages + imagesPerRequest
      );

      totalProcessedImages += imagesPerRequest;
    }
    setIsUploading(false);
  };

  /**
   * This will call the uploadImageToBackend function for each image
   * and will resolve when all the images are uploaded(success or error)
   * @param  images
   * @param  lastImg
   * @returns {Promise}
   */
  async function upload(images, lastImg = null) {
    const { highRes } = getLocalStorageUser() || {};

    return new Promise(function (resolve) {
      let result = {
        success: [],
        error: [],
      };

      let observable = new Observable.from(images);

      // eslint-disable-next-line no-unused-vars
      const subscription = observable.subscribe({
        next: async (image) => {
          // this is to ensure that user doesnot clear the localstorage while uploading
          lsProxy.setItem("isUploading", true);
          try {
            /**
             * This is to move the progress bar of each particular image
             *
             * @param {number} percentCompleted
             */
            const uploadProgress = (percentCompleted) => {
              setAllUploadedImages((prevState) =>
                prevState.map((img) => {
                  if (img.name === image.name) {
                    return { ...img, uploadProgress: percentCompleted };
                  }

                  return img;
                })
              );
            };

            // const status = await uploadImageToBackendWithMultipleCall(
            //   image,
            //   params.get("groupId"),
            //   folderPrefix === STATIC_FOLDERS.ALL_PHOTOS ? "" : folderPrefix,
            //   uploadProgress
            // );

            const isLast =
              image?.name === lastImg?.name && image?.size === lastImg?.size;

            const status = await uploadImageToBackend(
              image,
              params.get("groupId"),
              folderPrefix === STATIC_FOLDERS.ALL_PHOTOS ? "" : folderPrefix,
              uploadProgress,
              highRes,
              isLast
            );
            if (status === NETWORK_ERROR) {
              try {
                await AXIOS.get(process.env.REACT_APP_API_URL);
              } catch (err) {
                if (err?.code === NETWORK_ERROR) {
                  const _interval = setInterval(() => {
                    AXIOS.get(process.env.REACT_APP_API_URL).then(() => {
                      setHasInternetConnection(true);
                      clearInterval(_interval);
                    });
                  }, 3000);
                  subscription.unsubscribe();
                  setHasInternetConnection(false);
                  resolve("DO_NOTHING");
                }
              }

              // subscription.unsubscribe();
              // TODO: just found that we didn't cleared the interval. will need to handle this
              // const _interval = setInterval(async () => {
              //   if (navigator.onLine) {
              //     try {
              //       const resp = await AXIOS.get(process.env.REACT_APP_API_URL);
              //       if (resp) {
              //         setHasInternetConnection(true);
              //         clearInterval(_interval);
              //       }
              //     } catch (err) {
              //       setHasInternetConnection(false);
              //     }
              //   } else {
              //     setHasInternetConnection(false);
              //   }
              // }, 3000);
              // resolve("DO_NOTHING");
            }

            setAllUploadedImages((prevState) =>
              prevState.map((img) => {
                if (img.name === image.name) {
                  if (status === NETWORK_ERROR) {
                    return { ...img, status: imagesStatus.ERROR };
                  }
                  if (
                    status === imagesStatus.SUCCESS ||
                    status === imagesStatus.DUPLICATE
                  ) {
                    return { ...img, file: null, status };
                  }
                  return { ...img, status };
                }
                return img;
              })
            );

            if (
              status === imagesStatus.SUCCESS ||
              status === imagesStatus.DUPLICATE
            ) {
              result.success.push(image);

              if (
                images.length ===
                result.success.length + result.error.length
              ) {
                resolve(result);
                // handleAllImages(params.get("groupId"));
              }
            } else if (
              status === imagesStatus.ERROR ||
              status === NETWORK_ERROR
            ) {
              result.error.push(image);

              if (
                images.length ===
                result.success.length + result.error.length
              ) {
                resolve(result);
              }
            }
          } catch (err) {
            setAllUploadedImages((prevState) =>
              prevState.map((img) => {
                if (img.name === image.name) {
                  return { ...img, status: imagesStatus.ERROR };
                }
                return img;
              })
            );

            result.error.push(image);

            if (images.length === result.success.length + result.error.length) {
              resolve(result);
            }
          }
        },
      });
    });
  }

  const handleConfirmationModal = () => {
    if (isUploading) {
      setConfirmationModal({
        show: true,
        title: "Uploading in progress",
        message: "Are you sure you want to cancel the upload?",
        confirmText: "Yes",
        cancelText: "No",
        onConfirm: () => {
          setIsUploading(false);
          handleClose();
        },
        onCancel: () => {
          setConfirmationModal({});
        },
      });
    } else if (!hasUploadedAllImages && allUploadedImages?.length > 0) {
      setConfirmationModal({
        show: true,
        title: "Selected Images",
        message:
          "You have not uploaded the selected images. Are you sure you want to cancel the process?",
        confirmText: "Yes",
        cancelText: "No",
        onConfirm: () => {
          setConfirmationModal({});
          handleClose();
        },
        onCancel: () => {
          setConfirmationModal({});
        },
      });
    } else {
      setConfirmationModal({});
      handleClose();
    }
  };

  async function handleFailedUploads() {
    setAllUploadedImages((prev) => {
      const _prev = prev
        .filter((image) => image.status === imagesStatus.ERROR)
        .map((image) => {
          return { ...image, status: imagesStatus.READY, uploadProgress: 0 };
        });
      startUploading(_prev);
      return _prev;
    });
  }

  const uploadLimitChange = (event) => {
    if (isUploading) {
      toast.error("This change will not have any effect.");
      return;
    }
    const value = event.target.value;
    if (value === "one") {
      setImagesPerRequest(1);
    } else if (value === "five") {
      setImagesPerRequest(5);
    }
  };

  const handleSortBy = (sort, images) => {
    if (isUploading)
      return toast.error("Please wait for current uploads to finish");

    sortByRef.current = sort;
    // setCurrentSort(sort);
    setAllUploadedImages((prev) =>
      // a trick to use the `getSortedImagesFromFolders` for our use.
      getSortedImagesFromFolders([{ images: images || prev }], sort)
    );
  };

  return {
    successFullySentToBackend,
    totalImagesSize,
    handleImageUploads,
    startUploading,
    isUploading,
    loading,
    allUploadedImages,
    hasUploadedAllImages,
    handleConfirmationModal,
    confirmationModal,
    successfullyUploaded,
    imagesWithError,
    duplicateImages,
    folderPrefix,
    setFolderPrefix: setFldrPrefix,
    setAllUploadedImages,
    handleFailedUploads,
    imagesPerRequest,
    uploadLimitChange,
    sortByRef,
    handleSortBy,
  };
}

export const findLastImage = (images) => {
  let lastImg = images[0];

  for (const img of images) {
    if (img?.size > lastImg?.size) lastImg = img;
  }

  return lastImg;
};

export const imagesStatus = {
  SUCCESS: "SUCCESS",
  READY: "UPLOAD",
  DUPLICATE: "DUPLICATE",
  ERROR: "ERROR",
};

/**
 * This function will upload the image to the backend and return the status accordingly
 *
 * @param  image
 * @param {string} groupID
 * @param {string} folderPrefix
 * @param {Function} uploadProgress
 * @param {boolean} highRes
 * @param {object} lastImg
 * @param {number} count
 * @returns {imagesStatus}
 */
export async function uploadImageToBackend(
  image,
  groupID,
  folderPrefix,
  uploadProgress,
  highRes,
  isLast = false,
  count = 1
) {
  try {
    const formData = new FormData();
    let file = new File([image?.file], image?.name);

    const opt = await isImageOptimized(file);

    const uploadPath = opt
      ? "/api/app/group/upload-image/"
      : "/api/app/group/uncompressed-image-upload/";

    if (folderPrefix === STATIC_FOLDERS.HIGHLIGHTS) {
      file = new File([image.file], HIGHLIGHTS_IMAGE_PREFIX + image?.name);
    }

    formData.append("image", file);
    formData.append("folderPrefix", folderPrefix);
    if (opt == 2 || (opt == 0 && highRes)) formData.append("highRes", true);
    if (isLast) formData.append("photoUploadDone", true);

    const response = await AXIOS2.put(uploadPath + groupID, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        uploadProgress(percentCompleted);
      },
    });
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      return imagesStatus.SUCCESS;
    }
  } catch (err) {
    if (err?.code === NETWORK_ERROR) {
      if (count < 3) {
        return await uploadImageToBackend(
          image,
          groupID,
          folderPrefix,
          uploadProgress,
          highRes,
          isLast,
          ++count
        );
      } else {
        return NETWORK_ERROR;
      }
    }

    if (err?.response?.data?.status === 409) {
      return imagesStatus.DUPLICATE;
    } else {
      return imagesStatus.ERROR;
    }
  }
}

/**
 * check whether image exists or not if exists then rejects the promise else resolve it
 *
 * @param {File} image
 * @param {string} groupId
 * @param {string} folderPrefix
 * @returns
 */
export async function checkImageStatus(images, groupId, folderPrefix) {
  const imagesWithNameAndSize = images?.map((image) => {
    if (folderPrefix === STATIC_FOLDERS.HIGHLIGHTS) {
      return {
        name: HIGHLIGHTS_IMAGE_PREFIX + image.name,
        size: image.size,
      };
    }
    return {
      name: image.name,
      size: image.size,
    };
  });

  try {
    const response = await AXIOS.post("/api/app/pic/image-exist", {
      groupId,
      images: imagesWithNameAndSize,
      uncompressed: true,
      sizes: true,
    });

    const existingImages = response?.data?.data?.exist || [];

    const updateImages = images?.reduce((acc, image) => {
      const filterCondition =
        image.size < THUMB_SIZE && image.name.match(/^\./) !== null;

      const imageExists = existingImages.find((existingImage) => {
        if (folderPrefix === STATIC_FOLDERS.HIGHLIGHTS) {
          return (
            existingImage.image_name === HIGHLIGHTS_IMAGE_PREFIX + image.name &&
            existingImage.image_size === image.size
          );
        }
        return (
          existingImage.image_name === image.name &&
          existingImage.image_size === image.size
        );
      });

      if (!filterCondition) {
        if (imageExists) {
          acc.push({
            name: image.name,
            size: image.size,
            file: image,
            type: image.type,
            status: imagesStatus.DUPLICATE,
          });
        } else {
          acc.push({
            name: image.name,
            size: image.size,
            file: image,
            type: image.type,
            status: imagesStatus.READY,
          });
        }
      }
      return acc;
    }, []);

    return updateImages;
  } catch (err) {
    return new Error(err);
  }
}
export const NETWORK_ERROR = "ERR_NETWORK";

// export const uploadImageToBackendWithMultipleCall = async (...args) => {
//   let status;
//   for (let i = 0; i < 3; i++) {
//     if (status === undefined || status === NETWORK_ERROR) {
//       status = await uploadImageToBackend(...args);
//     }
//   }
//   return status;
// };
