import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApplicationLink from "../landing/components/ApplicationLink";
import ContactusForm from "./components/ContactusForm";
import StayTouch from "./components/StayTouch";
import ContactUsModal from "views/components/modals/ContactUsModal";
import DefaultButton from "views/components/button/DefaultButton";

const ContactUs = () => {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <div className="introduce-bg-color">
        <Container>
          <div className="contactus row">
            <div className="col-md-6 contact-info">
              <div className="head-title">
                <div className="contact-header mb-3 thick-font">Contact Us</div>
                <div className="description thick-font dark-gray-text">
                  We’re happy to answer and get you acquainted with Kwikpic.
                </div>
                <img
                  className="under-line"
                  src="/assets/images/landing/yellow-divider.png"
                  alt="Yellow-divider"
                />
              </div>

              <div className="middle">
                <div className="mb-3 thick-font dark-gray-text">
                  Fill out the form to get in touch with one of our
                  representatives
                </div>
                <div className="mb-3">OR</div>
                <div className="mb-3 know-more">
                  <DefaultButton
                    onClick={() => setShow(true)}
                    title="Talk to Us"
                    className="w-fit px-4 font-bold"
                  />
                </div>
              </div>

              <div className="end">
                <div className="FAQ-pc mb-3 thick-font dark-gray-text">
                  For technical issues or general queries, please visit our{" "}
                  <Link to="/helpdesk" target="_blank">
                    Helpdesk
                  </Link>
                </div>
              </div>
            </div>
            <div className="contact-form col-md-6">
              <ContactusForm />
            </div>
          </div>
        </Container>
        <Container>
          <div className="FAQ-mobile mb-3 dark-gray-text thick-font">
            For technical issues or general queries, please visit our{" "}
            <Link to="/helpdesk" target="_blank">
              Helpdesk
            </Link>
          </div>
        </Container>
      </div>
      <StayTouch />
      <ApplicationLink />
      <ContactUsModal show={show} hide={() => setShow(false)} />
    </>
  );
};

export default ContactUs;
