import React from "react";
import css from "./Buttons.module.css";
import cx from "classnames";

/**
 * @typedef {object} DefaultButtonProps
 * @property {string} title - button title
 * @property {function} onClick - button click handler
 * @property {string} type - button type
 * @property {string} className - button class name
 * @property {("xs"|"sm"|"md")} variant - button variant
 * @param {DefaultButtonProps}
 */
const DefaultButton = ({ title, onClick, type, className, variant = "md" }) => {
  return (
    <button
      className={cx(css[variant], css.defaultButton, className)}
      type={type}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default DefaultButton;
