import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RENAME_FOLDER } from "redux-store/sagas/saga-actions";
import DefaultInput from "views/components/input/DefaultInput";
import SmallRoundedButton from "../button/SmallRoundedButton";
import { toast } from "react-toastify";

export default function RenameFolderModal({ show, handleHide, id, name }) {
  const [newPrefix, setNewPrefix] = React.useState("");

  useEffect(() => {
    setNewPrefix(name);
  }, [name]);

  const dispatch = useDispatch();
  const { renameFolderResponse } = useSelector((state) => state.folders);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newName = newPrefix?.trim();

    if (newName === "") return toast.error("Folder name cannot be empty.");
    else if (name === newName)
      return toast.error("Folder name cannot be same as old name.");
    else {
      dispatch({
        type: RENAME_FOLDER,
        payload: {
          newPrefix: newName,
          folderId: id,
        },
      });
    }
  };

  useEffect(() => {
    handleHide();
  }, [renameFolderResponse]);

  return (
    <Modal
      centered
      show={show}
      onHide={handleHide}
      animation={false}
      className="py-3"
    >
      {/* <Spinner loading={settingLoader} /> */}
      <Modal.Header className="justify-content-center border-0">
        <Modal.Title>Rename Folder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="d-flex flex-column align-items-center w-100"
          onSubmit={handleSubmit}
        >
          <DefaultInput
            name="rename-folder"
            placeholder="Enter Folder Name"
            defaultValue={name}
            onChange={(e) => setNewPrefix(e.target?.value || "")}
            className="no-border"
          />
          <div className="mt-3" />
          <SmallRoundedButton title="Rename" type="submit" />
        </form>
      </Modal.Body>
    </Modal>
  );
}
