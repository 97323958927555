import React, { forwardRef } from "react";

const DefaultInput = forwardRef(
  (
    {
      name,
      value,
      defaultValue,
      disabled,
      type,
      onChange,
      placeholder,
      onClick,
      onKeyDown,
      error,
      className,
      required,
      labelClassName,
      autoFocus = false,
      ...others
    },
    ref
  ) => {
    return (
      <label style={{ width: "100%" }} className={labelClassName ?? ""}>
        <input
          className={`defaultInput ${className ?? ""}`}
          ref={ref}
          name={name}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          onClick={onClick}
          onKeyDown={onKeyDown}
          required={required}
          autoFocus={autoFocus}
          {...others}
        />
        <p className="input_fields-error">{error}</p>
      </label>
    );
  }
);
DefaultInput.displayName = "defaultInput";

export default DefaultInput;
