import React from "react";

export const EmptyAnonymousGallery = () => {
  //   useEffect(() => {
  //     props?.setLoading(false);
  //   }, []);

  return (
    <div className="emptyGallary flex-col-full-empty-gallary">
      <div className="nophotos-flex-col-full-empty-gallary">
        <p>No Photos to show yet</p>
      </div>
      <div className="mb-5" />
    </div>
  );
};

export default EmptyAnonymousGallery;
