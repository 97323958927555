import React, { useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { UPLOAD_MODALS_TYPES } from "../../gallary/gallaryPage/components/GalleryUploader";
import ModalFullWidthButton from "../button/ModalFullWidthButton";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";
import { toast } from "react-toastify";
import { lsProxy } from "utils/helpers/localstorage";
import ANALYTICS from "constants/analyticsKeys";

const UploadModal = ({ show, handleClose, handleTypeSelection }) => {
  // const [isVideo, setIsVideo] = useState(false);
  const [activeTab, setActiveTab] = useState("UploadPhotos");
  const { designSettings } = useSelector((state) => state.settings);
  const { font } = designSettings;
  const dispatch = useDispatch();

  const isVideo = activeTab === "UploadVideos";

  const handleUploadFromComputer = () => {
    if (!isVideo) {
      dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.UPLOAD_FS] },
      });

      handleTypeSelection(UPLOAD_MODALS_TYPES.UPLOAD_FROM_COMPUTER);
    } else {
      dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.UPLOAD_VIDEO] },
      });

      if (lsProxy.getItem("isUploading")) {
        toast.error(
          "One upload is already in progress. Please wait for that to finish"
        );
      } else {
        handleTypeSelection(UPLOAD_MODALS_TYPES.UPLOAD_VIDEO);
      }
    }
  };

  const handleOneShotupload = () => {
    if (!isVideo) {
      dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.UPLOAD_ONESHOT] },
      });

      handleTypeSelection(UPLOAD_MODALS_TYPES.UPLOAD_ONE_SHOT);
    } else {
      dispatch({
        type: SEND_USER_CLICK_ANALYTICS,
        payload: { buttons: [ANALYTICS.UPLOAD_VIDEO_YT] },
      });

      if (lsProxy.getItem("isUploading")) {
        toast.error(
          "One upload is already in progress. Please wait for that to finish"
        );
      } else {
        handleTypeSelection(UPLOAD_MODALS_TYPES.UPLOAD_YOUTUBE_VIDEO);
      }
    }
  };

  const hanldeTabs = (e) => {
    if (e === "UploadVideos") {
      setActiveTab("UploadVideos");
    } else {
      setActiveTab("UploadPhotos");
    }
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        animation={true}
        className={cx(font === 1 ? "fontSerif" : "fontSans")}
      >
        <Modal.Header className="justify-content-center border-0">
          <Tabs
            activeKey={activeTab}
            id="uncontrolled-tab-example"
            className="d-flex justify-content-center flex-nowrap w-100 folder"
            onSelect={(e) => hanldeTabs(e)}
          >
            <Tab
              eventKey="UploadPhotos"
              className="font-14 "
              tabClassName="Upload-photo-tab"
              title="Upload Photos"
            ></Tab>
            <Tab eventKey="UploadVideos" title="Upload Videos"></Tab>
          </Tabs>
        </Modal.Header>

        <Modal.Body>
          <div className="px-3 pb-3">
            <ModalFullWidthButton
              title="From your Computer"
              onClick={handleUploadFromComputer}
            />
            <div className="mb-3" />
            <ModalFullWidthButton
              title={
                !isVideo ? "Using One Shot Upload" : " Using Youtube Link "
              }
              onClick={handleOneShotupload}
            />
            <div className="mb-3" />
            {!isVideo && (
              <ModalFullWidthButton
                onClick={() => {
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: { buttons: [ANALYTICS.UPLOAD_DRIVE] },
                  });
                  handleTypeSelection(UPLOAD_MODALS_TYPES.UPLOAD_FROM_LINK);
                }}
                title="Using Google Drive, OneDrive or Dropbox link"
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadModal;
