import React from "react";
import SettingPageHeading from "views/settings/components/SettingPageHeading";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_MY_ACCOUNT } from "redux-store/sagas/saga-actions";
import Spinner from "views/components/loader/Spinner";
import "./PrivacySettings.css";
import MutedButton from "views/components/button/MutedButton";
import ConfirmationButton from "views/components/button/ConfirmationButton";
import cx from "classnames";

const MODAL_TYPES = {
  DELETE: "DELETE",
  GOODBYE: "GOODBYE",
};

const PrivacySecurity = () => {
  const [modalType, setModalType] = React.useState("");
  const { settingLoader, error } = useSelector((setting) => setting.settings);

  return (
    <div>
      <SettingPageHeading title="Privacy and Security" />
      <ul className="m-0 p-0 mt-4">
        <li className="mb-3">
          <Link
            to="/policy/privacy-policy"
            target="_blank"
            className="font-15 font-bold"
          >
            <u>Privacy Policy</u>
          </Link>
        </li>
        <li className="mb-3">
          <Link
            to="/policy/terms-and-conditions"
            target="_blank"
            className="font-15 font-bold"
          >
            <u>Terms of Use</u>
          </Link>
        </li>
        <li>
          <button
            onClick={() => setModalType(MODAL_TYPES.DELETE)}
            className="font-15 font-bold bg-transparent p-0 color-danger"
          >
            <u>Delete my Account</u>
          </button>
        </li>
      </ul>
      <DeleteAccountModal
        show={modalType === MODAL_TYPES.DELETE}
        setModal={setModalType}
      />
      {!settingLoader && !error && (
        <GoodByeModal
          show={modalType === MODAL_TYPES.GOODBYE}
          close={() => setModalType("")}
        />
      )}
      <Spinner loading={settingLoader} />
    </div>
  );
};

const DeleteAccountModal = ({ show, setModal }) => {
  const [text, setText] = React.useState(false);
  const dispatch = useDispatch();

  const deleteAccount = () => {
    if (text !== "DELETE MY ACCOUNT") {
      return;
    }
    dispatch({
      type: DELETE_MY_ACCOUNT,
    });

    setModal(MODAL_TYPES.GOODBYE);
  };

  return (
    <Modal centered show={show} onHide={() => setModal("")}>
      <Modal.Header className="text-center mx-auto pt-4 border-0 pb-0">
        <Modal.Title className="color-danger d-flex align-items-center gap-2 font-24">
          <img
            src="/assets/images/icons/privacy-security/caution.png"
            alt="caution"
            className="hw-18px"
          />
          Delete my Account will:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-auto">
        <ul className="font-bold color-h">
          <li className="bullet-list mb-1 font-15">
            Delete your account from Kwikpic
          </li>
          <li className="bullet-list mb-1 font-15">
            Erase all your photos from the platform
          </li>
          <li className="bullet-list mb-1 font-15">Delete all your uploads</li>
          <li className="bullet-list mb-1 font-15">
            Remove you from all Kwikpic Groups
          </li>
        </ul>
        <div>
          <p className="font-15 color-secondary font-bold mb-1 ">
            Type &quot;DELETE MY ACCOUNT&quot;
          </p>
          <input
            type="text"
            onChange={(e) => setText(e.target.value)}
            className="delete-input"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex justify-content-center pb-4">
        <MutedButton title="Cancel" onClick={() => setModal("")} />
        <ConfirmationButton
          title="Delete"
          className={cx("confirmation-btn", {
            active: text === "DELETE MY ACCOUNT",
          })}
          onClick={deleteAccount}
          disabled={text !== "DELETE MY ACCOUNT"}
        />
      </Modal.Footer>
    </Modal>
  );
};

const GoodByeModal = ({ show, close }) => {
  return (
    <Modal centered show={show} onHide={close}>
      <Modal.Body className="mx-auto text-center py-5">
        <img
          src="/assets/images/icons/privacy-security/goodbye.png"
          alt="goodbye"
          className="goodbye-wave"
        />
        <p className="font-24 color-primary mt-3 mb-1 font-bold">Goodbye!</p>
        <p className="font-bold font-15 color-h">
          Your account will be deleted in 24 hours.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacySecurity;
