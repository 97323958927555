import { createSlice } from "@reduxjs/toolkit";
import { addUserToLocalStorage, lsProxy } from "utils/helpers/localstorage";
import { setUserProfileData } from "./settings";

const user = createSlice({
  name: "user",
  initialState: {
    user: {},
    errors: {},
    firstTime: false,
    delegates: [],
    userLoading: false,
    error: "",
  },
  reducers: {
    setUserFirstTime: (state, action) => {
      return {
        ...state,
        firstTime: action.payload,
      };
    },
    setUser: (state, action) => {
      const { data, status } = action.payload;
      if (status === 200) {
        lsProxy.setItem("token", `Bearer ${data?.token}`);
        // lsProxy.setItem("user", JSON.stringify(data?.user));
        addUserToLocalStorage(data?.user);

        return {
          ...state,
          user: data?.user,
        };
      } else if (status === 400) {
        return {
          ...state,
          user: {},
          errors: data,
        };
      }
    },
    updateUser(state, action) {
      if (action?.payload?._id) {
        addUserToLocalStorage(action?.payload);
        // lsProxy.setItem("user", JSON.stringify(action?.payload));
      }
      return {
        ...state,
        user: action?.payload || {},
      };
    },
    setDelegates: (state, action) => {
      return {
        ...state,
        delegates: action.payload.data.delegateUsers,
      };
    },
    addOneDelegate: (state, action) => {
      const {
        userId,
        countryCode,
        phoneNumber,
        email,
        isRootAccess,
        groups,
        hasPassword,
      } = action.payload;

      return {
        ...state,
        delegates: [
          ...state.delegates,
          {
            _id: userId,
            countryCode,
            phoneNumber,
            email,
            isRootAccess,
            groups,
            hasPassword,
          },
        ],
      };
    },
    deleteOneDelegate: (state, action) => {
      const id = action.payload.id;

      return {
        ...state,
        delegates: state.delegates.filter((delegate) => delegate._id !== id),
      };
    },
    updateOneDelegate: (state, action) => {
      const {
        userId,
        countryCode,
        phoneNumber,
        email,
        isRootAccess,
        groups,
        hasPassword,
      } = action.payload;

      return {
        ...state,
        delegates: state.delegates.map((delegate) => {
          if (delegate._id === userId) {
            return {
              _id: userId,
              countryCode,
              phoneNumber,
              email,
              isRootAccess,
              groups,
              hasPassword,
            };
          }
          return delegate;
        }),
      };
    },
    reqStart: (state) => {
      return {
        ...state,
        userLoading: true,
      };
    },
    reqFail: (state, action) => {
      return {
        ...state,
        userLoading: false,
        error: action.payload,
      };
    },
    reqSuccess: (state) => {
      return {
        ...state,
        userLoading: false,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserProfileData, (state, action) => {
      const { user } = action.payload?.data || {};

      return {
        ...state,
        user,
      };
    });
  },
});

export const {
  setUserFirstTime,
  setUser,
  updateUser,
  setDelegates,
  addOneDelegate,
  deleteOneDelegate,
  updateOneDelegate,
  reqSuccess,
  reqStart,
  reqFail,
} = user.actions;

export default user.reducer;
