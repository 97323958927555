const ANALYTICS = {
  CREATE_GROUP: "createGroup",
  JOIN_GROUP: "joinGroup",
  SEARCH: "search",
  ANALYTICS: "analytics",
  HELP_SUPPORT: "helpSupport",

  UPLOAD_FS: "uploadFileSystem",
  UPLOAD_ONESHOT: "uploadOneShotUpload",
  UPLOAD_DRIVE: "uploadDrive",
  UPLOAD_FOLDER: "uploadFolder",

  SETTINGS: "settings",
  SHARE: "share",
  DOWNLOAD: "download",

  UPLOAD_VIDEO: "uploadVideo",
  UPLOAD_VIDEO_YT: "uploadVideoYT",

  SHARE_INVITE_LINKS: "shareInviteLinks",
  SHARE_PRINT_QR: "sharePrintQR",

  MULTISELECT_CURR_PAGE: "multiSelectCurrPage",
  MULTISELECT_CART: "multiSelectCart",
  MULTISELECT_TRANSFER: "multiSelectTransfer",
  MULTISELECT_DOWNLOAD: "multiSelectDownload",
  MULTISELECT_FAVORITE: "multiSelectFavorite",
  MULTISELECT_DELETE: "multiSelectDelete",
  MULTISELECT_RECOVER: "multiSelectRecover",

  SORT_PHOTOS: "sortPhotos",
  COVER_BROWSE_SAVE: "coverBrowseSave",

  // add participant events after merge
  PARTICIPANT_SETTINGS: "participantSettings",
  PARTICIPANT_CHANGE_ROLE: "participantChangeRole",
  PARTICIPANT_REMOVE: "participantRemove",
  PARTICIPANT_TRANSFER: "participantTransfer",
  PARTICIPANT_BLOCK: "participantBlock",
  PARTICIPANT_LEAVE: "participantLeave",
  PARTICIPANT_DELETE: "participantDelete",
  PARTICIPANT_NOTIFY: "participantNotify",

  PRIVACY_SETTINGS: "privacySettings",
  PRIVACY_SAVE: "privacySave",
  PRIVACY_NAME_ICON_TOGGLE: "privacyNameIconToggle",
  PRIVACY_ANYONE_JOIN_TOGGLE: "privacyAnyoneJoinToggle",
  PRIVACY_HIDE_DELETED_TOGGLE: "privacyHideDeletedToggle",
  PRIVACY_GUEST_UPLOADS_TOGGLE: "privacyGuestUploadsToggle",
  PRIVACY_ANONYMOUS_ACCESS_TOGGLE: "privacyAnonymousAccessToggle",

  FOLDER_SETTINGS: "folderSettings",
  FOLDER_RENAME: "folderRename",
  FOLDER_DELETE: "folderDelete",
  FOLDER_TRANSFER: "folderTransfer",
  FOLDER_REARRANGE: "folderRearrange",

  DESIGN_SETTINGS: "designSettings",
  DESIGN_SAVE: "designSave",

  DOWNLOAD_SETTINGS: "downloadSettings",
  DOWNLOAD_TOGGLE: "downloadToggle",
  DOWNLOAD_BULK_TOGGLE: "downloadBulkToggle",

  CLIENT_FAVOURITES: "clientFavourites",
  CLIENT_FAVOURITES_IMG_DOWNLOAD: "clientFavouritesImgDownload",
  CLIENT_FAVOURITES_CSV_DOWNLOAD: "clientFavouritesCSVDownload",
  CLIENT_FAVOURITES_TXT_DOWNLOAD: "clientFavouritesTXTDownload",

  // remove click analytics event from upgrade button
  ANALYTICS_EXPORT_SINGLE: "analyticsExport",
  ANALYTICS_EXPORT_ALL: "analyticsExportAll",

  TUTORIAL: "tutorial",
  HELPDESK_SUPPORT_SEARCH: "helpdeskSupportSearch",

  PROFILE_SAVE: "profileSave",
  PROFILE_SUBSCRIBE: "profileSubscribe",
  PROFILE_UPLOAD_HIGHRES: "profileUploadHighRes",
  PROFILE_STORAGE_INFO: "profileStorageInfo",

  BUSINESS_SETTINGS: "businessSettings",
  BRANDING_SETTINGS: "brandingSettings",
  BRANDING_LOGO_BROWSE: "brandingLogoBrowse",
  BRANDING_SAVE: "brandingSave",

  WATERMARK_SETTINGS: "watermarkSettings",
  WATERMARK_BROWSE_EDIT: "watermarkBrowseEdit",
  WATERMARK_SAVE: "watermarkSave",

  PORTFOLIO_SETTINGS: "portfolioSettings",
  PORTFOLIO_IMG_BROWSE: "portfolioImgBrowse",
  PORTFOLIO_IMG_EDIT: "portfolioImgEdit",
  PORTFOLIO_COVER_CHANGE: "portfolioCoverChange",
  PORTFOLIO_SAVE: "portfolioSave",
  PORTFOLIO_VIEW_SHARE: "portfolioViewShare",

  FLIPBOOK: "flipbook",
  FLIPBOOK_ADD_IMAGE: "flipbookAddImage",
  FLIPBOOK_SAVE: "flipbookSave",
  FLIPBOOK_VIEW_SHARE: "flipbookViewShare",

  SPONSOR_SETTINGS: "sponsorSettings",
  SPONSOR_BRANDING_LOGIN_PAGE: "sponsorBrandingLoginPage",
  SPONSOR_SHOW_MY_BRANDING: "sponsorShowMyBranding",
  SPONSOR_ADD: "sponsorAdd",

  SELL_PICS_SETTINGS: "sellPicsSettings",
  SELL_PICS_SAVE: "sellPicsSave",
  SELL_PICS_ADD_BUNDLE: "sellPicsAddBundle",

  TEAM_SETTINGS: "teamSettings",
  TEAM_ADD: "teamAdd",
  TEAM_EDIT: "teamEdit",

  FLIPBOOK_SETTINGS: "flipbookSettings",
  FLIPBOOK_SETTINGS_SAVE: "flipbookSettingsSave",
  FLIPBOOK_SETTINGS_BROWSE: "flipbookSettingsBrowse",

  WALLET_SETTINGS: "walletSettings",
  WALLET_ADD: "walletAdd",
};

export default ANALYTICS;
