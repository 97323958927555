import React from "react";
import { AutoSizer, List } from "react-virtualized";
import { UploadingCard } from "../../UploadFromComputer";
import Folder from "./Folder";

const FolderListings = ({
  folders,
  uploadedImages,
  isUploading,
  selectedFolder,
  setSelectedFolder,
}) => {
  if (selectedFolder) {
    const images = uploadedImages.filter(
      (image) => image.folderPrefix === selectedFolder.name
    );

    return (
      <>
        <div onClick={() => setSelectedFolder(null)}>
          <img
            src="/assets/images/icons/arrow-left.png"
            alt="back icon"
            width={40}
            className="pointer"
          />
          <span className="folder-name">{selectedFolder.name || "Others"}</span>
        </div>
        <div style={{ height: "320px", padding: "8px" }}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  height={height}
                  width={width}
                  rowHeight={45}
                  rowCount={images.length}
                  rowRenderer={({ style, key, index }) => {
                    let image = images[index];
                    return (
                      <UploadingCard key={key} style={style} image={image} />
                    );
                  }}
                />
              );
            }}
          </AutoSizer>
        </div>
      </>
    );
  } else {
    return folders.map((folder, index) => (
      <Folder
        folder={folder}
        key={index}
        isUploading={isUploading}
        uploadedImages={uploadedImages}
        onClick={(_folder) => setSelectedFolder(_folder)}
        id={folder}
      />
    ));
  }
};

export default FolderListings;
