import React, { useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import SecondaryButton from "../button/SmallRoundedButton";
import OutlinedButton from "../button/OutlinedButton";
import SolutionsDropdown from "./SolutionsDropdown";

const LandingPageHeader = ({ className = "" }) => {
  const [expanded, setExpanded] = useState(false);

  const navigator = useNavigate();
  const login = () => {
    navigator("/auth/login");
  };
  return (
    <Navbar
      sticky="top"
      key="lg"
      expand="lg"
      expanded={expanded}
      className={`navbar-bg thick-font ${className}`}
    >
      <Container>
        <Navbar.Brand as={Link} to="/landing/home">
          <img
            className="logo"
            src="/assets/images/global-images/logo.png"
            alt="logo"
          />
        </Navbar.Brand>

        <div className="create-join-sub ms-auto d-block d-lg-none">
          <a className="sub-menu" href="/landing/join">
            Join
          </a>
          <a className="sub-menu" href="/auth/login">
            Login
          </a>
        </div>

        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Offcanvas placement="end">
          <Offcanvas.Header closeButton onHide={() => setExpanded(false)}>
            <Offcanvas.Title>
              <Link to="/landing/home" onClick={() => setExpanded(false)}>
                <img
                  className="logo"
                  src="/assets/images/global-images/logo.png"
                  alt="logo"
                />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="font-14 align-items-lg-center flex-grow-1">
              <SolutionsDropdown />
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/landing/aboutus"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/helpdesk"
                target="_blank"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/helpdesk"
                target="_blank"
                className={expanded ? "d-block" : "d-none"}
              >
                FAQs
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/blog"
                target="_blank"
              >
                Blog
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/landing/pricing"
              >
                Pricing
              </Nav.Link>
              <Nav.Link
                className="blue-text ms-lg-auto d-block d-lg-none d-xl-block"
                href="/landing/join"
              >
                Join a Group
              </Nav.Link>
            </Nav>

            <SecondaryButton
              className="d-none d-lg-block px-36"
              onClick={login}
              rounded={false}
              variant="sm"
            >
              Sign Up or Login
            </SecondaryButton>
            <div className="button-box">
              <OutlinedButton
                className="w-100 mb-3"
                variant="sm"
                onClick={() =>
                  window.open("https://kwikpic-in.app.link/cfYvH3yuvtb")
                }
              >
                Download Kwikpic
              </OutlinedButton>
              <SecondaryButton
                onClick={login}
                rounded={false}
                variant="sm"
                className="w-100"
              >
                Sign Up or Login
              </SecondaryButton>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default LandingPageHeader;
