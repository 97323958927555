import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ProgressCard from "../progressBar/ProgressBar";
import SmallRoundedButton from "../button/SmallRoundedButton";
import SecondarySmallBtn from "../button/SecondarySmallBtn";
import PrimarySmallBtn from "../button/PrimarySmallBtn";
import Spinner from "../loader/Spinner";
import { bytesToSize } from "utils/helpers";
import useOneShotUpload from "../../../hooks/useOneShotUpload";
import DefaultButton from "../button/DefaultButton";
import FolderListings from "./components/uploadOneShot/FoldersListing";
import ConfirmationModal from "./ConfirmationModal";
import Dropzone from "react-dropzone";
import css from "./css/Uploads.module.css";
import { useSelector } from "react-redux";
import StorageFullModal from "./StorageFullModal";
import SubscriptionModal from "views/subscription";
import { storageFullSlackMessage } from "utils/helpers/subscription";
import sendSlackMessage from "utils/helpers/sendSlackMessage";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { toast } from "react-toastify";

export const SORT_TYPES = {
  DESC: "desc",
  ASC: "asc",
  DEFAULT: "default",
};

export const DEFAULT_SORT = SORT_TYPES.DESC;

const UploadOneShot = (props) => {
  /**
   * inputRef is populated if the user selects files from the file dialog
   * dropzoneRef is populated if the user drags and drops files
   */
  const inputRef = React.useRef(null);
  const dropzoneRef = React.useRef(null);
  const {
    loading,
    uploadedImages,
    isUploading,
    totalImagesSize,
    handleFolderUpload,
    duplicateImages,
    successfullyUploaded,
    successFullySentToBackend,
    imagesWithError,
    hasUploadedAllImages,
    allFolders,
    startUploading,
    handleFailedUploads,
    handleConfirmationModal,
    confirmationModal,
    processedImagesPercentage,
    setUploadedImages,
  } = useOneShotUpload({
    show: props.show,
    handleClose: props.handleClose,
  });

  const {
    userAnalytics,
    privacySettings: { guestFolderOnly },
    groupDetails: { isAdmin },
  } = useSelector((state) => state.settings);

  const {
    photoUploads,
    highResPhotoUploads,
    uploadLimit,
    totalUtilisation,
    maxPhotoUpload,
  } = userAnalytics || {};

  const allowOnlyGuestFolder = guestFolderOnly && !isAdmin;

  const { highRes } = getLocalStorageUser();

  const oneXCondition =
    totalUtilisation +
      (uploadedImages.length - duplicateImages.length) * (highRes ? 2.5 : 1) >
    uploadLimit;

  const limit = maxPhotoUpload ? maxPhotoUpload : uploadLimit * 2;
  const twoXCondition =
    photoUploads +
      highResPhotoUploads * 2.5 +
      (uploadedImages.length - duplicateImages.length) * (highRes ? 2.5 : 1) >
    limit;

  const storageCon = oneXCondition || twoXCondition;

  const [selectedFolder, setSelectedFolder] = React.useState(null);

  const [subscriptionModal, setSubscriptionModal] = useState({
    subscription: false,
    storage: false,
  });

  const [keepNested, setKeepNested] = useState(null);
  const [selectModal, setSelectModal] = useState(false);

  const handleStorageFull = () => {
    setSubscriptionModal({ ...subscriptionModal, storage: true });
    const msg = storageFullSlackMessage(uploadedImages.length);
    sendSlackMessage(msg, "#upload-exceed");
  };

  const checkNestedFolders = (files) => {
    const nested = files.some((file) =>
      file?.webkitRelativePath
        ? file.webkitRelativePath.split("/").length > 3
        : file?.path.split("/").length > 4
    );
    setSelectModal(nested);
    return nested;
  };

  // this function is not being executed, still keeping it for fallback
  // rejectedFiles toast is also handled in the upload hook
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0)
      toast.error(
        <>
          Only PNG, JPG and HEIC images are supported.
          <br />
          {rejectedFiles.length} file(s) were rejected.
        </>
      );

    if (acceptedFiles.length === 0) return;
    dropzoneRef.current = acceptedFiles;

    if (allowOnlyGuestFolder)
      return handleFolderUpload(acceptedFiles, false, true);

    let k = checkNestedFolders(acceptedFiles);
    if (!k) handleFolderUpload(acceptedFiles);
  };

  React.useEffect(() => {
    if (keepNested !== null) {
      if (inputRef.current?.files?.length > 0)
        handleFolderUpload(Array.from(inputRef.current?.files), keepNested);
      else handleFolderUpload(dropzoneRef.current, keepNested);
    }
  }, [keepNested]);

  const folderUploadHandler = (e) => {
    if (allowOnlyGuestFolder)
      return handleFolderUpload(Array.from(e.target?.files || []), false, true);

    let k = checkNestedFolders(Array.from(e.target?.files || []));
    if (!k) handleFolderUpload(Array.from(e.target?.files || []));
  };

  return (
    <>
      <Modal
        centered
        show={selectModal}
        onHide={() => {
          setKeepNested(null);
          setSelectModal(false);
        }}
      >
        <Modal.Header
          closeButton={true}
          className="justify-content-center border-0"
        >
          <Modal.Title>Keep folder structure?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 pt-0 font-15 text-center font-base">
          We detected nested folders. What do you want to do with the folders
          inside sub-folders?
          <div className="mt-4 d-flex align-items-center justify-content-center gap-4">
            <SmallRoundedButton
              onClick={() => {
                setKeepNested(true);
                setSelectModal(false);
              }}
              className="mr-2 px-4"
            >
              Keep Separate
            </SmallRoundedButton>
            <button
              onClick={() => {
                setKeepNested(false);
                setSelectModal(false);
              }}
              className="normal-white-btn rounded-pill font-15 px-4"
              style={{ height: "40px" }}
            >
              Merge Them
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={props.show}
        onHide={handleConfirmationModal}
        animation={true}
        className="uploadOneShot"
      >
        <Modal.Header
          closeButton={true}
          className="justify-content-center border-0 position-relative"
        >
          <Modal.Title>
            <p className="font-bold font-24 mb-0">Upload Photos</p>
            {highRes && (
              <p className="font-13 color-green base-font position-absolute end-0 top-50 translate-middle-y m-0 me-5">
                <img
                  src="/assets/images/icons/info-green.png"
                  alt="info plain"
                  className="info-icon me-1"
                />
                High Resolution mode is ON
                <br />1 High-Res Upload = 2.5 Photos
              </p>
            )}
          </Modal.Title>
          {/* {uploadedImages.length > 0 && (
            <>
              <span className={css.sortBy}>Sort </span>
              <select
                style={{
                  position: "absolute",
                  right: "7%",
                  top: "2%",
                  width: "13%",
                }}
                className="form-select"
                defaultValue={DEFAULT_SORT}
                onChange={(event) => handleSortBy(event.target.value)}
                disabled={isUploading}
              >
                {!sortByRef.current && (
                  <option value={sortTypes.DEFAULT}>sortBy</option>
                )}
                <option value={sortTypes.ASC}>A to Z</option>
                <option value={sortTypes.DESC}>Z to A</option>
              </select>
            </>
          )}
          {uploadedImages.length > 0 && (
            <div
              className="d-flex flex-column align-items-center"
              style={{
                position: "absolute",
                top: "2%",
                left: "2%",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                }}
                className={css.uploadSelector}
              >
                <label
                  className={imagesPerRequest === 5 ? css.selected : ""}
                  htmlFor="uplFive"
                >
                  Upload 5 photos
                  <br />
                  at once - <span className="fw-bold">Faster</span>
                </label>
                <input
                  onClick={uploadLimitChange}
                  type="radio"
                  value="five"
                  id="uplFive"
                  disabled={isUploading}
                />
                <label
                  className={imagesPerRequest !== 5 ? css.selected : ""}
                  htmlFor="uplOne"
                >
                  Upload 1 photo at
                  <br />
                  once - <span className="fw-bold">Sequential</span>
                </label>
                <input
                  onClick={uploadLimitChange}
                  type="radio"
                  value="one"
                  id="uplOne"
                  disabled={isUploading}
                />
              </div>
            </div>
          )} */}
        </Modal.Header>
        <Modal.Body className="px-2">
          <Spinner
            description={
              processedImagesPercentage
                ? `${processedImagesPercentage}%`
                : undefined
            }
            loading={loading}
          />
          <div className="row align-items-center">
            <div className="col-md-7 d-flex">
              <div className={css.iconHolder}>
                <img src="/assets/icons/jpg-icon.svg" alt="jpg-icon" />
              </div>
              <ProgressCard
                thumb={true}
                leftText={`${successFullySentToBackend}/${
                  uploadedImages.length
                } Photos${
                  hasUploadedAllImages
                    ? ", " +
                      successfullyUploaded.length +
                      " success, " +
                      duplicateImages.length +
                      " duplicates, " +
                      imagesWithError.length +
                      " errors."
                    : ""
                }`}
                rightText={bytesToSize(totalImagesSize)}
                progressPercentage={
                  (successFullySentToBackend / uploadedImages.length) * 100 || 0
                }
              />
            </div>
            <div className="col-md-5">
              <p className="mb-0 font-base font-12">
                Drag and drop parent folder with sub-folders inside to create
                the entire event directory at one go
              </p>
            </div>
          </div>

          <div className="row folders_list position-relative mx-0">
            {/* {(uploadedImages.length > 0 || isUploading) && (
              <div
                className="p-0"
                style={{
                  fontSize: "12px",
                  background: "#f3f7f9",
                  width: "8.5ch",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#3e6588",
                  }}
                  className="m-0 mt-4 position-absolute top-0"
                >
                  Displayed
                  <br />
                  LAST →
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#3e6588",
                  }}
                  className="m-0 mb-4 position-absolute bottom-0"
                >
                  Displayed
                  <br />
                  FIRST →
                </p>
              </div>
            )} */}
            <div className="col px-0">
              {isUploading || uploadedImages.length > 0 ? (
                <div
                  className="uploadingCard"
                  style={{
                    backgroundColor: "#f3f7f9",
                    maxHeight: "none",
                  }}
                >
                  <FolderListings
                    folders={allFolders}
                    setUploadedImages={setUploadedImages}
                    uploadedImages={uploadedImages}
                    isUploading={isUploading}
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                  />
                </div>
              ) : (
                <Dropzone
                  accept={{
                    "image/png": [".png"],
                    "image/jpeg": [".jpeg"],
                    "image/jpg": [".jpg"],
                    "image/heic": [".heic"],
                    "image/heif": [".heif"],
                  }}
                  multiple={true}
                  noClick={true}
                  onDrop={onDrop}
                >
                  {({ getRootProps, getInputProps }) => (
                    <label
                      className="dropableCard"
                      style={{
                        margin: "0",
                        padding: "0",
                        position: "relative",
                      }}
                      {...getRootProps()}
                      htmlFor="file"
                      id="drop-folder"
                    >
                      <div className="mt-2" />
                      <img
                        height="150px"
                        src="/assets/images/icons/folders.png"
                        alt="folders"
                      />
                      <div className="mt-2" />
                      <input
                        type="file"
                        id="file"
                        {...getInputProps({
                          webkitdirectory: "",
                          directory: "",
                        })}
                        style={{
                          opacity: 0,
                        }}
                        directory="" //eslint-disable-line
                        webkitdirectory=""
                        ref={inputRef}
                        onChange={folderUploadHandler}
                      />
                      <span className="font-15 font-bold gray-text">
                        Drag photos here
                      </span>
                      <div className="mt-2" />
                      <span className="font-15 font-bold gray-text">or</span>
                      <div className="mt-2" />
                      <SmallRoundedButton
                        title="Browse"
                        onClick={() => inputRef.current.click()}
                      />
                    </label>
                  )}
                </Dropzone>
              )}
            </div>
          </div>
        </Modal.Body>
        {uploadedImages.length > 0 && (
          <Modal.Footer className="px-1">
            <div className={css.footerContainer}>
              {isUploading ? (
                <span className="warning-message">
                  *Please do not quit the application. Images are still
                  Uploading.
                </span>
              ) : (
                <>
                  {hasUploadedAllImages ? (
                    <>
                      <span className="uploadComplete">Completed!</span>
                      {imagesWithError.length > 0 && (
                        <PrimarySmallBtn
                          title="Retry Failed Uploads"
                          onClick={handleFailedUploads}
                        />
                      )}
                      <label htmlFor="uploadMore">
                        <input
                          type="file"
                          className="d-none"
                          ref={inputRef}
                          id="uploadMore"
                          multiple="multiple"
                          directory="" //eslint-disable-line
                          webkitdirectory=""
                          onChange={folderUploadHandler}
                        />
                        <SecondarySmallBtn
                          title="Upload More"
                          onClick={() => {
                            setKeepNested(null);
                            setSelectModal(false);
                            dropzoneRef.current = [];
                            inputRef.current.click();
                          }}
                        />
                      </label>
                    </>
                  ) : (
                    <DefaultButton
                      title="Start Upload"
                      className="fw-bold"
                      onClick={() =>
                        storageCon ? handleStorageFull() : startUploading()
                      }
                    />
                  )}
                </>
              )}
            </div>
          </Modal.Footer>
        )}
        <ConfirmationModal {...confirmationModal} />
        <StorageFullModal
          show={subscriptionModal.storage}
          onHide={() => setSubscriptionModal((p) => ({ ...p, storage: false }))}
          onConfirm={() =>
            setSubscriptionModal({
              storage: false,
              subscription: true,
            })
          }
          type="photos"
          exceed={oneXCondition ? "1x" : "2x"}
        />
        {subscriptionModal.subscription && (
          <SubscriptionModal
            title="UPGRADE YOUR PLAN"
            isOpen={subscriptionModal.subscription}
            onClose={() =>
              setSubscriptionModal((p) => ({ ...p, subscription: false }))
            }
          />
        )}
      </Modal>
    </>
  );
};

export default UploadOneShot;
