import CryptoJS from "crypto-js";

const generateCipher = () => {
  const t = Date.now();
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_KEY);
  const iv = CryptoJS.lib.WordArray.random(16);

  const enc = CryptoJS.AES.encrypt(`${t}`, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    format: CryptoJS.format.Hex,
  });

  const cipher = `${iv.toString()}:${enc.toString()}`;

  return cipher;
};

export default generateCipher;
