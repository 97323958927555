import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { modifyAnonymousGallaryRoute } from "utils/helpers/routes";

const useAnonymousGalleryRouteManager = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const groupId = params.get("groupId");
  const userId = params.get("anonymousUserId");

  useEffect(() => {
    if (!groupId) {
      navigate("/", { replace: true });
      return;
    }

    const defaultFolder = groupId === userId ? "all-photos" : "my-photos";
    let page;
    let folderId;

    page = params.get("page");
    if (isNaN(Number(page)) || !page) {
      page = 1;
    }

    folderId = params.get("folderId");
    if (!folderId) {
      folderId = defaultFolder;
    }

    navigate(
      modifyAnonymousGallaryRoute(
        {
          anonymousUserId: userId,
          groupId,
          folderId,
          pageNo: page,
        },
        params
      ),
      { replace: true }
    );
  }, [params]);
};

export default useAnonymousGalleryRouteManager;
