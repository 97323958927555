import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LandingPageFooter from "../components/footer/LandingPageFooter";
import LandingPageHeader from "../components/header/LandingPageHeader";
import { useSelector } from "react-redux";

const LandingRoute = () => {
  const location = useLocation();
  const countryFromCookie = document.cookie
    .split(";")
    .find((item) => item.includes("country"));
  const countryFromCookieValue = countryFromCookie?.split("=")[1];

  const country =
    useSelector((state) => state.globals?.country?.code) ||
    countryFromCookieValue;

  const pathname = location.pathname;

  React.useEffect(() => {
    if (!location.hash) window.scrollTo(0, 0);
  }, [location.hash, location.pathname]);

  /**
   * @todo
   * Create a hook for country based redirection
   */

  if (country === "UK" || country === "US") {
    if (
      pathname.includes("home") ||
      pathname.includes("photographer-dashboard")
    )
      return <Navigate to={`/${country}`} />;

    if (pathname.includes("about"))
      return <Navigate to={`/${country}#about`} />;
    if (pathname.includes("contact"))
      return <Navigate to={`/${country}#contact`} />;
    if (pathname.includes("pricing"))
      return <Navigate to={`/${country}#plans`} />;
  }

  return (
    <div className="no-overflow-x">
      <LandingPageHeader />
      <Outlet />
      <LandingPageFooter />
    </div>
  );
};

export default LandingRoute;
