import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import css from "../../ads/AdsLanding.module.css";
import GradButton from "views/components/button/GradButton";

const Hero = ({ country, videoUrl, title: { start, end } = {} }) => {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h1 className={`font-bold ${css.heading}`}>
            {start || `Say Hello to `}
            <span className={css["color-grad"]}>
              {end || `Kwikpic ${country}!`}
            </span>
          </h1>
          <p className="font-16 base-font color-off m-0">
            An AI-powered Facial Recognition app that makes your photo-sharing
            seamless and <br />
            offers a singular platform for all your needs.
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className={css["yt-div"]}>
          <iframe
            src={videoUrl || "https://tube.rvere.com/embed?v=JiAZo9skR00"}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className={css.book}>
            <h2 className="font-24 font-bold">Learn how Kwikpic works</h2>
            <p className="font-16 base-font color-off">
              Share high quality photos using
              <br />
              facial recognition
            </p>
            <GradButton
              title="Book Free Demo Today!"
              className="w-fit px-3 lh-12"
              variant="sm"
              onClick={() => {
                document.querySelector("#contact").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Hero;
