import { call, put, takeLatest } from "redux-saga/effects";
import {
  setActivePlan,
  setAllPlans,
  setPlansLoading,
  setPublicPlans,
  setOrderDetails,
  setPaymentLink,
  setBillingInfo,
  setLoading,
  setFeatureUpgrades,
} from "redux-store/slices/subscription";
import { addUserSubscriptionToLocalStorage } from "utils/helpers/localstorage";
import { AXIOS } from "utils/setup/axios";
import {
  GET_ALL_PLANS,
  GET_MY_SUBSCRIPTION_PLAN,
  GET_PUBLIC_PLANS,
  CREATE_ORDER,
  PAYMENT_LINK,
  CREATE_ORDER_VISITOR,
  PAYMENT_VISITOR,
  GET_BILLING_INFO,
  POST_BILLING_INFO,
} from "./saga-actions";
import { toast } from "react-toastify";
import generateCipher from "utils/helpers/generateCipher";
import userPlans from "../../constants/user-plans.json";
import pgPlans from "../../constants/pg-plans.json";

async function getAllSubscriptionPlans() {
  return AXIOS.get(`/api/payment/plan/get-all-plans?enabled=true`);
}

async function getPublicPlans(payload) {
  const cipher = generateCipher();
  const userType = payload?.userType || "USER";

  try {
    const res = await AXIOS.get("/api/payment/plan/get-all-plans-public", {
      params: {
        enabled: true,
        userType,
        billingGT: 100,
      },
      headers: { cipher },
    });

    return res;
  } catch {
    let plans = [];
    if (userType === "USER") plans = userPlans;
    else plans = pgPlans;

    return { data: { data: { plans } } };
  }
}

async function getMySubscriptionPlan() {
  return AXIOS.get(`/api/payment/subscription/get`);
}

async function createOrder(data) {
  const { plan_id, currency, frontendType } = data || {};
  return AXIOS.post("/api/payment/subscription/create-order", {
    plan_id,
    currency,
    frontendType,
  });
}

async function createPaymentLink(data) {
  const { plan_id, currency, frontendType } = data || {};
  return AXIOS.post("/api/payment/subscription/create-payment-link", {
    plan_id,
    currency,
    frontendType,
  });
}

async function createOrderVisitor(data) {
  const cipher = generateCipher();
  const {
    plan_id,
    currency,
    frontendType,
    visitorFirstName,
    visitorLastName,
    visitorEmail,
    visitorPhone,
  } = data || {};

  return AXIOS.post(
    "/api/payment/subscription/create-order-for-site-visitor",
    {
      plan_id,
      currency,
      frontendType,
      visitorFirstName,
      visitorLastName,
      visitorEmail,
      visitorPhone,
    },
    { headers: { cipher } }
  );
}

async function createPaymentLinkVisitor(data) {
  const {
    plan_id,
    currency,
    frontendType,
    visitorFirstName,
    visitorLastName,
    visitorEmail,
    visitorPhone,
  } = data || {};
  return AXIOS.post(
    "/api/payment/subscription/create-payment-link-for-site-visitor",
    {
      plan_id,
      currency,
      frontendType,
      visitorFirstName,
      visitorLastName,
      visitorEmail,
      visitorPhone,
    }
  );
}

function* getAllSubscriptionPlansGenerator() {
  try {
    yield put(setPlansLoading(true));
    const response = yield call(getAllSubscriptionPlans);
    const { plans = [], groupFeatureUpgradeCosts = [] } =
      response.data?.data || {};
    yield put(setAllPlans(plans));
    yield put(setFeatureUpgrades(groupFeatureUpgradeCosts));
  } catch {
    //
  } finally {
    yield put(setPlansLoading(false));
  }
}

function* getMySubscriptionPlanGenerator() {
  try {
    yield put(setPlansLoading(true));
    const response = yield call(getMySubscriptionPlan);
    const plan = response?.data?.data?.plan || {};
    const subscription = response?.data?.data?.subscription || {};
    addUserSubscriptionToLocalStorage({ ...subscription, ...plan });
    yield put(setActivePlan({ ...subscription, ...plan }));
  } catch {
    //
  } finally {
    yield put(setPlansLoading(false));
  }
}

function* getPublicPlansGenerator(action) {
  try {
    yield put(setPlansLoading(true));
    const response = yield call(getPublicPlans, action?.payload);
    const data = response.data?.data?.plans || [];
    yield put(setPublicPlans(data));
  } catch {
    //
  } finally {
    yield put(setPlansLoading(false));
  }
}

function* createOrderGenerator(action) {
  try {
    yield put(setPlansLoading(true));
    const response = yield call(createOrder, action?.payload);
    yield put(setOrderDetails(response?.data?.data?.orderData || {}));
  } catch (e) {
    toast.error(e?.response?.data?.message || "Something went wrong");
  } finally {
    yield put(setPlansLoading(false));
  }
}

function* createPaymentLinkGenerator(action) {
  try {
    yield put(setPlansLoading(true));
    const response = yield call(createPaymentLink, action?.payload);
    yield put(setPaymentLink(response?.data?.data?.paymentLinkData || {}));
  } catch (e) {
    toast.error(e?.response?.data?.message || "Something went wrong");
  } finally {
    yield put(setPlansLoading(false));
  }
}

function* createOrderVisitorGenerator(action) {
  try {
    yield put(setPlansLoading(true));
    const response = yield call(createOrderVisitor, action?.payload);
    yield put(setOrderDetails(response?.data?.data?.orderData || {}));
  } catch (e) {
    toast.error(e?.response?.data?.message || "Something went wrong");
  } finally {
    yield put(setPlansLoading(false));
  }
}

function* createPaymentLinkVisitorGenerator(action) {
  try {
    yield put(setPlansLoading(true));
    const response = yield call(createPaymentLinkVisitor, action?.payload);
    yield put(setPaymentLink(response?.data?.data?.paymentLinkData || {}));
  } catch (e) {
    toast.error(e?.response?.data?.message || "Something went wrong");
  } finally {
    yield put(setPlansLoading(false));
  }
}

async function getBillingInfo({ orderId, user_id }) {
  const cipher = generateCipher();

  return AXIOS.get("/api/payment/billing/order-info", {
    params: {
      user_id,
      order_id: orderId,
    },
    headers: { cipher },
  });
}

function* getBillingInfoGenerator(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(getBillingInfo, action?.payload);
    yield put(setBillingInfo(response?.data?.data || {}));
  } catch (e) {
    toast.error(e?.response?.data?.message || "Something went wrong");
  } finally {
    yield put(setLoading(false));
  }
}

async function postBillingInfo(data) {
  const cipher = generateCipher();

  return AXIOS.post(`/api/payment/billing/save`, data, {
    headers: { cipher },
  });
}

function* postBillingInfoGenerator(action) {
  try {
    const response = yield call(postBillingInfo, action?.payload);
    yield put(setBillingInfo(response?.data?.data || {}));
  } catch (e) {
    toast.error(e?.response?.data?.message || "Something went wrong");
  }
}

export function* subscriptionsSaga() {
  yield takeLatest(GET_ALL_PLANS, getAllSubscriptionPlansGenerator);
  yield takeLatest(GET_MY_SUBSCRIPTION_PLAN, getMySubscriptionPlanGenerator);
  yield takeLatest(GET_PUBLIC_PLANS, getPublicPlansGenerator);
  yield takeLatest(CREATE_ORDER, createOrderGenerator);
  yield takeLatest(PAYMENT_LINK, createPaymentLinkGenerator);
  yield takeLatest(CREATE_ORDER_VISITOR, createOrderVisitorGenerator);
  yield takeLatest(PAYMENT_VISITOR, createPaymentLinkVisitorGenerator);
  yield takeLatest(GET_BILLING_INFO, getBillingInfoGenerator);
  yield takeLatest(POST_BILLING_INFO, postBillingInfoGenerator);
}
