import React from "react";
import ContactusForm from "views/contactUs/components/ContactusForm";
import "./Mobile.css";

const MobileSupport = () => {
  return (
    <div className="mobile-support">
      <ContactusForm fromApp={true} />
    </div>
  );
};

export default MobileSupport;
