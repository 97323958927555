import React from "react";

const SecondaryButton = ({ title, style, className, onClick }) => {
  return (
    <button
      className={`secondaryButton max-180 ${className || ""}`}
      style={style || {}}
      onClick={onClick}
    >
      {/* {props.icon && (
        <img src="../../../assets/images/icons/premium.png" alt="icon" />
      )} */}
      {title}
    </button>
  );
};
export default SecondaryButton;
