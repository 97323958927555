import React from "react";
import "react-virtualized/styles.css";
import { Table, Column } from "react-virtualized";

const CustomTable = ({
  rows,
  columns = [],
  totalWidth = 1000,
  totalHieght,
  analyticsEnabled,
  rowHeight = 70,
}) => {
  const headerRenderer = ({ dataKey, label }) => {
    return (
      <React.Fragment key={dataKey}>
        <div className="ReactVirtualized__Table__headerTruncatedText">
          {label}
        </div>
      </React.Fragment>
    );
  };

  const rowRenderer = ({ cellData }) => {
    return (
      <div className="containers">
        <p className="para">{cellData}</p>
      </div>
    );
  };

  return (
    <Table
      width={totalWidth}
      className={`table ${analyticsEnabled ? "analyticsEnabled" : ""}`}
      height={totalHieght || 500}
      headerHeight={30}
      rowHeight={rowHeight}
      rowStyle={{
        marginBottom: "12px",
      }}
      rowCount={rows?.length || 0}
      rowGetter={({ index }) => rows?.[index]}
    >
      {columns.map((column) => {
        return (
          <Column
            key={column.dataKey}
            headerRenderer={headerRenderer}
            dataKey={column.dataKey}
            label={column.label} //column.label doesnt exist because design doesnt show the column label
            width={column.width}
            cellRenderer={rowRenderer}
          />
        );
      })}
    </Table>
  );
};

export default CustomTable;
