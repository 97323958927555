export const POSITIONS = {
  TOPLEFT: "TOPLEFT",
  TOPRIGHT: "TOPRIGHT",
  BOTTOMLEFT: "BOTTOMLEFT",
  BOTTOMRIGHT: "BOTTOMRIGHT",
  CENTER: "CENTER",
  BOTTOMCENTER: "BOTTOMCENTER",
};

export const CARD_DATA = [
  {
    position: POSITIONS.TOPLEFT,
    title: "Top Left",
    icon: "/assets/images/icons/top-left.png",
  },
  {
    position: POSITIONS.TOPRIGHT,
    title: "Top Right",
    icon: "/assets/images/icons/top-right.png",
  },
  {
    position: POSITIONS.BOTTOMLEFT,
    title: "Bottom Left",
    icon: "/assets/images/icons/bottom-left.png",
  },
  {
    position: POSITIONS.BOTTOMRIGHT,
    title: "Bottom Right",
    icon: "/assets/images/icons/bottom-right.png",
  },
  {
    position: POSITIONS.BOTTOMCENTER,
    title: "Bottom Center",
    icon: "/assets/images/icons/bottom-center.png",
  },
  {
    position: POSITIONS.CENTER,
    title: "Center",
    icon: "/assets/images/icons/center.png",
  },
];
