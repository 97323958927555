import React from "react";
import FocusedImage from "views/settings/components/focused-image";
import { Navbar } from "react-bootstrap";
import Slider from "react-slick";
import SponsorBlock from "views/gallary/components/SponsorBlock";

const GroupDetails = ({ groupDetails }) => {
  const {
    groupName,
    allPicsCount,
    isPrivate,
    brandingData: {
      aboutUs,
      website_route,
      coverImage,
      logo,
      business_name,
    } = {},
    groupJoinBranding,
    icon,
    iconFocalPoint,
    sponsors,
    showMyBranding = false,
  } = groupDetails || {};

  const showPortfolio = !!aboutUs && !!website_route && !!coverImage;

  return (
    <>
      <div className="col7-join position-relative">
        <div className="signup-logo-wrapper d-none d-md-block">
          <a href="/landing/home">
            <img
              src="/assets/images/global-images/logo-white.png"
              width={157}
              alt="logo"
            />
          </a>
        </div>
        <Navbar fixed="top" className="p-2 mb-5 rounded d-md-none">
          <Navbar.Brand href="/landing/home">
            <img
              className="logo"
              src="/assets/images/global-images/logo-white.png"
              alt="logo"
            />
          </Navbar.Brand>
        </Navbar>
        <div className="left-side-image-back">
          {icon ? (
            <div className="join-group-cover">
              <FocusedImage
                imageSrc={icon}
                x={+iconFocalPoint?.split("/")[0]}
                y={+iconFocalPoint?.split("/")[1]}
              />
            </div>
          ) : (
            <img
              className="join-group-cover"
              src="/assets/images/landing/group-icon-fallback.jpg"
              alt=""
            />
          )}
          {groupJoinBranding !== false && (
            <div className="box-join gap-2">
              {groupDetails?.brandingData && (
                <div>
                  {sponsors?.length ? (
                    <Slider
                      dots={false}
                      autoplay={true}
                      infinite={true}
                      speed={1000}
                      slidesToShow={1}
                      fade={true}
                      prevArrow={<></>}
                      nextArrow={<></>}
                    >
                      {[
                        showMyBranding && {
                          _id: "1",
                          sTitle: "Album by",
                          sName: business_name,
                          sImage: logo,
                          sLink: showPortfolio
                            ? `/portfolio/${website_route}`
                            : "#",
                        },
                        ...sponsors,
                      ]
                        .filter((i) => i)
                        .map((sponsor) => (
                          <SponsorBlock
                            key={sponsor._id}
                            sponsor={sponsor}
                            isAnonymous={false}
                            showMedia={false}
                            template="4"
                            isLoginPage={true}
                          />
                        ))}
                    </Slider>
                  ) : showMyBranding ? (
                    <SponsorBlock
                      sponsor={{
                        _id: "1",
                        sTitle: "Album by",
                        sName: business_name,
                        sImage: logo,
                        sLink: showPortfolio
                          ? `/portfolio/${website_route}`
                          : "#",
                      }}
                      isAnonymous={false}
                      showMedia={true}
                      isLoginPage={true}
                      template="4"
                    />
                  ) : null}
                </div>
              )}
              <div>
                <h2 className="text-center group-header lh-base">
                  {groupName}
                </h2>
                <p className="text-center photo-count">{allPicsCount} photos</p>
              </div>
              {isPrivate ? (
                <span className="text-center group-type">
                  Private Group&nbsp;
                  <img
                    src="/assets/images/icons/photograper-icons/Group776.png"
                    alt=""
                  />
                </span>
              ) : (
                <span className="text-center group-type">
                  Public Group&nbsp;
                  <img
                    src="/assets/images/icons/photograper-icons/Group776.png"
                    alt=""
                  />
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default GroupDetails;
