import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  allImages: [],
  groupID: "",
  initialLoader: true,
  loader: false,
  deleteImageResponse: {},
  newImagesCount: 0,
  page: 1,
  commentLoader: false,
  commentData: null,
};

const slice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setAllImagesOfGroup: (state, action) => {
      const { groupID, data } = action?.payload || {};
      const count = data?.data?.count;
      const {
        status,
        data: { pics = [] },
      } = data || {};

      if (status === 200) {
        const allImages = [];

        pics.forEach((pic) => {
          allImages.push(...(pic?.images || []));
        });

        return {
          ...state,
          allImages: [...allImages],
          groupID,
          initialLoader: false,
          count,
          newImagesCount: allImages.length,
        };

        // if (page === 1) {
        //   return {
        //     ...state,
        //     allImages: [...allImages],
        //     groupID,
        //     initialLoader: false,
        //     count,
        //     newImagesCount: allImages.length,
        //   };
        // } else {
        //   return {
        //     ...state,
        //     allImages: [...state.allImages, ...allImages],
        //     count,
        //     newImagesCount: allImages.length,
        //   };
        // }
      }
    },
    patchImageOfGroup: (state, action) => {
      if (action.payload.response?.status === 200) {
        const response = action.payload.response?.data;
        const imgUrl = action.payload.imgUrl;
        toast.success(response.message);
        const filteredImages = state.allImages.filter(
          (image) => image.url !== imgUrl
        );
        return {
          ...state,
          allImages: filteredImages,
        };
      }
    },
    clearAllImagesOfGroup: () => {
      return initialState;
    },
    setInitialLoaderImages: (state, action) => {
      return {
        ...state,
        initialLoader: action?.payload,
      };
    },
    setCommentLoader: (state, action) => {
      return {
        ...state,
        commentLoader: action?.payload,
      };
    },
    setCommentData(state, action) {
      return {
        ...state,
        commentData: action?.payload,
      };
    },
    setAllImagesPage: (state, action) => {
      return {
        ...state,
        page: action?.payload || 1,
      };
    },
  },
});

export const {
  setAllImagesOfGroup,
  clearAllImagesOfGroup,
  patchImageOfGroup,
  setInitialLoaderImages,
  setAllImagesPage,
  setCommentLoader,
  setCommentData,
} = slice.actions;

export default slice.reducer;
