import React, { useState } from "react";
import SettingPageHeading from "./components/SettingPageHeading";
import DefaultSwitch from "../components/input/DefaultSwitch";
import SecondarySmallBtn from "../components/button/SecondarySmallBtn";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/loader/Spinner";
import { useSearchParams } from "react-router-dom";
// import {
//   GET_GROUP_DESIGNA_AND_SETTINGS,
//   GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
// } from "../../redux-store/sagas/saga-actions";
import { toast } from "react-toastify";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import { Accordion } from "react-bootstrap";
import copy from "clipboard-copy";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { PAID_FEATURES } from "constants/plans";
import SubscriptionModal from "views/subscription";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";
import ANALYTICS from "constants/analyticsKeys";

const MODAL_TYPES = {
  BULK_DOWNLOAD: "bulk_download",
  DOWNLOAD_SWITCH: "download_switch",
  NONE: "",
};

const DownloadSettings = () => {
  const {
    downloadSettings,
    saveLoader,
    groupId,
    settingLoader,
    groupDetails: { createdBy },
  } = useSelector((state) => state.settings);

  const { hasAccessToFeature } = useSubscription();

  const [download, setDownload] = useState({});
  const [subscriptionModal, setSubscriptionModal] = useState(MODAL_TYPES.NONE);
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  const { _id: currentUserId } = getLocalStorageUser();

  const copyURLLink = () => {
    let location = window.location.host;
    let value = location + "/gallery/download?groupId=" + params.get("groupId");

    copy(value).then(() => toast.success("Link copied to clipboard"));
  };

  // useEffect(() => {
  //   dispatch(setSettingLoader(true));
  //   dispatch({
  //     type: GET_GROUP_DESIGNA_AND_SETTINGS,
  //     groupId: params.get("groupId"),
  //   });
  //   dispatch({
  //     type: GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  //     groupID: params.get("groupId"),
  //   });
  // }, []);

  const handleSubmit = (prop) => {
    dispatch({
      type: "POST_GROUP_DESIGN_AND_SETTING",
      payload: {
        download: { ...download, ...prop },
        groupId,
        source: "download",
      },
    });
  };

  return (
    <div>
      <SettingPageHeading
        // btnTitle="Save"
        // onClick={handleSubmit}
        title="Download Setting"
        goBack={token && !noback ? () => history.back() : false}
      />
      <div className="row">
        <div className="col-md-6">
          <div className="mt-4">
            <div className="d-flex align-items-center mb-2 md-jcb">
              <h3 className="mb-0 me-2 font-16">Download</h3>
              <DefaultSwitch
                isChecked={downloadSettings?.allowDownload}
                onChange={(e) => {
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: { buttons: [ANALYTICS.DOWNLOAD_TOGGLE] },
                  });

                  if (
                    currentUserId === createdBy &&
                    !hasAccessToFeature(
                      PLAN_FEATURES.switch_on_off_downloads
                    ) &&
                    !downloadSettings.allowDownloadIsPaid
                  ) {
                    return setSubscriptionModal(MODAL_TYPES.DOWNLOAD_SWITCH);
                  }
                  setDownload({
                    ...download,
                    allowDownload: e?.target?.checked,
                  });
                  handleSubmit({ allowDownload: e?.target?.checked });
                }}
              />
            </div>
            <p className="gray-text med-font font-15">
              Switch off to disable photo downloads, sharing and screenshots
            </p>
          </div>
          <Accordion>
            <Accordion.Item eventKey={0} className="border-0">
              <Accordion.Header className="settings-page w-auto">
                Advance Settings
              </Accordion.Header>
              <Accordion.Body className="p-0">
                {/* <div className="mt-1">
                  <div className="d-flex align-items-center mb-2 md-jcb">
                    <h3 className="mb-0 me-2 font-16">
                      Download in Original Quality
                    </h3>
                    <DefaultSwitch
                      isChecked={
                        download?.originalDownload ??
                        downloadSettings?.originalDownload
                      }
                      onChange={(e) => {
                        if (
                          !hasAccessToFeature(
                            PLAN_FEATURES.high_quality_downloads
                          )
                        ) {
                          setSubscriptionModal(true);
                          return;
                        }
                        setDownload({
                          ...download,
                          originalDownload: e?.target?.checked,
                        });
                        handleSubmit({ originalDownload: e?.target?.checked });
                      }}
                    />
                  </div>
                  <p className="gray-text med-font font-15">
                    Switch on to allow Group Members to download photos in
                    original quality
                  </p>
                </div> */}
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-2 md-jcb">
                    <h3 className="mb-0 me-2 font-16">Bulk Downloads</h3>
                    <DefaultSwitch
                      isChecked={downloadSettings?.bulkDownload}
                      onChange={(e) => {
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: {
                            buttons: [ANALYTICS.DOWNLOAD_BULK_TOGGLE],
                          },
                        });

                        if (
                          currentUserId === createdBy &&
                          !hasAccessToFeature(PLAN_FEATURES.bulk_downloads)
                        ) {
                          return setSubscriptionModal(
                            MODAL_TYPES.BULK_DOWNLOAD
                          );
                        }
                        setDownload({
                          ...download,
                          bulkDownload: e?.target?.checked,
                        });
                        handleSubmit({ bulkDownload: e?.target?.checked });
                      }}
                    />
                  </div>
                </div>
                <p className="gray-text med-font font-15">
                  Switch on to allow Group Members to download all photos at
                  once
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-md-12">
          {downloadSettings?.link || downloadSettings?.pin ? (
            <div className="dropdown-divider"></div>
          ) : (
            <></>
          )}
        </div>

        <div className="col-md-6">
          <div className="mt-4 d-flex flex-column align-items-start">
            {downloadSettings?.pin && (
              <>
                {" "}
                <div className="d-flex align-items-center mb-2">
                  <h3 className="mb-0 font-17">Album Download</h3>
                </div>
                <p className="gray-text font-15 med-font">
                  Share link for full album download and enter the given pin for
                  access{" "}
                </p>
                <SecondarySmallBtn
                  onClick={copyURLLink}
                  title="Get Download Link"
                />
                <div className="mt-2" />
                <PinCard pin={downloadSettings?.pin || ""} />
              </>
            )}
          </div>
        </div>
      </div>

      <Spinner loading={saveLoader || settingLoader} />

      {subscriptionModal ? (
        <SubscriptionModal
          isOpen={true}
          onClose={() => setSubscriptionModal(MODAL_TYPES.NONE)}
          src={
            subscriptionModal == MODAL_TYPES.BULK_DOWNLOAD
              ? ""
              : PAID_FEATURES.switch_on_off_downloads
          }
          groupId={groupId}
        />
      ) : null}
    </div>
  );
};

export const PinCard = ({
  pin,
  showPinText = true,
  pinClassName = "",
  containerClassName = "",
}) => {
  return (
    <div className={`pinCard ${containerClassName}`}>
      <span className={`thick-font ${pinClassName}`}>
        {showPinText ? "Pin :" : null} {pin}
      </span>
      <div
        style={{ cursor: "pointer" }}
        className="copyBtn"
        onClick={() =>
          copy(pin).then(() => toast.success("Copied to clipboard"))
        }
      >
        <img src="../../.././assets/images/icons/copy.png" alt="copy" />
      </div>
    </div>
  );
};

export default DownloadSettings;
