import React from "react";
import { Container } from "react-bootstrap";
import ApplicationLink from "../landing/components/ApplicationLink";
import CardWrapper from "./components/cardWrapper";

const AboutUs = () => {
  return (
    <>
      <section id="memories">
        <div className="memories-img">
          <div className="description">
            <h2>We Deliver Memories</h2>
            <p>
              A photo sharing platform that allows you to seamlessly upload,
              share and store photos seamlessly
            </p>
          </div>
          <div className="card-with-icon mb-4">
            <div className="group">
              <CardWrapper
                img="/assets/images/icons/About Us/Group 751.png"
                header="Our Mission"
                description="To build an ecosystem that brings out the photographer and socialite inside every human"
              />
              <CardWrapper
                img="/assets/images/icons/About Us/Group 750.png"
                header="Our Vision"
                description="To globally revolutonize photo-sharing using AI"
              />
            </div>
            <div className="group">
              <CardWrapper
                img="/assets/images/icons/About Us/Group 748.png"
                header="Our Value"
                description="A community that values quality &amp; convenience"
              />
              <CardWrapper
                img="/assets/images/icons/About Us/Group 749.png"
                header="Our Culture"
                description="Innovative, Fun &amp; Progressive"
              />
            </div>
          </div>
          <div className="about-kwikpic">
            <div className="desktop-mobile-img"></div>
            <div className="team-description">
              <h1>About Kwikpic</h1>
              <img
                className="under-line"
                src="/assets/images/landing/yellow-divider.png"
                alt="Yellow-divider"
              />
              <p>
                Kwikpic is a community driven AI photo sharing platform. You can
                create a group on the Kwikpic App for every occasion. Just dump
                all the photos, and it will intelligently share pictures with
                friends and family in high quality without messing up your
                gallery.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="find-us">
        <div className="our-office">
          <h1>Where to find us?</h1>
          <span>Our Office</span>
          <h3>Kwikpic AI Solution</h3>
          <p className="address">
            1A-102, WeWork Chromium, <br /> Jogeshwari-Vikhroli Link Road,
            Andheri East,
            <br /> Mumbai, Maharashtra - 400076
          </p>
          <div className="d-flex gap-2 flex-wrap">
            <a
              href="tel:+919702767510"
              target="_blank"
              rel="noreferrer"
              className="d-flex align-items-center justify-content-center"
            >
              <img
                className="hw-18px"
                src="/assets/images/icons/business_phone.png"
                alt="icon"
              />
              <span className="font-15 font-base color-gray m-0">
                &nbsp;+91 9702767510
              </span>
            </a>
            <a
              href="mailto:sales@kwikpic.in"
              target="_blank"
              rel="noreferrer"
              className="d-flex align-items-center justify-content-center"
            >
              <img
                className="hw-18px"
                src="/assets/images/icons/business_email.png"
                alt="icon"
              />
              <span className="font-15 font-base color-gray m-0">
                &nbsp;sales@kwikpic.in
              </span>
            </a>
          </div>
        </div>
      </section>
      <section id="the-team">
        <Container>
          <div className="team mb-5">
            <div className="team-description">
              <div className="about-us-desktop-view">
                <h1>The Team</h1>
                <img
                  className="under-line"
                  src="/assets/images/landing/yellow-divider.png"
                  alt="Yellow-divider"
                />
              </div>
              <p>
                Kwikpic is founded by Harsh Khaitan, a NMIMS graduate. He is
                budding entrepreneur, hobby pilot and techie by passion.
                Together with his enthusiastic team and partners in crime, they
                are building Kwikpic to revolutionize the way photos are shared
                among the masses.
              </p>
            </div>
            <div className="team-members">
              <div className="member">
                <img
                  src="/assets/images/landing/DSC06486 1.jpg"
                  alt="DSC06486 1.jpg"
                />
                <h1 className="mt-2">Harsh Khaitan</h1>
                <p>CEO and Founder</p>
              </div>
              <div className="member">
                <img
                  src="/assets/images/landing/DSC06495 1.jpg"
                  alt="DSC06495 1.jpg"
                />
                <h1 className="mt-2">Ankush Rodewad</h1>
                <p>Backend Lead</p>
              </div>
            </div>
            <div className="about-us-mobile-view team-description">
              <h1>The Team</h1>
              <img
                className="under-line"
                src="/assets/images/landing/yellow-divider.png"
                alt="Yellow-divider"
              />
            </div>
          </div>
          <div className="brand-ambassador">
            <div className="about-us-mobile-view brand-ambassador-description">
              <h1>Our Brand Ambassador</h1>
              <img
                className="under-line"
                src="/assets/images/landing/yellow-divider.png"
                alt="Yellow-divider"
              />
            </div>
            <div className="member">
              <img src="/assets/images/landing/dabboo.jpg" alt="dabboo" />
              <h1 className="mt-2">Dabboo Ratnani</h1>
              <p className="bottom-margin-32">Advisory and Brand Ambassador</p>
            </div>
            <div className="brand-ambassador-description">
              <div className="about-us-desktop-view">
                <h1>Our Brand Ambassador</h1>
                <img
                  className="under-line"
                  src="/assets/images/landing/yellow-divider.png"
                  alt="Yellow-divider"
                />
              </div>
              <p>
                Dabboo Ratnani is a well known face in the photography industry.
                He is a celebrity photographer who has shot for almost all
                bollywood celebrities and for multiple magazine covers. As soon
                as he heard about Kwikpic and the idea, he knew that we were
                onto something great and wanted to be a part of the journey.
                Together with his guidance and our dedicated efforts, we aim to
                make Kwikpic a success story.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section id="application-link">
        <ApplicationLink />
      </section>
    </>
  );
};

export default AboutUs;
