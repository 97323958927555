import React from "react";
import css from "./Buttons.module.css";
import cx from "classnames";

/**
 * @typedef {object} OutlinedButtonProps
 * @property {string} title - button title
 * @property {React.ReactNode} children - button children
 * @property {function} onClick - button click handler
 * @property {string} [type] - button type
 * @property {string} [className] - button class name
 * @property {("xss"|"xs"|"sm"|"md")} [variant] - button variant
 * @property {boolean} [rounded] - button rounded
 * @property {boolean} [disabled] - button disabled
 * @param {OutlinedButtonProps}
 */
const OutlinedButton = ({
  title,
  children,
  onClick,
  type,
  className,
  variant = "md",
  rounded = false,
  disabled = false,
}) => {
  return (
    <button
      className={cx(
        css[variant],
        css["outlined-button"],
        { [css.rounded]: rounded },
        className
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {title || children}
    </button>
  );
};

export default OutlinedButton;
