import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import PrimaryButton from "../button/PrimaryButton";
import { DownloadButtons } from "./UserAgentModal";

const HighResWarn = ({ show, onHide }) => {
  return (
    <ConfirmationModal
      key={1}
      show={show}
      title="High Resolution Alert!"
      onCancel={onHide}
      confirmText=""
      cancelText=""
      footer={false}
      className="high-res-modal"
    >
      <>
        <p className="font-13 mb-3 text-center base-font">
          This group has High Resolution images which might not load properly on
          mobile browser.
          <br />
          <br />
          View on Kwikpic App for a seamless experience.
        </p>
        <img
          src="/assets/images/gallary/mobile.png"
          className="mx-auto text-center d-block mb-3"
          style={{
            height: "150px",
          }}
        />
        <div className="d-flex gap-2">
          <PrimaryButton
            title="Continue on browser"
            onClick={onHide}
            className="mb-2 font-13 font-bold w-100 px-2"
            isButton={true}
          />

          <DownloadButtons
            variant={0}
            deviceType={{
              os: "ios",
              type: "mobile",
            }}
            className="font-13"
          />
        </div>
      </>
    </ConfirmationModal>
  );
};

export default HighResWarn;
