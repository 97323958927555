import React, { Suspense, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PageHeading from "../components/pageHeading/PageHeading";
import GroupView from "./components/GroupView";
import { useDispatch } from "react-redux";
import {
  GET_MY_GROUPS,
  SEND_USER_CLICK_ANALYTICS,
} from "../../../redux-store/sagas/saga-actions";
import { useSelector } from "react-redux";
import Spinner from "../../components/loader/Spinner";
import useSubscription from "views/subscription/hooks/useSubscription";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import ANALYTICS from "constants/analyticsKeys";

const EmptyGroup = React.lazy(() => import("./components/EmptyGroup"));
const CreateGroupModal = React.lazy(() =>
  import("views/components/modals/CreateGroupModal")
);
const HomePageInfoModals = React.lazy(() =>
  import("./components/HomePageInfoModals")
);

const Home = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState({ page: 1, limit: 20 });
  const [search, setSearch] = useState("");

  const [createGroup, setCreateGroup] = useState(false);
  const { groups, count, initialLoader } = useSelector((state) => state.groups);
  const { getMySubscriptionPlanAPI } = useSubscription();

  const handleShow = () => {
    setCreateGroup(true);
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.CREATE_GROUP] },
    });
  };
  const handleClose = () => setCreateGroup(false);

  const handleSearch = (val) => {
    setSearch(val);
  };

  useEffect(() => {
    dispatch({
      type: GET_MY_GROUPS,
      payload: currentPage,
    });

    getMySubscriptionPlanAPI();
  }, []);

  const hasNoGroups = groups?.length === 0 && !initialLoader;

  return (
    <Container>
      <Spinner loading={initialLoader} />
      <div className="homePage">
        <PageHeading
          title="Groups"
          link="/join"
          handleShow={handleShow}
          rightBar={true}
          search={handleSearch}
        />
        {hasNoGroups ? (
          <Suspense fallback={<SuspenseLoader />}>
            <EmptyGroup />
          </Suspense>
        ) : (
          <GroupView
            setCurrentPage={setCurrentPage}
            groups={groups}
            count={count}
            currentPage={currentPage}
            search={search}
            thumb="/assets/images/groups/default-group-logo.png"
          />
        )}
        <Suspense fallback={<SuspenseLoader />}>
          {createGroup && (
            <CreateGroupModal show={createGroup} handleClose={handleClose} />
          )}

          <HomePageInfoModals />
        </Suspense>
      </div>
    </Container>
  );
};

export default Home;
