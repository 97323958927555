import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import css from "./AdsLanding.module.css";
import GradButton from "views/components/button/GradButton";
import cx from "classnames";
import GetInTouch from "views/country-landing/components/GetInTouch";
import { testimonies, features } from "./constants";
import CountryLandingHeader from "views/country-landing/components/Navbar";
import Pricing from "views/country-landing/components/Pricing";
import Testimonies from "views/country-landing/components/Testimonies";
import Footer from "views/country-landing/components/Footer";
import PlatformFeatures from "views/country-landing/components/PlatformFeatures";
import Hero from "views/country-landing/components/Hero";
import { useLocation } from "react-router-dom";

const AdsLanding = () => {
  const location = useLocation();

  React.useEffect(() => {
    let timeout;
    if (location.hash) {
      timeout = setTimeout(() => {
        const element = document.getElementById(location.hash.slice(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [location.hash]);

  return (
    <div className="no-overflow-x">
      <CountryLandingHeader
        country="IN"
        home="#"
        flag="/assets/images/country/in.png"
        aboutUs={() => window.open("/landing/aboutus")}
      />
      <div className={css["ads-page"]}>
        <Hero
          country="IN"
          videoUrl="https://tube.rvere.com/embed?v=jYM1mfNKEXs"
          title={{
            start: "AI Photo Sharing - ",
            end: "Smart, Easy & Kwik!",
          }}
        />
        <Testimonies testimonies={testimonies} />
        <Container>
          <Row className="mt-5">
            <Col>
              <h2 className="font-24 font-bold text-center mb-4">Features</h2>
              <img
                src="/assets/images/landing/yellow-divider.png"
                alt="yellow line"
                className="text-center d-block mx-auto"
              />
              {features.map((feature, index) => (
                <Feature
                  key={index}
                  {...feature}
                  reverse={index % 2 === 0 ? false : true}
                >
                  {feature.description}
                </Feature>
              ))}
            </Col>
          </Row>
        </Container>
        <Pricing country="in" />
        <GetInTouch
          slackChannel="#web-contact-form"
          countryCode="+91"
          sendTrackingEvent={() => {
            window.gtag("event", "conversion", {
              send_to: "AW-413510263/voH1CPqiyaQZEPfUlsUB",
            });
          }}
        />
        <PlatformFeatures />
        <Footer aboutUs={() => window.open("/landing/aboutus")} />
      </div>
    </div>
  );
};

export const Testimony = ({ image, name, company, testimony }) => {
  return (
    <div className={css.testimony}>
      <img src={image} />
      <div>
        <p className="font-16 mb-1">{testimony}</p>
        <p className="font-bold font-bold m-0 color-off">{name}</p>
        <p className="font-14 base-font color-off m-0">{company}</p>
      </div>
    </div>
  );
};

export const Feature = ({
  image,
  title,
  children,
  reverse,
  buttons = [],
  className,
}) => {
  return (
    <div className={cx(className, css.feature, reverse ? css.reverse : "")}>
      <div>
        <h2 className="font-24 font-bold">{title}</h2>
        <p className="font-16 base-font color-off">{children}</p>

        <div className="d-flex gap-3">
          {buttons.map((button, index) => (
            <GradButton
              key={index}
              className="w-fit px-3 lh-12"
              onClick={() => {
                button.link.startsWith("#")
                  ? document
                      .querySelector(button.link)
                      ?.scrollIntoView({ behavior: "smooth" })
                  : window.open(button.link);
              }}
              title={button.title}
              variant="sm"
            />
          ))}
        </div>
      </div>
      <img src={image} alt={title} />
    </div>
  );
};

export default AdsLanding;
