import React from "react";
import css from "./FolderImages.module.css";
import cx from "classnames";

const ImageSelector = ({ selectMode, isSelected, toggleSelect }) => {
  return (
    <>
      <input
        id="image-selector"
        className={css.checkBox}
        type="checkbox"
        checked={isSelected}
        onChange={(e) => e.stopPropagation()}
      />
      <label
        onClick={toggleSelect}
        htmlFor="image-selector"
        className={cx(css.imageSelector, { "opacity-100": selectMode })}
      />
    </>
  );
};

export default ImageSelector;
