import React from "react";
import cx from "classnames";
import css from "./Spinner.module.css";

const SuspenseLoader = ({ screenCenter }) => {
  return (
    <span
      className={cx({
        [css["suspense-loader"]]: true,
        [css["suspense-loader__screen-center"]]: screenCenter,
      })}
    ></span>
  );
};

export default SuspenseLoader;
