/**
 * This module is used to debounce the history API calls to prevent multiple
 * history changes in a short period of time.
 */
let nextPush = null;
let nextReplace = null;
let lastUpdate = 0;
let isScheduled = false;
let debounceTime = 300;

const setDebounceTime = (time) => {
  debounceTime = time;
};

const performChange = () => {
  isScheduled = false;
  lastUpdate = Date.now();

  if (nextPush) {
    window.history.pushState(...nextPush);
    nextPush = null;

    if (nextReplace) scheduleURLChange();
  } else {
    window.history.replaceState(...nextReplace);
    nextReplace = null;
  }
};

const scheduleURLChange = () => {
  if (isScheduled) return;

  const elapsed = Date.now() - lastUpdate;
  if (elapsed >= debounceTime) {
    performChange();
  } else {
    isScheduled = true;
    setTimeout(performChange, debounceTime - elapsed);
  }
};

const pushState = (state, title, url) => {
  nextPush = [state, title, url];
  nextReplace = null;
  scheduleURLChange();
};

const replaceState = (state, title, url) => {
  nextReplace = [state, title, url];
  scheduleURLChange();
};

export { setDebounceTime, replaceState, pushState };
