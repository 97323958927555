import React from "react";

const ModalFullWidtButton = ({ title, onClick }) => {
  return (
    <button
      style={{ border: "none" }}
      className="modalFullWidtButton"
      onClick={onClick}
    >
      {title}
    </button>
  );
};
export default ModalFullWidtButton;
