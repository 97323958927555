import { createSlice } from "@reduxjs/toolkit";
import { STATIC_FOLDERS } from "views/components/modals/UploadFromComputer";

const initialState = {
  folders: [],
  groupID: "",
  initialLoader: false,
  loader: false,
  verifyDownloadPinResponse: {},
  renameFolderResponse: {},
  editOrder: false,
};

const slice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    setFolders: (state, action) => {
      const { groupID, data } = action?.payload || {};
      const { status, folders = [] } = data || {};
      let _folders = [...folders];

      let highlightFolder;
      let allPhotosFolder;

      _folders = _folders.filter((folder) => {
        if (folder.folderPrefix === STATIC_FOLDERS.HIGHLIGHTS) {
          highlightFolder = folder;
          return false;
        }
        if (!folder.folderPrefix) {
          allPhotosFolder = folder;
          allPhotosFolder.folderPrefix = STATIC_FOLDERS.ALL_PHOTOS;
          return false;
        }
        return true;
      });

      if (highlightFolder) {
        _folders.unshift(highlightFolder);
      }

      if (allPhotosFolder) {
        _folders.unshift(allPhotosFolder);
      }

      if (status === 200) {
        return {
          ...state,
          folders: _folders,
          groupID,
          initialLoader: false,
        };
      }
    },
    setFolderLoader: (state, action) => {
      return {
        ...state,
        loader: action?.payload,
      };
    },
    setInitialFolderLoader: (state, action) => {
      return {
        ...state,
        initialLoader: action?.payload,
      };
    },
    handleDeleteFolder: (state, action) => {
      const { folders } = state;
      const newFolders = folders.filter(
        (folder) => folder.groupFolder !== action?.payload
      );

      return {
        ...state,
        folders: newFolders,
        loader: false,
      };
    },
    setRenameFolder(state, action) {
      const _folders = state.folders.map((folder) => {
        if (folder.groupFolder === action?.payload?.action?.payload?.folderId) {
          return {
            ...folder,
            folderPrefix: action?.payload?.action?.payload?.newPrefix,
          };
        }
        return folder;
      });
      return {
        ...state,
        folders: _folders,
        renameFolderResponse: action?.payload?.response || {},
        loader: false,
      };

      // need to work on it as API is not working
    },
    setVerifyDownloadPinResponse(state, action) {
      const { data: { match } = {}, status } = action?.payload || {};

      if (status === 200) {
        return {
          ...state,
          verifyDownloadPinResponse: {
            match,
            status,
          },
          loader: false,
        };
      }

      return {
        ...state,
        verifyDownloadPinResponse: {},
        loader: false,
      };
    },
    setFolderOrderEditor(state, action) {
      return {
        ...state,
        editOrder: action?.payload,
      };
    },
    /**
     * this reducer is just for triggering side effect in folerImages.js
     */
    setFolderTransferSuccess(state) {
      return state;
    },
  },
});

export const {
  setFolders,
  setFolderLoader,
  handleDeleteFolder,
  setInitialFolderLoader,
  setRenameFolder,
  setVerifyDownloadPinResponse,
  setFolderOrderEditor,
  setFolderTransferSuccess,
} = slice.actions;

export default slice.reducer;
