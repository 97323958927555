import React from "react";
import VerifyDownloadPin from "./components/VerifyDownloadPin";
import FolderDownloads from "./components/FolderDownloads";
import DownloadTypeSelection from "./components/DownloadTypeSelection";
// import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "views/components/loader/Spinner";
// import { GET_GROUP_DETAILS_AND_OTHER_SETTINGS } from "redux-store/sagas/saga-actions";
import { getLocalStorageUser } from "utils/helpers/localstorage";

const AlbumDownload = ({ isAnonymous }) => {
  const [selectionType, setSelectionType] = React.useState(SELECTION_TYPE.NONE);
  const [isPinVerified, setIsPinVerified] = React.useState(false);
  // const [params] = useSearchParams();
  // const dispatch = useDispatch();

  const {
    leaveParticipantSettings: { isAdmin } = {},
    settingLoader,
    generalSettings,
    groupDetails: { isForProductSale },
  } = useSelector((state) => state.settings);

  // DownloadRoutes is already dispatching this action, so we don't need to do it here.
  // useEffect(() => {
  //   dispatch({
  //     type: GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  //     groupID: params.get("groupId"),
  //   });
  // }, []);

  const {
    anonymousUser: { group },
  } = useSelector((state) => state.anonymous);

  const groupName = isAnonymous ? group?.name : generalSettings?.groupName;

  const renderContent = () => {
    const { userType } = getLocalStorageUser();

    const isUser = userType === "USER" || isAnonymous;

    if (selectionType === SELECTION_TYPE.NONE) {
      return (
        <DownloadTypeSelection
          isAdmin={isAdmin}
          setSelectionType={setSelectionType}
          groupName={groupName}
          isAnonymous={isAnonymous}
          isPhotographer={!isUser}
          isForProductSale={isForProductSale}
        />
      );
    }

    if (selectionType === SELECTION_TYPE.ALL_FOLDERS) {
      if (isPinVerified) {
        return (
          <FolderDownloads
            headerText={`Download Photos from “${groupName}”`}
            type={SELECTION_TYPE.ALL_FOLDERS}
          />
        );
      } else {
        return <VerifyDownloadPin setIsPinVerified={setIsPinVerified} />;
      }
    }
  };

  return (
    <div>
      <Spinner loading={settingLoader} />
      {renderContent()}
    </div>
  );
};

export const SELECTION_TYPE = {
  NONE: "NONE",
  ALL_FOLDERS: "ALL_FOLDERS",
  MY_FOLDER: "MY_FOLDER",
  MY_FAVORITES: "MY_FAVORITES",
  PURCHASED: "PURCHASED",
  SELECTED_IMAGES: "SELECTED_IMAGES",
};

export default AlbumDownload;
