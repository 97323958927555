import axios from "axios";
import { getDeviceType } from "hooks/useDetectUserAgent";
import { SHARE_STRING } from "views/components/modals/LinkShareModal";
import { createFilename } from "views/download/helpers";

/**
 * @param {string} url
 * @param {string} [title]
 * @param {string} fileUrl
 * @param {string} [hashtag]
 * @param {string} [suggest]
 * @param {(url: string, title: string, hashtag: string, suggest: string) => void} fallback
 * @param {()=>{}} [progressCallback]
 */
const share = async (
  url,
  fileUrl,
  fallback,
  progressCallback,
  title = SHARE_STRING,
  hashtag = ["kwikpic"],
  suggest = "@kwikpic_in"
) => {
  try {
    const { type } = getDeviceType();

    if (type !== "mobile") throw new Error("Use fallback for desktop devices");

    if (!navigator.canShare || !navigator.share) {
      throw new Error("Web Share API not supported");
    }

    const { fileName } = createFilename(fileUrl);
    const file = await getFileFromUrl(fileUrl, fileName, progressCallback);

    await navigator.share({
      files: [file],
    });
  } catch (e) {
    // check if user cancelled the share

    if (e.name === "AbortError") return;

    // original handler will be called if above fails

    const _url = encodeURIComponent(url);
    const _title = encodeURIComponent(title);
    const _hashtag = encodeURIComponent(hashtag);
    const _suggest = encodeURIComponent(suggest);

    fallback && fallback(_url, _title, _hashtag, _suggest);
  }
};

const getFileFromUrl = async (url, fileName, progressCallback) => {
  const response = await axios.get(url, {
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      if (progressCallback) {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        progressCallback(percent);
      }
    },
  });

  return new File([response.data], fileName, { type: "image/jpeg" });
};

export default share;
