import React from "react";
import css from "./Notifications.module.css";
import cx from "classnames";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_GROUP,
  GET_GROUPWISE_NOTIFICATIONS,
} from "redux-store/sagas/saga-actions";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Backdrop from "../modals/components/Backdrop";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import SuspenseLoader from "../loader/SuspenseLoader";
import { NOTIFICATIONS_TYPES } from "views/main/notifications/constants";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import ThreeDotAnimation from "../loader/ThreeDotAnimation";
import { toast } from "react-toastify";

const UserAgentErrorModal = React.lazy(() =>
  import("../modals/UserAgentModal")
);
const ConfirmationModal = React.lazy(() =>
  import("../modals/ConfirmationModal")
);
dayjs.extend(relativeTime);

const BTN_TITLES = {
  ALL: "See all notifications",
  GROUPS: "Show more",
  NONE: "",
};

const TABS = {
  ALL: "all",
  GROUPS: "groups",
};

const ERROR_MODAL = {
  GROUP_DELETED: "groupDeleted",
  USER_NOT_FOUND: "userNotFound",
  USER_AGENT: "userAgent",
  NONE: "",
};

const Notifications = ({ className }) => {
  const ref = React.useRef();
  const [showTray, setShowTray] = React.useState(false);
  const [rect, setRect] = React.useState({
    right: 0,
    top: 0,
  });

  const location = useLocation();

  React.useLayoutEffect(() => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();

    setRect({
      right: window.innerWidth - rect.right - 20,
      top: rect.bottom + 10,
    });
  }, []);

  return (
    <>
      <img
        ref={ref}
        role="button"
        src="/assets/icons/notifications.png"
        alt="notifications"
        title="Expand notifications"
        className={cx(css.icon, className)}
        onClick={() => {
          if (location.pathname !== "/notifications") setShowTray(!showTray);
        }}
      />

      {showTray && (
        <NotificationTray
          right={rect.right}
          top={rect.top}
          close={() => setShowTray(false)}
        />
      )}
    </>
  );
};

export default Notifications;

export const NotificationTray = ({
  right = 0,
  top = 0,
  close = () => {},
  className = "",
  fromPage = false,
}) => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const [activeTab, setActiveTab] = React.useState(
    params.get("tab") === "groups" ? TABS.GROUPS : TABS.ALL
  );
  const [groupIdx, setGroupIdx] = React.useState(-1);

  const {
    initialLoading,
    notificationsLoading,
    allNotificationsCount,
    allNotifications = [],
    allNotificationsGroup = [],
    groupWiseNotifications = [],
  } = useSelector((state) => state.notifications);

  const scrollContainer = useInfiniteScroll({
    loading: notificationsLoading,
    hasNextPage:
      fromPage &&
      (activeTab === TABS.ALL
        ? allNotifications.length < allNotificationsCount
        : allNotificationsGroup.length <
          groupWiseNotifications[groupIdx]?.count),
    onLoadMore: () => {
      if (activeTab === TABS.ALL)
        dispatch({
          type: GET_ALL_NOTIFICATIONS,
          payload: {
            page: Math.floor(allNotifications.length / 20) + 1,
          },
        });
      else
        dispatch({
          type: GET_ALL_NOTIFICATIONS_GROUP,
          payload: {
            page: Math.floor(allNotificationsGroup.length / 20) + 1,
            groupId: groupWiseNotifications[groupIdx]?._id,
          },
        });
    },
  });

  React.useEffect(() => {
    if (activeTab === TABS.ALL)
      dispatch({
        type: GET_ALL_NOTIFICATIONS,
        payload: {
          page: 1,
        },
      });
    else
      dispatch({
        type: GET_GROUPWISE_NOTIFICATIONS,
      });

    window.scrollTo(0, 0);

    if (fromPage) setParams({ tab: activeTab });
  }, [dispatch, activeTab, fromPage, setParams]);

  return (
    <div
      className={cx(css["tray-container"], css["fadeIn"], className)}
      style={{
        right,
        top,
      }}
    >
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => {
          setActiveTab(k);
          setGroupIdx(-1);
        }}
        className={css["tabs"]}
      >
        <Tab eventKey={TABS.ALL} title="All" className="h-100">
          <NotificationContent
            title={TABS.ALL}
            list={allNotifications}
            fromPage={fromPage}
            reff={scrollContainer}
            close={close}
            showMore={!fromPage}
            loading={initialLoading}
          />
        </Tab>

        <Tab eventKey={TABS.GROUPS} title="Groups" className="h-100">
          {groupIdx === -1 &&
          !initialLoading &&
          groupWiseNotifications.length ? (
            groupWiseNotifications.map((group, i) => (
              <NotificationContent
                index={i}
                key={group._id}
                title={group.name}
                list={group.notifications}
                setGroupIdx={() => {
                  setGroupIdx(i);
                  dispatch({
                    type: GET_ALL_NOTIFICATIONS_GROUP,
                    payload: { page: 1, groupId: group._id },
                  });
                }}
                fromPage={fromPage}
                close={close}
                showMore={group.count > 2}
                loading={initialLoading}
              />
            ))
          ) : (
            <NotificationContent
              reff={scrollContainer}
              title={groupWiseNotifications[groupIdx]?.name}
              list={allNotificationsGroup}
              reverse
              setGroupIdx={() => setGroupIdx(-1)}
              fromPage={fromPage}
              close={close}
              showMore
              loading={initialLoading}
            />
          )}
        </Tab>
      </Tabs>

      {!fromPage && (
        <Backdrop transparent zIndex={5} onClick={close} scroll={false} />
      )}
    </div>
  );
};

const NotificationContent = ({
  title,
  list = [],
  setGroupIdx,
  index = 0, // index is for showing loader only one time in case of groups tab
  reverse = false,
  fromPage = false,
  reff = null,
  showMore = false,
  close = () => {},
  loading = false,
}) => {
  const navigate = useNavigate();
  const [errorModal, setErrorModal] = React.useState(NOTIFICATIONS_TYPES.NONE);
  const [deviceType, setDeviceType] = React.useState({ type: "desktop" });
  useDetectUserAgent(setDeviceType);

  // hide button if component is rendered from 'all' tabs notifications page
  // or when a groups notification is expanded on page (reverse = true)
  const btnTitle =
    title === TABS.ALL || reverse
      ? fromPage
        ? BTN_TITLES.NONE
        : BTN_TITLES.ALL
      : BTN_TITLES.GROUPS;

  const notificationAction = (notification) => {
    switch (notification.type) {
      case NOTIFICATIONS_TYPES.CLOSE_FRIEND_REQUEST_RECEIVED:
      case NOTIFICATIONS_TYPES.CLOSE_FRIEND_REQUEST_ACCEPTED:
      case NOTIFICATIONS_TYPES.DELETE_REQUEST:
        return setErrorModal(ERROR_MODAL.USER_AGENT);

      case NOTIFICATIONS_TYPES.PHOTOS_DELETED:
      case NOTIFICATIONS_TYPES.NEW_PHOTO_UPLOAD:
      case NOTIFICATIONS_TYPES.NEW_VIDEO_UPLOAD:
      case NOTIFICATIONS_TYPES.NEW_MATCHING_PHOTOS:
      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_COMPLETED_SENDER:
      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_COMPLETED_RECEIVER:
      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_ACCEPTED_SENDER:
      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_ACCEPTED_RECEIVER:
      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_AUTO_ACCEPTED_SENDER:
      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_AUTO_ACCEPTED_RECEIVER:
      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_REJECTED_SENDER:
      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_REJECTED_RECEIVER:
      case NOTIFICATIONS_TYPES.DOWNLOAD_IS_AVAILABLE:
      case NOTIFICATIONS_TYPES.GROUP_NAME_CHANGED:
      case NOTIFICATIONS_TYPES.YOU_ARE_NOW_ADMIN:
      case NOTIFICATIONS_TYPES.PHOTOS_ACCESS_CHANGED:
      case NOTIFICATIONS_TYPES.UPLOAD_PHOTO_ACCESS_GRANTED:
        if (notification.group)
          return navigate(`/gallery?groupId=${notification.group._id}`);
        else return setErrorModal(ERROR_MODAL.GROUP_DELETED);

      case NOTIFICATIONS_TYPES.JOIN_GROUP:
      case NOTIFICATIONS_TYPES.LEAVE_GROUP:
        if (!notification.user)
          return setErrorModal(ERROR_MODAL.USER_NOT_FOUND);

        if (!notification.group)
          return setErrorModal(ERROR_MODAL.GROUP_DELETED);

        return navigate(
          `/settings/participants?groupId=${notification.group._id}`
        );

      case NOTIFICATIONS_TYPES.UPLOAD_LIMIT_EXCEEDED:
        return navigate("/profile-settings/profile");

      case NOTIFICATIONS_TYPES.TRANSFER_REQUEST_CREATED:
        if (notification.link && notification.group)
          return window.open(notification.link);
        else
          return toast.error(
            "Invalid transfer link, please ask admin to resend."
          );
    }
  };

  if (loading && index == 0)
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <ThreeDotAnimation />
      </div>
    );

  if (!list.length)
    return (
      <p className="font-15 font-base color-h text-center h-100 d-flex align-items-center justify-content-center">
        No notifications yet
      </p>
    );

  return (
    <>
      <div
        className={cx({
          [css.group]: title !== TABS.ALL,
        })}
      >
        {title === TABS.ALL ? null : (
          <p
            role="button"
            className={cx(
              "d-flex align-items-center m-0",
              reverse
                ? "flex-row-reverse gap-2 justify-content-end"
                : "justify-content-between"
            )}
            onClick={setGroupIdx}
          >
            <span className="font-16 color-secondary font-bold me-1">
              {title || "Deleted Group"}
            </span>

            <img
              className={cx({
                [css["arrow-reverse"]]: reverse,
                "d-none": !reverse,
              })}
              src="/assets/images/icons/arrow-down.png"
              alt="see more"
              title="Click to see all"
            />
          </p>
        )}

        <ul className="list-unstyled m-0" ref={reff}>
          {list.map((l) => (
            <NotificationItem
              key={l._id}
              item={l}
              notificationAction={() => notificationAction(l)}
            />
          ))}
        </ul>

        {showMore && btnTitle !== BTN_TITLES.NONE && (
          <button
            className={cx(
              "d-block mx-auto bg-transparent font-14 color-secondary font-bold",
              css["show-more"]
            )}
            onClick={
              btnTitle === BTN_TITLES.ALL
                ? () => {
                    close();
                    navigate(`/notifications`);
                  }
                : setGroupIdx
            }
          >
            {btnTitle}
          </button>
        )}
      </div>

      <React.Suspense fallback={<SuspenseLoader />}>
        {errorModal === ERROR_MODAL.USER_AGENT && (
          <UserAgentErrorModal
            show
            variant={2}
            onHide={() => setErrorModal(NOTIFICATIONS_TYPES.NONE)}
            deviceType={deviceType}
            textOverride="Download the Kwikpic App to view this notification."
          />
        )}

        {errorModal === ERROR_MODAL.GROUP_DELETED && (
          <ConfirmationModal
            show
            confirmText=""
            cancelText="Close"
            onCancel={() => setErrorModal(NOTIFICATIONS_TYPES.NONE)}
            title="Group Deleted"
            message="This group has been deleted by the admin."
            animation
          />
        )}

        {errorModal === ERROR_MODAL.USER_NOT_FOUND && (
          <ConfirmationModal
            show
            confirmText=""
            cancelText="Close"
            onCancel={() => setErrorModal(NOTIFICATIONS_TYPES.NONE)}
            title="User Not Found"
            message="This user has deleted their account or has been removed from the group."
            animation
          />
        )}
      </React.Suspense>
    </>
  );
};

const NotificationItem = ({ item: l, notificationAction }) => {
  const prefix =
    [NOTIFICATIONS_TYPES.LEAVE_GROUP, NOTIFICATIONS_TYPES.JOIN_GROUP].includes(
      l?.type
    ) && !l?.user
      ? "A user"
      : "";

  return (
    <li
      role="button"
      key={l._id}
      className={cx(
        "d-flex align-items-center justify-content-between",
        css["notification-item"]
      )}
      onClick={notificationAction}
    >
      <img
        src={l?.user?.avatar || l?.group?.icon || ""}
        alt="user/group"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/assets/images/groups/default-group-logo.png";
        }}
      />
      <p
        className="m-0 text-clip-line-2 w-100 font-14 font-base"
        title={prefix + l?.message}
      >
        {prefix + l?.message}
      </p>
      <span className="font-12 font-base text-nowrap">
        {dayjs(l?.createdAt).fromNow(true)}
      </span>
    </li>
  );
};
