import React from "react";
import { SidebarCol } from "views/components/sidebar/Sidebar";

const PrivacySecuritySidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebarMenu">
        <ul>
          <li className="sidebarHeading">
            <h6>Privacy Settings</h6>
          </li>
          <SidebarCol link="/privacy-security" name="Privacy and Security" />
        </ul>
      </div>
    </div>
  );
};

export default PrivacySecuritySidebar;
