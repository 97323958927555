import { takeLatest, put, call } from "redux-saga/effects";
import { AXIOS, AXIOS_ANALYTICS } from "../../utils/setup/axios";
import {
  setAllImages,
  updateImgFavStatus,
  deleteImg,
  photographerPortfolio,
  setImagesLoading,
  getAllVideos,
  patchVideoOfGroup,
} from "../slices/folderImages";

import {
  patchImageOfGroup,
  setCommentData,
  setCommentLoader,
} from "../slices/images";

import {
  GET_ALL_IMAGES_OF_GROUP,
  DELETE_IMAGE_OF_FOLDER,
  IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
  CHANGE_FAVORITE_STATUS,
  GET_PHOTOGRAPHER_PORTFOLIO,
  GET_ALL_VIDEOS,
  ADD_IMAGE_COMMENT,
  DELETE_VIDEO_OF_FOLDER,
  UPLOAD_YOUTUBE_VIDEO,
} from "./saga-actions";
import { toast } from "react-toastify";
import { setAnonymousUserData } from "redux-store/slices/anonymous";
import { setServices } from "redux-store/slices/portfolio";
import { ERROR_TYPES_404 } from "views/notFound/NotFound";
import generateCipher from "utils/helpers/generateCipher";

let imgUrl, vidUrl, vidId;

async function getAllImagesOfGroup({
  groupID,
  imageType,
  page,
  limit,
  anonymoususerid,
}) {
  const options = {
    params: {
      type: imageType,
      limit,
      page,
      website: true,
    },
    headers: anonymoususerid && { anonymoususerid },
  };

  const url = `/api/app/pic/list-all/${groupID}`;

  return AXIOS.get(url, options);
}

async function patchImageOfFolder(data) {
  const { _id, url } = data;
  imgUrl = url;
  return AXIOS.patch(`/api/app/pic/delete-pic/${_id}`, {
    url,
  });
}

async function getPhotographerPortfolio(data) {
  const cipher = generateCipher();
  const { id, editMode } = data?.payload || {};

  return AXIOS.get(`/api/app/user/photographer-portfolio/${id}`, {
    params: { settingsView: editMode },
    headers: { cipher },
  });
}

async function getFolderVideos(data) {
  const { groupID, page, limit, anonymoususerid } = data;

  const options = {
    params: {
      limit,
      page,
    },
  };

  if (anonymoususerid)
    options.headers = {
      anonymoususerid,
    };

  return AXIOS.get(`/api/app/video/get-group-videos/${groupID}`, options);
}
async function patchVideoOfFolder(data) {
  const { _id, url, video_id } = data;
  vidUrl = url;
  vidId = video_id;
  return AXIOS.patch(`/api/app/video/delete-video/${_id}`, {
    url,
  });
}
async function uploadYoutubeVideo(data) {
  const { groupId, url, isVideoPrivate } = data;
  return AXIOS.post("/api/app/user/upload-external-video", {
    groupId,
    url,
    isVideoPrivate,
  });
}

function* getAllImagesOfGroupGenerator(action) {
  try {
    yield put(setImagesLoading(true));
    const response = yield call(getAllImagesOfGroup, action);

    yield put(
      setAllImages({
        data: response.data,
        page: action?.page,
        groupID: action?.groupID,
      })
    );
  } catch (e) {
    if (
      e.response.data?.message?.includes("anonymousAccess enabled") &&
      action?.anonymoususerid
    ) {
      yield put(
        setAnonymousUserData({
          hasError: true,
          anonymousDisabled: true,
        })
      );
    }
  } finally {
    yield put(setImagesLoading(false));
  }
}

function* patchImageOfFolderGenerator(action) {
  try {
    const response = yield call(patchImageOfFolder, action.payload);
    yield put(patchImageOfGroup({ response, imgUrl }));
    yield put(deleteImg(action.payload));
  } catch (e) {
    toast.error(e.response.data?.message);
  }
}

async function postImageImpressionDownloadAnalytics(action) {
  const { groupId, imageIds, logType } = action?.payload || {};

  return AXIOS_ANALYTICS.post("/api/app/analytics/register", {
    groupId,
    imageIds,
    logType,
  });
}

function* postImageImpressionDownloadAnalyticsGenerator(action) {
  try {
    yield call(postImageImpressionDownloadAnalytics, action);
  } catch (e) {
    //
  }
}

async function changeFavoriteStatus(data) {
  // _id is groupId, url is image's url
  const { _id, url } = data;
  return AXIOS.patch(`/api/app/pic/favorite-v2/${_id}`, {
    _id,
    url,
  });
}
async function addImageComment(action) {
  const { imageId, groupId, comment } = action?.payload || {};

  return AXIOS.post("/api/app/group/comment-create", {
    imageId,
    groupId,
    comment,
  });
}
function* changeFavoriteStatusGenerator(action) {
  try {
    yield put(updateImgFavStatus(action?.payload));
    yield call(changeFavoriteStatus, action?.payload);
  } catch (e) {
    //
  }
}
function* getPhotographerPortfolioGenerator(action) {
  const { redirect } = action?.payload || {};
  try {
    yield put(setImagesLoading(true));
    const response = yield call(getPhotographerPortfolio, action);

    if (response?.status === 200) {
      yield put(photographerPortfolio(response?.data?.data));
      yield put(setServices(response.data?.data?.photographer?.services || []));
    }
  } catch (e) {
    if (redirect) {
      const message = e.response.data?.message;

      if (message?.includes("Invalid id")) {
        window.history.replaceState(
          { type: ERROR_TYPES_404.INVALID_PORTFOLIO_ROUTE },
          null,
          "/not-found"
        );
        window.location.reload();
      } else if (message?.includes("not subscribed")) {
        window.history.replaceState(
          { type: ERROR_TYPES_404.PORTFOLIO_NOT_SUBSCRIBED },
          null,
          "/not-found"
        );
        window.location.reload();
      } else if (message?.includes("This request has expired")) {
        window.location.reload();
      } else if (message?.includes("Unauthorized request")) {
        toast.error(
          "Please use the latest version of Kwikpic App or Website and try again."
        );
      } else if (message?.includes("Please sync device time and try again")) {
        toast.error("Please sync device time and try again.");
      }
    }
  } finally {
    yield put(setImagesLoading(false));
  }
}

function* addImageCommentGenerator(action) {
  try {
    yield put(setCommentLoader(true));
    const response = yield call(addImageComment, action);

    if (response?.status === 200) {
      yield put(setCommentData(response?.data?.data));
      toast.success(response?.data?.message);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message);
  } finally {
    yield put(setCommentLoader(false));
  }
}

function* getAllVideosGenerator(action) {
  try {
    yield put(setImagesLoading(true));
    const response = yield call(getFolderVideos, action.payload);
    // eslint-disable-next-line no-console
    if (response.status === 200) {
      yield put(getAllVideos(response?.data?.data));
    }
  } catch (e) {
    if (
      e.response.data?.message?.includes("anonymousAccess enabled") &&
      action?.anonymoususerid
    ) {
      yield put(
        setAnonymousUserData({
          hasError: true,
          anonymousDisabled: true,
        })
      );
    }
  } finally {
    yield put(setImagesLoading(false));
  }
}

function* patchVideoOfFolderGenerator(action) {
  try {
    const response = yield call(patchVideoOfFolder, action);
    yield put(patchVideoOfGroup({ response, vidUrl, vidId }));
  } catch (e) {
    toast.error(e.response.data?.message);
  }
}
function* uploadYoutubeVideoGenerator(action) {
  try {
    const response = yield call(uploadYoutubeVideo, action);

    if (response.status == 201) {
      toast.success(response?.data?.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export function* imagesSaga() {
  yield takeLatest(GET_ALL_IMAGES_OF_GROUP, getAllImagesOfGroupGenerator);
  yield takeLatest(DELETE_IMAGE_OF_FOLDER, patchImageOfFolderGenerator);
  yield takeLatest(
    IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
    postImageImpressionDownloadAnalyticsGenerator
  );
  yield takeLatest(CHANGE_FAVORITE_STATUS, changeFavoriteStatusGenerator);
  yield takeLatest(
    GET_PHOTOGRAPHER_PORTFOLIO,
    getPhotographerPortfolioGenerator
  );
  yield takeLatest(GET_ALL_VIDEOS, getAllVideosGenerator);
  yield takeLatest(ADD_IMAGE_COMMENT, addImageCommentGenerator);
  yield takeLatest(DELETE_VIDEO_OF_FOLDER, patchVideoOfFolderGenerator);
  yield takeLatest(UPLOAD_YOUTUBE_VIDEO, uploadYoutubeVideoGenerator);
}

export const LOG_TYPE = {
  IMPRESSION: "IMPRESSION",
  DOWNLOAD: "DOWNLOAD",
};
