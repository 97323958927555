import React from "react";
import { Dropdown } from "react-bootstrap";
import css from "./Sort.module.css";

const SortDropdown = ({ handleSort, label = "-- Select --" }) => {
  return (
    <Dropdown onSelect={handleSort} className="w-fit">
      <Dropdown.Toggle className={css.dropdown}>
        {label}
        <img
          src="/assets/images/icons/arrow-down.png"
          alt="select sort option"
          className={css.dropicon}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 w-100">
        {SORT_OPTIONS.map((opt, i) => (
          <Dropdown.Item
            key={i}
            eventKey={opt.type}
            className={css["dropdown-item"]}
          >
            <img src={opt.image} alt={opt.label} />
            {opt.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SortDropdown;

export const SORT_OPTIONS = [
  {
    label: "Name - A to Z",
    image: "/assets/images/icons/sorting/atoz.png",
    type: "ASC",
  },
  {
    label: "Name - Z to A",
    image: "/assets/images/icons/sorting/ztoa.png",
    type: "DESC",
  },
];
