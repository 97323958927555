import React from "react";

const IconedButton2 = ({
  title,
  onClick,
  icon,
  className,
  icon2,
  icon2Class,
}) => {
  return (
    <button
      className={`iconed-button-alt ${className ?? ""}`}
      onClick={onClick}
    >
      <img src={icon} alt={title} />
      <span>{title}</span>
      {icon2 && <img src={icon2} alt={title} className={icon2Class} />}
    </button>
  );
};
export default IconedButton2;
