import React from "react";
import cx from "classnames";
import css from "./Inputs.module.css";

/**
 * @typedef {Object} InputProps
 * @property {string} className
 * @property {("primary"|"secondary")} [variant]
 * @property {("sm"|"md")} [height]
 * @property {React.MutableRefObject<HTMLInputElement>} reff
 * @property {boolean} [valid]
 * @property {boolean} [invalid]
 */

/**
 * @param {InputProps & React.InputHTMLAttributes<HTMLInputElement>} props
 */
const Input = ({
  className,
  variant = "primary",
  height = "md",
  valid = null,
  invalid = null,
  reff,
  ...rest
}) => {
  return (
    <input
      ref={reff}
      className={cx(
        css.input,
        css[variant],
        css[height],
        typeof valid === "boolean" && (valid ? css.valid : ""),
        typeof invalid === "boolean" && (invalid ? css.invalid : ""),
        className
      )}
      {...rest}
    />
  );
};

export default Input;
