import React from "react";
import css from "./Buttons.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";

/**
 * @typedef {Object} ExpandableButtonProps
 * @property {string} className ClassName for the button
 * @property {string} icon
 * @property {string} iconClassName
 * @property {string} title
 * @property {string} titleClassName
 *  *
 * @param {ExpandableButtonProps & import("react-router-dom").LinkProps} props
 */
const ExpandableButton = ({
  className,
  icon,
  iconClassName,
  title,
  titleClassName,
  to,
  ...rest
}) => {
  return to ? (
    <Link
      to={to}
      role="button"
      title={title}
      className={classNames(css["expandable-btn"], className)}
      {...rest}
    >
      <img src={icon} alt={title} className={iconClassName} />
      <span className={titleClassName}>{title}</span>
    </Link>
  ) : (
    <button
      role="button"
      title={title}
      className={classNames(css["expandable-btn"], className)}
      {...rest}
    >
      <img src={icon} alt={title} className={iconClassName} />
      <span className={titleClassName}>{title}</span>
    </button>
  );
};

export default ExpandableButton;
