import { useRef, useLayoutEffect, useEffect } from "react";
import { getCFURL } from "utils/helpers";

/**
 * This hook is used to lazy load images using IntersectionObserver.
 * Dynamically sets the src of image when its in view using refs.
 */
const useIntersectionObserver = ({ updatedImages, folderId }) => {
  const removedImagesRef = useRef({});

  useEffect(() => {
    updatedImages?.forEach((image) => {
      const _id =
        folderId === "my-photos" ||
        folderId === "purchased" ||
        folderId === "deleted"
          ? image?.imageId
          : image?._id;

      removedImagesRef.current[_id] = getCFURL({
        url: image?.url,
        thumb: true,
      });
    });

    return () => {
      removedImagesRef.current = {};
    };
  }, [updatedImages, folderId]);

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: "33%",
  });

  function handleIntersection(entries) {
    entries?.forEach((entry) => {
      const id = entry.target.id.split("-")[1];
      if (entry.isIntersecting) {
        const childEl = entry.target.querySelector(`#image-${id}`);

        //this is to animate the loader only when it is in view
        const childElLoader = entry.target.querySelector(`#loader-${id}`);
        if (childElLoader) {
          childElLoader.classList.add("rotate-animation");
        }

        childEl.src = removedImagesRef.current[id];
      }
    });
  }

  useLayoutEffect(() => {
    if (updatedImages?.length > 0) {
      updatedImages.forEach((image) => {
        const _id =
          folderId === "my-photos" ||
          folderId === "purchased" ||
          folderId === "deleted"
            ? image?.imageId
            : image?._id;

        const target = document.querySelector("#image_container-" + _id);
        if (target) {
          observer.observe(target);
        }
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [updatedImages, folderId]);

  return {
    removedImagesRef,
  };
};

export default useIntersectionObserver;
