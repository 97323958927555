import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import DefaultHeader from "../components/header/DefaultHeader";
import ProtectedRoute from "./ProtectedRoute";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GET_USER_DETAILS } from "redux-store/sagas/saga-actions";
import { ErrorBoundary } from "@sentry/react";
import Fallback from "views/components/errors/Fallback";
import { clearGroupDetails } from "redux-store/slices/settings";
import { lsProxy } from "utils/helpers/localstorage";

const MainRoute = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  React.useEffect(() => {
    dispatch(clearGroupDetails());
  }, []);

  if (params.has("token")) {
    lsProxy.setItem("token", "Bearer " + params.get("token"));
    dispatch({
      type: GET_USER_DETAILS,
    });
  }

  return (
    <ProtectedRoute>
      <div className="mainPage">
        {params.has("token") ? null : (
          <DefaultHeader goBack={pathname == "/join"} />
        )}
        <ErrorBoundary fallback={<Fallback />}>
          <Outlet />
        </ErrorBoundary>
      </div>
    </ProtectedRoute>
  );
};

export default MainRoute;
