import React, { useState, useEffect } from "react";
import { Container, Dropdown, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  POST_TRANSFER_FOLDER,
  GET_FOLDERS,
} from "redux-store/sagas/saga-actions";
import SecondarySmallBtn from "../button/SecondarySmallBtn";
import AddFolder from "./components/uploadfromcomputer/AddFolder";
import { toast } from "react-toastify";

const TransferFolderModal = ({
  folders,
  open,
  onHide,
  groupID,
  sourceFolderId,
  imageIds,
}) => {
  const [source, setSource] = useState([]);
  const [sourceValue, setSourceValue] = useState("");
  const [destinationValue, setDestinationValue] = useState("");
  const [destination, setDestination] = useState([]);
  const [ids, setIds] = useState({
    sourceFolderId: sourceFolderId || "",
    destinationFolderName: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let destinationFolders;

    if (sourceFolderId) {
      destinationFolders = folders.reduce((acc, folder) => {
        if (folder.groupFolder !== sourceFolderId) acc.push(folder);
        else setSourceValue(folder.folderPrefix);

        return acc;
      }, []);

      setDestination(destinationFolders);
    } else {
      setSource(folders);
      setDestination(folders);
    }
  }, [folders, sourceFolderId]);

  const selectSource = (e) => {
    setSourceValue(e);
    const destinationFolders = folders.filter((f) => f.folderPrefix !== e);
    const selectedFolder = folders.filter((f) => f.folderPrefix === e);

    setIds((prev) => ({
      ...prev,
      sourceFolderId: selectedFolder[0].groupFolder,
    }));

    setDestination(destinationFolders);
    setDestinationValue("");
  };

  const selectDestination = (e) => {
    setDestinationValue(e);

    const dest = destination.find((f) => f.folderPrefix === e);

    setIds((prev) => ({
      sourceFolderId: prev.sourceFolderId,
      ...(dest && dest.groupFolder
        ? { destinationFolderId: dest.groupFolder }
        : { destinationFolderName: e }),
    }));
  };

  const handleSubmit = () => {
    if (!sourceValue || !destinationValue) {
      return toast.error("Please select source and destination folders");
    }

    const data = {
      id: {
        ...ids,
        ...(imageIds ? { imageIds } : {}),
      },
      close: () => {
        onHide(true);
        dispatch({
          type: GET_FOLDERS,
          groupID,
          includeAllPhotos: true,
        });
      },
      src: sourceFolderId ? "multi-select" : "folder-page",
    };

    dispatch({
      type: POST_TRANSFER_FOLDER,
      payload: data,
    });
  };

  return (
    <Modal
      className="confirmationModal"
      show={open}
      animation={true}
      onHide={onHide}
      centered
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="font-bold font-24"
        >
          Transfer {sourceFolderId ? "Photos" : "Folders"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="mb-3 bold-font font-15" gap={2}>
          Choose Source
          <Dropdown
            className="gray-text med-font font-15 p-0 createDrop"
            aria-label="Default select example"
            onSelect={selectSource}
          >
            <Dropdown.Toggle
              className="gray-text bold-font font-15"
              disabled={sourceFolderId}
            >
              <span className="font-bold font-15">
                {sourceValue ? sourceValue : "Source"}
              </span>
              <span>
                <img
                  width="15"
                  src="/assets/images/icons/arrow-down.png"
                  alt="down icon"
                />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-0">
              {source?.map((items, i) => {
                return (
                  <Dropdown.Item
                    className="gray-text bold-font font-15"
                    selected={items.folderPrefix === sourceValue}
                    key={i}
                    eventKey={items?.folderPrefix}
                  >
                    {items?.folderPrefix}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Container>
        <Container className="mb-3 bold-font font-15" gap={2}>
          Choose Destination
          <Dropdown
            className="gray-text med-font font-15 p-0 createDrop"
            onSelect={selectDestination}
          >
            <Dropdown.Toggle className="gray-text bold-font font-15">
              <span className="font-bold font-15">
                {destinationValue ? destinationValue : "Destination"}
              </span>
              <span>
                <img
                  width="15"
                  src="/assets/images/icons/arrow-down.png"
                  alt="down icon"
                />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-0">
              <AddFolder
                prefixed={true}
                allFolder={destination}
                setAllFolder={setDestination}
                setFolderPrefix={selectDestination}
              />

              {destination.length > 0 &&
                destination?.map((items, i) => {
                  return (
                    <Dropdown.Item
                      className="gray-text bold-font font-15"
                      key={i}
                      selected={items.folderPrefix === destinationValue}
                      eventKey={items?.folderPrefix}
                    >
                      {items?.folderPrefix}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex justify-content-center">
        <SecondarySmallBtn
          className="secondarySmallButton bold-font  w-130 px-0 justify-content-center w-130 "
          onClick={handleSubmit}
          title="Transfer"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default TransferFolderModal;
