import React from "react";

import {
  EMAIL_REGEX,
  LINKS_REGEX,
  PHONE_REGEX,
  WA_NUMBER_REGEX,
  WEBSITE_REGEX,
} from "constants/regexlib";
import Joi from "joi";
import { toast } from "react-toastify";
import removeQueryParams from "utils/helpers/removeQueryParams";

const ERROR_MESSAGES = {
  "string.empty": "{{#label}} is required",
  "string.pattern.base": "{{#label}} is not valid",
  "any.required": "{{#label}} is required",
};

const BusinessSettingsSchema = Joi.object({
  business_name: Joi.string()
    .required()
    .label("Business Name")
    .messages(ERROR_MESSAGES),
  business_phone: Joi.string()
    .required()
    .regex(PHONE_REGEX)
    .label("Business Phone")
    .messages(ERROR_MESSAGES),
  business_phone_country_code: Joi.string().required().label("Country Code"),
  business_phone_d: Joi.boolean().required(),
  business_email: Joi.string()
    .required()
    .regex(EMAIL_REGEX)
    .label("Business Email")
    .messages(ERROR_MESSAGES),
  business_email_d: Joi.boolean().required(),
  website: Joi.string()
    .allow(" ")
    .regex(WEBSITE_REGEX)
    .label("Website")
    .messages(ERROR_MESSAGES)
    .max(64),
  website_d: Joi.boolean().required(),
  insta_link: Joi.string()
    .allow(" ")
    .regex(LINKS_REGEX)
    .label("Instagram link")
    .messages(ERROR_MESSAGES)
    .max(64),
  insta_link_d: Joi.boolean().required(),
  fb_link: Joi.string()
    .allow(" ")
    .regex(LINKS_REGEX)
    .label("Facebook link")
    .messages(ERROR_MESSAGES)
    .max(64),
  fb_link_d: Joi.boolean().required(),
  whatsApp_link: Joi.string()
    .allow(" ")
    .regex(WA_NUMBER_REGEX)
    .label("WhatsApp phone number")
    .messages(ERROR_MESSAGES)
    .max(64),
  whatsApp_link_d: Joi.boolean().required(),
  vimeo_link: Joi.string()
    .allow(" ")
    .regex(LINKS_REGEX)
    .label("Vimeo link")
    .messages(ERROR_MESSAGES)
    .max(64),
  vimeo_link_d: Joi.boolean().required(),
  youtube_link: Joi.string()
    .allow(" ")
    .regex(LINKS_REGEX)
    .label("Youtube link")
    .messages(ERROR_MESSAGES)
    .max(64),
  youtube_link_d: Joi.boolean().required(),
});

const linkKeys = [
  "website",
  "insta_link",
  "fb_link",
  "vimeo_link",
  "youtube_link",
];

const validateBusinessSettings = (data) => {
  const _data = { ...data };

  for (const link of linkKeys)
    if (_data[link]?.length > 64) _data[link] = removeQueryParams(_data[link]);

  const { error, value } = BusinessSettingsSchema.validate(_data, {
    abortEarly: false,
  });

  const invalidFields = {};

  if (error) {
    error.details.forEach((e) => {
      invalidFields[e.context.key] = true;
    });

    const errmsg = error.details.map((err) => err.message);

    toast.error(
      <ul className="m-0 ps-4">
        {errmsg.map((err, index) => (
          <li className="mb-0 bullet-list" key={index}>
            {err}
          </li>
        ))}
      </ul>
    );
  }

  return { error, value, invalidFields };
};

export default validateBusinessSettings;
