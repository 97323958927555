import React, { useState, useEffect } from "react";

const AuthTextLeft = (props) => {
  // const isNotMobile = window.innerWidth > 900;
  const [isNotMobile, setIsNotMobile] = useState(window.innerWidth > 900);
  const {
    class: className,
    image = "/assets/images/auth/login-banner.jpg",
    text = (
      <div>
        <p>
          “ I am excited to be a part of Team Kwikpic. Using Facial Recognition
          to smartly deliver event photos to guests will become the norm in the
          industry. Jump on the band wagon with me and try out Kwikpic for your
          next event. “
        </p>
        <h4 style={{ marginBottom: "5px" }}>Dabboo Ratnani</h4>
        <p style={{ fontSize: "12px", marginBottom: "0px" }}>
          Celebrity Photographer
        </p>
      </div>
    ),
    width = isNotMobile ? "520px" : "410px",
    logo = "/assets/images/global-images/logo_colored_white.png",
  } = props;

  const handleResize = () => {
    if (window.innerWidth > 900) {
      setIsNotMobile(true);
    } else {
      setIsNotMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div className={className}>
      <div className="authTextLeft">
        <img src={image} alt="banner" />
        <div className="signup-logo-wrapper">
          <a href="/landing/home">
            <img src={logo} width={157} alt="logo" />
          </a>
        </div>
        <div style={{ width }} className="overlayText">
          {text}
        </div>
      </div>
    </div>
  );
};

export default AuthTextLeft;
