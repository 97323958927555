import React from "react";
import { useDispatch } from "react-redux";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";
import { STATIC_FOLDERS } from "../../UploadFromComputer";
import { INITIAL_FOLDER } from "hooks/useUploadFromComputer";
import ANALYTICS from "constants/analyticsKeys";

/**
 * @param {boolean} prefixed whether the object in folder array has folderPrefix
 * @param {Function} setAllFolder
 * @param {Array} allFolder
 * @param {Function} setFolderPrefix
 */
export default function AddFolder({
  prefixed = true,
  setAllFolder,
  allFolder,
  setFolderPrefix,
}) {
  const [createFolder, setCreateFolder] = React.useState({
    status: false,
    folderName: "",
  });
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();

  const handleSave = (e) => {
    e.preventDefault();

    if (!createFolder?.folderName) {
      // no need to show error toast here as it is already handled in input field
      return;
    }

    if (!RegExp(/^[a-zA-Z0-9]/).test(createFolder.folderName)) {
      setError("Folder name should start with a letter or number.");
      return;
    }

    if (!prefixed && createFolder?.folderName?.length > 20) {
      setError("Folder name should be less than 20 characters");
      return;
    }

    if (createFolder.folderName.trim() === INITIAL_FOLDER) {
      setError(`Folder name should not be ${INITIAL_FOLDER}.`);
      return;
    }

    const trimmedName = createFolder.folderName.trim();
    const trimmedLowerName = trimmedName.toLowerCase();

    const folderExist = prefixed
      ? allFolder.find(
          (folder) => folder.folderPrefix.toLowerCase() === trimmedLowerName
        ) ||
        trimmedLowerName === STATIC_FOLDERS.ALL_PHOTOS.toLowerCase() ||
        trimmedLowerName === STATIC_FOLDERS.HIGHLIGHTS.toLowerCase() ||
        trimmedLowerName === "all"
      : allFolder.find(
          (folder) => folder.folder.toLowerCase() === trimmedLowerName
        );
    if (folderExist) {
      setError("Folder already exists");
      return;
    }

    const newFolder = {
      _id: "",
      groupFolder: "",
      ...(prefixed ? { folderPrefix: trimmedName } : { folder: trimmedName }),
    };
    setAllFolder([...allFolder, newFolder]);
    setFolderPrefix(trimmedName);
    setCreateFolder({ status: false, folderName: "" });
  };

  return (
    <form onSubmit={handleSave}>
      <div className="add-folder-border-bottom">
        <button
          onClick={() => {
            setError("");
            setCreateFolder((prev) => ({
              ...prev,
              status: !prev.status,
            }));
          }}
          className="btn btn-anchor w-auto font-15 font-bold gray-text dropdown-item"
        >
          Add Folder +
        </button>
      </div>
      {createFolder.status && (
        <li className="createFolderInput">
          <input
            className="thick-input-pl font-15-input-pl gray-input-pl blue-border create-folder-input"
            onChange={(e) => {
              setError("");
              setCreateFolder({
                ...createFolder,
                folderName: e.target?.value,
              });
            }}
            value={createFolder.folderName}
            placeholder="Folder Name"
            required
          />
          <button
            type="submit"
            className="font-13 font-bold"
            onClick={() => {
              dispatch({
                type: SEND_USER_CLICK_ANALYTICS,
                payload: { buttons: [ANALYTICS.UPLOAD_FOLDER] },
              });
            }}
          >
            Create
          </button>
        </li>
      )}
      {error && (
        <p
          style={{
            color: "red",
            marginLeft: "10px",
            marginBottom: "4px",
            fontSize: "12px",
          }}
        >
          {error}
        </p>
      )}
    </form>
  );
}
