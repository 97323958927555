import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

const CarouselComponent = ({ chooseUs }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      variant="dark"
      activeIndex={index}
      onSelect={handleSelect}
      className="landing-carousel"
    >
      {chooseUs.map((info, index) => (
        <Carousel.Item key={index}>
          <div className="carousel-box">
            <div className="face-recognition-detail">
              <div className="face-icon bottom-margin-32">
                <img src={info.image} alt={info.image} />
              </div>
              <div className="face-recognition-header mb-3"> {info.header}</div>
              <div className="description">{info.description}</div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
