import useDetectUserAgent from "hooks/useDetectUserAgent";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import SuspenseLoader from "../loader/SuspenseLoader";
import ANALYTICS from "constants/analyticsKeys";

const UserAgentErrorModal = React.lazy(() =>
  import("../modals/UserAgentModal")
);

const SettingsMobilePage = () => {
  const {
    leaveParticipantSettings: { isAdmin },
    groupDetails: { isForProductSale },
  } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const setGoBackLoc = useOutletContext()[1];
  const { userType } = getLocalStorageUser();
  const isPhotographer = userType === "PHOTOGRAPHER";
  const [params] = useSearchParams();
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [device, setDevice] = React.useState({ type: "desktop" });
  useDetectUserAgent(setDevice);

  const groupID = params.get("groupId");
  const token = params.get("token");
  const frontendType = params.get("frontendType");

  const appendToken =
    (token ? `&token=${token}` : "") +
    (frontendType && token ? `&frontendType=${frontendType}` : "");

  useEffect(() => {
    setGoBackLoc("/gallery");
  }, []);

  return (
    <div className="drawerContainer">
      <div className="drawerItems">
        <p className="mobile-settings-heading">Group Settings</p>
        <ul>
          {appendToken ? null : (
            <li>
              <Link
                onClick={() => setGoBackLoc("/settings/list" + appendToken)}
                to={"/settings/general?groupId=" + groupID + appendToken}
              >
                General Settings
              </Link>
            </li>
          )}

          <li>
            <Link
              onClick={() => {
                setGoBackLoc("/settings/list" + appendToken);
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.PARTICIPANT_SETTINGS] },
                });
              }}
              to={"/settings/participants?groupId=" + groupID + appendToken}
            >
              Participants
            </Link>
          </li>
          {isAdmin && (
            <>
              <li>
                <Link
                  onClick={() => {
                    setGoBackLoc("/settings/list" + appendToken);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.PRIVACY_SETTINGS] },
                    });
                  }}
                  to={"/settings/privacy?groupId=" + groupID + appendToken}
                >
                  Privacy Settings
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    setGoBackLoc("/settings/list" + appendToken);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.FOLDER_SETTINGS] },
                    });
                  }}
                  to={"/settings/folders?groupId=" + groupID + appendToken}
                >
                  Folders
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    setGoBackLoc("/settings/list" + appendToken);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.DESIGN_SETTINGS] },
                    });
                  }}
                  to={"/settings/design?groupId=" + groupID + appendToken}
                >
                  Design
                  <img
                    style={{ marginLeft: "10px" }}
                    src={"/assets/images/icons/premium.png"}
                    alt="menu icon"
                  />
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    setGoBackLoc("/settings/list" + appendToken);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.DOWNLOAD_SETTINGS] },
                    });
                  }}
                  to={"/settings/download?groupId=" + groupID + appendToken}
                >
                  Download Setting
                  <img
                    style={{ marginLeft: "10px" }}
                    src={"/assets/images/icons/premium.png"}
                    alt="menu icon"
                  />
                </Link>
              </li>

              <li>
                <Link
                  to={"/settings/flipbook?groupId=" + groupID + appendToken}
                  onClick={() => {
                    setGoBackLoc("/settings/list" + appendToken);
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.FLIPBOOK_SETTINGS] },
                    });
                  }}
                >
                  Digital Flipbook
                  <img
                    style={{ marginLeft: "10px" }}
                    src="/assets/images/icons/premium.png"
                    alt="menu icon"
                  />
                </Link>
              </li>

              {isPhotographer && (
                <>
                  <li>
                    <Link
                      to={"/settings/sponsors?groupId=" + groupID + appendToken}
                      onClick={() => {
                        setGoBackLoc("/settings/list" + appendToken);
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: { buttons: [ANALYTICS.SPONSOR_SETTINGS] },
                        });
                      }}
                    >
                      Branding & Sponsors
                      <img
                        style={{ marginLeft: "10px" }}
                        src="/assets/images/icons/premium.png"
                        alt="menu icon"
                      />
                    </Link>
                  </li>

                  {isForProductSale ? (
                    <li>
                      <Link
                        to={
                          "/settings/sell-photos?groupId=" +
                          groupID +
                          appendToken
                        }
                        onClick={() => {
                          setGoBackLoc("/settings/list" + appendToken);
                          dispatch({
                            type: SEND_USER_CLICK_ANALYTICS,
                            payload: {
                              buttons: [ANALYTICS.SELL_PICS_SETTINGS],
                            },
                          });
                        }}
                      >
                        Sell Pictures
                        <img
                          style={{ marginLeft: "10px" }}
                          src="/assets/images/icons/premium.png"
                          alt="menu icon"
                        />
                      </Link>
                    </li>
                  ) : null}

                  {!appendToken && (
                    <li>
                      <Link
                        onClick={() => {
                          setGoBackLoc("/settings/list");
                          dispatch({
                            type: SEND_USER_CLICK_ANALYTICS,
                            payload: {
                              buttons: [ANALYTICS.CLIENT_FAVOURITES],
                            },
                          });
                        }}
                        to={`/settings/client?groupId=${groupID}&isAdmin=true&page=1`}
                      >
                        Client Favorite
                        <img
                          style={{ marginLeft: "10px" }}
                          src="/assets/images/icons/premium.png"
                          alt="menu icon"
                        />
                      </Link>
                    </li>
                  )}
                </>
              )}
            </>
          )}
        </ul>
      </div>
      <React.Suspense fallback={<SuspenseLoader />}>
        {showErrorModal ? (
          <UserAgentErrorModal
            show={showErrorModal}
            onHide={() => setShowErrorModal(false)}
            deviceType={device}
            variant={1}
          />
        ) : null}
      </React.Suspense>
    </div>
  );
};

export default SettingsMobilePage;
