import { toast } from "react-toastify";
import { isDirectorySelectionSupported } from "views/download/helpers";

const handleDownloadRouter = (
  groupID,
  navigate,
  deviceType,
  setShowErrorModal,
  { isAdmin, allowDownload, bulkDownload = false, isAnonymous, anonymousUserId }
) => {
  if (!bulkDownload) {
    toast.error(
      "Bulk download is disabled in free plan. Ask group creator to upgrade their plan."
    );
    return;
  }

  if (!allowDownload && !isAdmin) {
    toast.error("Downloading is disabled. Contact group admin for access.");
    return;
  }

  if (
    (deviceType && deviceType.type !== "desktop") ||
    !isDirectorySelectionSupported()
  ) {
    if (deviceType.type === "mobile") setShowErrorModal(true);
    else
      toast.error(
        "Bulk Download is not supported on this browser. Please use Chrome, Edge or Opera on desktop for downloading full album."
      );
  } else {
    let link = isAnonymous
      ? `/anonymous/gallery/download?groupId=${groupID}&anonymousUserId=${anonymousUserId}`
      : `/gallery/download?groupId=${groupID}`;

    navigate(link);
  }
};

export { handleDownloadRouter };
