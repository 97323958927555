import { takeLatest, put, call, delay } from "redux-saga/effects";
import { AXIOS } from "../../utils/setup/axios";
import {
  deleteImg,
  deleteMultipleImages,
  setFolderImageLoader,
  setImagesLoading,
  setImagesOfFolder,
  setMyImages,
  updateMultipleFavorite,
} from "../slices/folderImages";
import { retryAPIDelay } from "./folders";
import {
  GET_IMAGES_OF_GROUP,
  GET_MY_IMAGES,
  GET_PAID_PICS,
  FAVORITE_PICS,
  DELETE_PICS,
  GET_DELETED_IMAGES,
  UNDO_DELETE_IMAGES,
} from "./saga-actions";
import { toast } from "react-toastify";
import { setAnonymousUserData } from "redux-store/slices/anonymous";
import {
  BULK_OPERATION_STATUS,
  setBulkOperationStatus,
} from "redux-store/slices/globals";

export async function getImagesOfFolder(data) {
  const { folderId, page, limit, anonymoususerid } = data;
  const headers = {};
  if (anonymoususerid) {
    headers.anonymoususerid = anonymoususerid;
  }

  if (folderId) {
    return AXIOS.get(`/api/app/groupFolder/folderPics/${folderId}`, {
      params: { page, limit },
      headers,
    });
  }
}

export function* getImagesOfGroupGenerator(action) {
  try {
    yield put(setImagesLoading(true));
    const response = yield call(getImagesOfFolder, action);
    if (response.status === 200) {
      const data = response?.data;
      yield put(
        setImagesOfFolder({
          data,
          folderId: action.folderId,
          page: action.page,
        })
      );
    }
  } catch (e) {
    if (
      e.response.data?.message?.includes("anonymousAccess enabled") &&
      action?.anonymoususerid
    ) {
      yield put(
        setAnonymousUserData({
          hasError: true,
          anonymousDisabled: true,
        })
      );
    }
  } finally {
    yield put(setImagesLoading(false));
  }
}

async function getMyImages(data) {
  const { page, limit, groupID, isFavorite } = data?.payload || {};
  return AXIOS.get(`/api/app/pic/list/${groupID}`, {
    params: { page, limit, isFavorite },
  });
}

export function* getMyImagesGenerator(action) {
  try {
    const response = yield call(getMyImages, action);
    yield put(
      setMyImages({
        data: response?.data,
        groupID: action?.payload?.groupID,
        page: action?.payload?.page,
        folderId: action?.payload?.folderId || null,
      })
    );
  } catch (_) {
    try {
      yield delay(retryAPIDelay);
      const response = yield call(getMyImages, action);
      yield put(
        setMyImages({
          data: response?.data,
          groupID: action?.payload?.groupID,
          page: action?.payload?.page,
          folderId: action?.payload?.folderId || null,
        })
      );
    } catch (e) {
      // 400: invalid group id
      // 403: not a participant
      // this api is called concurrently with web-settings and group/details
      // both the above error cases are handled already in those sagas
    }
  }
}

function getPaidPics(data) {
  const { groupId, page, limit } = data || {};
  return AXIOS.get(`/api/app/pic/paid-pics/${groupId}`, {
    params: { page, limit },
  });
}

export function* getPaidPicsGenerator(action) {
  try {
    yield put(setImagesLoading(true));
    const response = yield call(getPaidPics, action.payload);
    yield put(
      setImagesOfFolder({
        data: {
          data: {
            groupId: action.payload?.groupId,
            allPics: response?.data?.data?.paidPics,
            allPicsCount: response?.data?.data?.count,
          },
        },
        folderId: "purchased",
        page: action.payload?.page,
      })
    );
  } catch (e) {
    toast.error(
      e.response?.data?.message ||
        "Something went wrong, please refresh the page."
    );
  } finally {
    yield put(setImagesLoading(false));
  }
}

async function favoritePics(data) {
  return AXIOS.patch("/api/app/pic/multiple-favorite-unfavorite", data);
}

export function* favoritePicsGenerator(action) {
  try {
    yield put(setFolderImageLoader(true));
    const { folderId, indices, ...rest } = action.payload;
    const { data } = yield call(favoritePics, rest);
    yield put(
      updateMultipleFavorite({
        ...data?.data,
        folderId,
        indices,
      })
    );
    toast.success(data.message);
    yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.SUCCESS));
  } catch (e) {
    toast.error(
      e.response?.data?.message ||
        "Something went wrong, please refresh the page."
    );
    yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.ERROR));
  } finally {
    yield put(setFolderImageLoader(false));
  }
}

async function deletePics({ urls }) {
  return AXIOS.patch("/api/app/pic/delete-multiple-pic", { urls });
}

export function* deletePicsGenerator(action) {
  try {
    yield put(setFolderImageLoader(true));
    const { data } = yield call(deletePics, action.payload);
    toast.success(data.message);
    yield put(deleteMultipleImages(action.payload));
    yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.SUCCESS));
  } catch (e) {
    toast.error(
      e.response?.data?.message ||
        "Something went wrong, please refresh the page."
    );
    yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.ERROR));
  } finally {
    yield put(setFolderImageLoader(false));
  }
}

async function getDeletedPics({ groupId, page, limit }) {
  return AXIOS.get(`/api/app/pic/deleted-pics/${groupId}`, {
    params: { page, limit },
  });
}

function* getDeletedPicsGenerator(action) {
  try {
    yield put(setImagesLoading(true));
    const { data } = yield call(getDeletedPics, action.payload);
    toast.success(data.message);

    yield put(
      setImagesOfFolder({
        data: {
          data: {
            groupId: action.payload?.groupId,
            allPics: data?.data?.deletedPics,
            allPicsCount:
              data?.data?.count || data?.data?.deletedPics?.length || 0,
          },
        },
        folderId: "deleted",
        page: action.payload?.page,
      })
    );
  } catch (e) {
    toast.error(
      e.response?.data?.message ||
        "Something went wrong, please refresh the page."
    );
  } finally {
    yield put(setImagesLoading(false));
  }
}

async function undeleteImages({ groupId, urls }) {
  return AXIOS.patch("/api/app/pic/undelete-images", { groupId, urls });
}

function* undeleteImagesGenerator(action) {
  try {
    yield put(setFolderImageLoader(true));
    const { src, ...rest } = action.payload;
    const { data } = yield call(undeleteImages, rest);

    if (src === "full-screen-restore") {
      yield put(deleteImg(action.payload));
    } else {
      yield put(
        deleteMultipleImages({ ...action.payload, folderId: "deleted" })
      );
    }
    toast.success(data.message);
    yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.SUCCESS));
  } catch (e) {
    toast.error(
      e.response?.data?.message ||
        "Something went wrong, please refresh the page."
    );
    yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.ERROR));
  } finally {
    yield put(setFolderImageLoader(false));
  }
}

export function* folderImageSaga() {
  yield takeLatest(GET_IMAGES_OF_GROUP, getImagesOfGroupGenerator);
  yield takeLatest(GET_MY_IMAGES, getMyImagesGenerator);
  yield takeLatest(GET_PAID_PICS, getPaidPicsGenerator);
  yield takeLatest(DELETE_PICS, deletePicsGenerator);
  yield takeLatest(FAVORITE_PICS, favoritePicsGenerator);
  yield takeLatest(GET_DELETED_IMAGES, getDeletedPicsGenerator);
  yield takeLatest(UNDO_DELETE_IMAGES, undeleteImagesGenerator);
}
