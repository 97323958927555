import React from "react";
import GallaryView from "./gallaryPage/GallaryView";
import GallaryHeader from "views/components/header/GallaryHeader";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useGalleryRouteManager from "hooks/useGalleryRoutesManager";

const Gallery = () => {
  useGalleryRouteManager();
  const { pathname } = useLocation();
  const {
    generalSettings: { coverIcon } = {},
    designSettings: {
      colorMode,
      font,
      grid,
      padding,
      photoSize,
      template,
    } = {},
  } = useSelector((state) => state.settings);

  return (
    <div
      className={cx(
        "gallaryPage",
        colorMode === 1 ? "light" : "dark",
        photoSize === 1 ? "sizeLarge" : "sizeSmall",
        grid === 1 ? "horizontal" : "vertical",
        padding === 1 ? "paddingLarge" : "paddingSmall",
        font === 1 ? "fontSerif" : "fontSans"
      )}
    >
      {!coverIcon ? (
        <GallaryHeader goBack={true} />
      ) : (
        <GallaryHeader template={template} goBack={true} />
      )}
      <GallaryView isFolderView={pathname === "/gallery/folders"} />
    </div>
  );
};

export default Gallery;
