import React from "react";
import { COUNTRIES } from "../../../utils/helpers/countryCodes";

const CountryJson = ({
  setCountryCode,
  countryCode,
  className = "",
  name = "",
  disabled = false,
  defaultCountryCode = "",
  defaultEnabled = false,
}) => {
  const countryCodes = COUNTRIES.filter((item, index, self) => {
    let prevDialCode = self.slice(0, index);
    let hasCode = !!prevDialCode.find(
      (prevDialCode) => prevDialCode.dial_code === item.dial_code
    );

    return !hasCode;
  });

  return (
    <select
      name={name}
      className={`selected ${className}`}
      {...(defaultEnabled
        ? {
            defaultValue: defaultCountryCode,
          }
        : {
            value: countryCode,
            onChange: (e) => setCountryCode?.(e.target.value),
          })}
      disabled={disabled}
    >
      {countryCodes.map((country) => {
        return (
          <option key={country.dial_code} value={country.dial_code}>
            {country.dial_code}
          </option>
        );
      })}
    </select>
  );
};
export default CountryJson;
