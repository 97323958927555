import React from "react";
import cx from "classnames";
import css from "./Buttons.module.css";

/**
 * @typedef {Object} GroupedButtonProps
 * @property {string} primaryText Text for the primary button
 * @property {string} secondaryText Text for the secondary button
 * @property {function} primaryOnClick Handler for the primary button
 * @property {function} secondaryOnClick Handler for the secondary button
 * @property {string} primaryClassName Class name for the primary button
 * @property {string} secondaryClassName Class name for the secondary button
 * @property {("xss"|"xs"|"sm"|"md")} variant Size of the buttons
 * @property {string} groupClassName Class name for the button group
 * @property {number} active Which button is active
 * @param {GroupedButtonProps} props
 */
const GroupedButton = ({
  primaryText,
  secondaryText,
  primaryOnClick,
  secondaryOnClick,
  primaryClassName,
  secondaryClassName,
  groupClassName,
  variant = "sm",
  active = 0,
}) => {
  return (
    <div className={cx(css.buttonGroup, groupClassName)}>
      <button
        onClick={primaryOnClick}
        className={cx(
          primaryClassName,
          css[variant],
          active === 0 ? css.secondaryButton : css["outlined-button"]
        )}
      >
        {primaryText}
      </button>
      <button
        onClick={secondaryOnClick}
        className={cx(
          secondaryClassName,
          css[variant],
          active === 1 ? css.secondaryButton : css["outlined-button"]
        )}
      >
        {secondaryText}
      </button>
    </div>
  );
};

export default GroupedButton;
