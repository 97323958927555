import React from "react";
import cx from "classnames";
import css from "./Backdrop.module.css";
import ReactDOM from "react-dom";

const Backdrop = ({
  onClick,
  show = true,
  zIndex = 1100,
  transparent = false,
  scroll = true,
}) => {
  return ReactDOM.createPortal(
    <div
      onClick={onClick}
      className={cx(show ? "d-block" : "d-none", {
        [css["no-scroll"]]: !scroll,
      })}
      style={{
        zIndex,
        backgroundColor: transparent ? "none" : "rgba(0,0,0,0.7)",
        position: "fixed",
        inset: 0,
        transition: "all 0.3",
      }}
    />,
    document.getElementById("backdrop")
  );
};

export default Backdrop;
