import React from "react";
import { Form } from "react-bootstrap";
import SecondaryButton from "../button/SmallRoundedButton";
import css from "./ImageUploaderWithPreview.module.css";
import cx from "classnames";

const ImageUploaderWithPreview = ({
  inputRef,
  required,
  onChange,
  previewImage,
  title = "",
  fieldName = "",
  titleClassName = "",
  uploadCardOverride = "",
  requirements = "",
}) => {
  return (
    <Form.Group>
      <Form.Control
        type="file"
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        required={required}
        className="visually-hidden"
        onChange={onChange}
        accept={["image/jpeg", "image/png"]}
      />
      <Form.Label htmlFor={fieldName} className={titleClassName}>
        {title}
      </Form.Label>
      {previewImage ? (
        <>
          <div className={cx(css["upload-card"], uploadCardOverride)}>
            <img
              src={previewImage}
              alt="sponsor logo"
              className={css["image-preview"]}
            />
          </div>
          <SecondaryButton
            title="Change"
            type="button"
            variant="xs"
            rounded={false}
            onClick={() => inputRef.current.click()}
            className="mt-2 px-4 font-14 py-2 h-auto"
          />
        </>
      ) : (
        <div className={cx(css["upload-card"], uploadCardOverride)}>
          <SecondaryButton
            title="Browse"
            type="button"
            className="cursor-pointer"
            onClick={() => inputRef.current.click()}
          />
          {requirements && <p className={css.instructions}>{requirements}</p>}
        </div>
      )}
    </Form.Group>
  );
};

export default ImageUploaderWithPreview;
