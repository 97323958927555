import React from "react";
import MediaIcons from "./MediaIcons";
import cx from "classnames";

/**
 * Component to render branding data of a single sponsor
 * @typedef {object} SponsorBlock
 * @property {object} sponsor - Sponsor data
 * @property {boolean} isAnonymous - Is user anonymous
 * @property {boolean} showMedia - Show social media icons, pass true if there's no sponsor
 * @property {("1"|"2"|"3"|"4")} template - Style of the component
 *  - 1: for template 1, 2, 3
 *  - 2: for template 4
 *  - 3: for template 5
 *  - 4: for login page
 * @property {string} classOverrides - Override classes
 * @property {boolean} isLoginPage - If it's login page
 * @param {SponsorBlock} props
 */
const SponsorBlock = ({
  sponsor,
  isAnonymous,
  showMedia,
  template,
  isLoginPage,
  classOverrides,
}) => {
  const className =
    template === "1"
      ? "d-flex flex-column-reverse align-items-end justify-content-center flex-md-row align-items-md-center justify-content-md-end gap-2"
      : template === "2"
      ? "d-flex flex-column-reverse align-items-center gap-2"
      : template === "3"
      ? "d-flex flex-row-reverse align-items-center justify-content-end gap-2"
      : "d-flex flex-row-reverse align-items-center justify-content-end flex-md-column-reverse gap-1";

  return (
    <div className={cx(className, classOverrides)}>
      <div
        className={cx("d-flex flex-column", {
          "align-items-end text-end": template === "1",
          "align-items-center text-center": template === "2",
          "text-start": template === "3",
          "align-items-start align-items-md-center text-start text-md-center":
            template === "4",
        })}
      >
        {showMedia ? (
          <h2 className="mb-1 p-0 font-bold font-12 font-xs-13 font-md-15 text-clip-line-2">
            {sponsor.sTitle} {sponsor.sName}
          </h2>
        ) : (
          <>
            <h2 className="m-0 pt-0 text-clip-line-1 font-11 font-md-13 font-base">
              {sponsor.sTitle}
            </h2>
            <h2 className="m-0 pt-0 font-bold text-clip-line-1 font-13 font-md-15">
              {sponsor.sName}
            </h2>
          </>
        )}
        {showMedia && (
          <MediaIcons isAnonymous={isAnonymous} isLoginPage={isLoginPage} />
        )}
      </div>

      {sponsor.sImage && (
        <>
          <div
            className={cx({
              "d-none": template === "2",
              vr: template === "3",
              "vr d-none d-md-block": template === "1",
            })}
          />
          {showMedia ? (
            <div className={cx({ companyLogo: !isLoginPage })}>
              <a href={sponsor.sLink} target="_blank" rel="noreferrer noopener">
                <img
                  src={sponsor.sImage}
                  alt="logo"
                  className={cx({
                    "pg-logo": isLoginPage,
                  })}
                />
              </a>
            </div>
          ) : (
            <a
              href={sponsor.sLink}
              target="_blank"
              rel="noreferrer noopener"
              className={cx({
                "col-5 col-md-6 text-end": template === "3" && !showMedia,
              })}
            >
              <img src={sponsor.sImage} alt="logo" className="pg-logo" />
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default SponsorBlock;
