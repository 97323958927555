import React from "react";
import css from "../../ads/AdsLanding.module.css";
import { Testimony } from "views/ads";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";

const Testimonies = ({ testimonies }) => {
  return (
    <Container fluid className={css.trusted}>
      <Container>
        <Row>
          <Col>
            <h2 className="font-24 font-bold text-center mb-4">
              Trusted by 1000+ Photographers
            </h2>
            <div className={css.slider}>
              <Slider
                {...{
                  dots: false,
                  infinite: true,
                  autoplay: true,
                  speed: 500,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  autoplaySpeed: 2500,
                  prevArrow: <img src="/assets/images/icons/backward.png" />,
                  nextArrow: <img src="/assets/images/icons/forward.png" />,
                  responsive: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        swipe: true,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        swipe: true,
                      },
                    },
                    {
                      breakpoint: 576,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        swipe: true,
                      },
                    },
                  ],
                }}
              >
                {testimonies.map((item, index) => (
                  <Testimony key={index} {...item} />
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Testimonies;
