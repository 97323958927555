import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import DefaultSwitch from "views/components/input/DefaultSwitch";
import PricingLeftArrow from "views/photographerDashboard/components/PricingLeftArrow";
import PricingListItem from "views/photographerDashboard/components/pricingListItem";
import PricingRightArrow from "views/photographerDashboard/components/PricingRightArrow";
import Spinner from "views/components/loader/Spinner";
import { loadRZP } from "utils/helpers/loadRZP";

export const ChooseSubscriptionPlan = () => {
  const [monthlyBilling, setMonthlyBilling] = React.useState(false); // quarterly
  const { allPlans, plansLoading } = useSelector((state) => state.subscription);
  const { isUserInIndia } = useSelector((state) => state.globals);
  const { userType } = getLocalStorageUser();

  React.useEffect(() => {
    loadRZP();
  }, []);

  const pricingListData = allPlans
    ?.filter((plan) =>
      monthlyBilling ? plan?.period === "monthly" : plan?.period === "yearly"
    )
    ?.filter((plan) => plan?.billing_amount > 100)
    ?.map((plan) => {
      let evsl = "";
      const features = plan?.features
        ?.filter((feature) => {
          if (feature?.enabled && feature?.name === "extended_video_storage") {
            evsl = feature?.limit;
            return false;
          } else if (feature?.enabled && feature?.name !== "web_mode") {
            return true;
          }
          return false;
        })
        .map((feature) => {
          if (feature?.name === "extended_photo_storage" && evsl)
            return (
              (feature?.extras?.featureText || "") + " and " + evsl + " videos"
            );
          return feature?.extras?.featureText || "";
        });

      return {
        plan_id: plan?.plan_id,
        price: isUserInIndia ? plan?.billing_amount : plan?.billing_amount_USD,
        type: monthlyBilling ? "month" : "year",
        period: plan?.period,
        packageName: plan?.displayText || "",
        packageDescription: plan?.displaySubText || "",
        packageIcon: "/assets/images/icons/premium.png",
        features,
        userType: plan?.userType,
        // used previously to navigate to the plan details page
        // navigateToUrl: () => onChoose(plan._id),
      };
    })
    .sort((a, b) => a.price - b.price);

  return (
    <div className="container pt-3 inside-app">
      <div className="pricing-wrapper">
        <div className="pricing-toggler">
          <div className="pricing-control-wrapper justify-content-center">
            <div className="check-box position-relative">
              <label className={!monthlyBilling ? "dim" : ""}>
                Bill Quarterly
              </label>
              <DefaultSwitch
                onChange={() => setMonthlyBilling(!monthlyBilling)}
                isChecked={!monthlyBilling}
              />
              <label className={monthlyBilling ? "dim" : ""}>
                Bill Annually
              </label>
              <img
                src="/assets/icons/30-percent.png"
                alt="30% off"
                className="discount-badge"
              />
            </div>
          </div>
        </div>
        <div className="d-none d-md-flex justify-content-center align-items-center font-15 flex-column">
          <p className="m-0 my-1">
            All plans have&nbsp;<b>Unlimited</b>
          </p>
          <div className="font-base color-primary d-flex">
            <p className="px-lg-3 text-center m-0">
              <img
                className="me-1 align-baseline hw-15px"
                src="/assets/icons/tick-blue.png"
                alt="tick"
              />
              FACIAL RECOGNITION
            </p>
            <p className="px-lg-3 text-center m-0">
              <img
                className="me-1 align-baseline hw-15px"
                src="/assets/icons/tick-blue.png"
                alt="tick"
              />
              USER REGISTRATION
            </p>
            <p className="px-lg-3 text-center m-0">
              <img
                className="me-1 align-baseline hw-15px"
                src="/assets/icons/tick-blue.png"
                alt="tick"
              />
              ALBUM CREATION
            </p>
            <p className="px-lg-3 text-center m-0">
              <img
                className="me-1 align-baseline hw-15px"
                src="/assets/icons/tick-blue.png"
                alt="tick"
              />
              24x7 SUPPORT ON CALL
            </p>
          </div>
        </div>
        <div className="d-flex d-md-none justify-content-center unlimted-fr">
          All plans have unlimited facial recognition
        </div>
        {(pricingListData?.length > 0 && (
          <Slider
            {...{
              infinite: true,
              speed: 500,
              slidesToShow: 3,
              initialSlide: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
              swipe: false,
              arrows: true,
              nextArrow: <PricingRightArrow />,
              prevArrow: <PricingLeftArrow />,
              rows: 1,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    swipe: true,
                  },
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    swipe: true,
                  },
                },
                {
                  breakpoint: 993,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    swipe: true,
                  },
                },
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    swipe: true,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    swipe: true,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipe: true,
                  },
                },
              ],
              className: `pricing-item-wrapper ${
                userType == "USER" ? "user-plan" : ""
              }`,
            }}
          >
            {pricingListData.map((item, index) => (
              <PricingListItem key={index} item={item} />
            ))}
          </Slider>
        )) || (
          <div
            className="text-center py-5 font-bold"
            style={{
              fontSize: "20px",
            }}
          >
            No plans available in this category.
          </div>
        )}
      </div>

      <div className="contact-us">
        <span>For Cancellation, Downgrading Plan or Change in Tenure </span>
        <Link to="/helpdesk" target="_blank">
          Contact Us
        </Link>
      </div>

      <Spinner loading={plansLoading} />
    </div>
  );
};
