import CryptoJS from "crypto-js";

export const ENC_DEC_KEY = process.env.REACT_APP_LS_ENC_KEY;
const TRANSER_INFO_INTERVAL = 72 * 60 * 60 * 1000; // 72 hours

export const lsProxy = {
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      // do nothing
    }
  },
  getItem: (key) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  },
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      // do nothing
    }
  },
  clear: () => {
    try {
      localStorage.clear();
    } catch (e) {
      // do nothing
    }
  },
};

export const ssProxy = {
  setItem: (key, value) => {
    try {
      sessionStorage.setItem(key, value);
    } catch (e) {
      // do nothing
    }
  },
  getItem: (key) => {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      return null;
    }
  },
  removeItem: (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      // do nothing
    }
  },
  clear: () => {
    try {
      sessionStorage.clear();
    } catch (e) {
      // do nothing
    }
  },
};

export const shouldShowTransferInfo = (i) => {
  const lastShown = lsProxy.getItem("transferTS" + i);

  if (lastShown && Date.now() - lastShown < TRANSER_INFO_INTERVAL) return false;

  lsProxy.setItem("transferTS" + i, Date.now());
  return true;
};

export const addUserToLocalStorage = (user) => {
  let updatesUser = {
    ...user,
  };

  if (Array.isArray(user?.featureAccess)) {
    const encryptedFeatures = encryptData(user.featureAccess);

    updatesUser = {
      ...user,
      featureAccess: encryptedFeatures,
    };
  }

  lsProxy.setItem("user", JSON.stringify(updatesUser));
};

export const setLastUpdateTimeInLocalStorage = () => {
  lsProxy.setItem("lastUpdated", Date.now());
};

export const getUserAnalyticsLastUpdate = () => {
  return getUserAnalyticsFromLS()?.lastLCUpdatedAt || 0;
};

export const getUserSubscriptionLastUpdate = () => {
  return getUserSubscriptionData()?.lastLCUpdatedAt || 0;
};

export const addUserSubscriptionToLocalStorage = (subscription) => {
  const data = { ...(subscription || {}), lastLCUpdatedAt: Date.now() };
  lsProxy.setItem("subscription", encryptData(data));
  // setLastUpdateTimeInLocalStorage();
};

export const addUserAnalyticsToLocalStorage = (analytics) => {
  const data = { ...(analytics || {}), lastLCUpdatedAt: Date.now() };
  lsProxy.setItem("userAnalytics", encryptData(data));
  // setLastUpdateTimeInLocalStorage();
};

export const getUserAnalyticsFromLS = () => {
  const analyticsData = lsProxy.getItem("userAnalytics");

  if (analyticsData) {
    return decryptData(analyticsData);
  }
};

export const getUserSubscriptionData = () => {
  const subsData = lsProxy.getItem("subscription");

  if (subsData) {
    return decryptData(subsData);
  }
};

export const getLocalStorageUser = () => {
  const jsonUSER = lsProxy.getItem("user");
  if (jsonUSER) {
    return JSON.parse(jsonUSER);
  }
  return {};
};

export const encryptData = (data) => {
  const userFeaturesAccess = JSON.stringify(data, (key, value) =>
    value ? value : ""
  );
  return CryptoJS.AES.encrypt(userFeaturesAccess, ENC_DEC_KEY).toString();
};

export const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, ENC_DEC_KEY);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return err;
  }
};

export const getFeaturesAccessFromLocalStorage = () => {
  const user = getLocalStorageUser();
  if (user.featureAccess) {
    return decryptData(user.featureAccess);
  }
  return [];
};

export const groupHasImagesSelected = (groupId) => {
  const _data = sessionStorage.getItem(`downloadUrls-${groupId}`);

  if (_data) return JSON.parse(_data);

  return null;
};
