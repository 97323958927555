import { getLocalStorageUser } from "./localstorage";

/**
 * Use this when redirecting to the gallary route for the first time
 */
export function goToInitialGallaryRoute(groupId) {
  const { userType } = getLocalStorageUser();
  const isUser = userType === "USER";
  const defaultFolder = isUser ? "my-photos" : "all-photos";

  return `/gallery?groupId=${groupId}&folderId=${defaultFolder}&page=1`;
}

/**
 * Use this when tring to change any param in galary route.
 *
 * Just pass the params that you want to change and the second param is `params` that you get from useSearchParams hook
 *
 */
export function modifyGallaryRoute(
  { groupId, folderId, imageId, pageNo, videoId, isFolderView },
  params
) {
  let _groupId = groupId || params.get("groupId");

  if (!_groupId) return;

  if (isFolderView) return `/gallery/folders?groupId=${_groupId}`;

  let _folderId = folderId || params?.get?.("folderId");
  let _imageId = imageId || params?.get?.("imageId");
  let _pageNo = pageNo || params?.get?.("page");
  let _videoId = videoId || params?.get?.("videoId");

  let route = "/gallery?groupId=" + _groupId;

  if (_folderId) {
    route += "&folderId=" + _folderId;
  }
  if (_pageNo) {
    route += "&page=" + _pageNo;
  }
  if (_imageId) {
    route += "&imageId=" + _imageId;
  }
  if (_videoId) {
    route += "&videoId=" + _videoId;
  }

  return route;
}

export const modifyAnonymousGallaryRoute = (
  { anonymousUserId, groupId, folderId, imageId, videoId, pageNo },
  params
) => {
  let _groupId = groupId || params.get("groupId");
  if (!_groupId) return;
  let _anonymousUserId = anonymousUserId || params.get("anonymousUserId");
  let _folderId = folderId || params.get("folderId");
  let _imageId = imageId || params.get("imageId");
  let _videoId = videoId || params.get("videoId");

  let _pageNo = pageNo || params.get("page");

  let route = "/anonymous/gallery?groupId=" + _groupId;
  if (_anonymousUserId) {
    route += "&anonymousUserId=" + _anonymousUserId;
  }
  if (_folderId) {
    route += "&folderId=" + _folderId;
  }
  if (_pageNo) {
    route += "&page=" + _pageNo;
  }
  if (_imageId) {
    route += "&imageId=" + _imageId;
  }
  if (_videoId) {
    route += "&videoId=" + _videoId;
  }

  return route;
};
