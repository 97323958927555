import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { groupHasImagesSelected } from "utils/helpers/localstorage";
import SecondarySmallBtn from "views/components/button/SecondarySmallBtn";
import SecondarySmallBtnDropdown from "views/components/button/SecondarySmallBtnDropdown";

const Folder = ({ folder, onDownload, onRetry, groupId }) => {
  const isCompleted =
    folder?.progress >= folder?.totalPics && folder?.status !== "Empty";
  const folderStatus = isCompleted ? "Downloaded" : folder?.status;
  const errors = folder?.error?.length || 0;
  const duplicates = folder?.duplicateImage || 0;
  const success = folder?.progress - errors - duplicates;
  const { originalDownload } = useSelector(
    (state) => state.settings.generalSettings
  );

  const getFolderStatus = ({
    errors,
    isCompleted,
    folderStatus,
    onRetry,
    folder,
  }) => {
    if (folderStatus === "Empty") {
      return (
        <div
          className="uploadingStatus justify-content-start"
          style={{ width: "110px" }}
        >
          <span className="download-retry">Empty</span>{" "}
        </div>
      );
    } else if (errors > 0 && isCompleted) {
      return (
        <span onClick={() => onRetry(folder)} className="download-retry">
          Retry &#x21bb;
        </span>
      );
    } else {
      return (
        <div
          className="uploadingStatus align-items-center gap-2"
          style={{ width: "110px" }}
        >
          <span>{folderStatus}</span>
          {folderStatus === "Downloaded" ? (
            <span>
              <img
                height={15}
                src="/assets/icons/tick-blue.png"
                alt="download complete"
              />
            </span>
          ) : (
            <span className="unchecked ms-0"></span>
          )}
        </div>
      );
    }
  };

  return (
    <div className="folder_card">
      <div className="folder_card-left">
        <img src="/assets/images/icons/folder.png" alt="folder" width="65px" />
      </div>
      <div className="folder_card-center">
        <div>
          <span className="folder-name">
            {folder?.name || "No-Name"}
            {folder?.id === "selected-images"
              ? ` (${groupHasImagesSelected(groupId)?.length})`
              : ""}
          </span>
        </div>
        <ProgressBar
          now={
            folderStatus === "Empty"
              ? 100
              : Math.round((folder?.progress / folder?.totalPics) * 100)
          }
        />

        <div className={"totalPics" in (folder || {}) ? "" : "mt-3"}>
          {"totalPics" in folder && (
            <>
              <span style={{ fontSize: "13px" }}>
                {folder.progress + "/" + (folder?.totalPics || 0)}
              </span>
              <>
                {isCompleted && (
                  <>
                    <span className="download-status success">
                      {" "}
                      , {success} Success{" "}
                    </span>
                    <span className="download-status error">
                      {" "}
                      , {errors} Errors{" "}
                    </span>
                    <span className="download-status duplicate">
                      {" "}
                      , {duplicates} Duplicates
                    </span>
                  </>
                )}
              </>
            </>
          )}
        </div>
      </div>
      <div className="folder_card-right">
        {folder.status === "Download" ? (
          originalDownload ? (
            <SecondarySmallBtnDropdown
              title="Download"
              onClick={() => onDownload(folder)}
              onClick2={() => onDownload(folder, true)}
            />
          ) : (
            <SecondarySmallBtn
              title="Download"
              onClick={() => onDownload(folder)}
            />
          )
        ) : (
          <>
            {getFolderStatus({
              errors,
              isCompleted,
              onRetry,
              folder,
              folderStatus,
            })}
            {/* {errors > 0 && isCompleted ? (
              <span onClick={() => onRetry(folder)} className="download-retry">
                Retry &#x21bb;
              </span>
            ) : (
              <div className="uploadingStatus" style={{ width: "110px" }}>
                <span>{folderStatus}</span>
                {folderStatus === "Downloaded" ? (
                  <span className="checked"></span>
                ) : (
                  <span className="unchecked"></span>
                )}
              </div>
            )} */}
          </>
        )}
      </div>
    </div>
  );
};

export default Folder;
