import React from "react";
import css from "./Buttons.module.css";
import cx from "classnames";

/**
 * @typedef {object} SecondaryButtonProps
 * @property {string} title - button title
 * @property {function} onClick - button click handler
 * @property {string} type - button type
 * @property {string} className - button class name
 * @property {("xss"|"xs"|"sm"|"md")} [variant] - button variant
 * @property {boolean} [rounded] - button rounded
 * @property {boolean} [disabled] - button disabled
 * @property {string} btnTitle - button title
 * @param {SecondaryButtonProps}
 */
const SecondaryButton = ({
  onClick,
  type,
  title,
  children,
  className,
  btnTitle,
  variant = "xs",
  rounded = true,
  disabled = false,
}) => {
  return (
    <button
      type={type}
      title={btnTitle || title}
      className={cx(
        css.secondaryButton,
        className,
        css[variant],
        {
          [css.rounded]: rounded,
        },
        { [css.disabled]: disabled }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {title || children}
    </button>
  );
};
export default SecondaryButton;
