import { call, put, takeLatest } from "redux-saga/effects";
import { AXIOS } from "utils/setup/axios";
import { toast } from "react-toastify";
import { notificationsActions } from "redux-store/slices/notifications";
import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_GROUP,
  GET_GROUPWISE_NOTIFICATIONS,
} from "./saga-actions";

function getGroupWiseNotifications() {
  return AXIOS.get("/api/app/notification/list-groupwise");
}

function* getGroupWiseNotificationsGenerator() {
  try {
    yield put(notificationsActions.setInitialLoader(true));
    const response = yield call(getGroupWiseNotifications);
    yield put(
      notificationsActions.setGroupWiseNotifications(response.data.data?.groups)
    );
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    yield put(notificationsActions.setInitialLoader(false));
  }
}

function getAllNotifications({ page, groupId }) {
  return AXIOS.get("/api/app/notification/list", {
    params: {
      page,
      groupId,
      limit: 20,
    },
  });
}

function* getAllNotificationsGenerator({ payload }) {
  try {
    if (payload.page === 1)
      yield put(notificationsActions.setInitialLoader(true));
    else yield put(notificationsActions.setLoader(true));

    const response = yield call(getAllNotifications, payload);
    yield put(
      notificationsActions.setAllNotifications({
        ...response.data.data,
        page: payload.page,
      })
    );
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    yield put(notificationsActions.setInitialLoader(false));
    yield put(notificationsActions.setLoader(false));
  }
}

function* getAllNotificationGroupGenerator({ payload }) {
  try {
    if (payload.page === 1)
      yield put(notificationsActions.setInitialLoader(true));
    else yield put(notificationsActions.setLoader(true));

    const response = yield call(getAllNotifications, payload);
    yield put(
      notificationsActions.setAllNotificationsGroup({
        ...response.data.data,
        page: payload.page,
      })
    );
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    yield put(notificationsActions.setInitialLoader(false));
    yield put(notificationsActions.setLoader(false));
  }
}

export function* notificationsSaga() {
  yield takeLatest(
    GET_GROUPWISE_NOTIFICATIONS,
    getGroupWiseNotificationsGenerator
  );
  yield takeLatest(GET_ALL_NOTIFICATIONS, getAllNotificationsGenerator);
  yield takeLatest(
    GET_ALL_NOTIFICATIONS_GROUP,
    getAllNotificationGroupGenerator
  );
}
