import React from "react";
import css from "./FolderImages.module.css";
import cx from "classnames";

/**
 * @typedef {object} ProductSalesIconsProps
 * @property {boolean} authCode
 * @property {boolean} uploadedByCurrentUser
 * @property {("listings" | "fullsize")} src
 * @param {ProductSalesIconsProps} props
 */
const ProductSalesIcons = ({
  authCode,
  uploadedByCurrentUser,
  src = "listings",
}) => {
  return (
    <div className={css[src]}>
      <p className={cx(css.premiumBadge)}>
        <img
          className={css.icon}
          {...(uploadedByCurrentUser
            ? {
                src: "/assets/images/icons/my-upload.png",
                title: "This image was uploaded by you",
              }
            : authCode
            ? {
                src: "/assets/images/icons/purchased.png",
                title: "You have purchased this image",
              }
            : {
                src: "/assets/images/icons/premium-white.png",
                title: "This is a premium image",
              })}
          alt="premium"
        />
        <span className="text-nowrap font-10 font-md-13">
          {uploadedByCurrentUser
            ? "My Uploads"
            : authCode
            ? "Purchased"
            : "Premium"}
        </span>
      </p>
    </div>
  );
};

export default React.memo(ProductSalesIcons);
