import React from "react";
import { Card, Modal } from "react-bootstrap";

const SelectUserTypeModal = ({ selector, setSelector, setSearchParams }) => {
  return (
    <Modal
      centered
      show={selector}
      onHide={() => setSelector(false)}
      restoreFocus={false}
      className="select-usertype"
    >
      <Modal.Header className="mx-auto font-bold border-0" closeButton>
        <Modal.Title>Choose your profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 font-bold color-secondary">
        <Card
          onClick={() => {
            setSearchParams({ userType: "USER" });
            setSelector(false);
          }}
          className="mb-3 p-3 border-0 d-flex gap-3 flex-row align-items-center justify-content-center cursor-pointer"
        >
          <img src="/assets/images/auth/user.png" alt="personal user" />
          Personal User
        </Card>
        <Card
          onClick={() => {
            setSearchParams({ userType: "PHOTOGRAPHER" });
            setSelector(false);
          }}
          className="p-3 border-0 d-flex gap-3 flex-row align-items-center justify-content-center cursor-pointer"
        >
          <img src="/assets/images/auth/photographer.png" alt="photographer" />
          Photographer
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default SelectUserTypeModal;
