import React from "react";
import DefaultInput from "views/components/input/DefaultInput";
import CountryJson from "./CountryJson";

const MobileNoInput = ({
  name,
  placeholder,
  val,
  onChange,
  countryCode,
  setCountryCode,
  error,
  classes,
  inputClass,
  onKeyDown,
  allowOnlyPhone,
  disabled = false,
  type = "text",
  defaultEnabled = false,
}) => {
  const phoneNumberCheck =
    allowOnlyPhone &&
    (!isNaN(val) || (!isNaN(val.split("#")[0]) && !isNaN(val.split("#")[1])));

  return (
    <div
      className={
        val?.length >= 1 && phoneNumberCheck
          ? `showDropdown d-flex justify-content-end ${classes}`
          : `d-flex hideDropdown ${classes}`
      }
    >
      {/* <div> */}
      <div className="countryJson mx-1">
        {val?.length >= 1 && phoneNumberCheck && (
          <CountryJson
            setCountryCode={setCountryCode}
            countryCode={countryCode}
            disabled={disabled}
            defaultEnabled={defaultEnabled}
          />
        )}
      </div>
      <DefaultInput
        name={name}
        placeholder={placeholder}
        error={error}
        value={val}
        // setVal={setVal}
        // ref={inputRef}
        onChange={onChange}
        disabled={disabled}
        type={type}
        onKeyDown={onKeyDown}
        className={inputClass}
      />
      {/* </div> */}
    </div>
  );
};

export default MobileNoInput;
