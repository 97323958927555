import { useRef, useLayoutEffect, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { LOG_TYPE } from "redux-store/sagas/images";
import { IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS } from "redux-store/sagas/saga-actions";

const useImpressionAnalytics = ({ updatedImages, folderId }) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const toBeSentRef = useRef([]);
  const successRef = useRef({});
  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: "0px",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (toBeSentRef.current?.length > 0) {
        dispatch({
          type: IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
          payload: {
            imageIds: toBeSentRef.current,
            groupId: params.get("groupId"),
            logType: LOG_TYPE.IMPRESSION,
          },
        });
        toBeSentRef.current?.forEach((id) => {
          successRef.current[id] = true;
        });

        toBeSentRef.current = [];
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * This function is an handler which will remove the images from the DOM
   * If they are not in the viewport
   * And it also add backs the images once they are in the viewport again
   */
  function handleIntersection(entries) {
    const imageIds = [];
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const id = entry.target.id.split("-")[1];
        imageIds.push(id);
      }
    });

    const _toBeSentIds = imageIds.filter((id) => !successRef.current[id]);

    if (_toBeSentIds.length > 0) {
      toBeSentRef.current = Array.from(
        new Set([...toBeSentRef.current, ..._toBeSentIds])
      );
    }

    // if (toBeSentIds.length > 0) {
    //   dispatch({
    //     type: IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
    //     payload: {
    //       imageIds: toBeSentIds,
    //       groupId: params.get("groupId"),
    //       logType: LOG_TYPE.IMPRESSION,
    //     },
    //   });
    //   toBeSentIds.forEach((id) => {
    //     successRef.current[id] = true;
    //   });
    // }
  }

  useLayoutEffect(() => {
    if (updatedImages?.length > 0) {
      updatedImages.forEach((image) => {
        const _id =
          folderId === "my-photos" ||
          folderId === "purchased" ||
          folderId === "deleted"
            ? image?.imageId
            : image?._id;

        const target = document.querySelector("#image_container-" + _id);
        if (target) {
          observer.observe(target);
        }
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [updatedImages?.length]);
};

export default useImpressionAnalytics;
