import React, { useState } from "react";
import MobileNoInput from "./MobileNoInput";
import DefaultInput from "../../components/input/DefaultInput";
import { useSelector } from "react-redux";

const RenderInputField = ({
  name,
  placeholder,
  errors,
  setErrors,
  countryCode,
  setCountryCode,
}) => {
  const [val, setVal] = useState("");
  const { user } = useSelector((state) => state.auth);

  if (placeholder.includes("Mobile")) {
    return (
      <MobileNoInput
        name={name}
        placeholder={placeholder}
        disabled={user?.loginType === "PHONE"}
        val={val || user?.phoneNumber || ""}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        onChange={(e) => {
          setVal(e.target.value);
          setErrors((prev) => prev.filter((error) => error.field !== name));
        }}
        error={errors.find((error) => error.field === name)?.message}
        allowOnlyPhone
        type="number"
        onKeyDown={(e) => {
          // reject [.,-,e,+] keys
          if (["e", ".", "-", "+"].includes(e.key)) e.preventDefault();
        }}
      />
    );
  } else if (placeholder.includes("Email")) {
    return (
      <DefaultInput
        name={name}
        placeholder={placeholder}
        disabled={user?.loginType === "EMAIL"}
        {...(user?.loginType === "EMAIL" && {
          value: user?.email || "",
        })}
        onChange={(e) =>
          e.target.value &&
          setErrors((prev) => prev.filter((error) => error.field !== name))
        }
        error={errors.find((error) => error.field === name)?.message}
      />
    );
  } else {
    return (
      <DefaultInput
        name={name}
        placeholder={placeholder}
        onChange={(e) =>
          e.target.value &&
          setErrors((prev) => prev?.filter((error) => error?.field !== name))
        }
        error={errors?.find((error) => error?.field === name)?.message}
      />
    );
  }
};

export default RenderInputField;
