const usePagination = ({ totalCount, perPageCount = 500, currentPage }) => {
  // console.log(currentPage, totalCount);
  if (typeof totalCount !== "number") return {};
  const totalPages = Math.ceil(totalCount / perPageCount);

  const pagesArray = [];

  if (totalPages <= 7) {
    for (let i = 0; i < totalPages; i++) {
      pagesArray.push(i + 1);
    }

    return {
      totalPages,
      pagesArray,
    };
  }

  if (currentPage === 1 || currentPage === 2) {
    return {
      totalPages,
      pagesArray: [1, 2, 3, "...", totalPages],
    };
  }

  if (currentPage === 3) {
    return {
      totalPages,
      pagesArray: [1, 2, 3, 4, "...", totalPages],
    };
  }

  if (currentPage === 4) {
    return {
      totalPages,
      pagesArray: [1, 2, 3, 4, 5, "...", totalPages],
    };
  }

  const pageDiffFromLast = totalPages - currentPage;

  if (pageDiffFromLast === 0 || pageDiffFromLast === 1) {
    return {
      totalPages,
      pagesArray: [1, "...", totalPages - 2, totalPages - 1, totalPages],
    };
  }

  if (pageDiffFromLast === 2) {
    return {
      totalPages,
      pagesArray: [
        1,
        "...",
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ],
    };
  }

  if (pageDiffFromLast === 3) {
    return {
      totalPages,
      pagesArray: [
        1,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ],
    };
  }

  pagesArray[0] = 1;
  pagesArray[6] = totalPages;

  if (currentPage - 1 >= 4) {
    pagesArray[1] = "...";
  } else {
    pagesArray[1] = 2;
  }

  if (totalPages - currentPage >= 4) {
    pagesArray[5] = "...";
  } else {
    pagesArray[5] = totalPages - 1;
  }

  pagesArray[2] = currentPage - 1;
  pagesArray[3] = currentPage;
  pagesArray[4] = currentPage + 1;

  return {
    totalPages,
    pagesArray,
  };
};

export default usePagination;
