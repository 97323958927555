import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import FolderImages from "./components/FolderImages";
import GalleryHeaderButton from "./components/GalleryHeaderButton";
import useUploadStatus from "hooks/useUploadingStatus";
import {
  clearFolderImages,
  setImagesLoading,
} from "redux-store/slices/folderImages";
import { modifyGallaryRoute } from "utils/helpers/routes";
import FolderVideos from "./components/FolderVideos";
import { getLocalStorageUser, lsProxy } from "utils/helpers/localstorage";
import { GET_CART } from "redux-store/sagas/saga-actions";
import { SocketContext } from "context/socket";
import GalleryUploader from "./components/GalleryUploader";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
const FoldersView = React.lazy(() =>
  import("./components/folder-view/FoldersView")
);

const GallaryView = ({ isFolderView }) => {
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const dispatch = useDispatch();
  const scrollRef = useRef();
  const uploderRef = useRef();

  const [showArrows, setShowArrows] = useState({ left: false, right: false });
  // can move this to renderGalleryImages
  const [hasUploadPermission, setHasUploadPermission] = useState(false);
  const [getEventStatusOfUploading, setGetEventSttausOfUploading] =
    useState(true);

  const { totalCount } = useSelector((state) => state.folderImages);
  const {
    groupDetails,
    designSettings,
    privacySettings: { isDeletedFolderHidden },
  } = useSelector((state) => state.settings);
  const { colorMode } = designSettings;
  const {
    isAdmin,
    canAnyoneUploadPhotos,
    isUpload,
    favoritePicsCount,
    folders,
    isForProductSale,
    paidPicsCount,
    allVideoCount,
    deletePicsCount,
  } = groupDetails || {};

  const folderId = params.get("folderId");
  const groupId = params.get("groupId");
  const { userType } = getLocalStorageUser();
  const { isParallelyUploading } = useUploadStatus();
  const isUser = userType === "USER";

  useEffect(() => {
    return () => {
      dispatch(clearFolderImages());
    };
  }, []);

  useEffect(() => {
    if (isForProductSale) {
      dispatch({
        type: GET_CART,
        payload: { groupId },
      });
    }
  }, [groupId, isForProductSale]);

  useEffect(() => {
    const result = async () => {
      const res = await isParallelyUploading();
      if (res) lsProxy.setItem("isUploading", true);
    };

    result();
  }, [getEventStatusOfUploading]);

  useEffect(() => {
    if (canAnyoneUploadPhotos) {
      setHasUploadPermission(true);
    } else {
      if (isUpload) {
        setHasUploadPermission(true);
      } else {
        setHasUploadPermission(false);
      }
    }
  }, [canAnyoneUploadPhotos, isUpload]);

  useEffect(() => {
    return () => {
      dispatch(setImagesLoading(true));
    };
  }, [folderId]);

  useEffect(() => {
    socket?.on("uploadPermissionInfo", (data) => {
      if (data?.status === 206) {
        setHasUploadPermission(false);
      } else if (data?.status === 207) {
        setHasUploadPermission(true);
      }
    });
  }, [socket]);

  useEffect(() => {
    if (isFolderView) return;

    const ScrollWidth = scrollRef.current.childNodes[1]?.scrollWidth;
    const ClientWidth = scrollRef.current.childNodes[1]?.clientWidth;
    if (ClientWidth <= ScrollWidth) {
      setShowArrows((state) => ({ ...state, right: true }));
    }
  }, [folders, isFolderView]);

  useEffect(() => {
    if (isFolderView) return;

    const scrollEl = scrollRef.current.childNodes[1];
    const interval = setInterval(() => {
      checkArrows(scrollEl, showArrows, setShowArrows);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleShow = uploderRef.current?.handleShow;

  return (
    <div className="gallaryContainer" id="gallery-folders-list">
      <div>
        {!isFolderView && (
          <div ref={scrollRef} className="gallary-header-container">
            <GalleryHeaderButton
              scroll={scroll}
              scrollRef={scrollRef}
              setShowArrows={setShowArrows}
              showArrows={showArrows}
              type="left"
              colorMode={colorMode}
            />
            <Tabs
              activeKey={folderId}
              className="d-flex flex-nowrap folder"
              onSelect={(folderId) => {
                navigate(modifyGallaryRoute({ folderId, pageNo: 1 }, params));
              }}
            >
              {isUser && (
                <Tab
                  eventKey="my-photos"
                  title={
                    <span className="group-top-text-wrapper font-14">
                      {folderId === "my-photos" && (
                        <span className="group-top-text">
                          {totalCount}&nbsp;Photos
                        </span>
                      )}
                      <span>My Photos</span>
                    </span>
                  }
                />
              )}

              {isForProductSale && paidPicsCount > 0 && (
                <Tab
                  eventKey="purchased"
                  title={
                    <span className="group-top-text-wrapper font-14">
                      {folderId === "purchased" && (
                        <span className="group-top-text">
                          {`${totalCount} Photos`}
                        </span>
                      )}
                      <span>Purchased</span>
                    </span>
                  }
                />
              )}

              <Tab
                eventKey="all-photos"
                title={
                  <span className="group-top-text-wrapper font-14">
                    {folderId === "all-photos" && (
                      <span className="group-top-text">
                        {totalCount}&nbsp;Photos
                      </span>
                    )}
                    <span>All Photos</span>
                  </span>
                }
              />

              {allVideoCount > 0 && (
                <Tab
                  eventKey="videos"
                  title={
                    <span className="group-top-text-wrapper font-14">
                      {folderId === "videos" && (
                        <span className="group-top-text">
                          {allVideoCount} Videos
                        </span>
                      )}
                      <span>All Videos</span>
                    </span>
                  }
                />
              )}

              {favoritePicsCount > 0 && (
                <Tab
                  eventKey="favorites"
                  title={
                    <span className="group-top-text-wrapper font-14">
                      {folderId === "favorites" && (
                        <span className="group-top-text">
                          {`${totalCount} Photos`}
                        </span>
                      )}
                      <span>Favorites</span>
                    </span>
                  }
                />
              )}

              {(isAdmin || !isDeletedFolderHidden) && deletePicsCount > 0 && (
                <Tab
                  eventKey="deleted"
                  title={
                    <span className="group-top-text-wrapper font-14">
                      {folderId === "deleted" && (
                        <span className="group-top-text">
                          {`${totalCount} Photos`}
                        </span>
                      )}
                      <span>Deleted</span>
                    </span>
                  }
                />
              )}

              {folders?.length > 0 &&
                folders?.map((folder) => {
                  return (
                    <Tab
                      eventKey={folder?.groupFolder}
                      key={folder?.groupFolder}
                      title={
                        folder.folderPrefix === "null" ? (
                          <span> No name </span>
                        ) : folder.groupFolder === folderId ? (
                          <span className="group-top-text-wrapper font-14">
                            <span className="group-top-text">
                              {`${totalCount} Photos`}
                            </span>
                            <span>{folder.folderPrefix}</span>
                          </span>
                        ) : (
                          <span className="group-top-text-wrapper font-14">
                            {folder.folderPrefix}
                          </span>
                        )
                      }
                    />
                  );
                })}
            </Tabs>
            <GalleryHeaderButton
              scroll={scroll}
              scrollRef={scrollRef}
              setShowArrows={setShowArrows}
              showArrows={showArrows}
              type="right"
              colorMode={colorMode}
            />
          </div>
        )}

        {renderGalleryImages(
          folderId,
          groupId,
          handleShow,
          hasUploadPermission,
          isUser,
          isFolderView
        )}
      </div>

      <GalleryUploader
        ref={uploderRef}
        toggleGetEventStatusOfUploading={() =>
          setGetEventSttausOfUploading((v) => !v)
        }
      />
    </div>
  );
};

export default GallaryView;

const renderGalleryImages = (
  fldrID,
  groupId,
  handleShow,
  hasUploadPermission,
  isUser,
  isFolderView
) => {
  return (
    <>
      {isFolderView ? (
        <React.Suspense fallback={<SuspenseLoader />}>
          <FoldersView
            groupId={groupId}
            handleShow={handleShow}
            hasUploadPermission={hasUploadPermission}
          />
        </React.Suspense>
      ) : fldrID === "videos" ? (
        <FolderVideos
          key={fldrID} // If you find this unexpected (Please dont remove it.It is used here to remount FolderImages component)
          folderId={fldrID}
          handleShow={handleShow}
          hasUploadPermission={hasUploadPermission}
        />
      ) : (
        <FolderImages
          key={fldrID} // If you find this unexpected (Please dont remove it.It is used here to remount FolderImages component)
          handleShow={handleShow}
          hasUploadPermission={hasUploadPermission}
          folderId={fldrID || (isUser ? "my-photos" : "all-photos")}
        />
      )}
    </>
  );
};

export const scroll = (
  threshold,
  direction,
  scrollEl,
  showArrows,
  setShowArrows
) => {
  const distance = scrollEl?.clientWidth * threshold;
  scrollEl?.scrollBy({
    left: direction * distance,
    behavior: "smooth",
  });

  const timer = setTimeout(() => {
    checkArrows(scrollEl, showArrows, setShowArrows);
  }, 750);

  return () => {
    clearTimeout(timer);
  };
};

export const checkArrows = (scrollEl, showArrows, setShowArrows) => {
  if (
    scrollEl?.scrollLeft >=
    scrollEl?.scrollWidth - scrollEl?.clientWidth - 10
  ) {
    if (showArrows.right === true) {
      setShowArrows((state) => ({ ...state, right: false }));
    }
  } else if (showArrows.right === false) {
    setShowArrows((state) => ({ ...state, right: true }));
  }
  if (scrollEl?.scrollLeft <= 10) {
    if (showArrows.left === true) {
      setShowArrows((state) => ({ ...state, left: false }));
    }
  } else if (showArrows.left === false) {
    setShowArrows((state) => ({ ...state, left: true }));
  }
};
