import React, { useEffect } from "react";

const ButtonInput = (props) => {
  const [value, setValue] = React.useState("");
  useEffect(() => {
    props?.setUrl(value);
  }, [value]);
  return (
    <div className="buttonInput position-relative">
      <input
        type={props.type}
        value={props.value}
        onChange={(e) => setValue(e.target?.value || "")}
        placeholder={props.placeholder}
        className="font-bold blue-text font-15"
      />
      {props.imgSrc && (
        <img
          src={props.imgSrc}
          alt="input icon"
          className="button-input-icon"
        />
      )}
      <div className="btnInputIcon"></div>
      <button
        className="btnInputButton bold-font font-15"
        onClick={() => props.onClick(value)}
      >
        Submit
      </button>
    </div>
  );
};
export default ButtonInput;
