import React from "react";
import { Link } from "react-router-dom";
const PrimaryButton = ({
  onClick,
  className,
  title,
  subText,
  isButton = false,
}) => {
  return (
    <>
      {isButton && (
        <button
          onClick={onClick}
          className={`primaryButton max-180 ${className}`}
        >
          <div className="lh-1">
            <p className="m-0">{title}</p>
            {subText && <span style={{ fontSize: "11px" }}>{subText}</span>}
          </div>
        </button>
      )}
      {!isButton && (
        <Link
          className={`primaryButton max-180 ${className}`}
          to={onClick ? onClick : ""}
        >
          {title}
        </Link>
      )}
    </>
  );
};
export default PrimaryButton;
