import React, { useState } from "react";
import { Outlet, Navigate, useSearchParams } from "react-router-dom";
import DefaultHeader from "../components/header/DefaultHeader";
import ProtectedRoute from "./ProtectedRoute";
import useAuth from "hooks/useAuth";
import PrivacySecuritySidebar from "views/privacySecurity/PrivacySecuritySidebar";

const PrivacySecurityRoute = () => {
  const [params] = useSearchParams();
  const [goBackLoc, setGoBackLoc] = useState("/gallery");

  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <ProtectedRoute>
      <div className="settingsRoute">
        <DefaultHeader
          goBack={
            params.get("groupId")
              ? `${goBackLoc}?groupId=${params.get("groupId")}`
              : `${goBackLoc}`
          }
        />
        <div className="d-flex">
          <PrivacySecuritySidebar />
          <div className="rightSide">
            <Outlet context={[goBackLoc, setGoBackLoc]} />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default PrivacySecurityRoute;
