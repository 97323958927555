import React from "react";
import { Container } from "react-bootstrap";

const ApplicationLink = () => {
  const storePhoto = [
    {
      name: "App Store",
      image: "/assets/images/landing/appstore-badge.png",
      link: "https://apps.apple.com/app/kwikpic-smart-photo-sharing/id1635944378",
    },
    {
      name: "Play Store",
      image: "/assets/images/landing/google-play-badge-logo.png",
      link: "https://play.google.com/store/apps/details?id=com.kwicpic",
    },
  ];

  return (
    <div className="introduce-bg-color">
      <Container className="applicaiton-link">
        <div className="try-app bottom-margin-32">
          Try the Kwikpic App for Free!
        </div>
        <div className="download-now mb-3">Download Now!</div>
        <div className="app-links">
          {storePhoto.map((store, index) => (
            <div className="play-store" key={index}>
              <img
                className="cursor-pointer"
                src={store.image}
                alt={store.name}
                onClick={() => {
                  window.open(store.link, "_blank");
                }}
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ApplicationLink;
