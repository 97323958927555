import React from "react";
import SecondarySmallBtn from "../../components/button/SecondarySmallBtn";
const SettingPageHeading = ({
  goBack,
  title,
  participantsLength,
  subTitle,
  btnTitle,
  btnTitle2,
  btnThree,
  btnClass,
  btnClass2,
  btnGrpClass,
  onClick,
  onClick2,
  showFirst = true,
  showSecond = true,
  className = "",
}) => {
  return (
    <div
      className={`settingPageHeading d-flex justify-content-between align-items-start ${className}`}
    >
      <div>
        <h2 className="thick-font d-flex align-items-center">
          {goBack && (
            <img
              src="/assets/images/icons/back-alt.png"
              alt="logo"
              className="back-button me-2 cursor-pointer"
              onClick={goBack}
            />
          )}
          {title}{" "}
          {title.includes("Participants") && <>({participantsLength})</>}
        </h2>
        {subTitle && (
          <p className="thick-font d-flex align-items-center">{subTitle}</p>
        )}
      </div>
      <div
        className={`d-flex align-items-center justify-content-end flex-wrap flex-lg-nowrap ${
          btnGrpClass ?? ""
        }`}
        style={{
          gap: "10px",
        }}
      >
        {btnThree && btnThree}
        {showSecond && btnTitle2 && (
          <SecondarySmallBtn
            title={btnTitle2}
            onClick={onClick2}
            className={btnClass2 ?? ""}
          />
        )}
        {showFirst && btnTitle && (
          <SecondarySmallBtn
            onClick={onClick}
            title={btnTitle}
            className={btnClass ?? ""}
          />
        )}
      </div>
    </div>
  );
};

export default SettingPageHeading;
