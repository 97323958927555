import React from "react";
import Backdrop from "views/components/modals/components/Backdrop";
import ConfirmationModal from "views/components/modals/ConfirmationModal";

const NonChromeErrorModal = ({ show, hide }) => {
  return (
    <>
      {show && <Backdrop onClick={hide} />}
      <ConfirmationModal
        show={show}
        onCancel={hide}
        className="p-4"
        footer={false}
      >
        <h2 className="font-24 color-primary">Browser Warning!</h2>
        <h3 className="font-20 color-primary mb-3">
          You might get errors while uploading from this browser.
        </h3>
        <h3 className="font-18">
          We recommend using Google Chrome for best experience{" "}
          <img
            src="/assets/images/icons/chrome.png"
            alt="chrome icon"
            className="hw-15px"
          />
        </h3>
      </ConfirmationModal>
    </>
  );
};

export default NonChromeErrorModal;
