import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import ApplicationLink from "./components/ApplicationLink";
import CarouselComponent from "./components/CarouselComponent";
import FaceCounts from "./components/FaceCounts";

const Landing = () => {
  const storePhoto = [
    {
      name: "App Store",
      image: "/assets/images/landing/appstore-badge.png",
      link: "https://apps.apple.com/app/kwikpic-smart-photo-sharing/id1635944378",
    },
    {
      name: "Play Store",
      image: "/assets/images/landing/google-play-badge-logo.png",
      link: "https://play.google.com/store/apps/details?id=com.kwicpic",
    },
  ];

  const countsCard = [
    {
      image: "/assets/images/landing/face-detection2.png",
      count: "200M",
      description: "Total Faces Scanned",
    },
    {
      image: "/assets/images/landing/photos-delivered.png",
      count: "70M",
      description: "Photos Delivered to Users",
    },
    {
      image: "/assets/images/landing/accuracy.png",
      count: "99.9%",
      description: "Face Recognition Accuracy",
    },
  ];

  const getStarted = [
    {
      icon: "/assets/images/landing/face-recognition.png",
      name: "Sign Up",
      description:
        "Sign up using phone number or email ID and register your face by clicking a selfie",
    },
    {
      icon: "/assets/images/landing/calendar1.png",
      name: "Join and Create Groups",
      description:
        "Join and create private or public event groups and upload photos to share them with people present there!",
    },
    {
      icon: "/assets/images/landing/photos1.png",
      name: "Share Memories",
      description:
        "Anecdotes are sweeter when they’re shared! Upload photos from multiple sources to take a trip down memory lane with people you’ve experienced them with.",
    },
  ];

  const chooseUs = [
    {
      header: "Facial Recognition",
      image: "/assets/images/landing/face-detection2.png",
      smallDescription:
        "Kwikpic uses facial recognition to find photos of just you!",
      description:
        "Kwikpic uses facial recognition to find photos of just you! It curates individual collections of people present in group photos to give your a smart gallery",
    },
    {
      header: "Quality Retention",
      image: "/assets/images/landing/quality-retention.png",
      smallDescription: "No quality loss while sharing and downloading photos",
      description:
        "No quality loss while sharing and downloading photos. Download large size images with no-visual quality loss unlike other platforms.",
    },
    {
      header: "Unlimited Event Groups",
      image: "/assets/images/landing/unlimitedgroupevents.png",
      smallDescription:
        "You can create multiple event groups for easy browsing",
      description:
        "You can create multiple event groups for easy browsing. Store photos like an event directory for quick access and sharing.",
    },
    {
      header: "One Shot Upload",
      image: "/assets/images/landing/oneshotupload.png",
      smallDescription: "Upload multiple folders at once, hassel free!",
      description:
        "Upload multiple folders at once, hassel free! Sub-folders inside parent folder will be created automatically to form the entire event directory at one go!",
    },
  ];
  return (
    <>
      <section id="introduce">
        <div className="introduce-bg-color">
          <Container>
            <Row className="g-2 align-items-center">
              <Col lg="6" md="12" sm="12">
                <div className="introduce-info">
                  <div className="title title-text">
                    Introducing the <br />
                    All-New Kwikpic App
                  </div>
                  <img
                    className="under-line"
                    src="/assets/images/landing/yellow-divider.png"
                    alt="Yellow-divider"
                  />
                  <div className="description">
                    A photo sharing app powered by AI that helps you smartly
                    share, find and interact with your photos.
                  </div>
                  <div className="app-links">
                    {storePhoto.map((store, index) => (
                      <div className="play-store" key={index}>
                        <img
                          className="cursor-pointer"
                          src={store.image}
                          alt={store.name}
                          onClick={() => {
                            window.open(store.link, "_blank");
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
              <Col lg="6" md="12" sm="12">
                {/* intentionally passing wrong image sizes, so to trick browser into loading 1920w image for ss < 1920px */}
                <img
                  className="introduce-info-image"
                  srcSet="/assets/images/landing/introduce-img-mob.png 1920w, /assets/images/landing/introduce-img.png 2560w"
                  sizes="(min-width: 1921px) 2560px, 100vw"
                  src="/assets/images/landing/introduce-img.png"
                  alt="Marriage Event"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section id="community">
        <>
          <div className="community">
            <Container>
              <div className="small-blue-header">JOIN OUR COMMUNITY</div>
              <div className="main-dark-header">
                Create your own AI-powered Photo Galleries
              </div>
            </Container>
            <img
              className="under-line"
              src="/assets/images/landing/yellow-divider.png"
              alt="Yellow-divider"
            />
            <div className="community-img"></div>
            <div className="community-info">
              <Container>
                You can create a group on the Kwikpic app for every occasion.
                Just dump all the photos, and it will intelligently detect your
                friends and family using face recognition.
              </Container>
            </div>
          </div>
        </>
      </section>
      <section id="counts">
        <Container>
          <Row className="g-2 align-items-center card-main">
            {countsCard.map((info, index) => (
              <FaceCounts info={info} key={index} />
            ))}
          </Row>
        </Container>
      </section>
      <div id="working">
        <>
          <Row className="g-2 align-items-center pb-3">
            <Col lg="6" md="12" sm="12">
              <div className="get-started-img"></div>
            </Col>
            <Col lg="6" md="12" sm="12" className="mt-5">
              <Container>
                <div className="get-started-info">
                  <div className="small-blue-header">HOW KWIKPIC WORKS?</div>
                  <div className="main-dark-header">
                    Get Started with Kwikpic
                  </div>
                  <img
                    className="under-line"
                    src="/assets/images/landing/yellow-divider.png"
                    alt="Yellow-divider"
                  />
                  <Row className="get-started-description">
                    {getStarted.map((ele, index) => (
                      <div className="box-info" key={index}>
                        <Col lg="6" md="12" sm="12" className="icon">
                          <img src={ele.icon} alt={ele.icon} />
                        </Col>
                        <Col lg="6" md="12" sm="12" className="info">
                          <div className="header">{ele.name}</div>
                          <div className="description">{ele.description}</div>
                        </Col>
                      </div>
                    ))}
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
          <Container>
            <Row className="g-2 align-items-center mt-5 d-flex flex-wrap-reverse">
              <Col lg="6" md="12" sm="12" className="mt-5">
                <div className="photograper-container">
                  <div className="small-blue-header">FOR OUR PROFESSIONALS</div>
                  <div className="main-dark-header">
                    Smartly Deliver Photos <br /> to your Clients
                  </div>
                  <img
                    className="under-line"
                    src="/assets/images/landing/yellow-divider.png"
                    alt="Yellow-divider"
                  />
                  <div className="get-started-description mb-4">
                    <div className="mb-4">
                      Clicking photos is one half of the task. The second half
                      is delivering them to your clients. Choose the modern way
                      of delivering photos smartly using AI.
                    </div>
                    <div className="">
                      With our paid plans, unlock Kwikpic s best features to
                      grow your brand and customer reach, choose from a range of
                      gallery templates to best represent your style, get
                      different download settings and much more!
                    </div>
                  </div>
                  <div className="know-more ">
                    <Link
                      to="/landing/photographer-dashboard"
                      className="small-blue-header"
                    >
                      <img
                        className="arrow-right"
                        src="/assets/images/icons/Vector104.svg"
                        alt=""
                      />
                      Know More
                    </Link>
                  </div>
                </div>
              </Col>
              <Col
                lg="12"
                md="12"
                sm="12"
                className="photograper-background-top text-center"
              >
                <img
                  className="photographer-text-image"
                  srcSet="/assets/images/landing/photographers-strip-1920w.png 1920w, /assets/images/landing/photographers-strip.png 2560w"
                  sizes="(min-width: 1921px) 2560px, 100vw"
                  src="/assets/images/landing/photographers-strip.png"
                  alt="photographers"
                />
              </Col>
              <Col lg="6" md="12" sm="12" className="mt-2 text-center">
                <img
                  className="photographer-image"
                  srcSet="/assets/images/landing/deliver-photos-1920w.png 1920w, /assets/images/landing/deliver-photos.png 2560w"
                  sizes="(min-width: 1921px) 2560px, 100vw"
                  src="/assets/images/landing/deliver-photos.png"
                  alt="deliver photos"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="photograper-background-bottom">
              <Col lg="12" md="12" sm="12">
                <img
                  className="photographer-text-image"
                  srcSet="/assets/images/landing/photographers-strip-1920w.png 1920w, /assets/images/landing/photographers-strip.png 2560w"
                  sizes="(min-width: 1921px) 2560px, 100vw"
                  src="/assets/images/landing/photographers-strip.png"
                  alt="photographers"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="g-2 align-items-center mt-5">
              <Col lg="6" md="12" sm="12">
                <div className="choose-us-img">
                  <img
                    srcSet="/assets/images/landing/segregate-photos-1920w.png 1920w, /assets/images/landing/segregate-photos.png 2560w"
                    sizes="(min-width: 1921px) 2560px, 100vw"
                    src="/assets/images/landing/segregate-photos.png"
                    alt="segregate"
                  />
                </div>
                <div className="small-blue-header">
                  <a href="">WHY CHOOSE US?</a>
                </div>
                <div className="main-dark-header text-header">
                  Kwikpic segregates thousands of photographs from an event so
                  you dont have to!
                </div>
                <img
                  className="under-line"
                  src="/assets/images/landing/yellow-divider.png"
                  alt="Yellow-divider"
                />
              </Col>
              <Col lg="6" md="12" sm="12" className="desktop-box">
                <Row className="g-0 align-items-center">
                  {chooseUs.map((info, index) => (
                    <Col
                      lg="6"
                      md="6"
                      sm="6"
                      className="child-selection"
                      key={index}
                    >
                      <div className="face-recognition text-start">
                        <div className="face-recognition-header">
                          {info.header}
                        </div>
                        <div className="face-recognition-description">
                          {info.smallDescription}
                        </div>
                        <div className="face-recognition-detail">
                          <div className="face-icon mb-4">
                            <img src={info.image} alt={info.image} />
                          </div>
                          <div className="face-recognition-header mb-2">
                            {info.header}
                          </div>
                          <div className="face-recognition-description mb-2">
                            {info.description}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              {/* responsive mobile part */}
              <div className="landing-carousel">
                <div className="carousel-dark-bg"></div>
                <CarouselComponent chooseUs={chooseUs} />
              </div>
            </Row>
          </Container>
        </>
      </div>
      <div id="celebration pt-0">
        <Container>
          <div className="every-where">
            <div className="main-dark-header mb-3">Kwikpic is Everywhere!</div>
            <div className="sharing">
              Sharing and celebrating your memories everyday
            </div>
            <img
              className="under-line"
              src="/assets/images/landing/yellow-divider.png"
              alt="Yellow-divider"
            />
            <div className="celebration-img"></div>
          </div>
        </Container>
      </div>
      <ApplicationLink />
    </>
  );
};

export default Landing;
