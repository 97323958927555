import React from "react";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuDrawer from "views/components/sidebar/MenuDrawer";

export default function Anonymoushead({ goBack }) {
  const {
    anonymousUser: { user },
  } = useSelector((state) => state.anonymous);
  const navigate = useNavigate();

  const { avatar } = user || {};

  const userName = "Anonymous User";

  return (
    <div className="defaultHeader anonymous">
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container className="position-relative mw-100">
          <div className="d-flex">
            {goBack && (
              <Navbar.Brand
                className="back-button-wrapper cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img
                  width="16px"
                  src="/assets/images/icons/back-arrow.png"
                  alt="logo"
                  className="back-button"
                />
              </Navbar.Brand>
            )}
            <Navbar.Brand as={Link} to="/" className="p-0">
              <img
                src="/assets/images/global-images/logo.png"
                alt="logo"
                className="logo logo-dark"
              />
              <img
                src="/assets/images/global-images/logo-white.png"
                alt="logo"
                className="logo logo-white"
              />
            </Navbar.Brand>
          </div>
          <MenuDrawer avatar={avatar} isAnonymous={true} className="ms-auto" />
          <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
            <Nav>
              <NavDropdown
                align="end"
                title={
                  <div className="header-user">
                    <div className="header-thumb">
                      <img
                        className="thumbnail-image object-fit-cover"
                        src={avatar || "/assets/images/icons/user.png"}
                        alt="user pic"
                      />
                    </div>
                    {userName}
                    <img
                      className="menu-arrow"
                      src="/assets/images/icons/arrow-down.png"
                      alt="user pic"
                    />
                  </div>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/helpdesk" target="_blank">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/infoq.png"
                    alt="user pic"
                  />
                  Help & Support
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/youtube-tut.png"
                    alt="tutorial"
                  />
                  Tutorial
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/landing/aboutus">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/info.png"
                    alt="user pic"
                  />
                  About
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
