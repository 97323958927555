import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Feature } from "views/ads";
import css from "../index.module.css";

const FeaturesGroup = ({ features }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);

  const tryNow = () => {
    navigate("/auth/login");
  };

  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h2 className="font-24 font-bold text-center mb-4">Features</h2>
          <img
            src="/assets/images/landing/yellow-divider.png"
            alt="yellow line"
            className="text-center d-block mx-auto"
          />
          {features.slice(0, 5).map((feature, index) => (
            <Feature
              key={index}
              {...feature}
              reverse={index % 2 === 0 ? false : true}
              className={css.feature}
            >
              {feature.description}
            </Feature>
          ))}
          <Accordion>
            <Accordion.Item eventKey="0" className="border-0">
              <Accordion.Button
                onClick={() => setExpanded(!expanded)}
                className="shadow-none font-bold color-secondary justify-content-center font-18"
              >
                {expanded ? "See Less" : "See More"}
              </Accordion.Button>
              <Accordion.Body>
                {features.slice(5).map((feature, index) => (
                  <Feature
                    key={index}
                    {...feature}
                    reverse={index % 2 === 0 ? true : false}
                    tryNow={tryNow}
                  >
                    {feature.description}
                  </Feature>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturesGroup;
