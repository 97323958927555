//source: https://vhudyma-blog.eu/detect-mobile-device-in-react/
import { useEffect } from "react";

export const isIosDevice = (device) => {
  let UA = device || navigator.userAgent;
  return /\b(iPad|iPhone|iPod)\b/i.test(UA);
};

const isSafariBrowser = () => {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1
  );
};

export const isNotChrome = () => {
  return (
    navigator.userAgent.indexOf("Chrome") == -1 &&
    navigator.userAgent.indexOf("CriOS") == -1
  );
};

export const getDeviceType = () => {
  let hasTouchScreen = false;
  const UA = navigator.userAgent;
  const checkRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(UA);
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer: coarse)");
    if (mQ && mQ.media === "(pointer: coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true;
    } else {
      hasTouchScreen = checkRegex;
    }
  }
  const isIos = isIosDevice(navigator.userAgent);
  if (hasTouchScreen && !checkRegex) {
    // if has touch screen and is not mobile => desktop
    return { type: "desktop", os: isIos ? "ios" : "android" };
  } else if (hasTouchScreen) {
    return { type: "mobile", os: isIos ? "ios" : "android" };
  } else {
    return { type: "desktop", os: isIos ? "ios" : "android" };
  }
};

function useDetectUserAgent(setDeviceType) {
  useEffect(() => {
    if (!setDeviceType) return;
    const deviceType = getDeviceType();
    setDeviceType(deviceType);
  }, [navigator.userAgent]);

  const isIOSAndSafari = () => {
    const isIos = isIosDevice(navigator.userAgent);
    const isSafari = isSafariBrowser();

    return !!(isIos && isSafari);
  };

  return { isIOSAndSafari };
}

export default useDetectUserAgent;
