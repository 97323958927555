import { lsProxy } from "utils/helpers/localstorage";

export default function useAuth() {
  const authToken = lsProxy.getItem("token");
  const isAuthenticated = authToken && authToken !== "";

  return {
    isAuthenticated,
  };
}
