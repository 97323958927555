import React from "react";

const ThreeDotAnimation = ({ customizeClass }) => {
  return (
    <>
      <div className={customizeClass}>
        <div className="dot-flashing"></div>
      </div>
    </>
  );
};

export default ThreeDotAnimation;
