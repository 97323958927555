import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import OutlinedButton from "views/components/button/OutlinedButton";
import cx from "classnames";
import GradButton from "views/components/button/GradButton";
import SecondaryButton from "views/components/button/SmallRoundedButton";

const CountryLandingHeader = ({
  className,
  country,
  flag,
  home,
  aboutUs = () => {},
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const navigator = useNavigate();
  const login = () => {
    navigator("/auth/login");
  };

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      className={cx("shadow-sm", className)}
    >
      <Container>
        <Navbar.Brand as={Link} to={home}>
          <img
            className="logo"
            src="/assets/images/global-images/logo.png"
            alt="logo"
          />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-lg"
          onClick={() => setExpanded((p) => !p)}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
          placement="end"
          data-bs-scroll="true"
          restoreFocus={false}
          onHide={() => setExpanded(false)}
        >
          <Offcanvas.Header closeButton onHide={() => setExpanded(false)}>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
              <Link to={home} onClick={() => setExpanded(false)}>
                <img
                  className="logo"
                  src="/assets/images/global-images/logo.png"
                  alt="logo"
                />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="justify-content-between h-auto flex-column flex-lg-row">
            <Nav className="font-14">
              <Nav.Link
                className="align-self-start align-self-lg-center"
                onClick={() => {
                  setExpanded(false);
                  aboutUs();
                }}
                href="#about"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                className="align-self-start align-self-lg-center"
                onClick={() => setExpanded(false)}
                href="#plans"
              >
                Plans & Pricing
              </Nav.Link>
              <Nav.Link
                className="align-self-start align-self-lg-center"
                onClick={() => setExpanded(false)}
                href="#contact"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                className="align-self-start align-self-lg-center"
                onClick={() => setExpanded(false)}
                href="/helpdesk"
                target="_blank"
              >
                Helpdesk
              </Nav.Link>
            </Nav>

            <div className="d-none d-lg-flex align-items-center gap-2">
              <img src={flag} alt={country} className="hw-18px w-auto" />
              <span>{country}</span>

              <GradButton
                title="Sign Up or Login"
                variant="sm"
                className="w-fit px-5 ms-2 lh-12"
                onClick={login}
              />
            </div>

            <div className="button-box d-block d-lg-none">
              <OutlinedButton
                title="Download Kwikpic"
                className="mb-3 w-100"
                onClick={() => {
                  window.open(
                    "https://kwikpic-in.app.link/cfYvH3yuvtb",
                    "_blank"
                  );
                }}
                variant="sm"
              />

              <SecondaryButton
                rounded={false}
                onClick={login}
                title="Sign Up or Login"
                variant="sm"
                className="w-100"
              />
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default CountryLandingHeader;
