import React, { useEffect } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { VERIFY_DOWNLOAD_PIN } from "redux-store/sagas/saga-actions";
import { setVerifyDownloadPinResponse } from "redux-store/slices/folders";
import SecondarySmallBtn from "views/components/button/SecondarySmallBtn";
import Spinner from "views/components/loader/Spinner";

export default function VerifyDownloadPin({ setIsPinVerified }) {
  const [pin, setPin] = React.useState("");

  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const { verifyDownloadPinResponse: { match, status } = {}, loader } =
    useSelector((state) => state.folders);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!pin) {
      toast.error("Please enter pin");
      return;
    }

    dispatch({
      type: VERIFY_DOWNLOAD_PIN,
      payload: { groupId: params.get("groupId"), pin },
    });
  };

  useEffect(() => {
    if (status === 200) {
      if (match) {
        toast.success("Pin verified successfully");
        setIsPinVerified(true);
      } else if (match === false) {
        toast.error("Invalid pin");
      }
    }
    dispatch(setVerifyDownloadPinResponse({}));
  }, [match, status]);

  return (
    <div className="mt-5 d-flex w-100 align-items-center flex-column text-center">
      <Spinner loading={loader} />
      <h5>Enter Download Pin</h5>
      <p className="fs-10">
        You can find download pin in Group Settings {">"} Download Settings
      </p>
      <div className="mt-4">
        <form onSubmit={handleSubmit}>
          <OtpInput
            className="otp-input form-font"
            numInputs={4}
            onChange={setPin}
            value={pin}
            separator={<span className="p-1"></span>}
          />
          <div className="mt-4 d-flex justify-content-center">
            <SecondarySmallBtn title="Continue" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}
