/*eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    analytics: [],
    initialLoader: true
}

export const analytics = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setMyAnalytics: (state, action) => {
            const {data, status} = action.payload || {};
            if(status === 200) {
                return {
                    ...state,
                    analytics: data,
                    initialLoader: false
                }
            }
        },
        setAnalyticsLoader: (state, action) => {
            return { ...state, initialLoader: action.payload };
        },
    }
})

export const { setMyAnalytics, setAnalyticsLoader } = analytics.actions;
export default analytics.reducer;
/*eslint-disable */