import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  portfolioLoader: false,
  services: [],
};

export const portfolio = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setPortfolioLoader: (state, action) => {
      return { ...state, portfolioLoader: action.payload };
    },
    setServices: (state, action) => {
      return { ...state, services: action.payload };
    },
  },
});

export const { setPortfolioLoader, setServices } = portfolio.actions;
export default portfolio.reducer;
