import { all } from "redux-saga/effects";
import { settingsSaga } from "./settings";
import { groupsSaga } from "./groups";
import { foldersSaga } from "./folders";
import { authSaga } from "./auth";
import { imagesSaga } from "./images";
import { folderImageSaga } from "./folderImages";
import { analyticsSaga } from "./analytics";
import { subscriptionsSaga } from "./subscription";
import { anonymousSaga } from "./anonymous";
import { globalsSaga } from "./globals";
import { portfolioSaga } from "./portfolio";
import { cartSaga } from "./cart";
import { userSaga } from "./user";
import { notificationsSaga } from "./notifications";

export default function* rootSaga() {
  yield all([
    // ...sagas
    settingsSaga(),
    groupsSaga(),
    foldersSaga(),
    authSaga(),
    imagesSaga(),
    folderImageSaga(),
    analyticsSaga(),
    subscriptionsSaga(),
    anonymousSaga(),
    globalsSaga(),
    portfolioSaga(),
    cartSaga(),
    userSaga(),
    notificationsSaga(),
  ]);
}
