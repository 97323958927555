import React from "react";
import css from "./Buttons.module.css";
import cx from "classnames";

/**
 * @typedef {object} MutedButtonProps
 * @property {string} title - button title
 * @property {function} onClick - button click handler
 * @property {string} type - button type
 * @property {string} className - button class name
 * @property {("xs"|"sm"|"md")} variant - button variant
 * @property {boolean} rounded - button rounded
 * @param {MutedButtonProps}
 */
const MutedButton = ({
  title,
  onClick,
  type,
  children,
  className,
  rounded = true,
  variant = "xs",
}) => {
  return (
    <button
      type={type}
      className={cx(css.mutedButton, className, css[variant], {
        [css.rounded]: rounded,
      })}
      onClick={onClick}
    >
      {title || children}
    </button>
  );
};
export default MutedButton;
