import React from "react";
const InfoWidget = ({title, description, icon = null}) => {
  return (
    <>
        <div className="info-widget">
            {icon && <img src={icon} />}
            <div>
              <h4>{title}</h4>
              <p>{description}</p>
            </div>
        </div>
    </>
  );
};

export default InfoWidget;
