import React from "react";
import { Col } from "react-bootstrap";

const FaceCounts = ({info}) => {
  return (
    <Col
      lg="4"
      md="6"
      sm="12"
      style={{width: '370px'}}
    >
      <div className="card-box">
        <div className="card-icon">
          <img src={info.image} alt="" />
        </div>
        <div className="card-info">
          <div className="card-title-custom">{info.count}</div>
          <div className="card-description">{info.description}</div>
        </div>
      </div>
    </Col>
  );
};

export default FaceCounts;
