import { lsProxy } from "utils/helpers/localstorage";

/**
 * @param {string} groupId
 * @param {("highResAlert"|"anonHighRes")} scope
 * @returns
 */
const useHighResAlert = (groupId, scope = "highResAlert") => {
  const store = JSON.parse(lsProxy.getItem(scope) || "{}");
  const performCheck = typeof store[groupId] === "undefined";

  const setResult = (result) => {
    const newStore = JSON.stringify({ ...store, [groupId]: result });
    lsProxy.setItem(scope, newStore);
  };

  return {
    performCheck,
    setResult,
  };
};

export default useHighResAlert;
