import React from "react";
import SecondaryButton from "views/components/button/SecondaryButton";
import { SELECTION_TYPE } from "../AlbumDownload";
import FolderDownloads from "./FolderDownloads";
import PlainButton from "views/components/button/PlainButton";
import { groupHasImagesSelected } from "utils/helpers/localstorage";
import { useNavigate, useSearchParams } from "react-router-dom";

const DownloadTypeSelection = ({
  setSelectionType,
  isAdmin,
  groupName,
  isAnonymous,
  isPhotographer,
  isForProductSale,
}) => {
  const [params] = useSearchParams();
  const groupId = params.get("groupId");
  const selected = params.has("selected");
  const [downloading, setDownloading] = React.useState(false);
  const navigate = useNavigate();

  return (
    <div className="download-folders-list d-flex justify-content-center flex-column ">
      <div className="d-flex justify-content-center flex-column">
        {groupHasImagesSelected(groupId) && selected ? (
          <>
            <FolderDownloads
              headerText="Download Selected Photos"
              type={SELECTION_TYPE.SELECTED_IMAGES}
              isAnonymous={isAnonymous}
              setDownloading={setDownloading}
            />

            {!downloading && (
              <PlainButton
                title="Clear Selected Photos"
                className="mt-3 ms-auto"
                onClick={() => {
                  sessionStorage.removeItem(`downloadUrls-${groupId}`);
                  navigate(
                    {
                      pathname: "/gallery/download",
                      search: `?groupId=${groupId}`,
                    },
                    { replace: true }
                  );
                }}
              />
            )}
          </>
        ) : (
          <>
            {!isPhotographer && (
              <FolderDownloads
                headerText={`Download Photos from “${groupName}”`}
                type={SELECTION_TYPE.MY_FOLDER}
                isAnonymous={isAnonymous}
              />
            )}
            {!isAnonymous && (
              <FolderDownloads
                headerText={
                  isPhotographer ? `Download Photos from “${groupName}”` : ""
                }
                type={SELECTION_TYPE.MY_FAVORITES}
                isAnonymous={isAnonymous}
              />
            )}
            {!isAnonymous && isForProductSale && (
              <FolderDownloads
                headerText=""
                type={SELECTION_TYPE.PURCHASED}
                isAnonymous={isAnonymous}
              />
            )}

            {isAdmin ? (
              <>
                <p className="mt-3 download-or">
                  <span>OR</span>
                </p>
                <div className="d-flex justify-content-center mt-4">
                  <SecondaryButton
                    title="All Folders"
                    onClick={() => setSelectionType(SELECTION_TYPE.ALL_FOLDERS)}
                  />
                </div>
              </>
            ) : (
              <div className="">
                <div className="border-top d-flex align-items-start mt-4 pt-3">
                  <img
                    className="me-1 mt-1"
                    style={{ width: "18px" }}
                    src="/assets/images/icons/info.png"
                  />
                  <p
                    className="mb-0 mt-auto mx-1 pt-1"
                    style={{
                      color: "#2887AF",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    To download all photos, you need to be a group admin.
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadTypeSelection;
