import React from 'react';
import { ProgressBar } from "react-bootstrap";
import { imagesStatus } from "../../../../../hooks/useUploadFromComputer";
import { useSortable } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';


const Folder = ({ folder, onClick, uploadedImages, isUploading, id }) => {
    const { attributes, listeners,  setNodeRef, transform, transition} = useSortable({id})
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      touchAction: "none",
    };
  
    const images = uploadedImages.filter(
      (image) => image.folderPrefix === folder.name
    );
  
    const successfullyUploaded = images.filter(
      (image) =>
        image.status === imagesStatus.SUCCESS ||
        image.status === imagesStatus.DUPLICATE ||
        image.status === imagesStatus.ERROR
    );
  
    const successfullyUploadedLength = successfullyUploaded.length;
  
    const folderStatus = getFolderStatus(
      isUploading,
      successfullyUploadedLength,
      images.length
    );
    
    return (
      <div className="folder_card modalFullWidthButton" onClick={() => onClick(folder)} style={style} >
        <div className="folder_card-left" ref={setNodeRef} {...attributes} {...listeners}>
          <img
            src="../../../../../../assets/images/icons/folder.png"
            alt="folder"
            width="65px"
          />
        </div>
        <div className="folder_card-center">
          <div>
            <span className="folder-name">{folder.name || "Others"}</span>
          </div>
          <ProgressBar
            now={Math.round((successfullyUploadedLength / images.length) * 100)}
          />
          <div className="photos-count">
            {successfullyUploadedLength} / {folder.images?.length || 0} Photos
          </div>
        </div>
        <div className="folder_card-right">
          <div className="uploadingStatus">
            <span>{folderStatus}</span>
            {folderStatus === "Uploaded" ? (
              <span className="checked"></span>
            ) : (
              <span className="unchecked"></span>
            )}
          </div>
        </div>
      </div>
    );
  };
  
function getFolderStatus(isUploading, successfullyUploadedLength, imagesLength) {
    if (successfullyUploadedLength === imagesLength) {
        return "Uploaded";
    }
    if (isUploading) {
        return "Uploading";
    }
    return "Upload";
}

export default Folder;