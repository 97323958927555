import { createSlice } from "@reduxjs/toolkit";
import { getCountryData } from "utils/helpers/countryCodes";
import { setUserProfileData } from "./settings";

export const BULK_OPERATION_STATUS = {
  IDLE: "IDLE",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const initialState = {
  selectedPlan: {},
  paymentResponse: {},
  slackMessage: {},
  notificationResponse: {},
  isUserInIndia: null,
  branchLink: null,
  globalLoading: false,
  countries: [],
  states: [],
  cities: [],
  country: null,
  paymentsCurrency: "", // keeping it blank initially
  bulkOperationStatus: BULK_OPERATION_STATUS.IDLE,
};

export const globals = createSlice({
  name: "globals",
  initialState,
  reducers: {
    setGlobalLoading: (state, action) => {
      state.globalLoading = action?.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action?.payload;
    },
    clearSelectedPlan: (state) => {
      state.selectedPlan = {};
    },
    setPaymentResponse: (state, action) => {
      state.paymentResponse = action?.payload;
    },
    clearPaymentResponse: (state) => {
      state.paymentResponse = {};
    },
    setSlackMessage: (state, action) => {
      state.slackMessage = action?.payload;
    },
    clearSlackMessage: (state) => {
      state.slackMessage = {};
    },
    setNotificationResponse: (state, action) => {
      state.notificationResponse = action?.payload;
    },
    clearNotificationResponse: (state) => {
      state.notificationResponse = {};
    },
    setIsUserInIndia: (state, action) => {
      state.isUserInIndia = action?.payload;
    },
    setBranchLink: (state, action) => {
      state.branchLink = action?.payload?.url;
    },
    clearBranchLink: (state) => {
      state.branchLink = null;
    },
    setCountries: (state, action) => {
      state.countries = action?.payload;
    },
    setStates: (state, action) => {
      state.states = action?.payload;
    },
    setCities: (state, action) => {
      state.cities = action?.payload;
    },
    setCountry: (state, action) => {
      const { code, isEU } = action.payload || {};
      const country = { ...getCountryData(code) };

      if (isEU) country.currency = "EUR";
      else if (code === "US") country.currency = "USD";
      else if (code === "GB") {
        country.currency = "GBP";
        country.code = "UK";
      } else country.currency = "INR";

      state.country = country;
      document.cookie = `country=${country?.code};path=/;max-age=31536000`;

      // set currency only if it is not set by extraReducers
      if (!state.paymentsCurrency) {
        state.paymentsCurrency = country.currency;
      }
    },
    setBulkOperationStatus: (state, action) => {
      state.bulkOperationStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserProfileData, (state, { payload }) => {
      const {
        user: { country },
      } = payload?.data || {};

      // if country is defined then use it to set the currency
      if (country) {
        state.paymentsCurrency =
          state.isUserInIndia || country === "India"
            ? "INR"
            : country === "United Kingdom"
            ? "GBP"
            : "USD";
      }
    });
  },
});

export const {
  setGlobalLoading,
  setSelectedPlan,
  clearSelectedPlan,
  setPaymentResponse,
  clearPaymentResponse,
  setSlackMessage,
  clearSlackMessage,
  setNotificationResponse,
  clearNotificationResponse,
  setIsUserInIndia,
  setBranchLink,
  clearBranchLink,
  setCountries,
  setStates,
  setCities,
  setCountry,
  setBulkOperationStatus,
} = globals.actions;

export default globals.reducer;
