import React from "react";
import css from "./FolderImages.module.css";
import { Link } from "react-router-dom";

const CheckoutIcon = ({ totalItems, groupId }) => {
  return (
    <Link
      to={`/gallery/checkout?groupId=${groupId}`}
      className={css.checkoutIcon}
      title="Click to checkout"
    >
      <img src="/assets/icons/cart.png" alt="cart" />
      <span className="font-15 font-base text-white">{totalItems}</span>
    </Link>
  );
};

export default CheckoutIcon;
