import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GET_PUBLIC_PLANS } from "redux-store/sagas/saga-actions";
import OutlinedButton from "../components/button/OutlinedButton";
import IconChip from "./components/iconChip";
import InfoWidget from "./components/infoWidget";
import PricingListWrapper from "./components/princingListWrapper";
import TestimonialWrapper from "./components/testimonialWrapper";
import UserDetailsForm from "views/components/modals/UserDetailsForm";
import PaymentLink from "views/components/modals/PaymentLink";
import { slackMessage, slackNoAuthMessage } from "utils/helpers/subscription";
import useOrder from "hooks/useOrder";
import useAuth from "hooks/useAuth";
import {
  clearSelectedPlan,
  clearPaymentResponse,
  setSlackMessage,
} from "redux-store/slices/globals";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import Spinner from "views/components/loader/Spinner";
import sendSlackMessage from "utils/helpers/sendSlackMessage";
import PlanUpgrade from "views/components/modals/PlanUpgrade";

const MODALS = {
  DETAILS: "details",
  PAY: "pay",
  ALREADY_SUBSCRIBED: "already_subscribed",
  NONE: "none",
};

const testimonials = [
  {
    title: "Kwikpic the ‘Saviour’",
    text: "Its been the most convenient method to share the pictures and we can not imagine our Weddings without this amazing Tool!",
    name: "Tarun Bhalla ",
    party: "Anhad Media House",
  },
  {
    title: "Excellent Service",
    text: "Their facial recognition is blazing fast and 100% accurate. I used it for my professional services and clients were very happy with the result.",
    name: "Chocolate Box Photography",
    party: "",
  },
];

const PhotographerDashboard = () => {
  const dispatch = useDispatch();
  const type = "PHOTOGRAPHER";

  const [showModal, setShowModal] = React.useState(MODALS.NONE);
  const [err, setErr] = React.useState("");
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    phone: "",
    cc: "+91",
  });

  const { createOrderVisitor, createPaymentLinkVisitor } = useOrder();
  const { isAuthenticated } = useAuth();

  const { selectedPlan, paymentResponse } = useSelector(
    (state) => state.globals
  );
  const { plansLoading } = useSelector((state) => state.subscription);

  React.useEffect(() => {
    dispatch({
      type: GET_PUBLIC_PLANS,
      payload: {
        userType: "PHOTOGRAPHER",
      },
    });

    return () => {
      dispatch(clearSelectedPlan());
      dispatch(clearPaymentResponse());
    };
  }, []);

  React.useEffect(() => {
    if (selectedPlan?.plan_id) {
      if (isAuthenticated) {
        if (selectedPlan?.alreadySubscribed)
          setShowModal(MODALS.ALREADY_SUBSCRIBED);
        else setShowModal(MODALS.PAY);

        const text = slackMessage(
          selectedPlan?.packageName,
          selectedPlan?.period,
          null,
          selectedPlan?.alreadySubscribed
        );

        sendSlackMessage(text, "#landing-pricing");
        dispatch(setSlackMessage(text));
      } else setShowModal(MODALS.DETAILS);
    } else setShowModal(MODALS.NONE);
  }, [selectedPlan]);

  const sendSlackLog = () => {
    const text = slackNoAuthMessage(
      selectedPlan?.packageName,
      selectedPlan?.period,
      user
    );
    sendSlackMessage(text, "#landing-pricing");
    dispatch(setSlackMessage(text));
  };

  const navigator = useNavigate();

  const contactUs = () => {
    window.location.href = "/helpdesk";
  };
  const tryKwikpic = () => {
    navigator("/auth/login");
  };

  const submitUserForm = (e) => {
    e.preventDefault();
    if (user.phone.length < 6 || user.phone.length > 14) {
      return setErr("Please enter a valid phone number");
    } else {
      setErr("");
    }
    setShowModal(MODALS.PAY);
    sendSlackLog();
    if (process.env.REACT_APP_STANDARD_CHECKOUT === "true")
      return createOrderVisitor(user, selectedPlan);
    createPaymentLinkVisitor(user, selectedPlan);
  };

  return (
    <>
      {/* HERO SECTION STARTS HERE */}
      <section id="hero">
        <div className="container text-center">
          <h1>Get the most out of Kwikpic</h1>
          <p>Transform the way you share your photographs with your clients </p>
          <div className=" d-inline-flex flex-column justify-center">
            <img
              className="under-line"
              src="/assets/images/landing/yellow-divider.png"
              alt="Yellow-divider"
            />
          </div>
          <div className="button-group">
            <OutlinedButton
              title="Try Kwikpic"
              className="mb-3"
              onClick={tryKwikpic}
            />
            <OutlinedButton
              title="Contact Us"
              className="mb-3"
              onClick={contactUs}
            />
          </div>
        </div>

        {/* icons list */}
        <IconChip
          src="/assets/images/icons/group1607.png"
          styleClass="icon-chip-one"
        />

        <IconChip
          src="/assets/images/icons/group1603.png"
          styleClass="icon-chip-two"
        />

        <IconChip
          src="/assets/images/icons/group1602.png"
          styleClass="icon-chip-three"
        />

        <IconChip
          src="/assets/images/icons/group1608.png"
          styleClass="icon-chip-four"
        />

        <IconChip
          src="/assets/images/icons/group1606.png"
          styleClass="icon-chip-five"
        />

        <IconChip
          src="/assets/images/icons/group1605.png"
          styleClass="icon-chip-six"
        />
      </section>
      {/* HERO SECTION ENDS HERE */}

      {/* EVENT SECTION STARTS HERE */}
      <section id="events">
        <Container>
          <h1 className="mb-3">
            For All Kinds of Photographers &amp; Event Professionals
          </h1>
          <p className="event-paragraph">
            From a budding photographer to a well established photography firm,
            Kwikpic is for everyone
          </p>
          <Row className="g-2">
            <Col lg="6" md="12" sm="12">
              <Row className="infoWidget-wrapper  g-2">
                <Col md="6" sm="12">
                  <InfoWidget
                    title="Branding"
                    description="Display your Logo and Company Name on every photo gallery, to promote your business"
                    icon="/assets/images/icons/brand.png"
                  />
                </Col>
                <Col md="6" sm="12">
                  <InfoWidget
                    title="Consumer Reach"
                    description="Add your contact number and socials so that guests can reach you easily for their next event."
                    icon="/assets/images/icons/announcement.png"
                  />
                </Col>
                <Col md="6" sm="12">
                  <InfoWidget
                    title="Simplified teamwork"
                    description="Share Admin rights with hosts and other photographers at the event for simplified teamwork of uploading and editing"
                    icon="/assets/images/icons/collaboration.png"
                  />
                </Col>
                <Col md="6" sm="12">
                  <InfoWidget
                    title="Track your Analytics"
                    description="Get insights like number of views, downloads, unique guests registered and much more."
                    icon="/assets/images/icons/analytics.png"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="12" sm="12" className="d-none d-lg-block">
              <img
                className="marriage-event-image"
                srcSet="/assets/images/landing/marriage-event-1920w.png 1920w, /assets/images/landing/marriage-event.png 2560w"
                sizes="(min-width: 1921px) 2560px, 100vw"
                src="/assets/images/landing/marriage-event.png"
                alt="Marriage Event"
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* EVENT SECTION ENDS HERE */}

      {/* OCCASION SECION STARTS HERE */}
      <section id="occasion">
        <Container>
          <Row className="g-2">
            <Col lg="3" xs="12"></Col>
            <Col lg="9" xs="12">
              <div className="occasion-overlay">
                <div className="occasion-overlay-container d-none d-lg-block"></div>
                <Row>
                  <Col>
                    <div className="occasion-info">
                      <div>
                        <h1>At All Kinds of Occasions</h1>
                        <p>
                          Use Kwikpic for Weddings, Corporate Events, Social
                          Gatherings or Parties and much more!
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="g-2">
                  <Col xs="12" md="4">
                    <InfoWidget
                      title="Group Privacy Settings"
                      description="Create Private groups to let everyone see all photos and Public groups to let everyone see only their photos."
                      icon="/assets/images/icons/security.png"
                    />
                  </Col>
                  <Col xs="12" md="4">
                    <InfoWidget
                      title="QR  Code"
                      description="Automatically generated template with QR code for printing and placing at events for guests to join groups"
                      icon="/assets/images/icons/qr.png"
                    />
                  </Col>
                  <Col xs="12" md="4">
                    <InfoWidget
                      title="Design Templates"
                      description="Choose from different gallery styles, fonts, colours and more to best compliment your brand."
                      icon="/assets/images/icons/design-template.png"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="amazing-photo-wrapper">
            <h1>Delivering Amazing Photos</h1>
            <p>Specialised features to cater to your special needs</p>
            <Row className="g-2">
              <Col md="6">
                <Row>
                  <Col md="6" sm="12">
                    <InfoWidget
                      title="Download Settings"
                      description="Enable or disable downloads for your photo album."
                      icon="/assets/images/icons/download-icon.png"
                    />
                  </Col>
                  <Col md="6" sm="12">
                    <InfoWidget
                      title="Client Favorites"
                      description="Clients can mark images as favorites for your reference to further edit or process them for final delivery."
                      icon="/assets/images/icons/heart.png"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6" className="text-center">
                <img
                  className="amazing-photo"
                  srcSet="/assets/images/landing/water-slide-1920w.png 1920w, /assets/images/landing/water-slide.png 2560w"
                  sizes="(min-width: 1921px) 2560px, 100vw"
                  src="/assets/images/landing/water-slide.png"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/* OCCASION SECION ENDS HERE */}

      {/* TESTIMONIAL SECION STARTS HERE */}
      <section id="testimonial">
        <Container>
          <Row>
            <Col md={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 2 }}>
              <TestimonialWrapper testimonials={testimonials} />
            </Col>
          </Row>
        </Container>
      </section>
      {/* TESTIMONIAL SECION ENDS HERE */}

      {/* PRICING SECION STARTS HERE */}
      <section id="pricing">
        <Container>
          <PaymentLink
            show={showModal == MODALS.PAY}
            hide={() => setShowModal(MODALS.NONE)}
          />
          <PlanUpgrade
            show={showModal == MODALS.ALREADY_SUBSCRIBED}
            hide={() => setShowModal(MODALS.NONE)}
          />
          <PricingListWrapper />
          <UserDetailsForm
            show={showModal == MODALS.DETAILS}
            setUser={setUser}
            user={user}
            onSubmit={submitUserForm}
            onCancel={() => setShowModal(MODALS.NONE)}
            err={err}
            type={type}
          />
        </Container>
      </section>
      {/* PRICING SECION ENDS HERE */}

      {/* KNOW MORE SECTION STARTS HERE */}
      <section id="know-more">
        {/* <a name="know-more"></a> */}
        <Container>
          <div className="text-center">
            <p className="know-more-title">Know More</p>
            <img
              className="under-line"
              src="/assets/images/landing/yellow-divider.png"
              alt="Yellow-divider"
            ></img>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-4 gap-md-5 flex-wrap mt-2">
            <div className="yt-container">
              <iframe
                width="100%"
                height="100%"
                src="https://tube.rvere.com/embed?v=SHdcydEuTN0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="yt-container">
              <iframe
                width="100%"
                height="100%"
                src="https://tube.rvere.com/embed?v=Ko30imhiq7k"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <a
            href="https://www.youtube.com/@kwikpic"
            target="_blank"
            rel="noopener noreferrer"
            className="explore-more w-fit mx-auto mt-4"
          >
            Explore More
          </a>
        </Container>
      </section>
      {/* KNOW MORE SECTION ENDS HERE */}
      <ConfirmationModal
        show={paymentResponse?.title}
        onCancel={() => (window.location.href = "/profile-settings/profile")}
        title={paymentResponse?.title}
        footer={false}
      >
        <p className="text-center font-15 mb-3">{paymentResponse?.details}</p>
      </ConfirmationModal>
      <Spinner loading={plansLoading} />
    </>
  );
};

export default PhotographerDashboard;
