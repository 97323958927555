import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import { useLocation, useSearchParams } from "react-router-dom";

// ref https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage

function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

const checkOnRoutes = [
  "/landing/join",
  "/auth",
  "/anonymous",
  "/settings",
  "/profile-settings",
  "/privacy-security",
  "/join",
  "/update-profile",
  "/notifications",
  "/analytics",
  "/gallery",
];

/**
 * @typedef {object} WarnDisabledCookiesProps
 * @property {("localStorage"|"sessionStorage")} type - The type of storage to check for. Defaults to local storage.
 * @param {WarnDisabledCookiesProps} props
 */
const WarnDisabledCookies = ({ type = "localStorage" }) => {
  const [show, setShow] = React.useState(false);
  const location = useLocation();
  const [params] = useSearchParams();
  const isWebView = params.has("token") || params.has("frontendType");

  React.useEffect(() => {
    if (
      !storageAvailable(type) &&
      !isWebView &&
      checkOnRoutes.some((route) => location.pathname.startsWith(route))
    )
      setShow(true);

    return () => setShow(false);
  }, [type, location, isWebView]);

  return (
    <ConfirmationModal
      show={show}
      title="Your browser has cookies disabled"
      buttonRounded={false}
      cancelText=""
      confirmText="Refresh"
      onConfirm={() => window.location.reload()}
      animation
    >
      <p className="mb-2 font-15">
        This website requires cookies to function properly. Please enable
        cookies in your browser settings and refresh the page.
      </p>
      <p className="font-15">
        <a
          href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/auto"
          target="_blank"
          rel="noreferrer"
        >
          Click here for instructions on how to enable cookies.
        </a>
      </p>
    </ConfirmationModal>
  );
};

export default WarnDisabledCookies;
