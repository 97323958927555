import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./css/CommentBoxModal.css";
import { useDispatch, useSelector } from "react-redux";
import { ADD_IMAGE_COMMENT } from "redux-store/sagas/saga-actions";
import { setCommentData } from "redux-store/slices/images";
import SecondaryButton from "views/components/button/SmallRoundedButton";

const CommentBoxModal = ({ show, onHide, image, getImagesData }) => {
  const [commentFill, setCommentFill] = React.useState(image?.comment);
  const dispatch = useDispatch();
  const { commentData } = useSelector((state) => state.images);

  useEffect(() => {
    if (commentData) {
      dispatch(setCommentData(null));
      getImagesData();
      onHide();
    }
  }, [commentData]);

  function handleSubmit() {
    const { groupId, _id } = image || {};
    if (groupId && _id && commentFill) {
      dispatch({
        type: ADD_IMAGE_COMMENT,
        payload: {
          groupId,
          comment: commentFill,
          imageId: _id,
        },
      });
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      animation={false}
      className="commentBoxModal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="mx-auto">Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <textarea
            className="commentTextarea rounded ps-3 py-2 pe-11 w-100"
            value={commentFill}
            placeholder={!commentFill && "Comment Here..."}
            onChange={(e) => setCommentFill(e.target.value)}
          />
          <div className="submit-btn">
            <SecondaryButton onClick={() => handleSubmit()} className="mt-3">
              Submit
            </SecondaryButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CommentBoxModal;
