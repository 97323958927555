import React, { useState } from "react";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import SecondarySmallBtn from "../../../components/button/SecondarySmallBtn";
import { JOIN_MY_GROUP } from "../../../../redux-store/sagas/saga-actions";
// import { useNavigate } from "react-router-dom";
// import { clearJoinGroupStatus } from "redux-store/slices/group";
import Spinner from "views/components/loader/Spinner";
import useJoinGroupInfo from "hooks/useJoinGroupInfo";

const GroupInput = () => {
  const [groupCode, setGroupCode] = useState(null);
  // const [joinGroupLoading, setJoinGroupLoading] = useState(false);
  const dispatch = useDispatch();

  const { loading: joinGroupLoading, joinNewGroupSocketInit } =
    useJoinGroupInfo();
  // const joinGroup = useSelector((state) => state.groups);
  // const navigator = useNavigate();

  async function sendOpt() {
    if (!groupCode) return;
    await joinNewGroupSocketInit();
    // socket.on("joinGroupInfo", (data) => {
    //   console.log("askjjbdjhaskbdha", data);
    //   if (data?.status === 206) {
    //     setJoinGroupLoading(true);
    //   } else {
    //     // setJoinGroupLoading(function () {
    //     //   return false;
    //     // });
    //   }
    // });

    dispatch({
      type: JOIN_MY_GROUP,
      groupCode,
    });
  }

  // useEffect(() => {
  //   if (joinGroup?.joinGroupResponse) {
  //     if (
  //       joinGroup?.joinGroupResponse?.status === 200 &&
  //       joinGroup?.joinGroupResponse?.message === "Group joined successfully"
  //     ) {
  //       setJoinGroupLoading(true);
  //       setTimeout(() => {
  //         setJoinGroupLoading(false);
  //         dispatch(clearJoinGroupStatus());
  //         navigator(
  //           "/gallery?groupId=" + joinGroup?.joinGroupResponse?.groupId,
  //           {
  //             state: {
  //               joined: true,
  //             },
  //           }
  //         );
  //       }, 10000);
  //     }
  //   }
  // }, [joinGroup]);

  return (
    <div className="groupInput">
      <h4 className="blue-text thick-font">Enter Event Ucode</h4>
      <p className="thick-font gray-text font-15">
        Enter the 6 digit unique code of your event to join the group
      </p>
      <OtpInput
        className="otp-input"
        numInputs={6}
        separator={<span className="p-1"></span>}
        onChange={(e) => setGroupCode(e.toUpperCase())}
        value={groupCode}
      />
      <div className="mt-4" />
      <SecondarySmallBtn title="Join Group" onClick={sendOpt} />
      <div className="mt-3" />
      <p className="thick-font gray-text font-15">
        You can also join group via shared link
      </p>
      <Spinner loading={joinGroupLoading} />
    </div>
  );
};
export default GroupInput;
