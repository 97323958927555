/* eslint-disable no-unused-vars */
import React from "react";
import { Dropdown } from "react-bootstrap";

const SecondarySmallBtnDropdown = ({ title, onClick, onClick2 }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        className="secondarySmallButtonDropdown"
        style={{
          fontSize: "14x",
        }}
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1">
        <Dropdown.Item className="card mb-1" onClick={onClick}>
          Kwikpic Optimized - High Quality
        </Dropdown.Item>
        <Dropdown.Item className="card" onClick={onClick2}>
          Full Original Quality
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default SecondarySmallBtnDropdown;
