import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: [],
  groupLayout: "grid",
  groupResponse: {},
  joinGroupResponse: {},
  initialLoader: true,
  transferDetails: {},
  deleteRequests: {},
};

export const groups = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setMyGroups: (state, action) => {
      const { data, status } = action.payload.data || {};
      if (status === 200) {
        const { myGroups, count } = data;
        if (action.payload.page === 1) {
          return { ...state, groups: myGroups, count, initialLoader: false };
        } else {
          return {
            ...state,
            groups: [...state.groups, ...myGroups],
            count,
            initialLoader: false,
          };
        }
      }
    },
    createMyGroup: (state, action) => {
      const { data, status, message } = action?.payload || {};
      if (status === 200 || status === 201) {
        return {
          ...state,
          groups: [data, ...state.groups],
          groupResponse: {
            status,
            message,
          },
        };
      }
    },
    joinMyGroup: (state, action) => {
      const { status, message, data } = action?.payload || {};
      if (status === 200) {
        return {
          ...state,
          joinGroupResponse: {
            status,
            message,
            groupId: data?.group?._id,
          },
        };
      }
      return {
        ...state,
        joinGroupResponse: {
          status,
          message,
          groupId: data?.groupId,
        },
      };
    },
    clearJoinGroupStatus: (state) => {
      return {
        ...state,
        joinGroupResponse: {
          groupId: state?.joinGroupResponse?.groupId,
        },
      };
    },
    setInitialLoader: (state, action) => {
      return { ...state, initialLoader: action.payload };
    },
    getGroupDetails: (state, action) => {
      const { data, status, message } = action?.payload || {};
      data.brandingData = data?.brandingData || undefined;
      return { ...state, group: { data: { message, status, ...data } } };
    },
    setGroupLayout: (state, action) => {
      return {
        ...state,
        groupLayout: action.payload,
      };
    },
    setTransferDetails: (state, action) => {
      const {
        data: { transferRequest },
      } = action?.payload || {};
      return {
        ...state,
        transferDetails: transferRequest,
      };
    },
    setDeleteRequests: (state, action) => {
      const { data } = action?.payload || {};
      return {
        ...state,
        deleteRequests: {
          ...data,
        },
      };
    },
    updateDeleteRequests: (state, action) => {
      const {
        data: { validRequests },
      } = action?.payload || {};

      let deleteRequests = [...(state.deleteRequests?.requests || [])];

      for (let i = 0; i < validRequests.length; i++) {
        deleteRequests = deleteRequests.filter(
          (item) => item._id !== validRequests[i]
        );
      }

      return {
        ...state,
        deleteRequests: {
          requests: deleteRequests,
        },
      };
    },
  },
});

export const {
  setMyGroups,
  createMyGroup,
  joinMyGroup,
  setInitialLoader,
  setGroupLayout,
  clearJoinGroupStatus,
  getGroupDetails,
  setTransferDetails,
  setDeleteRequests,
  updateDeleteRequests,
} = groups.actions;

export default groups.reducer;
