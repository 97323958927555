import React from "react";
import { handleNoUploadPermission } from "../GallaryEmpty";

const UploadIconComp = ({
  hasUploadPermission,
  handleShow,
  className = "",
}) => {
  return (
    <>
      {hasUploadPermission ? (
        <div
          className={`uploadIcon cursor-pointer ${className}`}
          onClick={handleShow}
        >
          <img
            src="/assets/images/icons/upload.png"
            className="d-none d-sm-block"
            alt="upload icon"
          />
          <img
            src="/assets/images/icons/upload-mob.png"
            className="d-sm-none"
            alt="upload icon"
          />
        </div>
      ) : (
        <div
          className={`uploadIcon cursor-pointer ${className}`}
          onClick={handleNoUploadPermission}
        >
          <img
            src="/assets/images/icons/upload-disabled.png"
            className="d-none d-sm-block"
            alt="upload icon"
          />
          <img
            src="/assets/images/icons/upload-disabled-mob.png"
            className="d-sm-none"
            alt="upload icon"
          />
        </div>
      )}
    </>
  );
};

export default UploadIconComp;
