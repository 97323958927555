import React from "react";
import { useDispatch } from "react-redux";
import { setCountry, setIsUserInIndia } from "redux-store/slices/globals";

const useGetCountry = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      const userCountryFromTZ = new RegExp(/GMT\+0530/).test(
        new Date().toString()
      )
        ? "IN"
        : "US";
      let userCountryFromAPI = "";
      let isEU = false;

      const signal = new AbortController();

      try {
        // cancel api call if it takes more than 1s
        setTimeout(() => signal.abort(), 1000);

        const req = await fetch(
          `https://api.weatherapi.com/v1/ip.json?key=bc4713d29475415b96c70921230407&q=auto:ip&t=${new Date().getTime()}`,
          { signal: signal.signal }
        );

        const res = await req.json();
        userCountryFromAPI = res?.country_code;
        isEU = res?.is_eu === "true";
      } catch (e) {
        // do nothing
      }

      // if api request fails then set country from TZ
      // else compare both the results
      dispatch(
        setIsUserInIndia(
          userCountryFromAPI
            ? userCountryFromAPI == userCountryFromTZ &&
                userCountryFromTZ == "IN"
            : userCountryFromTZ == "IN"
        )
      );
      dispatch(
        setCountry({
          code: userCountryFromAPI || userCountryFromTZ,
          isEU,
        })
      );
    })();
  }, [dispatch]);
};

export default useGetCountry;
