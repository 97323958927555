import { SocketContext } from "context/socket";
import React from "react";
import { lsProxy } from "utils/helpers/localstorage";

const useUploadStatus = () => {
  const socket = React.useContext(SocketContext);
  const [isUploadingFromLink, setIsUploadingFromLink] = React.useState(false);

  React.useEffect(() => {
    socket?.on("linkUploadInfo", (data) => {
      if (data?.status === 206 || data?.status === 201) {
        setIsUploadingFromLink(true);
      } else {
        setIsUploadingFromLink(false);
      }
    });
  }, [socket]);

  const isParallelyUploading = async () => {
    let isUploading = lsProxy.getItem("isUploading");
    if (isUploading) {
      return isUploading || isUploadingFromLink;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (isUploadingFromLink) {
            lsProxy.setItem("uploadingFrom", "Link");
          }
          resolve(isUploadingFromLink);
        }, 2000);
      });
    }
  };

  const uploadingFromLink = async () => {
    return isUploadingFromLink;
  };

  return {
    isParallelyUploading,
    uploadingFromLink,
  };
};

export default useUploadStatus;
