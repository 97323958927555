import { useEffect } from "react";
import { usePrompt } from "./useBlocker";
import { lsProxy } from "utils/helpers/localstorage";

const usePreventUnsavedNavigation = ({
  message = "Are you sure you want to leave this page?",
  block = true,
  refresh = false,
}) => {
  usePrompt(message, block, refresh);
  useEffect(() => {
    if (block) {
      window.onbeforeunload = () => {
        return message;
      };
      window.onunload = () => {
        lsProxy.removeItem("isUploading");
      };
    }

    return () => {
      window.onbeforeunload = null;
      window.onunload = null;
    };
  }, [block]);
};

export default usePreventUnsavedNavigation;
