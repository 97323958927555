import React from "react";
import { Container } from "react-bootstrap";
// import PageHeading from "../components/pageHeading/PageHeading";
import GroupInput from "./components/GroupInput";

const JoinGroup = () => {
  return (
    <Container>
      <div className="homePage">
        {/* <PageHeading title="Groups" /> */}
        <GroupInput />
      </div>
    </Container>
  );
};

export default JoinGroup;
