import React from "react";
import Modal from "react-bootstrap/Modal";
import MutedButton from "../button/MutedButton";
import SmallRoundedButton from "../button/SmallRoundedButton";

function ConfirmationCommentModal({ show, onConfirm, onClose }) {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        animation={false}
        className="commentBoxModal"
      >
        <Modal.Header className="d-none d-md-block mx-auto" closeButton>
          <Modal.Title className="color-primary font-bold">
            Download TXT or CSV file?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="py-0">
          <p className="bold-font font-15 text-center pt-4 mb-0 color-primary d-md-none">
            Do you want to download file with comments?
          </p>
          <img
            src="/assets/images/icons/clientfavs.png"
            className="d-none d-md-block w-100 object-fit-cover"
            alt="features"
          />
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-center">
          <span className="d-none d-md-block">
            <MutedButton title="CSV" onClick={() => onConfirm(true)} />
          </span>
          <span className="d-none d-md-block">
            <SmallRoundedButton title="TXT" onClick={() => onConfirm(false)} />
          </span>
          <span className="d-md-none">
            <MutedButton title="No" onClick={() => onConfirm(false)} />
          </span>
          <span className="d-md-none">
            <SmallRoundedButton title="Yes" onClick={() => onConfirm(true)} />
          </span>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationCommentModal;
