import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  allPlans: [],
  featureUpgrades: {},
  hasActivePlan: false,
  activePlan: {},
  plansLoading: false,
  loading: false,
  orderDetails: {},
  paymentLink: {},
  billingInfo: {},
};

const slice = createSlice({
  name: "subscription",
  initialState: INITIAL_STATE,
  reducers: {
    setAllPlans(state, action) {
      return {
        ...state,
        allPlans: action.payload,
      };
    },
    setFeatureUpgrades(state, action) {
      return {
        ...state,
        featureUpgrades: action.payload,
      };
    },
    setPlansLoading(state, action) {
      return {
        ...state,
        plansLoading: action.payload,
      };
    },
    setActivePlan(state, action) {
      return {
        ...state,
        activePlan: action?.payload || {},
      };
    },
    setPublicPlans(state, action) {
      return {
        ...state,
        publicPlans: action.payload,
      };
    },
    setOrderDetails(state, action) {
      return {
        ...state,
        orderDetails: action.payload,
      };
    },
    clearOrderDetails(state) {
      return {
        ...state,
        orderDetails: {},
      };
    },
    setPaymentLink(state, action) {
      return {
        ...state,
        paymentLink: action.payload,
      };
    },
    setBillingInfo(state, action) {
      return {
        ...state,
        billingInfo: action.payload,
      };
    },
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const {
  setAllPlans,
  setFeatureUpgrades,
  setPlansLoading,
  setActivePlan,
  setPublicPlans,
  setOrderDetails,
  clearOrderDetails,
  setPaymentLink,
  setBillingInfo,
  setLoading,
} = slice.actions;

export default slice.reducer;
