import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { IMAGES_PER_PAGE } from "views/gallary/gallaryPage/components/FolderImages";
import { VIDEOS_PER_PAGE } from "views/gallary/gallaryPage/components/FolderVideos";
import { setFolderTransferSuccess } from "./folders";

const initialState = {
  groupId: "",
  folderPrefix: "",
  folderId: "",
  fullScreenImages: [],
  allPics: {},
  totalCount: 0,
  newImagesCount: 0,
  page: 1,
  imagesLoading: false,
  photographer: {},
  portfolio: [],
  allVideos: [],
  videos: [],
  allVideosCount: 0,
  folderImageLoader: false,
  reevaluatePath: 0, // keeping dtype number so that we can have multiple reevaluations
};

export const folderImages = createSlice({
  name: "folderImages",
  initialState,
  reducers: {
    setImagesOfFolder: (state, action) => {
      const {
        data: { data },
        folderId,
        page,
      } = action?.payload || {};

      const allPics = data?.allPics || [];
      let fullScreenImages = [...(state?.fullScreenImages || [])];

      if (fullScreenImages?.length === 0) {
        fullScreenImages = Array.from({
          length: data?.allPicsCount || 0,
        }).fill(undefined);
      }
      const startIndex = (page - 1) * IMAGES_PER_PAGE;

      fullScreenImages?.splice(startIndex, allPics?.length, ...allPics);

      return {
        ...state,
        groupId: data?.groupId,
        totalCount: data?.allPicsCount || 0,
        allPics: {
          ...state.allPics,
          [folderId]: allPics,
        },
        fullScreenImages,
        // fullScreenImages: __fullScreenImages,
        folderPrefix: data?.folderPrefix,
        folderId,
        newImagesCount: allPics?.length || 0,
        page,
      };
    },
    setMyImages: (state, action) => {
      const folderId = action?.payload?.folderId || "my-photos";
      const { page } = action?.payload || {};
      const { pics, count } = action?.payload?.data?.data || {};
      const allPics = [...pics];

      let fullScreenImages = [...(state?.fullScreenImages || [])];
      if (fullScreenImages?.length === 0) {
        fullScreenImages = Array.from({
          length: count || 0,
        }).fill(undefined);
      }

      const startIndex = (page - 1) * IMAGES_PER_PAGE;

      fullScreenImages?.splice(startIndex, allPics?.length, ...allPics);

      return {
        ...state,
        groupId: action?.payload?.groupID,
        folderId,
        allPics: {
          ...state.allPics,
          [folderId]: allPics,
        },
        totalCount: count,
        folderPrefix: "",
        fullScreenImages,
        newImagesCount: pics?.length || 0,
        imagesLoading: false,
        page,
      };
    },
    setAllImages: (state, action) => {
      const folderId = "all-photos";

      const { groupID, data = {}, page } = action?.payload || {};
      const {
        status,
        data: { count, pics = [] },
      } = data || {};

      if (status === 200) {
        // const allPics = [];

        // pics.forEach((pic) => {
        //   allPics.push(...(pic?.images || []));
        // });

        let fullScreenImages = [...(state?.fullScreenImages || [])];

        if (fullScreenImages?.length === 0) {
          fullScreenImages = Array.from({
            length: count || 0,
          }).fill(undefined);
        }

        const startIndex = (page - 1) * IMAGES_PER_PAGE;

        fullScreenImages?.splice(startIndex, pics?.length, ...pics);

        return {
          ...state,
          groupId: groupID,
          folderId,
          allPics: {
            ...state.allPics,
            [folderId]: pics,
          },
          fullScreenImages,
          totalCount: count,
          folderPrefix: "",
          newImagesCount: pics?.length || 0,
          imagesLoading: false,
          page,
        };
      }
    },
    clearFolderImages: () => {
      return initialState;
    },
    setFoldersPage: (state, action) => {
      const TotalPages = action?.payload?.totalPages;
      const Page = action?.payload?.page;
      if (TotalPages) {
        const foldersPage =
          Page <= 0 ? 1 : Page > TotalPages ? TotalPages : Page;
        return { ...state, page: foldersPage };
      } else {
        return { ...state, page: Page };
      }
    },
    setImagesLoading: (state, action) => {
      return {
        ...state,
        imagesLoading: action?.payload,
      };
    },
    updateImgFavStatus: (state, action) => {
      const { index, page, fromModal, isFavorite } = action?.payload || {};
      let fullScreen = [...(state?.fullScreenImages || [])];
      let allPics = [...(state?.allPics?.[state.folderId] || [])];

      const thumbIndex = index % IMAGES_PER_PAGE;
      const fullScreenIndex =
        index + (fromModal ? 0 : (page - 1) * IMAGES_PER_PAGE);

      const fsImg = {
        ...fullScreen[fullScreenIndex],
        isFavorite: !isFavorite,
      };

      const aImg = {
        ...allPics[thumbIndex],
        isFavorite: !isFavorite,
      };

      try {
        fullScreen[fullScreenIndex] = fsImg;
        allPics[thumbIndex] = aImg;
      } catch (e) {
        // console.log(e);
      }

      return {
        ...state,
        fullScreenImages: fullScreen,
        allPics: {
          ...state.allPics,
          [state.folderId]: allPics,
        },
      };
    },
    deleteImg: (state, action) => {
      const { nonAuthUrl } = action?.payload || {};
      let tmpImgs = [...(state?.fullScreenImages || [])];
      tmpImgs = tmpImgs.filter((img) => img?.url !== nonAuthUrl);

      return {
        ...state,
        totalCount: state?.totalCount - 1,
        fullScreenImages: tmpImgs,
        allPics: {
          ...state.allPics,
          [state.folderId]: tmpImgs,
        },
      };
    },
    photographerPortfolio: (state, action) => {
      return {
        ...state,
        ...action?.payload,
      };
    },
    deleteOrRenameFolder: (state, action) => {
      const {
        folderName,
        newFolderName,
        action: method,
      } = action?.payload || {};

      let folders = [...(state.portfolio || [])];

      if (method === "DELETE") {
        folders = folders.filter((folder) => folder?.folder !== folderName);
      }

      if (method === "RENAME") {
        folders = folders.map((folder) => {
          return {
            ...folder,
            folder:
              folder?.folder === folderName ? newFolderName : folder?.folder,
          };
        });
      }

      return {
        ...state,
        portfolio: folders,
      };
    },
    deleteImagesPortFolio: (state, action) => {
      const { urls, folderName } = action?.payload || {};

      let portfolio = [...(state?.portfolio || [])];
      let folderIndex = portfolio.findIndex(
        (folder) => folder?.folder === folderName
      );
      let folder = portfolio[folderIndex];

      if (folder?.images?.length === urls?.length) {
        return {
          ...state,
          portfolio: portfolio.filter((folder) => {
            return folder?.folder !== folderName;
          }),
        };
      }

      folder = {
        ...folder,
        images: folder?.images?.filter((img) => {
          return !urls?.includes(img?.url);
        }),
      };

      portfolio[folderIndex] = folder;

      return {
        ...state,
        portfolio,
      };
    },
    setCoverImage: (state, action) => {
      const { url } = action?.payload || {};
      return {
        ...state,
        photographer: {
          ...state?.photographer,
          coverImage: url,
        },
      };
    },
    getAllVideos: (state, action) => {
      const { page, videos, allVideosCount } = action.payload || {};
      state.videos = videos;
      let allVideos = [...(state?.allVideos || [])];
      state.allVideosCount = allVideosCount;
      state.totalCount = allVideosCount;
      if (allVideos?.length === 0) {
        allVideos = Array.from({
          length: allVideosCount || 0,
        }).fill(undefined);
      }
      const startIndex = (page - 1) * VIDEOS_PER_PAGE;
      allVideos.splice(startIndex, videos.length, ...videos);
      state.allVideos = allVideos;
    },
    patchVideoOfGroup: (state, action) => {
      if (action?.payload?.response?.data?.status === 200) {
        const message = action?.payload?.response?.data?.message;
        // const videoUrl = action?.payload?.vidUrl;
        const vidId = action?.payload?.vidId;
        toast.success(message);
        const filterVideos = state.allVideos?.filter(
          (video) => video?._id !== vidId
        );
        const _videos = state.videos?.filter((video) => video?._id !== vidId);
        return {
          ...state,
          allVideos: filterVideos,
          allVideosCount: filterVideos.length,
          videos: _videos,
        };
      }
    },
    deleteMultipleImages: (state, action) => {
      const { ids } = action.payload;
      const totalCount = state.totalCount - ids.length;

      /**
       * page will change when I'm on the last page and delete all images on that page -> no reevaluation
       * reevaluate when it's not the last page, but some or all images are deleted
       */

      const totalPages = Math.ceil(totalCount / IMAGES_PER_PAGE);
      const shouldReevaluate = state.page <= totalPages || state.page === 1;

      return {
        ...state,
        reevaluatePath: shouldReevaluate ? Math.random() * 1000 : 0,
        totalCount,
      };
    },
    setFolderImageLoader: (state, action) => {
      return {
        ...state,
        folderImageLoader: action.payload,
      };
    },
    clearReevaluatePath: (state) => {
      return {
        ...state,
        reevaluatePath: 0,
      };
    },
    // just to trigger side effect (extraReducer in settings)
    updateMultipleFavorite: (state, action) => {
      const { indices } = action.payload;
      const allPics = [...state.allPics[state.folderId]];
      const fullScreenImages = [...state.fullScreenImages];

      for (const index of indices) {
        // prevent array index error
        try {
          allPics[index] = {
            ...allPics[index],
            isFavorite: !allPics[index].isFavorite,
          };
          const fsIndex = index + (state.page - 1) * IMAGES_PER_PAGE;
          fullScreenImages[fsIndex] = {
            ...fullScreenImages[fsIndex],
            isFavorite: !fullScreenImages[fsIndex].isFavorite,
          };
        } catch (e) {
          // console.log(e);
        }
      }

      return {
        ...state,
        allPics: {
          ...state.allPics,
          [state.folderId]: allPics,
        },
        fullScreenImages,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setFolderTransferSuccess, (state, action) => {
      const {
        id: { imageIds },
      } = action.payload;

      const totalCount =
        state.totalCount +
        (state.folderId === "deleted" ? imageIds.length : -imageIds.length);

      /**
       * page will change when I'm on the last page and delete all images on that page -> no reevaluation
       * reevaluate when it's not the last page, but some or all images are deleted
       */

      const totalPages = Math.ceil(totalCount / IMAGES_PER_PAGE);
      const shouldReevaluate = state.page <= totalPages || state.page === 1;

      if (imageIds)
        return {
          ...state,
          // allPics: {
          //   ...state.allPics,
          //   [state.folderId]: allPics,
          // },
          // fullScreenImages: allPics,
          reevaluatePath: shouldReevaluate ? Math.random() * 1000 : 0,
          totalCount,
        };
    });
  },
});

export const {
  setImagesOfFolder,
  setMyImages,
  clearFolderImages,
  setFoldersPage,
  setAllImages,
  setImagesLoading,
  updateImgFavStatus,
  deleteImg,
  photographerPortfolio,
  setCoverImage,
  getAllVideos,
  patchVideoOfGroup,
  deleteOrRenameFolder,
  deleteImagesPortFolio,
  deleteMultipleImages,
  setFolderImageLoader,
  clearReevaluatePath,
  updateMultipleFavorite,
} = folderImages.actions;

export default folderImages.reducer;
