export const NOTIFICATIONS_TYPES = {
  CLOSE_FRIEND_REQUEST_RECEIVED: "closeFriendRequestReceived",
  CLOSE_FRIEND_REQUEST_ACCEPTED: "closeFriendRequestAccepted",

  DELETE_REQUEST: "deleteRequest",

  PHOTOS_DELETED: "photosDeleted",
  NEW_PHOTO_UPLOAD: "newPhotoUpload",
  NEW_MATCHING_PHOTOS: "newMatchingPhotosFound",

  NEW_VIDEO_UPLOAD: "newVideoUpload",
  JOIN_GROUP: "joinGroup",
  LEAVE_GROUP: "leaveGroup",

  UPLOAD_LIMIT_EXCEEDED: "uploadLimitExceed",

  TRANSFER_REQUEST_CREATED: "transferRequestCreated",
  TRANSFER_REQUEST_COMPLETED_SENDER: "transferRequestCompletedSender",
  TRANSFER_REQUEST_COMPLETED_RECEIVER: "transferRequestCompletedReceiver",
  TRANSFER_REQUEST_ACCEPTED_SENDER: "transferRequestAcceptedSender",
  TRANSFER_REQUEST_ACCEPTED_RECEIVER: "transferRequestAcceptedReceiver",
  TRANSFER_REQUEST_REJECTED_SENDER: "transferRequestRejectedSender",
  TRANSFER_REQUEST_REJECTED_RECEIVER: "transferRequestRejectedReceiver", // no handler
  TRANSFER_REQUEST_AUTO_ACCEPTED_SENDER: "transferRequestAutoAcceptedSender",
  TRANSFER_REQUEST_AUTO_ACCEPTED_RECEIVER:
    "transferRequestAutoAcceptedReceiver",

  DOWNLOAD_IS_AVAILABLE: "downloadIsAvailable",
  GROUP_NAME_CHANGED: "groupNameChanged",
  YOU_ARE_NOW_ADMIN: "youAreNowAdmin",
  PHOTOS_ACCESS_CHANGED: "photosAccessChanged",
  UPLOAD_PHOTO_ACCESS_GRANTED: "uploadPhotoAccessGranted",
};
