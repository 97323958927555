import React from 'react'

const CardWrapper = (props) => {
  return (
    <div className="card-wrapper">
        <div className="card-wrapper-icon">
            <img src={props.img} alt={props.img} />
        </div>
        <div className="card-wrapper-descripton">
            <h1>{props.header}</h1>
            <p>{props.description}</p>
        </div>
    </div>

  )
}

export default CardWrapper