import React from "react";
/**
 * @typedef {object} PlainButtonProps
 * @property {React.ReactNode} children - button children
 * @param {PlainButtonProps & React.HTMLAttributes<HTMLButtonElement>}
 */
const PlainButton = ({ title, children, onClick, className = "", ...rest }) => {
  return (
    <button
      onClick={onClick}
      className={`font-15 color-secondary font-bold bg-transparent p-0 ${className}`}
      {...rest}
    >
      {title || children}
    </button>
  );
};

export default PlainButton;
