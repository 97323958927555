import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialLoading: true,
  notificationsLoading: true,
  allNotificationsCount: 0,
  allNotifications: [],
  allNotificationsGroup: [],
  groupWiseNotifications: [],
  error: "",
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.notificationsLoading = action.payload;
    },
    setInitialLoader: (state, action) => {
      state.initialLoading = action.payload;
    },
    setAllNotifications: (state, action) => {
      const { notifications, count, page } = action.payload;
      state.allNotificationsCount = count || 0;

      if (page === 1) {
        state.allNotifications = notifications;
      } else {
        state.allNotifications = [...state.allNotifications, ...notifications];
      }
    },
    setGroupWiseNotifications: (state, action) => {
      state.groupWiseNotifications = action.payload;
    },
    setAllNotificationsGroup: (state, action) => {
      const { notifications, page } = action.payload;

      if (page === 1) {
        state.allNotificationsGroup = notifications;
      } else {
        state.allNotificationsGroup = [
          ...state.allNotificationsGroup,
          ...notifications,
        ];
      }
    },
  },
});

export default notifications.reducer;
export const notificationsActions = notifications.actions;
