import React from "react";
import { Pagination } from "react-bootstrap";
import css from "../../settings/css/ClientFavourite.module.scss";

const ClientFavPagination = ({ currentPage, setPage, totalPages }) => {
  return totalPages > 1 ? (
    <div className={css.pagination}>
      <Pagination className="justify-content-center mb-2">
        <Pagination.First
          onClick={() => setPage(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => currentPage > 1 && setPage(currentPage - 1)}
          disabled={currentPage === 1}
        />

        <Pagination.Item onClick={() => setPage(1)} disabled active>
          {currentPage}
        </Pagination.Item>

        <Pagination.Next
          onClick={() => currentPage < totalPages && setPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last
          onClick={() => setPage(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
      <p className="text-center">
        Page {currentPage} of {totalPages}
      </p>
    </div>
  ) : null;
};

export default ClientFavPagination;
