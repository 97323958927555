import { put, call, takeLatest } from "redux-saga/effects";
import { AXIOS2, AXIOS } from "../../utils/setup/axios";
import {
  GET_ANONYMOUS_USER_IMAGES,
  UPLOAD_ANONYMOUS_AVATAR,
} from "./saga-actions";
import {
  setAnonymousUserData,
  setLoading,
  setAnonymousUserImages,
} from "../slices/anonymous";
import { toast } from "react-toastify";
import {
  setGroupDesignaAndSettings,
  setGroupSettingsAndOtherSettings,
  setSponsors,
} from "redux-store/slices/settings";
import generateCipher from "utils/helpers/generateCipher";
import handleCipherErrors from "utils/helpers/handleCipherErrors";

async function uploadanonymousAvatar(action) {
  const cipher = generateCipher();
  const { avatar, groupId, adminToken, fGroupCode } = action?.payload || {};
  const formData = new FormData();
  formData.append("avatar", avatar);
  formData.append("groupId", groupId);
  if (adminToken) formData.append("adminToken", adminToken);
  else if (fGroupCode) formData.append("fGroupCode", fGroupCode);

  return AXIOS2.put("/api/app/user/upload-anonymous-avatar", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      cipher,
    },
  });
}

async function getAnonyousUserData(action) {
  const cipher = generateCipher();
  const { userId, groupId } = action?.payload || {};

  return AXIOS.get(
    `/api/app/user/get-anonymous-user/${userId}?groupId=${groupId}`,
    { headers: { cipher } }
  );
}

function* uploadAnonymousAvatarGenerator(action) {
  try {
    yield put(setLoading(true));
    const avatarResp = yield call(uploadanonymousAvatar, action);

    if (avatarResp?.data?.status === 200 || avatarResp?.status === 201) {
      toast.success(
        avatarResp?.data?.message || "Avatar Uploaded Successfully"
      );
      yield put(setAnonymousUserData(avatarResp?.data?.data));
    }
  } catch (e) {
    if (e?.response?.data?.reason || e?.response?.data?.message) {
      toast.error(e?.response?.data?.reason || e?.response?.data?.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* getAnonymousUserImagegenerator(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(getAnonyousUserData, action);

    if (response?.data?.status === 200 || response?.data?.status === 201) {
      yield put(setAnonymousUserImages(response?.data?.data));
      yield put(setAnonymousUserData(response?.data?.data));
      yield put(
        setGroupSettingsAndOtherSettings({
          status: response?.data?.status,
          data: response?.data?.data,
        })
      );
      yield put(
        setGroupDesignaAndSettings({
          status: response?.data?.status,
          data: response?.data?.data?.webSettings,
        })
      );
      yield put(
        setSponsors({
          data: {
            groupWebSettings: response.data?.data?.webSettings,
          },
        })
      );
    }
  } catch (e) {
    const handled = handleCipherErrors(e?.response?.data?.message || "");

    if (!handled)
      yield put(
        setAnonymousUserData({
          hasError: true,
          anonymousDisabled: e.response.data?.message?.includes(
            "anonymousAccess enabled"
          ),
        })
      );
  } finally {
    yield put(setLoading(false));
  }
}

export function* anonymousSaga() {
  yield takeLatest(UPLOAD_ANONYMOUS_AVATAR, uploadAnonymousAvatarGenerator);
  yield takeLatest(GET_ANONYMOUS_USER_IMAGES, getAnonymousUserImagegenerator);
}
