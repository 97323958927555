import React from "react";
import { useNavigate } from "react-router-dom";

const VideosMasony = ({ setImageView, videos, params, modifyGallaryRoute }) => {
  const navigate = useNavigate();
  //   const [params, setParams] = useSearchParams();
  return (
    <div>
      {videos.map((video, index) => {
        return (
          <li
            className={`photo-item`}
            // style={{ width: `${100 / 4}%` }}
            key={video?._id}
          >
            <div
              className="folder-imgs-image"
              style={{
                // width: `${imageWidth}px`,
                // height: `${imageHeight}px`,
                position: "relative",
              }}
              id={"image_container-" + video?._id}
            >
              <img
                src={video?.thumbnailUrl}
                onClick={() => {
                  navigate(modifyGallaryRoute({ videoId: video?._id }, params));
                  setImageView({
                    show: true,
                    selectedID: video?._id,
                    index,
                  });
                }}
              />
            </div>
          </li>
        );
      })}
    </div>
  );
};

export default VideosMasony;
