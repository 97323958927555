import React from "react";
import { Card, Modal, Stack } from "react-bootstrap";
import { getWhatsappMessage } from "utils/helpers/subscription";
import { useSelector } from "react-redux";
import Backdrop from "./components/Backdrop";

const PlanUpgrade = ({ show, hide }) => {
  const { slackMessage } = useSelector((state) => state.globals);

  return show ? (
    <>
      <Backdrop onClick={hide} />
      <Modal
        show={show}
        onHide={hide}
        style={{
          zIndex: 1101,
        }}
        centered
      >
        <Modal.Header closeButton className="border-0 mx-auto">
          <Modal.Title className="bold-font">Change Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="font-base text-center pt-0">
          <Stack gap={3}>
            <p className="m-0">
              To modify your paid subscription, please contact us via:
            </p>
            <Card
              onClick={() => {
                window.open(getWhatsappMessage(slackMessage));
              }}
              className="p-3 border-0 d-flex flex-row align-items-center cursor-pointer"
            >
              <img
                src="/assets/icons/social/whatsapp.png"
                className="social-icon mx-4"
              />
              <span>WhatsApp</span>
            </Card>
            <Card
              onClick={() =>
                window.open(
                  `mailto:sales@kwikpic.in?subject=Plan Change&body=${slackMessage}`
                )
              }
              className="p-3 border-0 d-flex flex-row align-items-center cursor-pointer"
            >
              <img
                src="/assets/icons/social/email.png"
                className="social-icon mx-4"
              />
              <span>Email</span>
            </Card>
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  ) : null;
};

export default PlanUpgrade;
