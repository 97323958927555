import { ERROR_TYPES_404 } from "views/notFound/NotFound";

/**
 * @param {string} message
 * @returns {boolean}
 */
const handleCipherErrors = (message) => {
  let handled = false; // whether the error was handled or not

  if (message.includes("This request has expired")) {
    // just reload
    handled = true;
    window.location.reload();
  } else if (message.includes("Unauthorized request")) {
    handled = true;
    window.history.replaceState(
      {
        type: ERROR_TYPES_404.CIPHER_ERROR_UNAUTHORIZED,
      },
      null,
      "/not-found"
    );
    window.location.reload();
  } else if (message?.includes("Please sync device time and try again")) {
    handled = true;
    window.history.replaceState(
      {
        type: ERROR_TYPES_404.CIPHER_ERROR_TIMESTAMP,
      },
      null,
      "/not-found"
    );
    window.location.reload();
  } else {
    // do nothing
  }

  return handled;
};

export default handleCipherErrors;
