import React from "react";

const DefaultSwitch = ({
  onChange,
  className,
  name = "",
  isChecked = false,
}) => {
  return (
    <label className={`switch ${className}`}>
      <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={onChange}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default DefaultSwitch;
