import React from "react";

const ConfirmationButton = ({
  title,
  type,
  onClick,
  bgColor,
  className,
  btnRef,
  disabled,
}) => {
  return (
    <button
      type={type ?? "button"}
      onClick={onClick}
      className={className}
      style={{
        backgroundColor: bgColor,
      }}
      ref={btnRef}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default ConfirmationButton;
