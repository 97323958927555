import React from "react";
import css from "../index.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { detect } from "detect-browser";
import Slider from "react-slick";

const Pricing = ({ country }) => {
  const getLink = () => {
    return detect()?.os == "android" || detect()?.os == "Android OS"
      ? "https://play.google.com/store/apps/details?id=com.locaFlashKwikpic"
      : "https://apps.apple.com/us/app/locaflash/id6466164299";
  };

  return (
    <Container id="plans" fluid className={css.pricingContainer}>
      <Container>
        <Row className="mb-3 mb-md-4">
          <Col className="mx-auto text-center position-relative">
            <div>
              <h2 className="font-24">Plans & Pricing</h2>
              <img
                className="mb-3"
                src="/assets/images/landing/yellow-divider.png"
                alt="Yellow-divider"
              />
            </div>
            <a className={css.salesBtn} href="#contact">
              Contact Sales
            </a>
          </Col>
        </Row>
        <Row>
          <Col className={css.plans}>
            <span className={css.step}>Plans</span>
            <span className={css.step}>Add-Ons (Optional)</span>

            <div className={css.choose}>
              <div>
                <h2 className="font-24 font-bold mb-2">
                  Choose a Subscription Plan
                </h2>
                <p className={css.muted}>
                  Elevate your Kwikpic experience by diving into our premium
                  features. Subscribe now and unlock the limitless potential of
                  our platform.
                </p>
              </div>

              <div className="d-none d-lg-block">
                <p className="font-15 font-bold mt-5">
                  Included Unlimited in every plan
                </p>
                <img
                  src="/assets/images/country-landing/uk/features.png"
                  alt="features"
                />
              </div>
            </div>
            <div className={css.plansContainer}>
              <div className="w-100">
                <Slider
                  slidesToShow={2}
                  centerMode={true}
                  autoplay={true}
                  swipeToSlide={true}
                  autoplaySpeed={2500}
                  initialSlide={country === "in" ? 2 : 0}
                  responsive={[
                    {
                      breakpoint: 1192,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 992,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: "20px",
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: "60px",
                      },
                    },
                    {
                      breakpoint: 576,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        arrows: false,
                        dots: true,
                        dotsClass: `${css.dots} slick-dots`,
                      },
                    },
                  ]}
                >
                  {country === "in" && (
                    <div className={css.plan}>
                      <img
                        src={`/assets/images/country-landing/${country}/basic.png`}
                        alt="basic plan"
                      />
                    </div>
                  )}
                  {country === "in" && (
                    <div className={css.plan}>
                      <img
                        src={`/assets/images/country-landing/${country}/standard.png`}
                        alt="standard plan"
                      />
                    </div>
                  )}
                  <div className={css.plan}>
                    <img
                      src={`/assets/images/country-landing/${country}/essential.png`}
                      alt="essential plan"
                    />
                  </div>
                  <div className={css.plan}>
                    <img
                      src={`/assets/images/country-landing/${country}/premium.png`}
                      alt="premium plan"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <h2 className="font-24 font-bold mb-2">
              OPTIONAL - Personalised Website, iOS and Android App
            </h2>
            <p className={css.muted}>
              Enhance your digital presence further with a Personalised Website
              and Mobile App for a nominal one-time fee.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-wrap flex-md-nowrap gap-4">
            <div className="d-flex flex-column gap-2">
              <img
                src={`/assets/images/country-landing/${country}/web.png`}
                alt="website services"
                className="w-100"
              />
              <a
                className={css.tryBtn}
                href="https://locaflash.kwikpic.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                Try Website Now
              </a>
            </div>
            <div className="d-flex flex-column gap-2">
              <img
                src={`/assets/images/country-landing/${country}/app.png`}
                alt="app services"
                className="w-100"
              />
              <a
                className={css.tryBtn}
                href={getLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Try App Now
              </a>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <p className={css.muted}>
              *Note: Additional charges apply for updates.
            </p>
            <p className="font-base mb-0 mt-2 d-flex align-items-center gap-2">
              <img
                src="/assets/images/icons/info-white.png"
                alt="info"
                className="hw-18px"
              />
              <span>
                Opting for a personalized website or app is Not Mandatory. You
                can simply use the subscription on Kwikpic&apos;s website and
                app
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Pricing;
